import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import TextField from 'components/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import dotProp from 'dot-prop-immutable'
import PropTypes from 'prop-types'
import styles from './styles'

class GroupProperties extends Component {
  handleChange = event => {
    const { onChange, propertiesList, propertyName } = this.props
    const { id, value } = event.target
    onChange(propertyName, dotProp.set(propertiesList, id, value))
  }

  removeProperty = index => () => {
    const { onChange, propertiesList, propertyName } = this.props
    const newSkus = dotProp.delete(propertiesList, index)
    onChange(propertyName, newSkus)
  }

  renderGroupProperties = (propertiesList, property) =>
    propertiesList.map((sku, index) => (
      <Grid container direction="row" spacing={16} alignItems="center">
        <Grid item xs={8}>
          <TextField
            label={`${property} ${index + 1}`}
            margin="normal"
            id={index}
            index={index}
            value={sku}
            handleChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <IconButton aria-label="Delete" onClick={this.removeProperty(index)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ))

  render() {
    const { propertiesList, addProperty, classes, propertyName } = this.props
    const property = propertyName === 'includedSku' ? 'SKU' : 'Regra'
    return (
      <Grid
        container
        direction="row"
        spacing={16}
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Typography variant="h6">{`${property}s`}</Typography>
        </Grid>
        <Grid item>
          <Fab
            size="small"
            color="primary"
            aria-label="Add"
            onClick={addProperty}
          >
            <AddIcon className={classes.icon} />
          </Fab>
        </Grid>
        {this.renderGroupProperties(propertiesList, property)}
      </Grid>
    )
  }
}

GroupProperties.propTypes = {
  addProperty: PropTypes.func,
  propertiesList: PropTypes.array,
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  propertyName: PropTypes.string,
}

GroupProperties.defaultProps = {
  addProperty: () => {},
  propertiesList: [],
  onChange: () => {},
  propertyName: '',
}

export default withStyles(styles)(GroupProperties)
