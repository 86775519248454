import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from '.'

export const feedstockSearchTitle = (
  <FormattedMessage {...defineMessages.feedstockSearchTitle} />
)

export const tableHeader = [
  <FormattedMessage {...defineMessages.code} />,
  <FormattedMessage {...defineMessages.name} />,
  <FormattedMessage {...defineMessages.stockType} />,
  <FormattedMessage {...defineMessages.price} />,
]
