import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'

export const FormContext = React.createContext()

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      validators: {},
    }
  }

  getErrors() {
    const { validators } = this.state
    const allErrors = Object.entries(validators).reduce(
      (partialErrors, [_, { errors }]) => [...partialErrors, ...errors],
      []
    )
    return allErrors
  }

  handleRegister = (id, validateFunction) => {
    this.setState(state => ({
      validators: {
        ...state.validators,
        [id]: {
          errors: [],
          validator: validateFunction,
        },
      },
    }))
    return this.handleStatusChange(id)
  }

  handleRemoveRegister = id => {
    const newValidators = { ...this.state.validators }
    delete newValidators[id]
    this.setState(_state => ({ validators: newValidators }))
  }

  handleStatusChange = id => errors => {
    this.setState(state => ({
      validators: {
        ...state.validators,
        [id]: { errors, validator: state.validators[id].validator },
      },
    }))
  }

  validateAll = () => {
    const { validators } = this.state
    const errors = Object.values(validators).reduce(
      (partialErrors, { validator }) => [...partialErrors, ...validator()],
      []
    )

    if (errors.length > 0) {
      this.props.enqueueSnackbar(
        `Erro: Verifique se todos os campos obrigatórios (*) foram preenchidos corretamente`,
        {
          variant: 'error',
          horizontal: 'left',
          preventDuplicate: true,
        }
      )
      return false
    }
    return true
  }

  render() {
    const { children } = this.props
    return (
      <Fragment>
        <FormContext.Provider
          value={{
            registerValidator: this.handleRegister,
            removeValidator: this.handleRemoveRegister,
            validateAll: this.validateAll,
            getErrors: this.getErrors,
          }}
        >
          {children}
        </FormContext.Provider>
      </Fragment>
    )
  }
}

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default withSnackbar(Form)
