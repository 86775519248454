import styled from 'styled-components'
import Button from '../../../../components/Button'
import Input from '../../../components/atoms/input'
import { Lookup } from '../../../components/molecules/lookup'

export const KitchensSelect = styled(Lookup)`
  && {}
`

export const InputForm = styled(Input)`
  && {
    width: 100%;
  }
`

export const ActionsFooter = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 16px;
  }
`

export const SaveButton = styled(Button)`
  font-family: ${props => props.theme.v2.typography.fontFamily};
  font-size: ${props => props.theme.v2.typography.fontSize.small};
  background-color: #0d8059;
  color: #ffffff;
  font-weight: bold;
  border-radius: 2px;

  :hover {
    background-color: #084d35;
  }
`
