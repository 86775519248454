import React, { Component } from 'react'
import dotProp from 'dot-prop-immutable'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputField from 'components/InputField'
import DateSelect from 'components/DateSelect'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CardBox from 'components/CardBox'
import { styles } from '../styles'

class SearchPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: undefined,
      feedstock: undefined,
      supplier: undefined,
      kitchenOrigin: '',
      fromDate: undefined,
      untilDate: undefined,
    }
  }

  handleChange = (fieldName, field) => {
    const state = dotProp.set(this.state, fieldName, field)
    this.setState(state)
  }

  handleKitchenSelect = event => {
    this.setState({ ...this.state, kitchenOrigin: event.target.value })
  }

  handleFromDate = date => {
    const state = dotProp.set(this.state, 'fromDate', date)
    this.setState(state)
  }

  handleUntilDate = date => {
    const state = dotProp.set(this.state, 'untilDate', date)
    this.setState(state)
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.onSearchSubmit(this.state)
  }

  renderKitchenOptions = () => {
    const { kitchens, loadingKitchens } = this.props
    if (loadingKitchens) {
      return <CircularProgress />
    }
    return [<MenuItem value="" />].concat(
      kitchens.map(({ id, name }) => (
        <MenuItem value={id} name={name}>
          {name}
        </MenuItem>
      ))
    )
  }

  render() {
    return (
      <CardBox styleName="col-sm-10">
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={16} alignItems="center">
            <Grid item xs={6}>
              <InputField
                value={this.state.code}
                onChange={this.handleChange}
                label="Código do Recebimento"
                fieldName="code"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl className={this.props.classes.formControl}>
                <InputLabel>Local de Recebimento</InputLabel>
                <Select
                  value={this.state.kitchenOrigin}
                  onChange={this.handleKitchenSelect}
                >
                  {this.renderKitchenOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputField
                value={this.state.supplier}
                onChange={this.handleChange}
                label="Código ou Nome do Fornecedor"
                fieldName="supplier"
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                value={this.state.feedstock}
                onChange={this.handleChange}
                label="Código ou Nome da Matéria-Prima"
                fieldName="feedstock"
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelect
                onSelect={this.handleFromDate}
                label="Data Esperada de"
                selectedDate={this.state.fromDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelect
                onSelect={this.handleUntilDate}
                label="até"
                selectedDate={this.state.untilDate}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Button
                  variant="raised"
                  color="primary"
                  type="submit"
                  className="jr-btn text-blue-gray"
                >
                  Buscar
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </CardBox>
    )
  }
}

SearchPanel.propTypes = {
  onSearchSubmit: PropTypes.func.isRequired,
  kitchens: PropTypes.array.isRequired,
  loadingKitchens: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchPanel)
