import React, { ChangeEvent } from 'react'

import TextField, { TextFieldProps } from '@material-ui/core/TextField'

type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'type' | 'variant'> & {
  variant: any
  precision: number
  value: number
  onChange: (value: number) => void
}

function FixedPrecisionInput(props: Props) {
  const { value, onChange } = props

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const integerValue = Number(e.target.value.replace(/(?!\d)./g, ''))
    const numericValue = integerValue / 10 ** props.precision

    onChange && onChange(numericValue)
  }

  const formattedValue = value
    ? value.toLocaleString('pt-BR', {
        minimumFractionDigits: props.precision,
      })
    : ''
  return (
    <TextField
      {...props}
      type="text"
      value={formattedValue}
      onChange={handleChange}
    />
  )
}

export default FixedPrecisionInput
