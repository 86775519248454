export const RECIPE_PROCESS_NAMES = [
  'DESEMBALAGEM',
  'HIGIENIZAÇÃO',
  'LIMPEZA',
  'PROCESSAMENTO PRÉ-COCÇÃO',
  'TEMPERO E MISTURA PRÉ-COCÇÃO',
  'MODELAGEM MANUAL',
  'PRÉ-COCÇÃO',
  'EMPANAMENTO',
  'MODELAGEM MÁQUINA',
  'COCÇÃO',
  'PROCESSAMENTO PÓS-COCÇÃO',
  'TEMPERO E MISTURA PÓS-COCÇÃO',
  'CONGELAMENTO',
  'PORCIONAMENTO',
  'SELAGEM',
]

export const RECIPE_PROCESS_TYPES = ['Pré-Cocção', 'Cocção']

export const RECIPE_PROCESS_RELATIONS = [
  {
    name: RECIPE_PROCESS_NAMES[0],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[1],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[2],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[3],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[4],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[5],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[6],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[7],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[8],
    type: RECIPE_PROCESS_TYPES[0],
  },
  {
    name: RECIPE_PROCESS_NAMES[8],
    type: RECIPE_PROCESS_TYPES[1],
  },
  {
    name: RECIPE_PROCESS_NAMES[9],
    type: RECIPE_PROCESS_TYPES[1],
  },
  {
    name: RECIPE_PROCESS_NAMES[10],
    type: RECIPE_PROCESS_TYPES[1],
  },
  {
    name: RECIPE_PROCESS_NAMES[11],
    type: RECIPE_PROCESS_TYPES[1],
  },
  {
    name: RECIPE_PROCESS_NAMES[12],
    type: RECIPE_PROCESS_TYPES[1],
  },
  {
    name: RECIPE_PROCESS_NAMES[13],
    type: RECIPE_PROCESS_TYPES[1],
  },
  {
    name: RECIPE_PROCESS_NAMES[14],
    type: RECIPE_PROCESS_TYPES[1],
  },
]
