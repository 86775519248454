import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { NutritionalInfoSources } from '../../../contracts/nutritionalInfo'
import { IconButton } from '../../atoms/iconButton/'
import {
  ActionInputContainer,
  ActionInputLookup,
  IconButtonBorder,
  IconButtonContainer,
  StyledInput,
} from './styles'
import { LookupItem } from '../../../contracts/lookupItem'
export interface ActionInputProps {
  placeholder: string
  icon: ReactElement
  required?: boolean
  disabled?: boolean
  onIconClick: (inputValue: string) => void
  options?: any[]
  onSelect?: (option: any) => void
  nutritionalInfoSource?: NutritionalInfoSources
}

export const ActionInput = ({ onSelect, ...props }: ActionInputProps) => {
  const [inputValue, setInput] = useState()
  const [actionInput, setActionInput] = useState(true)
  const [options, setOptions] = useState()
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const [nutritionalInfoSource, setNutritionalInfoSource] = useState(
    props.nutritionalInfoSource
  )
  const handleChange = (event: any) => {
    const {
      currentTarget: { value },
    } = event
    setInput(value)
  }
  const handleSubmit = (event: any) => {
    const {
      charCode,
      target: { value },
    } = event
    if (charCode === 13) {
      props.onIconClick(value)
    }
  }
  const handleClickIcon = () => {
    props.onIconClick(inputValue)
  }

  const handleSelect = (option: LookupItem) => {
    if (!option) {
      setActionInput(true)
    } else {
      onSelect(option)
    }
    setMenuIsOpen(false)
  }

  useEffect(() => {
    if (
      props.options &&
      props.options.length > 0 &&
      JSON.stringify(props.options) !== JSON.stringify(options)
    ) {
      setOptions(props.options)
      setActionInput(false)
    }
    if (props.nutritionalInfoSource !== nutritionalInfoSource) {
      setNutritionalInfoSource(props.nutritionalInfoSource)
      setActionInput(true)
    }
  })

  const menuProps = {
    ...(menuIsOpen && { menuIsOpen }),
  }

  return (
    <ActionInputContainer>
      {!actionInput && options ? (
        <ActionInputLookup
          options={props.options}
          onSelect={handleSelect}
          placeholder="Escolha"
          isClearable
          {...menuProps}
        />
      ) : (
        <Fragment>
          <StyledInput
            {...props}
            label={props.placeholder}
            onKeyPress={handleSubmit}
            onChange={handleChange}
          />
          <IconButtonContainer>
            <IconButton icon={props.icon} onClick={handleClickIcon} />
            <IconButtonBorder />
          </IconButtonContainer>
        </Fragment>
      )}
    </ActionInputContainer>
  )
}
