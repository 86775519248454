import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import styled from 'styled-components'

export const TextField = styled(props => <MuiTextField {...props} />)`
  && div[class*='focused'] {
    fieldset {
      border-color: ${props => props.theme.v2.palette.primary.darker};
    }
  }
  && label[class*='shrink'] {
    color: ${props => props.theme.v2.palette.primary.darker};
  }
`
