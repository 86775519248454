import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from '../Messages/index'

export const recipeCreationTitle = (
  <FormattedMessage {...defineMessages.recipeCreation} />
)

export const recipeInfoTitle = (
  <FormattedMessage {...defineMessages.recipeInfo} />
)

export const recipeNameTitle = (
  <FormattedMessage {...defineMessages.recipeName} />
)

export const recipeYieldTitle = (
  <FormattedMessage {...defineMessages.recipeYield} />
)

export const recipeWasteTitle = (
  <FormattedMessage {...defineMessages.recipeWaste} />
)

export const portionSizeTitle = (
  <FormattedMessage {...defineMessages.portionSize} />
)

export const portionQuantityTitle = (
  <FormattedMessage {...defineMessages.portionQuantity} />
)

export const referenceGrammageTitle = (
  <FormattedMessage {...defineMessages.referenceGrammage} />
)

export const batchSizeTitle = <FormattedMessage {...defineMessages.batchSize} />

export const packageTypeTitle = (
  <FormattedMessage {...defineMessages.packageType} />
)

export const typeTitle = <FormattedMessage {...defineMessages.type} />

export const ingredientsTypeTitle = (
  <FormattedMessage {...defineMessages.ingredientsType} />
)

export const skuButtonTitle = <FormattedMessage {...defineMessages.sku} />

export const versionNumberTitle = (
  <FormattedMessage {...defineMessages.versionNumber} />
)

export const skuButtonAction = (
  <FormattedMessage {...defineMessages.skuAction} />
)

export const containsTitle = <FormattedMessage {...defineMessages.contains} />

export const glutenOption = <FormattedMessage {...defineMessages.gluten} />

export const lactoseOption = <FormattedMessage {...defineMessages.lactose} />

export const classificationOption = (
  <FormattedMessage {...defineMessages.classification} />
)

export const allergenicsTitle = (
  <FormattedMessage {...defineMessages.allergenics} />
)

export const allergenicsContains = (
  <FormattedMessage {...defineMessages.allergenicsContains} />
)

export const allergenicsMayContain = (
  <FormattedMessage {...defineMessages.allergenicsMayContain} />
)

export const sealingSettings = (
  <FormattedMessage {...defineMessages.sealingSettings} />
)

export const sealingType = <FormattedMessage {...defineMessages.sealingType} />

export const sealingTemperatureTitle = (
  <FormattedMessage {...defineMessages.sealingTemperature} />
)

export const sealingFreezingQuantity = (
  <FormattedMessage {...defineMessages.sealingFreezingQuantity} />
)

export const cookingTimesTitle = (
  <FormattedMessage {...defineMessages.cookingTimes} />
)

export const cookingTimeMinTitle = (
  <FormattedMessage {...defineMessages.cookingTimeMin} />
)

export const cookingTimeMaxTitle = (
  <FormattedMessage {...defineMessages.cookingTimeMax} />
)

export const productionLocationTitle = (
  <FormattedMessage {...defineMessages.productionLocation} />
)
