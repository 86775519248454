import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import CardBox from 'components/CardBox'
import PropTypes from 'prop-types'
import Form from 'components/Form'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Link from '@material-ui/core/Link'
import { Typography } from '@material-ui/core'
import {
  addRecipeStep,
  removeRecipeStep,
  handleChangeRecipeData,
  handleChangeRecipeStep,
  handleSelectRecipeStep,
  fetchProductionRecipe,
  createProductionRecipe,
  setRecipeToRegister,
  editProductionRecipe,
} from 'actions/ProductionRecipe'
import { fetchSelectResources } from 'actions/Resources'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorBox from 'components/ErrorBox'
import ProductionInfoForms from '../../components/ProductionInfoForms'
import RecipeSteps from '../../components/RecipeSteps'
import ActionButtons from '../../components/ActionButtons'
import { recipeSteps, addStep } from '../../../formattedMessages'
import styles from './styles'

const complexityRange = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
]

class RegisterProductionRecipeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSku: props.match.params.sku ? props.match.params.sku : '',
      isVisualizing: !!props.match.params.sku,
      isRegistering: !props.match.params.sku,
      isEditing: !!props.match.params.sku,
    }
  }

  componentDidMount() {
    this.props.fetchSelectResources()
    if (this.state.selectedSku && this.state.selectedSku !== '') {
      this.props.fetchProductionRecipe(this.state.selectedSku)
    } else {
      this.props.setRecipeToRegister()
    }
  }

  getRecipeSteps = () => {
    const { selectResources } = this.props
    const recipe = this.state.isRegistering
      ? this.props.registerRecipe
      : this.props.editRecipe
    return (
      recipe.data &&
      recipe.data.stepsByResource &&
      recipe.data.stepsByResource.map((step, index) => (
        <RecipeSteps
          key={index}
          removeStep={this.removeRecipeStep}
          productionResources={selectResources.data}
          complexityRange={complexityRange}
          index={index}
          handleSelect={this.handleSelect}
          onChange={this.handleChangeRecipeStep}
          selectedItem={recipe.data.stepsByResource}
          isVisualizing={this.state.isVisualizing}
          isRegistering={this.state.isRegistering}
        />
      ))
    )
  }

  handleChangeRecipeStep = (event, index) => {
    const { id, value } = event.target
    const { isEditing } = this.state
    this.props.handleChangeRecipeStep(id, value, index, isEditing)
  }

  handleChangeData = event => {
    const { id, value } = event.target
    const { isEditing } = this.state
    this.props.handleChangeRecipeData(id, value, isEditing)
  }

  handleSelect = (index, name, value) => {
    const { isEditing } = this.state
    this.props.handleSelectRecipeStep(name, value, index, isEditing)
  }

  addRecipeStep = () => {
    const { isEditing, isVisualizing } = this.state
    if (!isVisualizing) {
      this.props.addRecipeStep(isEditing)
    }
  }

  removeRecipeStep = index => {
    const { isEditing } = this.state
    this.props.removeRecipeStep(index, isEditing)
  }

  toggleIsVisualizing = () => {
    this.setState(prevState => ({
      isVisualizing: !prevState.isVisualizing,
    }))
  }

  render() {
    const recipe = this.state.isEditing
      ? this.props.editRecipe
      : this.props.registerRecipe
    if (recipe.isLoading || this.props.selectResources.isLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (recipe.error || this.props.selectResources.error) {
      return <ErrorBox />
    }
    if (recipe.data && this.props.selectResources.data) {
      return (
        <CardBox styleName="col-sm-12">
          <Form submitText="Criar Receita">
            <Grid container>
              <ProductionInfoForms
                onChange={this.handleChangeData}
                selectedItem={recipe.data}
                isVisualizing={this.state.isVisualizing}
                isRegistering={this.state.isRegistering}
              />
              <Typography
                className={this.props.classes.title}
                variant="subtitle1"
              >
                {recipeSteps}
              </Typography>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link
                    variant="subtitle1"
                    onClick={this.addRecipeStep}
                    className={this.props.classes.addStep}
                  >
                    {addStep}
                  </Link>
                </Grid>
              </Grid>
              {this.getRecipeSteps()}
              <ActionButtons
                isRegistering={this.state.isRegistering}
                onEditClick={this.toggleIsVisualizing}
                onSaveClick={this.props.createProductionRecipe}
                onSaveEditionClick={this.props.editProductionRecipe}
              />
            </Grid>
          </Form>
        </CardBox>
      )
    }
  }
}

const mapStateToProps = state => ({
  editRecipe: state.productionRecipes.editRecipe,
  registerRecipe: state.productionRecipes.registerRecipe,
  selectResources: state.resources.selectResources,
})

const mapActionsToProps = {
  addRecipeStep,
  removeRecipeStep,
  handleChangeRecipeData,
  handleChangeRecipeStep,
  handleSelectRecipeStep,
  fetchProductionRecipe,
  createProductionRecipe,
  setRecipeToRegister,
  editProductionRecipe,
  fetchSelectResources,
}

RegisterProductionRecipeContainer.propTypes = {
  addRecipeStep: PropTypes.func.isRequired,
  removeRecipeStep: PropTypes.func.isRequired,
  handleChangeRecipeData: PropTypes.func.isRequired,
  handleChangeRecipeStep: PropTypes.func.isRequired,
  handleSelectRecipeStep: PropTypes.func.isRequired,
  fetchProductionRecipe: PropTypes.func.isRequired,
  createProductionRecipe: PropTypes.func.isRequired,
  editProductionRecipe: PropTypes.func.isRequired,
  setRecipeToRegister: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  editRecipe: PropTypes.object,
  registerRecipe: PropTypes.object,
  selectResources: PropTypes.object.isRequired,
  fetchSelectResources: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sku: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

RegisterProductionRecipeContainer.defaultProps = {
  editRecipe: {},
  registerRecipe: {},
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(RegisterProductionRecipeContainer))
)
