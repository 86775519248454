import MuiEditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

import { Typography } from 'src/v2/components/atoms'

export const Title = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-weight: bold;
  }
`

export const ActionsContainer = styled.div`
  && {
    display: flex;
    :hover {
      cursor: pointer;
    }
  }
`

export const EditLabel = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props => props.theme.v2.palette.primary.darker};
    font-weight: bold;
  }
`

export const EditIcon = styled(MuiEditIcon)`
  && {
    color: ${props => props.theme.v2.palette.primary.darker};
    margin-left: 4px;
  }
`
