export function stringLiteralArray<T extends string>(a: T[]) {
  return a
}

export const nutritionalInfoSources = stringLiteralArray([
  'TACO',
  'USDA',
  'Preenchimento manual',
])

export type NutritionalInfoSources = typeof nutritionalInfoSources[number]

export type NutritionaInfoDataNames =
  | 'sodium'
  | 'fibers'
  | 'transFats'
  | 'satFats'
  | 'totalFats'
  | 'proteins'
  | 'carbs'
  | 'kcal'
  | 'totalSugars'
  | 'addedSugars'

export type NutritionaInfoDisplayNames =
  | 'Sódio (mg)'
  | 'Fibras (g)'
  | 'Gorduras trans (g)'
  | 'Gorduras sat. (g)'
  | 'Gorduras totais (g)'
  | 'Proteínas (g)'
  | 'Carboidratos (g)'
  | 'Calorias (kcal)'
  | 'Açúcares totais (g)'
  | 'Açúcares adicionados (g)'

export type NutritionalInfo = {
  [Key in NutritionaInfoDataNames]: any
}

export interface NutritionalInfoDisplayObject {
  id: NutritionaInfoDataNames
  name: NutritionaInfoDisplayNames
  portionValue: string
}

export const nutritionalInfoMap: Array<
  [NutritionaInfoDataNames, NutritionaInfoDisplayNames]
> = [
  ['sodium', 'Sódio (mg)'],
  ['fibers', 'Fibras (g)'],
  ['transFats', 'Gorduras trans (g)'],
  ['satFats', 'Gorduras sat. (g)'],
  ['totalFats', 'Gorduras totais (g)'],
  ['proteins', 'Proteínas (g)'],
  ['carbs', 'Carboidratos (g)'],
  ['kcal', 'Calorias (kcal)'],
  ['totalSugars', 'Açúcares totais (g)'],
  ['addedSugars', 'Açúcares adicionados (g)'],
]
