import styled from 'styled-components'
import { StyledButton } from './styles'

export const GhostButton = styled(StyledButton).attrs({
  classes: { label: 'label' },
})`
  & .label {
    font-weight: bold;
    color: ${props =>
      props.disabled
        ? props.theme.v2.palette.grayScale['40']
        : props.theme.v2.palette[props.color].light};
  }
`
