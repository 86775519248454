import MuiEditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

import { Paper, Typography } from '../../../components/atoms'

export const Card = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    margin-bottom: 8px;
  }
`

export const Title = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-weight: bold;
  }
`

export const Feedstock = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props => props.theme.v2.palette.grayScale['60']};
  }
`

export const Status = styled(Typography) <{ active: boolean }>`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props =>
    props.active
      ? props.theme.v2.palette.success.regular
      : props.theme.v2.palette.error.regular};
  }
`

export const ActionsContainer = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    :hover {
      cursor: pointer;
    }
  }
`

export const EditLabel = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props => props.theme.v2.palette.primary.darker};
    font-weight: bold;
  }
`

export const EditIcon = styled(MuiEditIcon)`
  && {
    color: ${props => props.theme.v2.palette.primary.darker};
    margin-left: 4px;
  }
`
