import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  createMuiTheme,
  MuiThemeProvider,
  jssPreset,
} from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import JssProvider from 'react-jss/lib/JssProvider'
import MomentUtils from 'material-ui-pickers/utils/moment-utils'
import { v2 } from '../../src/v2/theme'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import 'styles/app.scss'
import 'styles/global.scss'
import MainApp from 'routes/index'
import NotificationCenter from 'containers/NotificationCenter'

import { create } from 'jss'

import defaultTheme from './themes/defaultTheme'

import AppLocale from '../languageProvider'

import Login from './Login'

function createGenerateClassName() {
  let ruleCounter = 0

  return (rule, sheet) => {
    ruleCounter += 1

    if (sheet && sheet.options.meta) {
      return `${sheet.options.meta}-${rule.key}-${ruleCounter}`
    }

    return `${rule.key}-${ruleCounter}`
  }
}

const generateClassName = createGenerateClassName()
const jss = create({
  ...jssPreset(),
  // Define a custom insertion for injecting the JSS styles in the DOM
  insertionPoint: document.getElementById('jss-insertion-point'),
})

class App extends Component {
  render() {
    const { match, location, locale, authUser, initURL } = this.props

    if (location.pathname === '/') {
      return <Redirect to="/app" />
    }

    console.log('connecting with ')
    const currentAppLocale = AppLocale[locale.locale]
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={createMuiTheme({ ...defaultTheme, v2 })}>
          <ThemeProvider theme={createMuiTheme({ ...defaultTheme, v2 })}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <IntlProvider
                locale={currentAppLocale.locale}
                defaultLocale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
              >
                <div className="app-main">
                  <NotificationCenter />
                  <Route path="/login" component={Login} authUser={authUser} />
                  <Route path="/app" component={MainApp} authUser={authUser} />
                </div>
              </IntlProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </JssProvider>
    )
  }
}

App.propTypes = {
  authUser: PropTypes.object,
  initURL: PropTypes.string,
}

App.defaultProps = {
  authUser: null,
  initURL: '/',
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale } = settings
  const { authUser, initURL } = auth
  return { sideNavColor, locale, authUser, initURL }
}

export default connect(mapStateToProps)(App)
