import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    marginBottom: '12px',
  },
  breadcrumb: {
    '&:hover': {
      cursor: 'pointer',
      color: '#02004b',
      textDecoration: 'underline',
    },
  },
  bold: {
    fontWeight: '600',
  },
})

class Breadcrumbs extends PureComponent {
  handleBreadcrumbClick = event => {
    const { id } = event.target
    const { handleBreadcrumbClick } = this.props
    handleBreadcrumbClick(id)
  }

  render() {
    const { breadcrumbs, classes } = this.props
    return (
      <Grid container direction="row" className={classes.root} spacing={8}>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <Grid item>
                  <Typography variant="subtitle1">{'>'}</Typography>
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="subtitle1"
                  id={breadcrumb.path}
                  onClick={this.handleBreadcrumbClick}
                  className={classNames(
                    classes.breadcrumb,
                    index === breadcrumbs.length - 1 && classes.bold
                  )}
                >
                  {breadcrumb.name}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    )
  }
}
Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  handleBreadcrumbClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(Breadcrumbs)
