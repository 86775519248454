import Select, { components } from 'react-select'
import styled from 'styled-components'

export const DropdownIndicator = styled(components.DropdownIndicator)`
  && {
    color: ${props => props.theme.palette.grayScale['80']};
  }
`

export const Placeholder = styled(components.Placeholder)`
  && {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
  }
`

export const StyledSelect = styled(Select)`
  && {
    min-height: 56px;
    height: 100%;
    div[class*='-control'] {
      min-height: 56px;
      height: 100%;
    }
    div[class*='-menu'] {
      z-index: 10;
    }
  }
`

export const SelectContainer = styled.div`
  && {
    position: relative;
  }
`
