import ContainerHeader from 'components/ContainerHeader/index'
import React from 'react'
import IntlMessages from 'utils/IntlMessages'

class SamplePage extends React.Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={<IntlMessages id="pages.home" />}
        />
        <div className="d-flex justify-content-center">
          <h1>Liv Up Kitchen</h1>
        </div>
      </div>
    )
  }
}

export default SamplePage
