import { defineMessages } from 'react-intl'

export default defineMessages({
  feedstockSearchTitle: {
    id: 'app.feedstock.search.title',
    defaultMessage: 'Busca de matérias primas',
  },
  filterTitle: {
    id: 'app.feedstock.search.filterTitle',
    defaultMessage: 'Filtrar',
  },
  filterPlaceholder: {
    id: 'app.feedstock.search.filterPlaceholder',
    defaultMessage: 'Nome ou código',
  },
  filterButtonTitle: {
    id: 'app.feedstock.search.filterButtonTitle',
    defaultMessage: 'Filtrar',
  },
  code: {
    id: 'app.feedstock.search.code',
    defaultMessage: 'Código',
  },
  name: {
    id: 'app.feedstock.search.name',
    defaultMessage: 'Nome',
  },
  stockType: {
    id: 'app.feedstock.search.stockType',
    defaultMessage: 'Tipo de estoque',
  },
  price: {
    id: 'app.feedstock.search.price',
    defaultMessage: 'Preço (R$)',
  },
  registerDate: {
    id: 'app.feedstock.search.registerDate',
    defaultMessage: 'Data Cadastro',
  },
  searchWithoutResults: {
    id: 'app.feedstock.search.searchWithoutResults',
    defaultMessage:
      'Não foi possível encontrar resultados para a sua busca. Verifique se o nome/código inserido está correto e tente novamente :)',
  },
})
