export default theme => ({
  tableRow: {
    cursor: 'pointer',
  },
  center: {
    textAlign: 'center',
  },
  highlight: {
    backgroundColor: '#C0C0C0',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    height: '500px',
  },
  head: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
  },
  columnHead: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    padding: theme.spacing.unit / 2,
    maxWidth: theme.spacing.unit * 80,
    whiteSpace: 'pre-wrap',
  },
  columnRoot: {
    height: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowY: 'auto',
  },
  columnHeaderRoot: {
    width: '100%',
    height: '500px',
    marginTop: theme.spacing.unit * 3,
    overflowY: 'auto',
  },
  column: {
    backgroundColor: '#fff',
    position: 'sticky',
    left: 0,
    padding: theme.spacing.unit / 2,
  },
  stikyHeadColumn: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    padding: theme.spacing.unit / 2,
    zIndex: 2,
  },
  scrollGrid: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  reducedTableCell: {
    padding: theme.spacing.unit / 2,
  },
  wrapper: {
    maxWidth: '100px',
  },
})
