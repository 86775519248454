export const validatePositiveNumber = (value, options = {}) => {
  const { integer, maxValue } = options
  if (value === '.') {
    return 0
  } else if (typeof value === 'number' && value >= 0) {
    return value
  } else if (!isNaN(value)) {
    const valueArray = value.split('')
    if (
      !valueArray.some(char => char === 'e') &&
      (!integer || (integer && !valueArray.some(char => char === '.')))
    ) {
      if (value === '' || value < 0) {
        return 0
      } else if (
        valueArray[0] === '0' &&
        valueArray[1] &&
        valueArray[1] !== '.' &&
        valueArray[1] !== ' '
      ) {
        return value.slice(1)
      } else if (maxValue && value >= maxValue) {
        return maxValue
      } else if (value.endsWith(' ')) {
        return value.slice(0, value.length - 1)
      }
      return value
    }
  }
  return null
}
