import { Paper as MuiPaper } from '@material-ui/core'
// tslint:disable-next-line:no-submodule-imports
import { PaperProps } from '@material-ui/core/Paper'
import { ComponentType } from 'react'
import styled from 'styled-components'

export const Paper: ComponentType<PaperProps> = styled(MuiPaper)`
  && {
    border-radius: 2px;
    padding: ${props => props.theme.v2.spacing.g};
  }
`
