import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DropDown from 'components/DropDown'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import CardBox from 'components/CardBox'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  resourceName,
  resourceDescription,
  resourceDifficulty,
  resourceLimit,
  limitType,
  resourceStatus,
  resourceType,
  save,
  edit,
} from './formattedMessages'
import { createResource, editResource } from 'actions/Resources'

const mockedResourceStatus = [
  { value: 'active', label: 'Ativo' },
  { value: 'inactive', label: 'Inativo' },
]
const mockedResourceType = [
  { value: 'area', label: 'Área' },
  { value: 'machine', label: 'Máquina' },
  { value: 'process', label: 'Processo' },
  { value: 'tool', label: 'Ferramenta' },
]
const mockedLimitType = [
  { value: 'relevance', label: 'Pertinência' },
  { value: 'time', label: 'Tempo' },
]

const styles = () => ({
  grid: {
    marginTop: '2rem',
  },
  editButton: {
    margin: '1rem',
  },
})

class RegisterResourceContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedResource: props.location.selectedResource
        ? props.location.selectedResource
        : {},
      isRegistering: !props.location.selectedResource,
      isEditing: false,
    }
  }

  handleSave = () => {
    const resource = {
      ...this.state.selectedResource,
      limit: this.state.selectedResource.limit.value,
      type: this.state.selectedResource.type.value,
      status: this.state.selectedResource.status.value,
    }
    if (this.state.isRegistering) {
      this.props.createResource(resource)
      this.setState({ isRegistering: false })
    } else if (this.state.isEditing) {
      this.props.editResource(resource)
      this.setState({ isEditing: false })
    }
  }

  handleChange = event => {
    const { id, value } = event.target
    this.setState(prevState => ({
      selectedResource: {
        ...prevState.selectedResource,
        [id]: value,
      },
    }))
  }

  handleSelect = name => value => {
    this.setState(prevState => ({
      selectedResource: {
        ...prevState.selectedResource,
        [name]: value,
      },
    }))
  }

  activateEditing = () => {
    this.setState({
      isEditing: true,
    })
  }

  render() {
    const { classes } = this.props
    const { selectedResource, isEditing, isRegistering } = this.state
    return (
      <Fragment>
        <CardBox styleName="col-sm-12">
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={resourceName}
                value={selectedResource.name}
                margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                id="name"
                disabled={!isRegistering && !isEditing}
              />
              <TextField
                fullWidth
                label={resourceDescription}
                value={selectedResource.description}
                margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                id="description"
                multiline
                rows="2"
                disabled={!isRegistering && !isEditing}
              />
              <TextField
                fullWidth
                label={resourceLimit}
                value={selectedResource.resourceLimit}
                margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                id="resourceLimit"
                disabled={!isRegistering && !isEditing}
              />
              <TextField
                fullWidth
                label={resourceDifficulty}
                value={selectedResource.difficulty}
                margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                id="difficulty"
                disabled={!isRegistering && !isEditing}
              />
              <DropDown
                fullWidth
                items={mockedLimitType}
                name="limit"
                handleChange={this.handleSelect}
                selectedItem={selectedResource.limit}
                label={limitType}
                isVisualizing={!isRegistering}
              />
              <DropDown
                fullWidth
                items={mockedResourceType}
                name="type"
                handleChange={this.handleSelect}
                selectedItem={selectedResource.type}
                label={resourceType}
                isVisualizing={!isRegistering && !isEditing}
              />
              <DropDown
                fullWidth
                items={mockedResourceStatus}
                name="status"
                handleChange={this.handleSelect}
                selectedItem={selectedResource.status}
                label={resourceStatus}
                isVisualizing={!isRegistering && !isEditing}
              />
            </Grid>
            <Grid
              container
              justify="center"
              direction="row"
              className={classes.grid}
            >
              <Grid item xs={4}>
                <Grid container justify="space-around">
                  {isEditing || isRegistering ? (
                    <Button isDefault onClick={this.handleSave}>
                      {save}
                    </Button>
                  ) : (
                    <Button onClick={() => this.activateEditing()}>
                      {edit}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBox>
      </Fragment>
    )
  }
}

RegisterResourceContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  createResource: PropTypes.func.isRequired,
  editResource: PropTypes.func.isRequired,
  location: PropTypes.object,
}

RegisterResourceContainer.defaultProps = {
  location: {},
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(null, {
    createResource,
    editResource,
  })
)(RegisterResourceContainer)
