export const GET_NUTRITIONAL_INFO_REQUEST = 'GET_NUTRITIONAL_INFO_REQUEST'
export const GET_NUTRITIONAL_INFO_RESPONSE = 'GET_NUTRITIONAL_INFO_RESPONSE'
export const GET_NUTRITIONAL_INFO_ERROR = 'SEARCH_PRODUCT_BY_BATCH_ERROR'
export const GET_SUPPLIERS_REQUEST = 'GET_SUPPLIERS_REQUEST'
export const GET_SUPPLIERS_RESPONSE = 'REJECT_PRODUCT_BY_BATCH_SUCCESS'
export const GET_SUPPLIERS_ERROR = 'REJECT_PRODUCT_BY_BATCH_ERROR'
export const GET_ALLERGENICS_REQUEST = 'GET_ALLERGENICS_REQUEST'
export const GET_ALLERGENICS_RESPONSE = 'GET_ALLERGENICS_RESPONSE'
export const GET_ALLERGENICS_ERROR = 'GET_ALLERGENICS_ERROR'
export const SAVE_FEEDSTOCK_REQUEST = 'SAVE_FEEDSTOCK_REQUEST'
export const SAVE_FEEDSTOCK_RESPONSE = 'SAVE_FEEDSTOCK_RESPONSE'
export const SAVE_FEEDSTOCK_ERROR = 'SAVE_FEEDSTOCK_ERROR'
export const DELETE_FEEDSTOCK_REQUEST = 'DELETE_FEEDSTOCK_REQUEST'
export const DELETE_FEEDSTOCK_RESPONSE = 'DELETE_FEEDSTOCK_RESPONSE'
export const DELETE_FEEDSTOCK_ERROR = 'DELETE_FEEDSTOCK_ERROR'
export const GET_NEXT_AVAILABLE_CODE_REQUEST = 'GET_NEXT_AVAILABLE_CODE_REQUEST'
export const GET_NEXT_AVAILABLE_CODE_RESPONSE =
  'GET_NEXT_AVAILABLE_CODE_RESPONSE'
export const GET_NEXT_AVAILABLE_CODE_ERROR = 'GET_NEXT_AVAILABLE_CODE_ERROR'
export const GET_FEEDSTOCK_REQUEST = 'GET_FEEDSTOCK_REQUEST'
export const GET_FEEDSTOCK_RESPONSE = 'GET_FEEDSTOCK_RESPONSE'
export const GET_FEEDSTOCK_ERROR = 'GET_FEEDSTOCK_ERROR'
export const GET_FEEDSTOCK_PRODUCT_REQUEST = 'GET_FEEDSTOCK_PRODUCT_REQUEST'
export const GET_FEEDSTOCK_PRODUCT_RESPONSE = 'GET_FEEDSTOCK_PRODUCT_RESPONSE'
export const GET_FEEDSTOCK_PRODUCT_ERROR = 'GET_FEEDSTOCK_PRODUCT_ERROR'
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_RESPONSE = 'GET_PRODUCTS_RESPONSE'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const CLEAR_FEEDSTOCK_STORE = 'CLEAR_FEEDSTOCK_STORE'
