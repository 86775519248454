import React, { Component, Fragment } from 'react'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import CardBox from 'components/CardBox'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import dotProp from 'dot-prop-immutable'
import InputField from 'components/InputField'
import { required } from 'components/Form/validations'
import InputValidator from 'components/Form/validators/SimpleInputValidator'
import DateSelect from 'components/DateSelect'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Form from 'components/Form'
import { withSnackbar } from 'notistack'
import DefaultSubmit from 'components/DefaultSubmit'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import ReceptionsInfoTable from './ReceptionsInfoTable'
import { styles } from '../styles'
import defineMessages from '../Messages'

class EditReception extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reception: {},
      isReceptionLoaded: false,
      invalidCostModalOpen: false,
    }
  }
  static getDerivedStateFromProps = (nextProps, prevState) =>
    Object.keys(nextProps.editReception.data).length > 0 &&
    !prevState.isReceptionLoaded
      ? {
          ...prevState,
          reception: nextProps.editReception.data,
          isReceptionLoaded: true,
        }
      : prevState

  handleChange = (fieldName, field) => {
    const state = dotProp.set(this.state, `reception.${fieldName}`, field)
    this.setState(state)
  }

  handleDate = date => {
    const updatedState = dotProp.set(this.state, `reception.receiveDate`, date)
    this.setState(updatedState)
  }

  handleSubmit = () => {
    const invalidReceptionInfo = this.state.reception.receptionsInfo.some(
      receptionInfo =>
        !receptionInfo.receivedDate ||
        (receptionInfo.feedstock.stockType === 'Refrigerado' &&
          !receptionInfo.expirationDate)
    )

    if (invalidReceptionInfo) {
      return this.props.enqueueSnackbar(
        `Erro: Verifique se as datas foram preenchida corretamente.`,
        {
          variant: 'error',
          horizontal: 'left',
          preventDuplicate: true,
        }
      )
    }

    return this.props.onUpdateReception(this.state.reception)
  }

  handleLinkedCodeSelect = selectedObjectsArray => {
    this.setState(state => ({
      reception: {
        ...state.reception,
        linkedReceptions: selectedObjectsArray.map(element => element.value),
      },
    }))
  }

  renderReceptionsInfoTable = () => (
    <ReceptionsInfoTable
      receptionsInfo={this.state.reception.receptionsInfo}
      onReceptionsInfoUpdate={this.handleChange}
      feedstockPricesArray={this.props.feedstockPrices.data}
    />
  )

  renderLinkedReceptionCodeSelector = () => (
    <Fragment>
      <h4 style={{ marginTop: '5px', marginBottom: '10px' }}>
        {defineMessages.addLinkedReception.defaultMessage}
      </h4>
      <Select
        isMulti
        defaultValue={this.props.linkedReceptions}
        options={this.props.receptionsCodes}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={this.handleLinkedCodeSelect}
        placeholder={defineMessages.selectReceptionCode.defaultMessage}
      />
    </Fragment>
  )

  renderInvalidCostModal = () => (
    <Dialog open={this.state.invalidCostModalOpen}>
      <DialogContent>
        O preço do recebimento não pode desviar mais do que 10% da média.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ invalidCostModalOpen: false })}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )

  render() {
    if (this.props.editReception.loading) {
      return <CircularProgress size={50} />
    }
    const { reception } = this.state
    return this.state.isReceptionLoaded && Object.keys(reception).length > 0 ? (
      <Fragment>
        <CardBox styleName="col-sm-12">
          <Form>
            <Grid container spacing={16} alignItems="center">
              <Grid item xs={3}>
                <InputField
                  value={reception.code}
                  label="Código do Recebimento"
                  fieldName="code"
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <InputValidator
                  id="PersonInCharge"
                  validations={[required('Obrigatório(*)')]}
                >
                  <InputField
                    value={reception.personInCharge}
                    label="Responsável (*)"
                    onChange={this.handleChange}
                    fieldName="personInCharge"
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={3}>
                <InputValidator
                  id="Invoice"
                  validations={[required('Obrigatório(*)')]}
                >
                  <InputField
                    value={reception.invoice}
                    label="Nota Fiscal (*)"
                    fieldName="invoice"
                    onChange={this.handleChange}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={3}>
                <DateSelect
                  onSelect={this.handleDate}
                  label="Data Esperada (*)"
                  selectedDate={moment(reception.receiveDate, 'YYYY-MM-DD')}
                />
              </Grid>
              <Grid item xs={12}>
                {this.renderReceptionsInfoTable()}
              </Grid>
              <Grid item xs={12}>
                {this.renderLinkedReceptionCodeSelector()}
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <FormControl>
                    <Button
                      onClick={this.props.onCloseEditReceptionPage}
                      variant="raised"
                      color="warning"
                      className={`jr-btn text-blue-gray ${this.props.classes.buttonPadding}`}
                    >
                      Voltar
                    </Button>
                  </FormControl>
                  <FormControl>
                    <DefaultSubmit
                      submitText="Salvar"
                      onSubmit={this.handleSubmit}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </CardBox>
        {this.renderInvalidCostModal()}
      </Fragment>
    ) : null
  }
}

EditReception.propTypes = {
  editReception: PropTypes.object,
  feedstockPrices: PropTypes.object,
  onUpdateReception: PropTypes.func.isRequired,
  onCloseEditReceptionPage: PropTypes.func.isRequired,
  receptionsCodes: PropTypes.array.isRequired,
  linkedReceptions: PropTypes.array.isRequired,
}

EditReception.defaultProps = {
  editReception: {
    data: {},
    loading: false,
    error: null,
  },
  feedstockPrices: {
    data: [],
    loading: false,
    error: null,
  },
}
export default withStyles(styles)(withSnackbar(EditReception))
