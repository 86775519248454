import React, { useCallback, useEffect, useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'notistack'

import { FilterContainer } from '../../components/filter-container/index'
import IngredientCard from '../../components/IngredientCard'
import { Ingredient } from '../../../api/dto/ingredients'
import { FactoryProcesses } from '../../../api/dto/FactoryProcesses'
import { Endpoints } from '../../../api/endpoints'
import { useAPI } from '../../../api'
import { createSnackbarOptions } from '../../../logic/notification'
import { ErrorMessage, Divider } from './styles'

function IngredientProcessPage() {
  const [fetchProcesses, areProcessesLoading] = useAPI()
  const [factoryProcesses, setFactoryProcesses] = useState<FactoryProcesses>({
    activities: [],
    areas: [],
    processes: [],
    resources: [],
  })

  const [searchIngredients, areIngredientsLoading] = useAPI()
  const [ingredients, setIngredients] = useState([])
  const [filter, setFilter] = useState('')
  const [currentIngredientEditCode, setCurrentIngredientEditCode] = useState('')

  const [saveProcesses, isSaving] = useAPI()
  const { enqueueSnackbar } = useSnackbar()

  const search = useCallback(
    (name?: string) => {
      const uri = name
        ? `${Endpoints.SEARCH_INGREDIENTS}?name=${name}`
        : Endpoints.SEARCH_INGREDIENTS

      return searchIngredients({ uri, method: 'GET' })
    },
    [searchIngredients]
  )

  const handleRequestError = useCallback(
    (error: Error) =>
      enqueueSnackbar(error.message, createSnackbarOptions('error')),
    [enqueueSnackbar]
  )

  useEffect(() => {
    search(filter)
      .then((data: { ingredients: Ingredient[] }) => {
        setIngredients(data.ingredients)
      })
      .catch(handleRequestError)
  }, [search, filter, handleRequestError])

  useEffect(() => {
    fetchProcesses({ uri: Endpoints.FETCH_PROCESSES, method: 'GET' })
      .then((data: FactoryProcesses) => {
        setFactoryProcesses(data)
      })
      .catch(handleRequestError)
  }, [fetchProcesses, handleRequestError])

  function handleFilterChange(value: string) {
    const v = (value || '').trim()

    if (v.length && v.length < 3) {
      return
    }

    setFilter(v)
  }

  function handleIngredientProcessEdit(ingredient: Ingredient) {
    if (currentIngredientEditCode === ingredient.code) {
      setCurrentIngredientEditCode('')
      return
    }

    setCurrentIngredientEditCode(ingredient.code)
  }

  function handleIngredientProcessCancel() {
    setCurrentIngredientEditCode('')
  }

  function handleSave(newIngredient: Ingredient) {
    if (!newIngredient.preparationProcesses.length) {
      enqueueSnackbar(
        'Pelo menos 1 processo precisa estar cadastrado para salvar',
        createSnackbarOptions('error')
      )
      return
    }

    const errors = newIngredient.preparationProcesses
      .map((preparationProcess, i) => {
        const processCount = i + 1

        if (!preparationProcess.activity) {
          return `Nenhuma atividade foi selecionada para o Processo ${processCount}`
        }

        if (!preparationProcess.area) {
          return `Nenhuma área foi selecionada para o Processo ${processCount}`
        }

        if (!preparationProcess.process) {
          return `Nenhum processo foi selecionada para o Processo ${processCount}`
        }

        if (!preparationProcess.waste && preparationProcess.waste !== 0) {
          return `Nenhum desperdício cadastrado para o Processo ${processCount}`
        }

        if (preparationProcess.waste < 0 || preparationProcess.waste > 100) {
          return `O desperdício só pode conter valores entre 0 e 100`
        }

        return null
      })
      .filter(error => error)

    if (errors.length) {
      enqueueSnackbar(
        <Grid container direction="column" spacing={8}>
          {errors.map(error => (
            <ErrorMessage key={error}>{error}</ErrorMessage>
          ))}
        </Grid>,
        createSnackbarOptions('error')
      )
      return
    }

    saveProcesses({
      uri: Endpoints.SAVE_PROCESSES(newIngredient.code),
      method: 'POST',
      body: {
        preparationProcesses: newIngredient.preparationProcesses.map(
          ({ id: _, ...rest }) => ({
            ...rest,
          })
        ),
      },
    })
      .then(() => {
        if (
          !newIngredient.preparationProcesses ||
          !newIngredient.preparationProcesses.length
        ) {
          return
        }

        const indexTobeUpdated = ingredients.findIndex(
          ingredient => ingredient.code === newIngredient.code
        )
        const updatedIngredients = [...ingredients]
        updatedIngredients[indexTobeUpdated] = newIngredient
        setIngredients(updatedIngredients)

        enqueueSnackbar(
          'Processos cadastrados com sucesso!',
          createSnackbarOptions('success')
        )

        setCurrentIngredientEditCode('')
      })
      .catch(handleRequestError)
  }

  return (
    <div>
      <FilterContainer debounce={300} onChange={handleFilterChange} />
      <Divider />
      {areIngredientsLoading || areProcessesLoading ? (
        <Grid container justify="center">
          <CircularProgress size={35} />
        </Grid>
      ) : (
        ingredients &&
        ingredients.map(ingredient => (
          <IngredientCard
            key={ingredient.code}
            ingredient={ingredient}
            processes={factoryProcesses}
            editing={ingredient.code === currentIngredientEditCode}
            loading={isSaving}
            onEdit={handleIngredientProcessEdit}
            onCancel={handleIngredientProcessCancel}
            onSave={handleSave}
          />
        ))
      )}
    </div>
  )
}

export default IngredientProcessPage
