export const mockAPI = {
	resources: [
		{
			_id: '5c3cebdec0f1cc418f6f8fb9',
			name: 'a1',
			limit: {
				value: 9500.0,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'area',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fba',
			name: 'a2',
			limit: {
				value: 2106.0,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'area',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fbb',
			name: 'a3',
			limit: {
				value: 8000.0,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'area',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fbc',
			name: 'modelagem',
			limit: {
				value: 1800.0,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'process',
			status: 'inactive',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fbd',
			name: 'hamburgueira',
			limit: {
				value: 2600.0,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fbe',
			name: 'porcionamento',
			limit: {
				value: 220320.0,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'process',
			status: 'inactive',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fbf',
			name: 'rational',
			limit: {
				value: 2246.4,
				type: 'time',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc0',
			name: 'caldeira',
			limit: {
				value: 4.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc1',
			name: 'bralyx',
			limit: {
				value: 3.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'inactive',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc2',
			name: 'unifiller',
			limit: {
				value: 3.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc3',
			name: 'roastray',
			limit: {
				value: 5.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc4',
			name: 'granito',
			limit: {
				value: 10.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'tool',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc5',
			name: 'furada',
			limit: {
				value: 5.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'tool',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc6',
			name: '65mm',
			limit: {
				value: 5.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'tool',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc7',
			name: 'ovo',
			limit: {
				value: 2.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'tool',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc8',
			name: 'muffin',
			limit: {
				value: 4.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'tool',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fc9',
			name: 'coc',
			limit: {
				value: 1.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'tool',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fca',
			name: 'grill',
			limit: {
				value: 1.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
		{
			_id: '5c3cebdec0f1cc418f6f8fcb',
			name: 'trilax',
			limit: {
				value: 20.0,
				type: 'relevance',
			},
			description: 'Recurso de Produção',
			type: 'machine',
			status: 'active',
		},
	],
	recipes: [
		{
			_id: '5b69b682ed31350014dcb52f',
			name: 'Filé Mignon Suíno com Abacaxi',
			code: 'P060',
			recipeYield: 100,
			recipeWaste: 1,
			updatedAt: '2019-02-02',
			ingredients: [
				{
					ingredient: {
						_id: '5a7ddb0fe1e2c5e5b5363dff',
						productionName: 'Cebola Roxa DESCASCADA',
						code: 'IR045',
						waste: 20,
						processingYield: 100,
						feedstock: {
							currentPrice: 5.83,
							nutritionalInfo: {
								sodium: 4,
								fibers: 1.7,
								transFats: 0,
								satFats: 0.042,
								totalFats: 0.1,
								proteins: 1.1,
								carbs: 9.34,
								kcal: 40,
							},
						},
					},
					quantity: 6260,
				},
				{
					ingredient: {
						_id: '5a7ddb0fe1e2c5e5b5363e7a',
						productionName: 'Hortelã DESFOLHADA',
						code: 'IR101',
						waste: 55,
						processingYield: 100,
						feedstock: {
							currentPrice: 13.89,
							nutritionalInfo: {
								sodium: 30,
								fibers: 6.8,
								transFats: 0,
								satFats: 0.191,
								totalFats: 0.73,
								proteins: 3.29,
								carbs: 8.41,
								kcal: 44,
							},
						},
					},
					quantity: 180,
				},
			],
			recipesInfo: [
				{
					sub_recipeYield: 100,
					recipe: {
						_id: '5b69b57ced31350014dcb524',
						name: 'Base filé mignon suíno',
						recipeYield: 70.0371747211896,
						recipeWaste: 1,
						ingredients: [
							{
								ingredient: {
									_id: '5b69b2abed31350014dcb512',
									productionName: 'Filé Mignon Suíno LIMPO',
									code: 'IR208',
									waste: 10,
									processingYield: 100,
									feedstock: {
										currentPrice: 8.98,
										nutritionalInfo: {
											kcal: 127,
											carbs: 0,
											proteins: 27,
											satFats: 1,
											transFats: 0,
											totalFats: 2,
											fibers: 0,
											sodium: 86,
										},
									},
								},
								quantity: 24500,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363dd9',
									productionName: 'Azeite',
									code: 'IR025',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 30.21,
										nutritionalInfo: {
											sodium: 0,
											fibers: 0,
											transFats: 0,
											satFats: 14.61538462,
											totalFats: 92.30769231,
											proteins: 0,
											carbs: 0,
											kcal: 830,
										},
									},
								},
								quantity: 970,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363e66',
									productionName: 'Gengibre DESCASCADO',
									code: 'IR093',
									waste: 25,
									processingYield: 100,
									feedstock: {
										currentPrice: 3.5,
										nutritionalInfo: {
											sodium: 13,
											fibers: 2,
											transFats: 0,
											satFats: 0.203,
											totalFats: 0.75,
											proteins: 1.82,
											carbs: 17.77,
											kcal: 80,
										},
									},
								},
								quantity: 835,
							},
							{
								ingredient: {
									_id: '5ab3d94b06d65f0014071a08',
									productionName: 'Alho DESCASCADO',
									code: 'IR179',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 15.73,
										nutritionalInfo: {
											kcal: 100,
											carbs: 18,
											proteins: 7,
											satFats: 0,
											transFats: 0,
											totalFats: 0,
											fibers: 0.5,
											sodium: 0,
										},
									},
								},
								quantity: 330,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363edd',
									productionName: 'Sal Rosa',
									code: 'IR148',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 3.18,
										nutritionalInfo: {
											sodium: 19200,
											fibers: 0,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 0,
											carbs: 0,
											kcal: 0,
										},
									},
								},
								quantity: 250,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363ebb',
									productionName: 'Pimenta Do Reino em Pó',
									code: 'IR131',
									waste: 5,
									processingYield: 100,
									feedstock: {
										currentPrice: 11.25,
										nutritionalInfo: {
											sodium: 20,
											fibers: 25.3,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 0,
											carbs: 63.95,
											kcal: 260,
										},
									},
								},
								quantity: 15,
							},
						],
						recipesInfo: [],
						__v: 0,
						nutritionalInfo: {},
					},
					quantity: 18800,
				},
				{
					sub_recipeYield: 100,
					recipe: {
						_id: '5b69b5b3ed31350014dcb52b',
						name: 'Abacaxi assado',
						recipeYield: 71.0407239819005,
						recipeWaste: 1,
						ingredients: [
							{
								ingredient: {
									_id: '5b69b48eed31350014dcb522',
									productionName: 'Abacaxi em Cubos ',
									code: 'IR210',
									waste: 10,
									processingYield: 100,
									feedstock: {
										currentPrice: 18.9,
										nutritionalInfo: {
											sodium: 0.4,
											fibers: 1,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 1,
											carbs: 12,
											kcal: 48,
										},
									},
								},
								quantity: 13260,
							},
						],
						recipesInfo: [],
						nutritionalInfo: {},
					},
					quantity: 9420,
				},
			],
			packageWeight: 110,
			nutritionalInfo: {
				carbs: {
					portion: 7.77,
					daily_value: 3,
				},
				proteins: {
					portion: 22.17,
					daily_value: 30,
				},
				totalFats: {
					portion: 4.52,
					daily_value: 8,
				},
				satFats: {
					portion: 1.27,
					daily_value: 6,
				},
				transFats: {
					portion: 0,
					daily_value: 0,
				},
				sodium: {
					portion: 224.69,
					daily_value: 9,
				},
				fibers: {
					portion: 0.88,
					daily_value: 4,
				},
				kcal: {
					portion: 160.44,
					daily_value: 8,
				},
			},
		},
		{
			_id: '5b69b682ed31350014dcb52f',
			name: 'Posta de Salmão',
			code: 'P020',
			recipeYield: 100,
			recipeWaste: 1,
			updatedAt: '2019-02-02',
			ingredients: [
				{
					ingredient: {
						_id: '5a7ddb0fe1e2c5e5b5363dff',
						productionName: 'Cebola Roxa DESCASCADA',
						code: 'IR045',
						waste: 20,
						processingYield: 100,
						feedstock: {
							currentPrice: 5.83,
							nutritionalInfo: {
								sodium: 4,
								fibers: 1.7,
								transFats: 0,
								satFats: 0.042,
								totalFats: 0.1,
								proteins: 1.1,
								carbs: 9.34,
								kcal: 40,
							},
						},
					},
					quantity: 6260,
				},
				{
					ingredient: {
						_id: '5a7ddb0fe1e2c5e5b5363e7a',
						productionName: 'Hortelã DESFOLHADA',
						code: 'IR101',
						waste: 55,
						processingYield: 100,
						feedstock: {
							currentPrice: 13.89,
							nutritionalInfo: {
								sodium: 30,
								fibers: 6.8,
								transFats: 0,
								satFats: 0.191,
								totalFats: 0.73,
								proteins: 3.29,
								carbs: 8.41,
								kcal: 44,
							},
						},
					},
					quantity: 180,
				},
			],
			recipesInfo: [
				{
					sub_recipeYield: 100,
					recipe: {
						_id: '5b69b57ced31350014dcb524',
						name: 'Base filé mignon suíno',
						recipeYield: 70.0371747211896,
						recipeWaste: 1,
						ingredients: [
							{
								ingredient: {
									_id: '5b69b2abed31350014dcb512',
									productionName: 'Filé Mignon Suíno LIMPO',
									code: 'IR208',
									waste: 10,
									processingYield: 100,
									feedstock: {
										currentPrice: 8.98,
										nutritionalInfo: {
											kcal: 127,
											carbs: 0,
											proteins: 27,
											satFats: 1,
											transFats: 0,
											totalFats: 2,
											fibers: 0,
											sodium: 86,
										},
									},
								},
								quantity: 24500,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363dd9',
									productionName: 'Azeite',
									code: 'IR025',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 30.21,
										nutritionalInfo: {
											sodium: 0,
											fibers: 0,
											transFats: 0,
											satFats: 14.61538462,
											totalFats: 92.30769231,
											proteins: 0,
											carbs: 0,
											kcal: 830,
										},
									},
								},
								quantity: 970,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363e66',
									productionName: 'Gengibre DESCASCADO',
									code: 'IR093',
									waste: 25,
									processingYield: 100,
									feedstock: {
										currentPrice: 3.5,
										nutritionalInfo: {
											sodium: 13,
											fibers: 2,
											transFats: 0,
											satFats: 0.203,
											totalFats: 0.75,
											proteins: 1.82,
											carbs: 17.77,
											kcal: 80,
										},
									},
								},
								quantity: 835,
							},
							{
								ingredient: {
									_id: '5ab3d94b06d65f0014071a08',
									productionName: 'Alho DESCASCADO',
									code: 'IR179',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 15.73,
										nutritionalInfo: {
											kcal: 100,
											carbs: 18,
											proteins: 7,
											satFats: 0,
											transFats: 0,
											totalFats: 0,
											fibers: 0.5,
											sodium: 0,
										},
									},
								},
								quantity: 330,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363edd',
									productionName: 'Sal Rosa',
									code: 'IR148',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 3.18,
										nutritionalInfo: {
											sodium: 19200,
											fibers: 0,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 0,
											carbs: 0,
											kcal: 0,
										},
									},
								},
								quantity: 250,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363ebb',
									productionName: 'Pimenta Do Reino em Pó',
									code: 'IR131',
									waste: 5,
									processingYield: 100,
									feedstock: {
										currentPrice: 11.25,
										nutritionalInfo: {
											sodium: 20,
											fibers: 25.3,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 0,
											carbs: 63.95,
											kcal: 260,
										},
									},
								},
								quantity: 15,
							},
						],
						recipesInfo: [],
						__v: 0,
						nutritionalInfo: {},
					},
					quantity: 18800,
				},
				{
					sub_recipeYield: 100,
					recipe: {
						_id: '5b69b5b3ed31350014dcb52b',
						name: 'Abacaxi assado',
						recipeYield: 71.0407239819005,
						recipeWaste: 1,
						ingredients: [
							{
								ingredient: {
									_id: '5b69b48eed31350014dcb522',
									productionName: 'Abacaxi em Cubos ',
									code: 'IR210',
									waste: 10,
									processingYield: 100,
									feedstock: {
										currentPrice: 18.9,
										nutritionalInfo: {
											sodium: 0.4,
											fibers: 1,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 1,
											carbs: 12,
											kcal: 48,
										},
									},
								},
								quantity: 13260,
							},
						],
						recipesInfo: [],
						nutritionalInfo: {},
					},
					quantity: 9420,
				},
			],
			packageWeight: 110,
			nutritionalInfo: {
				carbs: {
					portion: 7.77,
					daily_value: 3,
				},
				proteins: {
					portion: 22.17,
					daily_value: 30,
				},
				totalFats: {
					portion: 4.52,
					daily_value: 8,
				},
				satFats: {
					portion: 1.27,
					daily_value: 6,
				},
				transFats: {
					portion: 0,
					daily_value: 0,
				},
				sodium: {
					portion: 224.69,
					daily_value: 9,
				},
				fibers: {
					portion: 0.88,
					daily_value: 4,
				},
				kcal: {
					portion: 160.44,
					daily_value: 8,
				},
			},
		},
		{
			_id: '5b69b682ed31350014dcb234',
			name: 'Hamburguer de Ervilha com Hortelã',
			code: 'P030',
			recipeYield: 100,
			recipeWaste: 1,
			updatedAt: '2019-02-02',
			ingredients: [
				{
					ingredient: {
						_id: '5a7ddb0fe1e2c5e5b5363dff',
						productionName: 'Cebola Roxa DESCASCADA',
						code: 'IR045',
						waste: 20,
						processingYield: 100,
						feedstock: {
							currentPrice: 5.83,
							nutritionalInfo: {
								sodium: 4,
								fibers: 1.7,
								transFats: 0,
								satFats: 0.042,
								totalFats: 0.1,
								proteins: 1.1,
								carbs: 9.34,
								kcal: 40,
							},
						},
					},
					quantity: 6260,
				},
				{
					ingredient: {
						_id: '5a7ddb0fe1e2c5e5b5363e7a',
						productionName: 'Hortelã DESFOLHADA',
						code: 'IR101',
						waste: 55,
						processingYield: 100,
						feedstock: {
							currentPrice: 13.89,
							nutritionalInfo: {
								sodium: 30,
								fibers: 6.8,
								transFats: 0,
								satFats: 0.191,
								totalFats: 0.73,
								proteins: 3.29,
								carbs: 8.41,
								kcal: 44,
							},
						},
					},
					quantity: 180,
				},
			],
			recipesInfo: [
				{
					sub_recipeYield: 100,
					recipe: {
						_id: '5b69b57ced31350014dcb524',
						name: 'Base filé mignon suíno',
						recipeYield: 70.0371747211896,
						recipeWaste: 1,
						ingredients: [
							{
								ingredient: {
									_id: '5b69b2abed31350014dcb512',
									productionName: 'Filé Mignon Suíno LIMPO',
									code: 'IR208',
									waste: 10,
									processingYield: 100,
									feedstock: {
										currentPrice: 8.98,
										nutritionalInfo: {
											kcal: 127,
											carbs: 0,
											proteins: 27,
											satFats: 1,
											transFats: 0,
											totalFats: 2,
											fibers: 0,
											sodium: 86,
										},
									},
								},
								quantity: 24500,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363dd9',
									productionName: 'Azeite',
									code: 'IR025',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 30.21,
										nutritionalInfo: {
											sodium: 0,
											fibers: 0,
											transFats: 0,
											satFats: 14.61538462,
											totalFats: 92.30769231,
											proteins: 0,
											carbs: 0,
											kcal: 830,
										},
									},
								},
								quantity: 970,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363e66',
									productionName: 'Gengibre DESCASCADO',
									code: 'IR093',
									waste: 25,
									processingYield: 100,
									feedstock: {
										currentPrice: 3.5,
										nutritionalInfo: {
											sodium: 13,
											fibers: 2,
											transFats: 0,
											satFats: 0.203,
											totalFats: 0.75,
											proteins: 1.82,
											carbs: 17.77,
											kcal: 80,
										},
									},
								},
								quantity: 835,
							},
							{
								ingredient: {
									_id: '5ab3d94b06d65f0014071a08',
									productionName: 'Alho DESCASCADO',
									code: 'IR179',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 15.73,
										nutritionalInfo: {
											kcal: 100,
											carbs: 18,
											proteins: 7,
											satFats: 0,
											transFats: 0,
											totalFats: 0,
											fibers: 0.5,
											sodium: 0,
										},
									},
								},
								quantity: 330,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363edd',
									productionName: 'Sal Rosa',
									code: 'IR148',
									waste: 1,
									processingYield: 100,
									feedstock: {
										currentPrice: 3.18,
										nutritionalInfo: {
											sodium: 19200,
											fibers: 0,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 0,
											carbs: 0,
											kcal: 0,
										},
									},
								},
								quantity: 250,
							},
							{
								ingredient: {
									_id: '5a7ddb0fe1e2c5e5b5363ebb',
									productionName: 'Pimenta Do Reino em Pó',
									code: 'IR131',
									waste: 5,
									processingYield: 100,
									feedstock: {
										currentPrice: 11.25,
										nutritionalInfo: {
											sodium: 20,
											fibers: 25.3,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 0,
											carbs: 63.95,
											kcal: 260,
										},
									},
								},
								quantity: 15,
							},
						],
						recipesInfo: [],
						__v: 0,
						nutritionalInfo: {},
					},
					quantity: 18800,
				},
				{
					sub_recipeYield: 100,
					recipe: {
						_id: '5b69b5b3ed31350014dcb52b',
						name: 'Abacaxi assado',
						recipeYield: 71.0407239819005,
						recipeWaste: 1,
						ingredients: [
							{
								ingredient: {
									_id: '5b69b48eed31350014dcb522',
									productionName: 'Abacaxi em Cubos ',
									code: 'IR210',
									waste: 10,
									processingYield: 100,
									feedstock: {
										currentPrice: 18.9,
										nutritionalInfo: {
											sodium: 0.4,
											fibers: 1,
											transFats: 0,
											satFats: 0,
											totalFats: 0,
											proteins: 1,
											carbs: 12,
											kcal: 48,
										},
									},
								},
								quantity: 13260,
							},
						],
						recipesInfo: [],
						nutritionalInfo: {},
					},
					quantity: 9420,
				},
			],
			packageWeight: 110,
			nutritionalInfo: {
				carbs: {
					portion: 7.77,
					daily_value: 3,
				},
				proteins: {
					portion: 22.17,
					daily_value: 30,
				},
				totalFats: {
					portion: 4.52,
					daily_value: 8,
				},
				satFats: {
					portion: 1.27,
					daily_value: 6,
				},
				transFats: {
					portion: 0,
					daily_value: 0,
				},
				sodium: {
					portion: 224.69,
					daily_value: 9,
				},
				fibers: {
					portion: 0.88,
					daily_value: 4,
				},
				kcal: {
					portion: 160.44,
					daily_value: 8,
				},
			},
		},
	],
	recipeVersions: [
		{
			name: 'Brigadeiro com  crosta de granulado 70% cacau',
			code: 'S047',
			inProduction: true,
			changingCommit: {
				message: 'mais chocolate',
				responsible: 'Caio',
			},
			versionNumber: 5,
			previousVersion: { versionNumber: 4 },
		},
		{
			name: 'Brigadeiro com  crosta de granulado 70% cacau',
			code: 'S047',
			inProduction: false,
			changingCommit: {
				message: 'mais chocolate',
				responsible: 'Caio',
			},
			versionNumber: 4,
			previousVersion: { versionNumber: 2 },
		},
		{
			name: 'Brigadeiro com  crosta de granulado 70% cacau',
			code: 'S047',
			inProduction: false,
			changingCommit: {
				message: 'teste com nescau',
				responsible: 'Caio',
			},
			versionNumber: 3,
			previousVersion: { versionNumber: 2 },
		},
		{
			name: 'Brigadeiro com  crosta de granulado 70% cacau',
			code: 'S047',
			inProduction: false,
			changingCommit: {
				message: 'mais chocolate',
				responsible: 'Caio',
			},
			versionNumber: 2,
			previousVersion: { versionNumber: 1 },
		},
		{
			name: 'Brigadeiro com  crosta de granulado 70% cacau',
			code: 'S047',
			inProduction: false,
			changingCommit: {
				message: '-',
				responsible: 'Caio',
			},
			versionNumber: 1,
			previousVersion: '-',
		},
	],
	ingredients: [
		{
			_id: '5a7ddb0fe1e2c5e5b5363dff',
			productionName: 'Cebola Roxa DESCASCADA',
			code: 'IR045',
			waste: 20,
			processingYield: 100,
			feedstock: {
				currentPrice: 5.83,
				nutritionalInfo: {
					sodium: 4,
					fibers: 1.7,
					transFats: 0,
					satFats: 0.042,
					totalFats: 0.1,
					proteins: 1.1,
					carbs: 9.34,
					kcal: 40,
				},
			},
		},
		{
			_id: '5a7ddb0fe1e2c5e5b5363e7a',
			productionName: 'Hortelã DESFOLHADA',
			code: 'IR101',
			waste: 55,
			processingYield: 100,
			feedstock: {
				currentPrice: 13.89,
				nutritionalInfo: {
					sodium: 30,
					fibers: 6.8,
					transFats: 0,
					satFats: 0.191,
					totalFats: 0.73,
					proteins: 3.29,
					carbs: 8.41,
					kcal: 44,
				},
			},
		},
		{
			_id: '5b69b2abed31350014dcb512',
			productionName: 'Filé Mignon Suíno LIMPO',
			code: 'IR208',
			waste: 10,
			processingYield: 100,
			feedstock: {
				currentPrice: 8.98,
				nutritionalInfo: {
					kcal: 127,
					carbs: 0,
					proteins: 27,
					satFats: 1,
					transFats: 0,
					totalFats: 2,
					fibers: 0,
					sodium: 86,
				},
			},
		},
		{
			_id: '5a7ddb0fe1e2c5e5b5363dd9',
			productionName: 'Azeite',
			code: 'IR025',
			waste: 1,
			processingYield: 100,
			feedstock: {
				currentPrice: 30.21,
				nutritionalInfo: {
					sodium: 0,
					fibers: 0,
					transFats: 0,
					satFats: 14.61538462,
					totalFats: 92.30769231,
					proteins: 0,
					carbs: 0,
					kcal: 830,
				},
			},
		},
		{
			_id: '5a7ddb0fe1e2c5e5b5363e66',
			productionName: 'Gengibre DESCASCADO',
			code: 'IR093',
			waste: 25,
			processingYield: 100,
			feedstock: {
				currentPrice: 3.5,
				nutritionalInfo: {
					sodium: 13,
					fibers: 2,
					transFats: 0,
					satFats: 0.203,
					totalFats: 0.75,
					proteins: 1.82,
					carbs: 17.77,
					kcal: 80,
				},
			},
		},
		{
			_id: '5ab3d94b06d65f0014071a08',
			productionName: 'Alho DESCASCADO',
			code: 'IR179',
			waste: 1,
			processingYield: 100,
			feedstock: {
				currentPrice: 15.73,
				nutritionalInfo: {
					kcal: 100,
					carbs: 18,
					proteins: 7,
					satFats: 0,
					transFats: 0,
					totalFats: 0,
					fibers: 0.5,
					sodium: 0,
				},
			},
		},
		{
			_id: '5a7ddb0fe1e2c5e5b5363edd',
			productionName: 'Sal Rosa',
			code: 'IR148',
			waste: 1,
			processingYield: 100,
			feedstock: {
				currentPrice: 3.18,
				nutritionalInfo: {
					sodium: 19200,
					fibers: 0,
					transFats: 0,
					satFats: 0,
					totalFats: 0,
					proteins: 0,
					carbs: 0,
					kcal: 0,
				},
			},
		},
		{
			_id: '5a7ddb0fe1e2c5e5b5363ebb',
			productionName: 'Pimenta Do Reino em Pó',
			code: 'IR131',
			waste: 5,
			processingYield: 100,
			feedstock: {
				currentPrice: 11.25,
				nutritionalInfo: {
					sodium: 20,
					fibers: 25.3,
					transFats: 0,
					satFats: 0,
					totalFats: 0,
					proteins: 0,
					carbs: 63.95,
					kcal: 260,
				},
			},
		},
		{
			_id: '5b69b48eed31350014dcb522',
			productionName: 'Abacaxi em Cubos ',
			code: 'IR210',
			waste: 10,
			processingYield: 100,
			feedstock: {
				currentPrice: 18.9,
				nutritionalInfo: {
					sodium: 0.4,
					fibers: 1,
					transFats: 0,
					satFats: 0,
					totalFats: 0,
					proteins: 1,
					carbs: 12,
					kcal: 48,
				},
			},
		},
	],
};

export const productionPlanning = {
	pruductionId: 'PPCP001',
	productionLotsByDate: {
		'2019-01-01': [
			{ sku: 'C003', lots: 1, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 3, units: 189, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 1, units: 145, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 176, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 3, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 125, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 3, units: 125, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 125, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 2, units: 125, name: 'Edamame' },
			{ sku: 'P007', lots: 3, units: 189, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 2, units: 125, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 3, units: 125, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-02': [
			{ sku: 'C003', lots: 1, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 0, units: 0, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 3, units: 125, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 1, units: 145, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 0, units: 0, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 176, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 3, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 125, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 125, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 2, units: 125, name: 'Edamame' },
			{ sku: 'P007', lots: 0, units: 0, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 3, units: 125, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-03': [
			{ sku: 'C003', lots: 1, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 0, units: 0, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 1, units: 125, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 176, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 3, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 176, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 125, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 125, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 2, units: 176, name: 'Edamame' },
			{ sku: 'P007', lots: 3, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 0, units: 0, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-04': [
			{ sku: 'C003', lots: 1, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 1, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 0, units: 0, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 1, units: 125, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 3, units: 189, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 1, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 3, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 3, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 125, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 1, units: 125, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 189, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 2, units: 125, name: 'Edamame' },
			{ sku: 'P007', lots: 1, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 3, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 3, units: 125, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 0, units: 0, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-05': [
			{ sku: 'C003', lots: 1, units: 189, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 0, units: 0, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 1, units: 189, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 3, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 125, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 189, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 2, units: 125, name: 'Edamame' },
			{ sku: 'P007', lots: 3, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 0, units: 0, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-06': [
			{ sku: 'C003', lots: 2, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 189, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 3, units: 125, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 1, units: 125, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 145, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 3, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 2, units: 189, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 125, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 189, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 3, units: 145, name: 'Edamame' },
			{ sku: 'P007', lots: 3, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 2, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 2, units: 125, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-07': [
			{ sku: 'C003', lots: 2, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 0, units: 0, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 0, units: 0, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 2, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 189, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 125, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 0, units: 0, name: 'Edamame' },
			{ sku: 'P007', lots: 2, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 0, units: 0, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-08': [
			{ sku: 'C003', lots: 2, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 0, units: 0, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 0, units: 0, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 2, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 189, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 125, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 0, units: 0, name: 'Edamame' },
			{ sku: 'P007', lots: 2, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 0, units: 0, name: 'Estrogonofe de Cogumelos' },
		],
		'2019-01-09': [
			{ sku: 'C003', lots: 2, units: 125, name: 'Arroz  com uva passa' },
			{ sku: 'L004', lots: 2, units: 125, name: 'Mix de Legumes' },
			{ sku: 'P005', lots: 0, units: 0, name: 'Hamburguer de Fraldinha' },
			{ sku: 'S001', lots: 0, units: 0, name: 'Bolinho de cenoura' },
			{ sku: 'L006', lots: 2, units: 125, name: 'Abobrinha com tomate' },
			{ sku: 'P003', lots: 3, units: 125, name: 'Hamburguer de salmão' },
			{ sku: 'S010', lots: 1, units: 125, name: 'Quibe assado com castanha do pará' },
			{ sku: 'L011', lots: 2, units: 125, name: 'Arroz de pupunha' },
			{ sku: 'P008', lots: 2, units: 125, name: 'Saint Peter com limão siciliano' },
			{ sku: 'C004', lots: 1, units: 125, name: 'Risoto de Funghi' },
			{ sku: 'L007', lots: 2, units: 189, name: 'Pure de Abobora' },
			{ sku: 'P009', lots: 0, units: 0, name: 'Feijão Preto' },
			{ sku: 'S003', lots: 1, units: 125, name: 'Cookie com gotas de chocolate 70% cacau' },
			{ sku: 'L009', lots: 0, units: 0, name: 'Edamame' },
			{ sku: 'P007', lots: 2, units: 125, name: 'Lentilha mediterrânea' },
			{ sku: 'S002', lots: 1, units: 125, name: 'Crepioca com queijo branco' },
			{ sku: 'L014', lots: 0, units: 0, name: 'Talharim de pupunha ao molho pesto' },
			{ sku: 'P015', lots: 0, units: 0, name: 'Estrogonofe de Cogumelos' },
		],
	},
	dates: [
		'2019-01-01',
		'2019-01-02',
		'2019-01-03',
		'2019-01-04',
		'2019-01-05',
		'2019-01-06',
		'2019-01-07',
		'2019-01-08',
		'2019-01-09',
	],
};

export const tableHead = [
	'SKU',
	'10/01/2019',
	'11/01/2019',
	'12/01/2019',
	'13/01/2019',
	'14/01/2019',
	'15/01/2019',
	'16/01/2019',
	'17/01/2019',
	'18/01/2019',
	'19/01/2019',
	'20/01/2019',
];

export const skus = {
	C003: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C004: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C005: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C006: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C007: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C008: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C009: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C010: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C011: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C012: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C013: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C014: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
	C015: [
		{ date: '10/01/2019', lots: 2 },
		{ date: '11/01/2019', lots: 3 },
		{ date: '12/01/2019', lots: 1 },
		{ date: '13/01/2019', lots: 3 },
		{ date: '14/01/2019', lots: 2 },
		{ date: '15/01/2019', lots: 0 },
		{ date: '16/01/2019', lots: 1 },
		{ date: '17/01/2019', lots: 1 },
		{ date: '18/01/2019', lots: 3 },
		{ date: '19/01/2019', lots: 1 },
		{ date: '20/01/2019', lots: 0 },
	],
};

export const planningHistoric = [
	{
		id: 'PPCP001',
		date: '2019-02-01',
		beginningDate: '2019-02-01',
		endDate: '2019-02-8',
	},
	{
		id: 'PPCP002',
		date: '2019-02-08',
		beginningDate: '2019-02-09',
		endDate: '2019-02-19',
	},
	{
		id: 'PPCP003',
		date: '2019-02-19',
		beginningDate: '2019-02-20',
		endDate: '2019-02-31',
	},
	{
		id: 'PPCP004',
		date: '2019-02-31',
		beginningDate: '2019-03-01',
		endDate: '2019-03-09',
	},
	{
		id: 'PPCP005',
		date: '2019-03-09',
		beginningDate: '2019-03-10',
		endDate: '2019-03-19',
	},
	{
		id: 'PPCP006',
		date: '2019-03-19',
		beginningDate: '2019-03-20',
		endDate: '2019-03-30',
	},
	{
		id: 'PPCP007',
		date: '2019-03-30',
		beginningDate: '2019-04-01',
		endDate: '2019-04-8',
	},
];

export const productionRecipes = [
	{
		sku: 'C001',
		name: 'arroz integral com brócolis e amêndoas',
		stepsByResource: [
			{
				resource: {
					id: '5c3cebdec0f1cc418f6f8fb9',
					name: { label: 'a1', value: 'a1' },
					limit: {
						value: 9500.0,
						type: 'time',
					},
					description: 'Recurso de Produção',
					type: 'area',
					status: 'active',
				},
				timeSetup: 1,
				timeByLot: 2,
				complexity: { label: '1', value: '1' },
			},
		],
		lotSize: 3,
		minLot: 4,
		maxLot: 4,
		minInventory: 3,
		maxInventory: 5,
		isActive: true,
	},
	{
		sku: 'C002',
		name: 'risoto de shimeji',
		stepsByResource: [
			{
				resource: {
					id: '5c3cebdec0f1cc418f6f8fb9',
					name: { label: 'a2', value: 'a2' },
					limit: {
						value: 9500.0,
						type: 'time',
					},
					description: 'Recurso de Produção',
					type: 'area',
					status: 'active',
				},
				timeSetup: 1,
				timeByLot: 2,
				complexity: { label: '2', value: '2' },
			},
		],
		lotSize: 3,
		minLot: 4,
		maxLot: 4,
		minInventory: 3,
		maxInventory: 5,
		isActive: true,
	},
	{
		sku: 'L001',
		name: 'Mix de legumes no vapor',
		stepsByResource: [
			{
				resource: {
					id: '5c3cebdec0f1cc418f6f8fb9',
					name: { label: 'a1', value: 'a1' },
					limit: {
						value: 9500.0,
						type: 'time',
					},
					description: 'Recurso de Produção',
					type: 'area',
					status: 'active',
				},
				timeSetup: 1,
				timeByLot: 2,
				complexity: { label: '3', value: '3' },
			},
		],
		lotSize: 3,
		minLot: 4,
		maxLot: 4,
		minInventory: 3,
		maxInventory: 5,
		isActive: true,
	},
	{
		sku: 'C005',
		name: 'risoto de alho poró',
		stepsByResource: [
			{
				resource: {
					id: '5c3cebdec0f1cc418f6f8fb9',
					name: { label: 'hamburgueira', value: 'hamburgueira' },
					limit: {
						value: 9500.0,
						type: 'time',
					},
					description: 'Recurso de Produção',
					type: 'area',
					status: 'active',
				},
				timeSetup: 1,
				timeByLot: 2,
				complexity: { label: '1', value: '1' },
			},
		],
		lotSize: 3,
		minLot: 4,
		maxLot: 4,
		minInventory: 3,
		maxInventory: 5,
		isActive: true,
	},
];
