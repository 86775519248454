import { Divider } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { Radio } from '../../../../components/atoms/radio/index'
import HeaderfulContainer from '../../../../components/molecules/headerfulContainer/index'

export const AllergenicsContainer = styled(HeaderfulContainer)``

export const AllergenicsRadio = styled(props => <Radio {...props} />)`
  padding-bottom: 4px;
  margin-top: -14px;
`

export const StyledDivider = styled(props => <Divider {...props} />)`
  && {
    margin-bottom: 6px;
  }
`
