import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import { Paper } from '../../../../components/atoms/paper'

export const StyledPaper = styled(Paper)`
  margin-top: 20px;
`

export const StyledCircularProgress = styled(CircularProgress)`
  margin-top: 100px;
`
