import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ContainerHeader from 'src/components/ContainerHeader'
import { LookupItem } from 'src/v2/contracts/lookupItem'
import { FilterContainer } from '../../components/filter-container'
import { RecipeCard } from '../../components/recipe-card'
import { configTags } from '../../messages/formattedMessages'
import { Divider, LoadingContainer, RecipesContainer } from './styles'

export interface Recipe {
  id: string
  code: string
  name: string
  kind: string
  kitchens: string[]
  updatedAt: string
  productInfo?: {
    barcode: string
    expiration: number
    expirationInDays: number
    expirationCut: number
    denomination: string
  }
  claims?: string
  denomination?: string
  homeMeasurementUnit?: string
  homeMeasurementQuantity?: string
}

export interface ConfigPageProps extends RouteComponentProps {
  updateConfig: (
    input: Array<{
      code: string
      kind: string
      expiration: number
      claims: string
      barcode: string
      expirationCut?: number
      denomination?: string
      showAddressOnTag?: boolean
      tagLayout?: string
      homeMeasurementUnit?: string
      homeMeasurementQuantity?: string
    }>
  ) => Promise<void>
  fetchRecipes: (blacklist: string, productInfo: boolean) => any
  fetchKitchens: () => any
  fetchSingleTagConfiguration: (code: string) => any
  recipes: { data: Recipe[]; loading: boolean }
  kitchens: { data: Array<{ id: string; name: string }> }
}

export function ConfigPage({
  updateConfig,
  fetchRecipes,
  fetchKitchens,
  fetchSingleTagConfiguration,
  recipes,
  kitchens,
}: ConfigPageProps) {
  const [filter, setFilter] = useState('')
  const [selectedKitchens, setSelectedKitchens] = useState<LookupItem[]>([])

  const kitchensOptions = kitchens?.data?.map(kitchen => ({
    value: kitchen.id,
    label: kitchen.name,
  }))

  useEffect(() => {
    fetchRecipes('IT', true)
    fetchKitchens()
  }, [])

  const handleChange = (value: string) => {
    setFilter(value)
  }

  const byRegex = (recipe: Recipe) =>
    filter.trim()
      ? new RegExp(filter, 'i').test(`${recipe.code}${recipe.name}`)
      : true

  const byKitchen = (recipe: Recipe) =>
    selectedKitchens.length > 0
      ? recipe.kitchens.some(kitchen =>
          selectedKitchens.some(
            selectedKitchen => selectedKitchen.value === kitchen
          )
        )
      : true

  const fetchTagConfiguration = (code: string) =>
    fetchSingleTagConfiguration(code)

  return (
    <div className="app-wrapper">
      <ContainerHeader location={location} title={configTags} />
      <FilterContainer
        onChange={handleChange}
        kitchens={selectedKitchens}
        onSelectKitchen={setSelectedKitchens}
        kitchensOptions={kitchensOptions}
      />
      <Divider />
      <RecipesContainer>
        {recipes?.loading && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
        {recipes?.data
          ?.filter(recipe => byRegex(recipe) && byKitchen(recipe))
          ?.map(recipe => (
            <RecipeCard
              key={`${recipe.code}`}
              recipe={recipe}
              onSave={cfg => updateConfig([cfg])}
              fetchTagConfiguration={fetchTagConfiguration}
            />
          ))}
      </RecipesContainer>
    </div>
  )
}
