import styled from 'styled-components'
import HeaderfulContainer from '../../../../components/molecules/headerfulContainer/index'
import Input from 'src/v2/components/atoms/input'
export interface ItemProps {
  xs: number
  position: 'start' | 'middle' | 'end'
}

export interface ContentProps {
  direction: 'row' | 'column'
}

export const Container = styled(HeaderfulContainer)``

export const Content = styled.div<ContentProps>`
  && {
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
  }
`

export const Item = styled.div<ItemProps>`
  && {
    flex-basis: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    max-width: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    padding: 8px ${props => (props.position === 'end' ? '0' : '8px')} 8px
      ${props => (props.position === 'start' ? '0' : '8px')};
  }
`

export const StyledInput = styled(Input)`
  && {
    width: 100%;
  }
`
