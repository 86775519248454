export default theme => ({
  fab: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit,
  },
  icon: {
    fontSize: '16px',
  },
  container: {
    marginTop: theme.spacing.unit * 4,
  },
})
