import {
  SIGNIN_REQUEST,
  SIGNIN_RESPONSE,
  SIGNIN_ERROR,
  SIGNOUT_REQUEST,
  SIGNOUT_RESPONSE,
  SIGNOUT_ERROR,
  SET_USER,
} from 'constants/ActionTypes'

import * as authToken from '../utils/authToken'
import { getJwtPayload } from '../utils/decodeToken'
import { isValidAccount } from '../utils/isValidAccount'

export const fetchUser = history => async (dispatch, _, api) => {
  try {
    const token = await authToken.getToken()
    const account = await getJwtPayload(token)
    if (isValidAccount(account)) {
      await dispatch({
        type: SET_USER,
        payload: { data: account },
      })
    } else {
      authToken.discardToken()
      history.replace('/login')
    }
  } catch (error) {
    dispatch({
      type: SIGNIN_ERROR,
      payload: error,
    })
  }
}

export const signIn =
  (email, password, history) => async (dispatch, getState, api) => {
    try {
      dispatch({
        type: SIGNIN_REQUEST,
      })

      const { data } = await api.post(
        `${process.env.AUTH_URL}/api/auth/email/signin`,
        {
          email,
          password,
        }
      )
      if (authToken.storeToken(data.token)) {
        const account = await getJwtPayload(data.token)
        await dispatch(fetchUser(history))
        if (isValidAccount(account)) {
          dispatch({
            type: SIGNIN_RESPONSE,
          })
        } else {
          dispatch({
            type: SIGNIN_ERROR,
            payload: 'Usuário não possui as permissões necessárias.',
          })
        }
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: SIGNIN_ERROR,
        payload: 'Verifique seu e-mail e senha',
      })
    }
  }

export const signOut = history => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: SIGNOUT_REQUEST,
    })

    authToken.discardToken()
    history.replace('/login')
    dispatch({
      type: SIGNOUT_RESPONSE,
    })
  } catch (err) {
    dispatch({
      type: SIGNOUT_ERROR,
      payload: err,
    })
  }
}

export const resetState = () => async (dispatch, _, api) => {
  dispatch({
    type: SIGNOUT_RESPONSE,
  })
}
