import React from 'react'
import { Grid } from '@material-ui/core'

import {
  ActionsContainer,
  Card,
  EditIcon,
  EditLabel,
  Title,
  Status,
  Feedstock,
} from './styles'

interface IngredientCardProps {
  title: string
  active: boolean
  disableEdit: boolean
  feedstock: string
  onEdit?: () => void
}

function IngredientCard(props: IngredientCardProps) {
  const { title, active, feedstock, disableEdit, onEdit } = props

  const handleEdit = () => {
    if (disableEdit) {
      return
    }

    onEdit && onEdit()
  }

  return (
    <Card elevation={1}>
      <Grid container spacing={16}>
        <Grid item md={6} sm={4} xs={3}>
          <Title>{title}</Title>
        </Grid>
        <Grid item md={4} sm={4} xs={3}>
          <Feedstock>{feedstock}</Feedstock>
        </Grid>
        <Grid item md={1} sm={2} xs={3}>
          <Status active={active}>{active ? 'Ativo' : 'Inativo'}</Status>
        </Grid>
        <Grid item md={1} sm={2} xs={3}>
          <ActionsContainer onClick={handleEdit}>
            <EditLabel>Editar</EditLabel>
            <EditIcon />
          </ActionsContainer>
        </Grid>
      </Grid>
    </Card>
  )
}

export default IngredientCard
