import Button from '@material-ui/core/Button'
import { SideNavItem } from 'components/SideNavItem'
import 'jquery-slimscroll/jquery.slimscroll.min'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import IntlMessages from 'utils/IntlMessages'
import defineMessages from './Messages'

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props
    const slideDuration = 250

    const pathname = `#${history.location.pathname}` // get current path

    $('ul.nav-menu > li.menu').click(function () {
      const menuLi = this
      $('ul.nav-menu > li.menu').not(menuLi).removeClass('open')
      $('ul.nav-menu > li.menu ul').not($('ul', menuLi)).slideUp(slideDuration)
      $('> ul', menuLi).slideToggle(slideDuration)
      $(menuLi).toggleClass('open')
    })

    $('ul.sub-menu li').click(function (e) {
      const superSubMenu = $(this).parent()
      if (superSubMenu.parent().hasClass('active')) {
        $('li', superSubMenu).not($(this)).removeClass('active')
      } else {
        $('ul.sub-menu li').not($(this)).removeClass('active')
      }

      $(this).toggleClass('active')
      e.stopPropagation()
    })

    const activeLi = $(`a[href="${pathname}"]`) // select current a element
    const activeNav = activeLi.closest('ul') // select closest ul
    if (activeNav.hasClass('sub-menu')) {
      activeNav.slideDown(slideDuration)
      activeNav.parent().addClass('open')
      activeLi.parent().addClass('active')
    } else {
      activeLi.parent().addClass('open')
    }
  }

  subMenu = (message, disabled = false, items) =>
    !disabled && (
      <li className="menu">
        <Button href="javascript:void(0)">
          <i className="zmdi zmdi-file-text" />
          <span className="nav-text">{message}</span>
        </Button>
        <ul className="sub-menu">{items.map(item => item)}</ul>
      </li>
    )

  render() {
    const isPPCP = process.env.FEATURE_FLAG.split(',').includes('ppcp')
    const isSimulate = process.env.FEATURE_FLAG.split(',').includes('simulate')
    const isVersioning =
      process.env.FEATURE_FLAG.split(',').includes('versioning')
    return (
      <ul className="nav-menu">
        <li className="nav-header">
          <IntlMessages id="sidebar.main" />
        </li>
        <li className="menu no-arrow">
          <NavLink to="/app">
            <span className="nav-text">
              <IntlMessages id="pages.home" />
            </span>
          </NavLink>
        </li>

        {this.subMenu(defineMessages.tags.defaultMessage, false, [
          <SideNavItem
            key={defineMessages.configTags.id}
            icon={<i className="zmdi zmdi-view-list" />}
            link="/app/tags/config"
            message={defineMessages.configTags.defaultMessage}
            disabled={false}
          />,
          <SideNavItem
            key={defineMessages.printTags.id}
            icon={<i className="zmdi zmdi-view-list" />}
            link="/app/tags/print"
            message={defineMessages.printTags.defaultMessage}
            disabled={false}
          />,
        ])}

        {this.subMenu(defineMessages.feedstock.defaultMessage, false, [
          <SideNavItem
            key={defineMessages.feedstockSearch.id}
            icon={<i className="zmdi zmdi-view-list" />}
            link="/app/feedstock/search"
            message={defineMessages.feedstockSearch.defaultMessage}
            disabled={false}
          />,
          <SideNavItem
            key={defineMessages.registerFeedstock.id}
            icon={<i className="zmdi zmdi-view-list" />}
            link="/app/feedstock/register/regular"
            message={defineMessages.registerFeedstock.defaultMessage}
            disabled={false}
          />,
        ])}

        {this.subMenu(defineMessages.ingredients.defaultMessage, false, [
          <SideNavItem
            key={defineMessages.searchIngredients.id}
            link="/app/ingredients/search"
            icon={<i className="zmdi zmdi-search" />}
            message={defineMessages.searchIngredients.defaultMessage}
            disabled={false}
          />,
          <SideNavItem
            key={defineMessages.createIngredient.id}
            link="/app/ingredients/create"
            icon={<i className="zmdi zmdi-file-plus" />}
            message={defineMessages.createIngredient.defaultMessage}
            disabled={false}
          />,
        ])}

        {this.subMenu(defineMessages.recipes.defaultMessage, isSimulate, [
          <SideNavItem
            key={defineMessages.simulateRecipes.id}
            icon={<i className="zmdi zmdi-view-list" />}
            link="/app/recipes/simulate"
            message={defineMessages.simulateRecipes.defaultMessage}
            disabled={isSimulate}
          />,
          <SideNavItem
            key={defineMessages.createRecipes.id}
            icon={<i className="zmdi zmdi-file-plus" />}
            link="/app/recipes/create"
            message={defineMessages.createRecipes.defaultMessage}
            disabled={isVersioning}
          />,
          <SideNavItem
            key={defineMessages.printRecipes.id}
            icon={<i className="zmdi zmdi-print" />}
            link="/app/recipes/print"
            message={defineMessages.printRecipes.defaultMessage}
            disabled={isVersioning}
          />,
          <SideNavItem
            key={defineMessages.searchRecipes.id}
            icon={<i className="zmdi zmdi-search" />}
            link="/app/recipes/search"
            message={defineMessages.searchRecipes.defaultMessage}
            disabled={isVersioning}
          />,
        ])}

        {this.subMenu(defineMessages.productionPlanning.defaultMessage, false, [
          <SideNavItem
            key={defineMessages.importProductionPlanning.id}
            link="/app/production-planning/import"
            message={defineMessages.importProductionPlanning.defaultMessage}
          />,
          <SideNavItem
            key={defineMessages.processConfig.id}
            link="/app/ingredients-process"
            message={defineMessages.processConfig.defaultMessage}
            disabled={false}
          />,
        ])}

        {this.subMenu(defineMessages.shop.defaultMessage, false, [
          <SideNavItem
            key={defineMessages.ingredientsShop.id}
            link="/app/ingredients-shop/export"
            message={defineMessages.ingredientsShop.defaultMessage}
          />,
        ])}

        {this.subMenu(defineMessages.ppcp.defaultMessage, isPPCP, [
          <SideNavItem
            key={defineMessages.searchResources.id}
            link="/app/resources/search"
            message={defineMessages.searchResources.defaultMessage}
            disabled={isPPCP}
          />,
          <SideNavItem
            key={defineMessages.registerResources.id}
            link="/app/resources/register"
            message={defineMessages.registerResources.defaultMessage}
            disabled={isPPCP}
          />,
          <SideNavItem
            key={defineMessages.searchProductionRecipe.id}
            link="/app/productionRecipe/Search"
            message={defineMessages.searchProductionRecipe.defaultMessage}
            disabled={isPPCP}
          />,
          <SideNavItem
            key={defineMessages.registerProductionRecipe.id}
            link="/app/productionRecipe/Register"
            message={defineMessages.registerProductionRecipe.defaultMessage}
            disabled={isPPCP}
          />,
          <SideNavItem
            key={defineMessages.registerProductionGroup.id}
            link="/app/productionGroups/Register"
            message={defineMessages.registerProductionGroup.defaultMessage}
            disabled={isPPCP}
          />,
          <SideNavItem
            key={defineMessages.ppcpStart.id}
            link="/app/ppcp/input"
            message={defineMessages.ppcpStart.defaultMessage}
            disabled={isPPCP}
          />,
          <SideNavItem
            key={defineMessages.planningHistoric.id}
            link="/app/ppcp/input"
            message={defineMessages.planningHistoric.defaultMessage}
            disabled={isPPCP}
          />,
        ])}
      </ul>
    )
  }
}

SidenavContent.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(SidenavContent)
