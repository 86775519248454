import { defineMessages } from 'react-intl'

export default defineMessages({
  recipeVersioningHistoric: {
    id: 'app.recipeVersioningHistoric.title',
    defaultMessage: 'Histórico de Versões',
  },
  versionNumber: {
    id: 'app.recipeVersioningHistoric.versionNumber',
    defaultMessage: 'Versão',
  },
  inProduction: {
    id: 'app.recipeVersioningHistoric.inProduction',
    defaultMessage: 'Ativa',
  },
  changeReason: {
    id: 'app.recipeVersioningHistoric.changeReason',
    defaultMessage: 'Motivo da Mudança',
  },
  personInCharge: {
    id: 'app.recipeVersioningHistoric.personInCharge',
    defaultMessage: 'Responsável',
  },
  originVersionNumber: {
    id: 'app.recipeVersioningHistoric.originVersionNumber',
    defaultMessage: 'Versão de Origem',
  },
  activeVersion: {
    id: 'app.recipeVersioningHistoric.activeVersion',
    defaultMessage: 'Versão ativa: {activerVersionNumber}',
  },
  reason: {
    id: 'app.recipeVersioningHistoric.reason',
    defaultMessage: 'Razão da Mudança: {reason}',
  },
  responsible: {
    id: 'app.recipeVersioningHistoric.responsible',
    defaultMessage: 'Responsável: {responsible}',
  },
})
