import { validatePositiveNumber } from '../../../helperFunctions/validateInput'

export const required = errorMessage => value => {
  if (!value && value !== false && value !== 0) {
    return [false, errorMessage]
  }

  return [true, null]
}

export const positiveNumber = (errorMessage, options) => value => {
  const validation = validatePositiveNumber(value, options)
  if (validation || validation === 0) {
    return [true, null]
  }
  return [false, errorMessage]
}
