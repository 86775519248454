import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { withConsumer } from 'helperFunctions/withConsumer'
import { isNull } from 'utils/isNull'
import { FormContext } from '..'

const getFinalInputValue = (inputValue, childValue, stateValue) => {
  if (typeof inputValue === 'number' && inputValue === 0) return 0
  if (typeof childValue === 'number' && childValue === 0) return 0
  if (typeof stateValue === 'number' && stateValue === 0) return 0
  return inputValue || childValue || stateValue
}

class InputValidator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      value: getFinalInputValue(
        props.inputValue,
        props.children.props.value || props.children.props.selectedItem
      ),
    }
  }

  componentDidMount() {
    const { id } = this.props
    if (id) {
      this.onStatusChange = this.props.registerValidator(id, this.validate)
    }
  }

  componentWillUnmount() {
    const { id } = this.props
    if (id) {
      this.props.removeValidator(id)
    }
  }

  getSubscribedChildren() {
    const { children } = this.props
    const hasErrors = this.state.errors.length > 0

    const subscribedChildren = React.Children.map(children, child =>
      React.cloneElement(child, {
        onBlur: this.handleBlur,
        error: hasErrors,
      })
    )
    return subscribedChildren
  }

  handleBlur = event => {
    const { value } = event.target
    this.setState({
      value,
    })
    this.validate(value)
  }

  validate = argValue => {
    const { validations } = this.props
    const children = this.getSubscribedChildren()[0]
    const childrenValue =
      children &&
      children.props &&
      (children.props.value || children.props.selectedItem)

    const value = isNull(argValue)
      ? getFinalInputValue(
          this.props.inputValue,
          childrenValue,
          this.state.value
        )
      : argValue
    const errors = validations
      .map(validation => validation(value))
      .filter(([isValid, _error]) => !isValid)
      .map(([_isValid, error]) => error)

    this.setState({
      errors,
    })
    this.onStatusChange(errors)
    return errors
  }

  render() {
    const children = this.getSubscribedChildren()
    const firstError = this.state.errors[0]
    return firstError ? (
      <Grid container direction="column">
        <Grid item>{children}</Grid>
        <Grid item>
          <Typography variant="caption" color="error">
            <FormattedMessage {...firstError} />
          </Typography>
        </Grid>
      </Grid>
    ) : (
      children
    )
  }
}

InputValidator.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  validations: PropTypes.array.isRequired,
  registerValidator: PropTypes.func.isRequired,
}

export default withConsumer(FormContext)(InputValidator)
