import React, { Component } from 'react'
import dotProp from 'dot-prop-immutable'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputField from 'components/InputField'
import Checkbox from '@material-ui/core/Checkbox'
import CardBox from 'components/CardBox'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
} from '@material-ui/core'
import { Radio } from '../../../../v2/components/atoms/radio'
import { Typography } from '../../../../v2/components/atoms/typography'
import { fieldLabels } from '../formattedMessages'
import { getSelectedKitchenId } from '../../helpers'

class SearchPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recipe: '',
      ingredient: '',
      openConfirmation: false,
      includeInactives: false,
      selectedKitchen: '',
    }
  }

  handleChange = (fieldName, field) => {
    const state = dotProp.set(this.state, fieldName, field)
    this.setState(state)
  }

  searchSubmit = event => {
    event.preventDefault()
    const { openConfirmation, ...state } = this.state
    this.props.onSearchSubmit(state)
  }

  recalculateAllRecipes = () => {
    this.props.recalculateAllRecipes()
    this.setState({ openConfirmation: false })
    this.props.emitSnackbar()
  }

  handleCheckboxChange = () => {
    const state = dotProp.set(
      this.state,
      'includeInactives',
      !this.state.includeInactives
    )
    this.setState(state)
  }

  openConfirmationDialog = () => {
    this.setState({ openConfirmation: true })
  }

  closeConfirmationDialog = () => {
    this.setState({ openConfirmation: false })
  }
  handleSelectedKitchen = index => {
    const { kitchens } = this.props
    const kitchenLabels = kitchens && kitchens.map(kitchen => kitchen.name)
    const selectedKitchenLabel = kitchenLabels[index]
    this.setState({
      selectedKitchen: getSelectedKitchenId(selectedKitchenLabel, kitchens),
    })
  }

  render() {
    const includeInactivesLabel = (
      <InputLabel>{fieldLabels.includeInactives}</InputLabel>
    )
    const { kitchens } = this.props
    const kitchenLabels = kitchens && kitchens.map(kitchen => kitchen.name)
    const selectedKitchen =
      kitchens &&
      kitchens.find(kitchen => kitchen.id === this.state.selectedKitchen)
    const selectedKitchenLabel = selectedKitchen && selectedKitchen.name
    return (
      <CardBox styleName="col-sm-12">
        <Grid container spacing={16} alignItems="center">
          <Grid item xs={6}>
            <InputField
              value={this.state.recipe}
              onChange={this.handleChange}
              label={fieldLabels.recipe}
              fieldName="recipe"
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              value={this.state.ingredient}
              onChange={this.handleChange}
              label={fieldLabels.ingredient}
              fieldName="ingredient"
            />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              checked={this.state.includeInactives}
              onChange={this.handleCheckboxChange}
              value="includeInactives"
            />
            {includeInactivesLabel}
          </Grid>
          {kitchens && <Grid item xs={6} />}
          {kitchens && (
            <Grid item style={{ paddingLeft: 24 }}>
              <Typography fontSize="medium" color="black">
                Selecione a cozinha de origem:
              </Typography>
              <Radio
                labels={kitchenLabels}
                direction="row"
                selected={selectedKitchenLabel}
                onSelect={this.handleSelectedKitchen}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Button
                onClick={this.searchSubmit}
                variant="contained"
                color="primary"
                type="submit"
                className="jr-btn text-blue-gray"
              >
                Buscar
              </Button>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.openConfirmationDialog}
                  className="jr-btn text-blue-gray"
                >
                  Recalcular Preços e Tabelas Nutricionais
                </Button>
                <Dialog
                  open={this.state.openConfirmation}
                  onClose={this.closeConfirmationDialog}
                >
                  <DialogTitle>
                    {'Recalcular Custo por Saquinho e Tabela Nutricional'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Você tem certeza que deseja recalcular o custo por
                      saquinho e tabela nutricional de todas as receitas?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.closeConfirmationDialog}
                      className="jr-btn text-blue-gray"
                    >
                      NÃO
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.recalculateAllRecipes}
                      className="jr-btn text-blue-gray"
                    >
                      SIM
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardBox>
    )
  }
}

SearchPanel.propTypes = {
  onSearchSubmit: PropTypes.func.isRequired,
  recalculateAllRecipes: PropTypes.func.isRequired,
  emitSnackbar: PropTypes.func.isRequired,
}

export default SearchPanel
