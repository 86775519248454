import React from 'react'
import ContainerHeader from '../../components/ContainerHeader'
import IngredientProcessPage from './pages/IngredientProcessPage'

function IngredientProcessContainer(props) {
  return (
    <div className="app-wrapper">
      <ContainerHeader
        location={props.location}
        title="Cadastrar Processos de Ingredientes"
      />
      <IngredientProcessPage />
    </div>
  )
}

export default IngredientProcessContainer
