import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { areas, categories, feedstockTypes } from 'v2/contracts/feedstock'
import { nutritionalInfoSources } from 'v2/contracts/nutritionalInfo'
import { withRouter } from 'react-router-dom'
import {
  arrayToLookupItem,
  suppliersToLookupItems,
} from 'v2/feedstock/register/logic'
import {
  getAllergenics,
  getNutritionalInfo,
  getSuppliers,
  saveFeedstock,
  getNextAvailableCode,
  getFeedstock,
  getProducts,
  deleteFeedstock,
  clearStore,
} from '../actions/'
import FeedstockRegisterPage from '../pages/FeedstockRegisterPage'
import { Container, CircularProgressContainer } from './styles'
import { sendNotification } from '../../../../actions/Notifications'
import { fetchKitchens } from '../../../../actions/Reception'

const FeedstockRegisterContainer = ({ ...props }) => {
  useEffect(() => {
    if (props.match && props.match.params && props.match.params.code) {
      const {
        match: {
          params: { code },
        },
      } = props
      props.getFeedstock(code)
    }
    props.getSuppliers()
    props.getProducts({ sku: encodeURIComponent('^F8') })
    props.getAllergenics()
    props.fetchKitchens()
    return props.clearStore
  }, [])

  useEffect(() => {
    if (props.deleted) {
      props.history.push({
        pathname: `/app/feedstock/search`,
      })
    }
  }, [props.deleted])

  const handleSave = data => {
    props.saveFeedstock(data)
    props.history.push({
      pathname: `/app/feedstock/search`,
    })
  }

  const handleDelete = () => {
    props.deleteFeedstock(props.feedstock.code)
  }

  return (
    <Container>
      {props.productsLoading ||
      props.feedstockLoading ||
      props.kitchens.loading ||
      !props.suppliers ||
      !props.allergenics ? (
        <CircularProgressContainer>
          <CircularProgress size={50} />
        </CircularProgressContainer>
      ) : (
        <FeedstockRegisterPage
          sendNotification={props.sendNotification}
          nextAvailableCodeLoading={props.nextAvailableCodeLoading}
          nutritionalInfosLoading={props.nutritionalInfosLoading}
          nextAvailableCode={props.nextAvailableCode}
          getNutritionalInfo={props.getNutritionalInfo}
          getNextAvailableCode={props.getNextAvailableCode}
          categoriesToGenerateCode={arrayToLookupItem(categories)}
          feedstockCategories={arrayToLookupItem(areas)}
          feedstockTypes={feedstockTypes}
          nutritionalInfoSources={arrayToLookupItem(nutritionalInfoSources)}
          nutritionalInfos={props.nutritionalInfos}
          allergenics={props.allergenics}
          suppliers={suppliersToLookupItems(props.suppliers)}
          onSave={handleSave}
          kitchens={props.kitchens}
          feedstock={props.feedstock}
          saving={props.saving}
          authUser={props.authUser}
          products={
            props.products &&
            props.products.map(p => ({ label: p.name, value: p.sku }))
          }
          onDeleteFeedstock={handleDelete}
          deleting={props.deleting}
        />
      )}
    </Container>
  )
}
const mapStateToProps = ({ feedstockRegister, auth, receptions }) => ({
  allergenics: feedstockRegister.allergenics,
  allergenicsLoading: feedstockRegister.allergenicsLoading,
  nutritionalInfos: feedstockRegister.nutritionalInfos,
  nutritionalInfosLoading: feedstockRegister.nutritionalInfosLoading,
  suppliers: feedstockRegister.suppliers,
  suppliersLoading: feedstockRegister.suppliersLoading,
  nextAvailableCodeLoading: feedstockRegister.nextAvailableCodeLoading,
  nextAvailableCode: feedstockRegister.nextAvailableCode,
  saving: feedstockRegister.saving,
  feedstock: feedstockRegister.feedstock,
  feedstockLoading: feedstockRegister.feedstockLoading,
  feedstockError: feedstockRegister.feedstockError,
  authUser: auth.authUser,
  kitchens: receptions.kitchens.data,
  products: feedstockRegister.products,
  productsLoading: feedstockRegister.productsLoading,
  productsError: feedstockRegister.productsError,
  deleted: feedstockRegister.deleted,
  deleting: feedstockRegister.deleting,
})

const mapDispatchToProps = {
  getAllergenics,
  getNutritionalInfo,
  getSuppliers,
  saveFeedstock,
  getNextAvailableCode,
  sendNotification,
  getFeedstock,
  fetchKitchens,
  getProducts,
  deleteFeedstock,
  clearStore,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedstockRegisterContainer)
)
