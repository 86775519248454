import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FIXED_DRAWER, COLLAPSED_DRAWER } from 'constants/ActionTypes'
import { toggleDrawerType } from 'actions/Setting'
import {
  clearGeneratedRecipeSKU,
  generateRecipeSKU,
  deleteRecipeSKU,
  handleChangeRecipeInfo,
  fetchRecipe,
  calculateNutritionalTable,
  calculateRecipeCost,
  fetchRecipeUsage,
  fetchRecipeRelatedProducts,
  updateRecipeRelatedProducts,
  updateRecipeVersion,
  removeAllAllergenics,
  updateRecipe,
  fetchCookingMethods,
  fetchRecipeAllergenics,
  fetchIngredientAllergenics,
  removeAllergenic,
  editCurrentRecipeNutritionalInfo,
  updateRecipeManuallyCalculatedValue,
  fetchProductionLocations,
} from 'actions/Recipes'
import { sendNotification } from 'actions/Notifications'
import { fetchKitchens } from '../../../../actions/Reception'
import CreateRecipeForm from '../../Create/components/CreateRecipeForm'
import ActivationHeader from '../components/ActivationHeader'
import ListDialog from '../../../../components/ListDialog'
import { shouldUpdateNutritionalInfo } from '../../logic'

class EditRecipeContainer extends Component {
  state = {
    activationDialogOpened: false,
    productsListOpened: false,
    products: [],
  }

  componentWillMount() {
    this.props.fetchCookingMethods()
    this.props.fetchKitchens()
    this.props.fetchProductionLocations()
  }

  componentDidMount() {
    const { match } = this.props
    const recipeCode = match && match.params && match.params.code
    this.props.fetchRecipe(recipeCode)
    this.props.toggleDrawerType(COLLAPSED_DRAWER)
  }

  componentDidUpdate() {
    const {
      activationSuccess,
      loading,
      match,
      relatedProducts,
      loadingRelatedProducts,
    } = this.props

    const { products } = this.state

    if (!loading && activationSuccess) {
      const recipeCode = match && match.params && match.params.code
      this.props.fetchRecipe(recipeCode)
      this.props.sendNotification({
        type: 'success',
        message: 'Receita ativada com sucesso!',
      })
    }

    if (
      !loadingRelatedProducts &&
      relatedProducts.length &&
      products.length !== relatedProducts.length
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        products: relatedProducts.map(product => ({
          ...product,
          checked: false,
        })),
      })
    }
  }

  componentWillUnmount() {
    this.props.toggleDrawerType(FIXED_DRAWER)
    this.props.removeAllAllergenics()
    this.props.clearGeneratedRecipeSKU()
  }

  handleToggleClick = () => {
    const { match } = this.props
    const recipeCode = match && match.params && match.params.code
    this.setState({ activationDialogOpened: true })
    this.props.fetchRecipeUsage(recipeCode)
  }

  handleActivationClick = () => {
    const { match, userEmail } = this.props
    const recipeCode = match && match.params && match.params.code
    this.setState({ activationDialogOpened: false })
    this.props.updateRecipeVersion(recipeCode, userEmail)
  }

  handleActivationDialogClose = () =>
    this.setState({ activationDialogOpened: false })

  handleProductsListClick = () => {
    const { match } = this.props
    const recipeCode = match && match.params && match.params.code
    this.setState({ productsListOpened: true, products: [] })
    this.props.fetchRecipeRelatedProducts(recipeCode)
  }

  handleUpdateProductsClick = () => {
    const { products } = this.state
    const selectedProducts = products.filter(product => product.checked)
    this.setState({ productsListOpened: false })
    this.props.updateRecipeRelatedProducts(selectedProducts)
  }

  handleProductsListClose = () => this.setState({ productsListOpened: false })

  handleCheckboxChange = (sku, checked) => {
    const { products } = this.state
    const index = products.findIndex(product => product.sku === sku)
    products[index].checked = checked
    this.setState({ products })
  }

  render() {
    const {
      allergenics,
      cookingMethods,
      costDirty,
      dirty,
      kitchens,
      loading,
      loadingRecipeUsage,
      loadingRelatedProducts,
      recipe,
      recipeUsages,
      relatedProductsError,
      updateRecipeNutritionalInfo,
    } = this.props

    const { activationDialogOpened, productsListOpened, products } = this.state

    if (relatedProductsError) this.handleProductsListClose()

    const sku = recipe && recipe.recipeInfo && recipe.recipeInfo.sku
    const activateDisabled = shouldUpdateNutritionalInfo(recipe)
      ? dirty || (recipe && recipe.inProduction)
      : costDirty || (recipe && recipe.inProduction)

    return (
      <div>
        {!loading && (
          <ActivationHeader
            tooltip={
              recipe && recipe.inProduction
                ? 'Para desativar uma versão é preciso ativar outra :)'
                : 'Ativar versão'
            }
            active={recipe && recipe.inProduction}
            onToggleClick={this.handleToggleClick}
            disabled={activateDisabled}
            onProductsListClick={this.handleProductsListClick}
          />
        )}
        <ListDialog
          open={activationDialogOpened}
          title="Ativação de receita"
          subTitle={
            'Confirma que os alergênicos e os dados estão corretos? ' +
            'Ao ativar essa receita as receitas abaixo serão impactadas,' +
            ' deseja continuar?'
          }
          items={
            recipeUsages &&
            recipeUsages.map(recipeUsage => ({
              primaryText: recipeUsage.name,
              secondaryText: `${recipeUsage.code}`,
              onClick: () => {},
            }))
          }
          loading={loadingRecipeUsage}
          actions={[
            {
              text: 'Cancelar',
              handler: this.handleActivationDialogClose,
            },
            {
              text: 'Confirmar',
              color: 'secondary',
              handler: this.handleActivationClick,
            },
          ]}
          onClose={this.handleActivationDialogClose}
        />
        <ListDialog
          open={productsListOpened}
          title="Atualização de Produtos"
          subTitle={
            'Essa receita impacta as informações nutricionais dos seguintes ' +
            'produtos. Selecione aqueles que deseja atualizar com as ' +
            'informações dessa versão.'
          }
          checkbox
          onCheckboxChange={this.handleCheckboxChange}
          items={products.map(product => ({
            primaryText: product.name,
            secondaryText: product.sku,
            checked: product.checked,
          }))}
          loading={loadingRelatedProducts}
          actions={[
            {
              text: 'Cancelar',
              handler: this.handleProductsListClose,
            },
            {
              text: 'Atualizar',
              color: 'secondary',
              handler: this.handleUpdateProductsClick,
            },
          ]}
          onClose={this.handleActivationDialogClose}
        />
        <CreateRecipeForm
          allergenics={allergenics}
          allergenicsLoading={this.props.allergenicsLoading}
          calculateNutritionalTable={this.props.calculateNutritionalTable}
          cookingMethods={cookingMethods}
          costDirty={this.props.costDirty}
          deleteRecipeSku={this.props.deleteRecipeSKU}
          dirty={this.props.dirty}
          editing
          fetchIngredientAllergenics={this.props.fetchIngredientAllergenics}
          fetchRecipeAllergenics={this.props.fetchRecipeAllergenics}
          generateSKU={this.props.generateRecipeSKU}
          handleChangeRecipeInfo={this.props.handleChangeRecipeInfo}
          handleSubmit={this.props.updateRecipe}
          kitchens={kitchens.data}
          loading={loading}
          loadingNutritionalTable={this.props.loadingNutritionalTable}
          onManuallyCalculatedValueChange={
            this.props.updateRecipeManuallyCalculatedValue
          }
          onNutritionalFactsChange={updateRecipeNutritionalInfo}
          productionLocations={this.props.productionLocations}
          productionLocationsLoading={this.props.productionLocationsLoading}
          recipe={recipe}
          removeAllergenic={this.props.removeAllergenic}
          sku={sku}
          updateRecipeCost={this.props.calculateRecipeCost}
        />
      </div>
    )
  }
}

EditRecipeContainer.propTypes = {
  calculateNutritionalTable: PropTypes.func.isRequired,
  calculateRecipeCost: PropTypes.func.isRequired,
  clearGeneratedRecipeSKU: PropTypes.func.isRequired,
  cookingMethods: PropTypes.array,
  deleteRecipeSKU: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  fetchProductionLocations: PropTypes.func.isRequired,
  fetchRecipe: PropTypes.func.isRequired,
  fetchRecipeRelatedProducts: PropTypes.func.isRequired,
  fetchRecipeUsage: PropTypes.func.isRequired,
  generateRecipeSKU: PropTypes.func.isRequired,
  handleChangeRecipeInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingNutritionalTable: PropTypes.bool,
  loadingRecipeUsage: PropTypes.bool,
  loadingRelatedProducts: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
  }),
  productionLocations: PropTypes.array,
  productionLocationsLoading: PropTypes.bool,
  recipe: PropTypes.object,
  recipeUsages: PropTypes.array,
  relatedProducts: PropTypes.array,
  relatedProductsError: PropTypes.bool,
  toggleDrawerType: PropTypes.func.isRequired,
  updateRecipe: PropTypes.func.isRequired,
  updateRecipeRelatedProducts: PropTypes.func.isRequired,
  updateRecipeVersion: PropTypes.func.isRequired,
}

const mapActionsToProps = {
  calculateNutritionalTable: calculateNutritionalTable(true),
  calculateRecipeCost: calculateRecipeCost(true),
  clearGeneratedRecipeSKU,
  deleteRecipeSKU,
  fetchCookingMethods,
  fetchIngredientAllergenics,
  fetchKitchens,
  fetchProductionLocations,
  fetchRecipe,
  fetchRecipeAllergenics,
  fetchRecipeRelatedProducts,
  fetchRecipeUsage,
  generateRecipeSKU,
  handleChangeRecipeInfo: handleChangeRecipeInfo(true),
  removeAllAllergenics,
  removeAllergenic,
  sendNotification,
  toggleDrawerType,
  updateRecipe,
  updateRecipeManuallyCalculatedValue,
  updateRecipeNutritionalInfo: editCurrentRecipeNutritionalInfo,
  updateRecipeRelatedProducts,
  updateRecipeVersion,
}

const mapStateToProps = state => ({
  activationSuccess: state.recipes.currentRecipe.activationSuccess,
  allergenics:
    state.recipes.allergenics &&
    Array.from(
      // removes the duplicates
      new Set(
        Object.values(state.recipes.allergenics).reduce(
          (prev, curr) => [...prev, ...curr.allergenicsNames],
          []
        )
      )
    ),
  allergenicsLoading: state.recipes.allergenicsLoading,
  costDirty: state.recipes.currentRecipe.costDirty,
  cookingMethods: state.recipes.cookingMethods,
  dirty: state.recipes.currentRecipe.dirty,
  kitchens: state.receptions.kitchens,
  loading: state.recipes.currentRecipe.loading,
  loadingNutritionalTable: state.recipes.currentRecipe.loadingNutritionalTable,
  loadingRecipeUsage: state.recipes.currentRecipe.loadingRecipeUsage,
  loadingRelatedProducts: state.recipes.currentRecipe.loadingRelatedProducts,
  productionLocations: state.recipes.productionLocations,
  productionLocationsLoading: state.recipes.productionLocationsLoading,
  recipe: state.recipes.currentRecipe.data,
  recipeUsages: state.recipes.currentRecipe.recipeUsages,
  relatedProducts: state.recipes.currentRecipe.relatedProducts,
  relatedProductsError: state.recipes.currentRecipe.relatedProductsError,
})

EditRecipeContainer.defaultProps = {
  cookingMethods: [],
  dirty: false,
  loading: false,
  loadingNutritionalTable: false,
  loadingRecipeUsage: false,
  loadingRelatedProducts: false,
  match: {},
  productionLocations: [],
  productionLocationsLoading: false,
  recipe: {},
  recipeUsages: [],
  relatedProducts: [],
  relatedProductsError: false,
}

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(EditRecipeContainer)
)
