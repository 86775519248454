import React, { Component } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import PropTypes from 'prop-types'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import { reactDates } from 'utils/customPropTypes'

class PeriodSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: null,
    }
  }

  forbidPastDates = () => false

  render() {
    const { onDatesChange, startDate, endDate, isOutsideRange } = this.props
    return (
      <DateRangePicker
        startDate={startDate}
        startDateId="ppcp_start_date"
        endDate={endDate}
        endDateId="ppcp_end_date"
        onDatesChange={onDatesChange}
        focusedInput={this.state.focusedInput}
        isOutsideRange={isOutsideRange ? () => false : () => true}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
      />
    )
  }
}

PeriodSelector.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  startDate: reactDates,
  endDate: reactDates,
  isOutsideRange: PropTypes.bool,
}

PeriodSelector.defaultProps = {
  startDate: null,
  endDate: null,
  isOutsideRange: true,
}

export default PeriodSelector
