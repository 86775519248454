import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from '../Messages'

export const tableHeader = [
  <FormattedMessage {...defineMessages.recipeName} />,
  <FormattedMessage {...defineMessages.recipeCode} />,
  <FormattedMessage {...defineMessages.recipeLastUpdate} />,
  <FormattedMessage {...defineMessages.recipeEdit} />,
  <FormattedMessage {...defineMessages.recipeHistory} />,
  <FormattedMessage {...defineMessages.versionInfo} />,
]

export const fieldLabels = {
  recipe: <FormattedMessage {...defineMessages.recipeFieldLabel} />,
  ingredient: <FormattedMessage {...defineMessages.ingredientFieldLabel} />,
  includeInactives: (
    <FormattedMessage {...defineMessages.includeInactivesFieldLabel} />
  ),
}

export const searchRecipesTitle = (
  <FormattedMessage {...defineMessages.recipeSearchTitle} />
)
