import React from 'react'
import dotProp from 'dot-prop-immutable'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DateSelect from 'components/DateSelect'
import PropTypes from 'prop-types'
import InputField from 'components/InputField'
import { parseCurrencyStringToFloat } from 'utils/parsers'
import InputValidator from 'components/Form/validators/SimpleInputValidator'
import { required } from 'components/Form/validations'

const ReceptionInfoRow = ({
  classes,
  receptionInfo,
  onReceptionInfoUpdate,
  feedstockPricesArray,
}) => {
  const getFeedstockName = () =>
    receptionInfo.feedstock ? receptionInfo.feedstock.feedstockName : '-'

  const getReceivedPriceByKg = () =>
    receptionInfo.received
      ? `R$ ${(
          parseCurrencyStringToFloat(receptionInfo.total) /
          parseCurrencyStringToFloat(receptionInfo.receivedQuantity)
        ).toLocaleString('pt-BR')}`
      : '-'
  const getCurrentPriceByKg = () => {
    const matchingFeedstock = feedstockPricesArray.find(
      feedstockPrice => feedstockPrice.code === receptionInfo.feedstock.code
    )
    return matchingFeedstock
      ? `R$ ${matchingFeedstock.current_price.toLocaleString('pt-BR')}`
      : '-'
  }

  const updateReceivedStatus = updatedReceptionInfoField =>
    parseCurrencyStringToFloat(updatedReceptionInfoField.total) > 0 &&
    parseCurrencyStringToFloat(updatedReceptionInfoField.receivedQuantity) > 0

  const handleChangeNumber = (fieldName, value) => {
    const updatedReceptionInfoField = dotProp.set(
      receptionInfo,
      fieldName,
      value
    )
    const updatedReceivedStatus = updateReceivedStatus(
      updatedReceptionInfoField
    )
    onReceptionInfoUpdate(
      dotProp.set(updatedReceptionInfoField, 'received', updatedReceivedStatus)
    )
  }

  const handleChangeDate = (date, fieldName) => {
    const updatedReceptionInfo = dotProp.set(receptionInfo, fieldName, date)
    onReceptionInfoUpdate(updatedReceptionInfo)
  }

  return (
    <TableRow
      className={`${classes.tableRow} ${classes.receptioInfoTable} `}
      hover
    >
      <TableCell padding="none">{getFeedstockName()}</TableCell>
      <TableCell padding="none">
        <TableCell padding="none">
          <InputValidator
            id="ExpectedQuantity"
            validations={[required('Obrigatório(*)')]}
          >
            <InputField
              value={receptionInfo.expectedQuantity}
              onChange={handleChangeNumber}
              fieldName="expectedQuantity"
              className="small"
              disabled={receptionInfo.received}
              isCurrency
            />
          </InputValidator>
        </TableCell>
      </TableCell>
      <TableCell padding="none">
        <InputValidator
          id="InvoiceQuantity"
          validations={[required('Obrigatório(*)')]}
        >
          <InputField
            value={receptionInfo.invoiceQuantity}
            onChange={handleChangeNumber}
            fieldName="invoiceQuantity"
            className="small"
            isCurrency
            isPositiveOnly
          />
        </InputValidator>
      </TableCell>
      <TableCell padding="none">
        <InputValidator
          id="ReceivedQuantity"
          validations={[required('Obrigatório(*)')]}
        >
          <InputField
            value={receptionInfo.receivedQuantity}
            onChange={handleChangeNumber}
            fieldName="receivedQuantity"
            className="small"
            isCurrency
            isPositiveOnly
          />
        </InputValidator>
      </TableCell>
      <TableCell padding="none">
        <InputValidator
          id="TotalQuantity"
          validations={[required('Obrigatório(*)')]}
        >
          <InputField
            value={receptionInfo.total}
            onChange={handleChangeNumber}
            fieldName="total"
            className="small"
            isCurrency
            isPositiveOnly
          />
        </InputValidator>
      </TableCell>
      <TableCell padding="none">{getReceivedPriceByKg()}</TableCell>
      <TableCell padding="none" className={classes.currentAverage}>
        {getCurrentPriceByKg()}
      </TableCell>
      <TableCell padding="none">
        <DateSelect
          label=""
          className="small"
          onSelect={date => handleChangeDate(date, 'receivedDate')}
          emptyLabel="Selecione"
          selectedDate={
            receptionInfo.receivedDate &&
            moment(receptionInfo.receivedDate, 'YYYY-MM-DD')
          }
          maxDate={moment()}
        />
      </TableCell>
      <TableCell padding="default">
        <DateSelect
          className="small"
          label=""
          emptyLabel="Selecione"
          onSelect={date => handleChangeDate(date, 'expirationDate')}
          selectedDate={
            receptionInfo.expirationDate &&
            moment(receptionInfo.expirationDate, 'YYYY-MM-DD')
          }
        />
      </TableCell>
    </TableRow>
  )
}

ReceptionInfoRow.propTypes = {
  receptionInfo: PropTypes.object,
  feedstockPricesArray: PropTypes.array,
  onReceptionInfoUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

ReceptionInfoRow.defaultProps = {
  receptionInfo: {},
  feedstockPricesArray: [],
  classes: {},
}

export default ReceptionInfoRow
