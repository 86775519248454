import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'

import { useAPI } from '../../../api'
import { Kitchen } from '../../../api/dto/kitchen'
import { Endpoints } from '../../../api/endpoints'
import { LookupItem } from '../../../contracts/lookupItem'
import { createSnackbarOptions } from '../../../logic/notification'
import { Props as SelectProps } from 'react-select'
import { Lookup } from '../lookup'

export interface SelectKitchensProps extends SelectProps {
  onSelect: (option: LookupItem | LookupItem[], keys: string[]) => void
  isClearable?: boolean
  selected?: string[]
  disabled?: boolean
  showInactives?: boolean
}

const inactiveKitchensOption = { value: 'inactive', label: 'Outras cozinhas' }

function SelectKitchens(props: SelectKitchensProps) {
  const { selected, showInactives, onSelect } = props
  const [fetchData, isLoadingOptions] = useAPI()
  const { enqueueSnackbar } = useSnackbar()
  const [kitchensSelectOptions, setKitchensSelectOptions] = useState<
    LookupItem[]
  >([])
  const [kitchens, setKitchens] = useState<Kitchen[]>([])

  useEffect(() => {
    fetchData({ uri: Endpoints.KITCHENS, method: 'GET' })
      .then((response: Kitchen[]) => {
        setKitchens(response)
        const activeKitchens = response.filter(kitchen => kitchen.active)
        const kitchensList = showInactives ? response : activeKitchens
        setKitchensSelectOptions(
          kitchensList.map(k => ({ value: k.id, label: k.name }))
        )
      })
      .catch(e => {
        setKitchensSelectOptions([])
        enqueueSnackbar(e.message, createSnackbarOptions('error'))
      })
  }, [enqueueSnackbar, fetchData, showInactives])

  const selectedKitchensValue: LookupItem[] = useMemo(() => {
    if (!kitchensSelectOptions?.length) {
      return []
    }

    const selectedKitchens = selected || []
    const validKitchensItem: Record<string, LookupItem> = {}

    for (const kitchenIndex in selectedKitchens) {
      const ck = kitchensSelectOptions.find(
        o => o.value === selectedKitchens[kitchenIndex]
      )

      if (ck) {
        validKitchensItem[ck.value] = {
          value: ck.value,
          label: ck.label,
        }
      } else {
        validKitchensItem[inactiveKitchensOption.value] = inactiveKitchensOption
      }
    }

    return Object.values(validKitchensItem)
  }, [selected, kitchensSelectOptions])

  const handleOnSelect = (selectedKitchens: LookupItem[]) => {
    const inactiveKitchens = kitchens
      .filter(kitchen => !kitchen.active)
      .map(kitchen => kitchen.id)
    const values = selectedKitchens.reduce((acc, cur) => {
      if (cur.value === inactiveKitchensOption.value) {
        return [...acc, ...inactiveKitchens]
      }

      return [...acc, cur.value]
    }, [])

    onSelect(selectedKitchens, values)
  }

  return (
    <Lookup
      {...props}
      options={kitchensSelectOptions}
      placeholder="Filtre por cozinhas"
      disabled={isLoadingOptions}
      onSelect={handleOnSelect}
      isMulti
      isClearable
      selected={selectedKitchensValue}
    />
  )
}

export default SelectKitchens
