import React, { Component } from 'react'
import { withRouter, NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CardBox from 'components/CardBox'
import { CircularProgress, Grid, IconButton } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'
import EditIcon from '@material-ui/icons/Edit'
import ResultsTable from 'components/ResultsTable'
import ErrorBox from 'components/ErrorBox'
import InfoIconTooltip from 'components/InfoIconTooltip'
import PropTypes from 'prop-types'
import {
  fetchRecipes,
  searchRecipes,
  recalculateRecipes,
} from 'actions/Recipes'
import SearchPanel from '../components/SearchPanel'
import { tableHeader } from '../formattedMessages'
import { withSnackbar } from 'notistack'
import { fetchKitchens } from '../../../../actions/Reception'

class RecipeSearchContainer extends Component {
  componentDidMount() {
    this.props.fetchRecipes()
    this.props.fetchKitchens()
  }

  getSearchResults = params => {
    this.props.searchRecipes(params)
  }

  recalculateAllRecipes = () => {
    this.props.recalculateRecipes()
  }

  emitSnackbar = () => {
    const { enqueueSnackbar } = this.props
    enqueueSnackbar('Aguarde só uns minutos que estamos recalculando!', {
      variant: 'success',
      horizontal: 'left',
      preventDuplicate: true,
    })
  }

  getRecipeFieldsPath = () => [
    'name',
    'code',
    'updatedAt',
    'edit',
    'history',
    'versionInfo',
  ]

  getRecipeVersionInfo = recipe => {
    if (recipe.versionNumber === 1) {
      return <React.Fragment>Versão inicial da receita.</React.Fragment>
    }
    if (
      recipe.changingCommit &&
      recipe.changingCommit.message &&
      recipe.changingCommit.responsible
    ) {
      return (
        <Grid container direction="column">
          <Grid item>
            <p>{`Motivo: ${recipe.changingCommit.message}`}</p>
          </Grid>
          <Grid item>
            <p>{`Responsável: ${recipe.changingCommit.responsible}`}</p>
          </Grid>
        </Grid>
      )
    }
    return (
      <React.Fragment>Não há informações sobre essa versão.</React.Fragment>
    )
  }

  addEditButton = recipe => {
    recipe.edit = (
      <NavLink to={`/app/recipes/edit/${recipe.code}`}>
        <IconButton aria-label="Edit">
          <EditIcon />
        </IconButton>
      </NavLink>
    )
    return recipe
  }

  addHistoryButton = recipe => {
    recipe.history = (
      <Link to={`/app/recipes/historic/${recipe.code}`}>
        <IconButton aria-label="History">
          <HistoryIcon />
        </IconButton>
      </Link>
    )
    return recipe
  }

  addVersionInfoButton = recipe => {
    recipe.versionInfo = (
      <InfoIconTooltip content={this.getRecipeVersionInfo(recipe)} />
    )
    return recipe
  }

  addButtons = recipes =>
    recipes.map(recipe =>
      this.addVersionInfoButton(
        this.addHistoryButton(this.addEditButton(recipe))
      )
    )

  formatDates = recipes =>
    recipes.map(recipe => this.formatDateToLocaleString(recipe))

  formatDateToLocaleString = recipe => {
    recipe.updatedAt = new Date(recipe.updatedAt).toLocaleDateString('pt-BR')
    return recipe
  }
  isKitchenAdmin = scopes => {
    const kitchenAdminScope = 'kitchen/*'
    return scopes && scopes.length > 0 && scopes.indexOf(kitchenAdminScope) > -1
  }
  renderSearchPanel() {
    const { recipeSearchResults, kitchens, authUser } = this.props
    if (recipeSearchResults.error) {
      return <ErrorBox message={recipeSearchResults.error} />
    }
    const isAdmin =
      authUser && authUser.scopes && this.isKitchenAdmin(authUser.scopes)
    return (
      <SearchPanel
        emitSnackbar={this.emitSnackbar}
        onSearchSubmit={this.getSearchResults}
        recalculateAllRecipes={this.recalculateAllRecipes}
        kitchens={isAdmin && !kitchens.loading && kitchens.data}
      >
        <CircularProgress size={50} />
      </SearchPanel>
    )
  }

  renderSearchResults() {
    const { recipeSearchResults, kitchens } = this.props

    if (recipeSearchResults.loading || kitchens.loading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (recipeSearchResults.error) {
      return <ErrorBox message={recipeSearchResults.error} />
    }

    const rows = recipeSearchResults.data.map(row => ({
      ...row,
      name: <NavLink to={`/app/recipes/edit/${row.code}`}>{row.name}</NavLink>,
    }))
    const results = this.addButtons(this.formatDates(rows))

    return (
      <CardBox styleName="col-sm-12">
        <ResultsTable
          tableHeader={tableHeader}
          results={results}
          fieldsPath={this.getRecipeFieldsPath()}
        />
      </CardBox>
    )
  }

  render() {
    return (
      <Grid container justify="center">
        {this.renderSearchPanel()}
        {this.renderSearchResults()}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  recipeSearchResults: state.recipes.recipeSearchResults,
  kitchens: state.receptions.kitchens,
  authUser: state.auth.authUser,
})

RecipeSearchContainer.propTypes = {
  enqueueSnackbar: PropTypes.func,
  fetchRecipes: PropTypes.func.isRequired,
  recalculateRecipes: PropTypes.func.isRequired,
  searchRecipes: PropTypes.func.isRequired,
  recipeSearchResults: PropTypes.object,
  kitchens: PropTypes.object,
  fetchKitchens: PropTypes.func.isRequired,
}

RecipeSearchContainer.defaultProps = {
  enqueueSnackbar: () => null,
  recipeSearchResults: {
    data: [],
    loading: false,
    error: null,
  },
  kitchens: {
    data: [],
    loading: false,
    error: null,
  },
}

export default withRouter(
  connect(mapStateToProps, {
    fetchRecipes,
    searchRecipes,
    recalculateRecipes,
    fetchKitchens,
  })(withSnackbar(RecipeSearchContainer))
)
