import { ComponentType } from 'react'
import styled from 'styled-components'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import NutritionalInfoForm from '../../../components/organisms/nutritionalInfo'
import { Button } from '../../../components/atoms/button'
import { FormControl } from '@material-ui/core'

export const FeedstockRegisterContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    margin: 0 0 24px 0;
  }
`

export const FeedstockRegisterButtonsContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`

export const FeedstockRegisterCancelButton = styled(Button)`
  && {
    margin-right: 24px;
  }
`

export const FeedstockRegisterSaveButton = styled(Button)`
  && {
  }
`
export const BasicInfoContainer = styled.div`
  && {
    flex-basis: 66.66%;
    max-width: 66.66%;
    margin-right: 25px;
  }
`

export const FeedstockRegisterNutritionalInfo = styled(NutritionalInfoForm)`
  && {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
`

export const StyledCircularProgress: ComponentType<CircularProgressProps> = styled(
  CircularProgress
)`
  && {
    margin-left: 63px;
    margin-right: 62px;
  }
`

export const ModalFormControl = styled(FormControl)`
  flex-direction: row;
`
