import React, { useRef, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { read, utils } from 'xlsx'
import moment from 'moment'

import { useAPI } from '../../../api'
import { Button } from '../../../components/atoms/button'
import {
  Container,
  Divider,
  HiddenButton,
  Info,
  Subtitle,
  Title,
} from './styles'
import { Endpoints } from '../../../api/endpoints'

const reader = new FileReader()

export interface Props {
  onUpload?: (_list: Array<Record<string, string>>) => void
}

export const ExportXLSXContainer: React.FC<Props> = (props: Props) => {
  const { onUpload } = props
  const [exportIngredientsShop, isLoadingExportIngredientsShop] = useAPI()
  const [ingredientsShopList, setIngredientsShopList] = useState<
    Array<Record<string, string>>
  >([])
  const { enqueueSnackbar } = useSnackbar()

  const [filename, setFileName] = useState('')
  const [disabled, setDisabled] = useState(true)
  const xlsxInput = useRef<HTMLInputElement>()

  const today = moment().format('L').replace('/', '_')

  const handleRequestError = (error: Error) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      preventDuplicate: true,
      autoHideDuration: 5000,
    })
  }

  const handleRequestSuccess = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      preventDuplicate: true,
      autoHideDuration: 5000,
    })
  }

  reader.onload = (event: ProgressEvent<FileReader>) => {
    const bstr = event.target.result
    const wb = read(bstr, { type: 'binary' })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const wsjson = utils.sheet_to_json<Record<string, string>>(ws)
    setIngredientsShopList(wsjson)
    onUpload && onUpload(wsjson)
    setDisabled(false)
  }

  const handleUpload = () => {
    xlsxInput.current.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      files: { 0: file },
    } = event.target
    setFileName(file.name)
    setDisabled(true)
    reader.readAsBinaryString(file)
  }

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes
  }

  function saveByteArray(reportName: string, byte: BlobPart) {
    const blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    const fileName = reportName
    link.download = fileName
    link.click()
  }

  const handleSave = () => {
    exportIngredientsShop({
      method: 'POST',
      uri: Endpoints.EXPORT_INGREDIENTS_SHOP_LIST,
      body: { excelArray: ingredientsShopList },
    })
      .then(response => {
        const arrayBuffer = base64ToArrayBuffer(response.ingredientsShopList)
        saveByteArray(`lista_de_compras_gerada_${today}`, arrayBuffer)
        handleRequestSuccess('Planilha exportada com sucesso!')
      })
      .catch(handleRequestError)
  }

  return (
    <Container elevation={1}>
      <Grid container direction="column">
        <Title>Instruções e Formatos da Tabela:</Title>
        <Info>
          <ul>
            <li>
              Os nomes das colunas devem ser: skus | DD/MM/AA | DD/MM/AA | ...
            </li>
            <li>
              Verifique se todas as células do excel estão formatadas como Texto
              ou Geral
            </li>
            <li>Verifique se a extensão do arquivo é .xls ou .xlsx</li>
          </ul>
        </Info>
        <Divider />
        <Title>Adicione um arquivo Excel:</Title>
        <Subtitle>Nome do arquivo selecionado: </Subtitle>
        <Info>{filename ? filename : 'Nenhum arquivo selecionado'}</Info>
        <Grid container justify="space-between">
          <HiddenButton
            type="file"
            id="feedstocks-planning-upload"
            ref={xlsxInput}
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            color="success"
            size="large"
            onClick={handleUpload}
          >
            Selecionar Arquivo
          </Button>
          <Button
            disabled={disabled || isLoadingExportIngredientsShop}
            variant="contained"
            color="success"
            size="large"
            onClick={handleSave}
          >
            {isLoadingExportIngredientsShop
              ? 'Carregando...'
              : 'Gerar lista de compras'}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
