export const UPDATE_CONFIG_REQUEST = 'UPDATE_CONFIG_REQUEST'
export const UPDATE_CONFIG_RESPONSE = 'UPDATE_CONFIG_RESPONSE'
export const UPDATE_CONFIG_ERROR = 'UPDATE_CONFIG_ERROR'

export const FETCH_TAG_CONFIGURATION_RESPONSE =
  'FETCH_TAG_CONFIGURATION_RESPONSE'

export const FETCH_TAG_CONFIGURATION_ERROR = 'FETCH_TAG_CONFIGURATION_ERROR'

export const PRINT_NEW_TAG_DATE = 'PRINT_NEW_TAG_DATE'
export const PRINT_NEW_TAG_ERROR = 'PRINT_NEW_TAG_ERROR'
export const PRINT_NEW_TAG_REFRESH = 'PRINT_NEW_TAG_REFRESH'
export const PRINT_NEW_TAG_REQUEST = 'PRINT_NEW_TAG_REQUEST'
export const PRINT_NEW_TAG_RESPONSE = 'PRINT_NEW_TAG_RESPONSE'
