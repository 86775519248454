import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import Radio, { RadioProps } from '@material-ui/core/Radio'
import { ComponentType } from 'react'
import styled from 'styled-components'
interface DivProps {
  direction: 'row' | 'column'
}

interface StyledFormControlLabelProps {
  fontSize?: string
}
export const StyledDiv = styled.div<DivProps>`
  display: flex;
  flex-direction: ${props => props.direction};
`

export const StyledFormControlLabel: ComponentType<
  FormControlLabelProps & StyledFormControlLabelProps
> = styled(FormControlLabel)<StyledFormControlLabelProps>`
  && {
    span[class*='MuiRadio-checked'] {
      color: ${props => props.theme.v2.palette.primary.darker};
    }
    span[class*='MuiFormControlLabel-label'] {
      color: ${props => props.theme.v2.palette.grayScale['80']};
      font-size: ${props => props.fontSize || '14'}px;
      font-family: ${props => props.theme.v2.typography.fontFamily};
    }
  }
`

export const StyledRadio: ComponentType<RadioProps> = styled(Radio)`
  && {
    color: ${props => props.theme.v2.palette.primary.dark};
  }
`
