import React, { ChangeEvent, useState } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Select from 'src/v2/components/molecules/select'
import { LookupItem } from 'src/v2/contracts/lookupItem'
import {
  HOME_MEASUREMENT_UNITS,
  RECIPE_KINDS,
  TAG_LAYOUTS,
} from '../../constants'
import {
  ActionContainer,
  Cancel,
  Name,
  Save,
  SaveIcon,
  StyledCancelIcon,
  StyledHomeMeasuerementQuantityContainer,
} from './styles'

export type RecipeTagFormFields = {
  kind: string
  tagLayout?: string
  denomination?: string
  homeMeasurementQuantity?: string
  homeMeasurementUnit?: string
  expiration: number
  expirationCut?: number
  claims: string
  barcode: string
  showAddressOnTag?: boolean
}

interface EditCardComponentProps {
  code: string
  name: string
  initialState?: RecipeTagFormFields
  isLoading?: boolean
  handleSave: (e: RecipeTagFormFields) => void
  handleCancel: () => void
}

export function EditCardComponent({
  code,
  name,
  initialState,
  isLoading,
  handleSave,
  handleCancel,
}: EditCardComponentProps) {
  const [formValues, setFormValues] = useState<RecipeTagFormFields>(
    initialState || { kind: '', expiration: undefined, claims: '', barcode: '' }
  )

  function handleSelection(field: string, option: LookupItem) {
    setFormValues({ ...formValues, [field]: option.value })
  }

  function getSelectedOption(field: string, options: LookupItem[]): LookupItem {
    const fieldKey = field as keyof RecipeTagFormFields
    return formValues && options.find(opt => opt.value === formValues[fieldKey])
  }

  function validateBarcodeInput(value: string): boolean {
    if (!value) {
      return true
    }
    if (value.length > 13) {
      return false
    }
    if (/\D/.exec(value)) {
      return false
    }
    return true
  }

  function handleChange(
    {
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    checked?: boolean
  ) {
    if (name === 'showAddressOnTag') {
      setFormValues({ ...formValues, [name]: checked })
      return
    }
    if (name === 'barcode') {
      if (!validateBarcodeInput(value)) {
        return
      }
    }
    setFormValues({ ...formValues, [name]: value })
  }

  function onSave() {
    handleSave({
      ...formValues,
      homeMeasurementQuantity:
        formValues?.homeMeasurementQuantity?.trim() || null,
      barcode: formValues?.barcode?.trim() || null,
      expiration: Number(formValues?.expiration ?? 0),
      expirationCut: Number(formValues?.expirationCut ?? 0),
    })
  }

  return (
    <>
      <Grid container direction="column" spacing={16}>
        <Grid item>
          <Name>
            {code} - {name}
          </Name>
        </Grid>
        <Grid item>
          <Select
            selected={getSelectedOption('kind', RECIPE_KINDS)}
            options={RECIPE_KINDS}
            onSelect={op => handleSelection('kind', op)}
            label="Tipo de Produto"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Select
            selected={getSelectedOption('tagLayout', TAG_LAYOUTS)}
            options={TAG_LAYOUTS}
            onSelect={op => handleSelection('tagLayout', op)}
            label="Layout da etiqueta"
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            name="denomination"
            label="Denominação"
            value={formValues?.denomination}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            name="barcode"
            label="Código de Barras (EAN13)"
            value={formValues?.barcode}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Grid container spacing={8} direction="row">
            <StyledHomeMeasuerementQuantityContainer item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                name="homeMeasurementQuantity"
                label="Quantidade Caseira"
                value={formValues?.homeMeasurementQuantity ?? ''}
                onChange={handleChange}
                fullWidth
              />
            </StyledHomeMeasuerementQuantityContainer>
            <Grid item xs={6}>
              <Select
                selected={getSelectedOption(
                  'homeMeasurementUnit',
                  HOME_MEASUREMENT_UNITS
                )}
                options={HOME_MEASUREMENT_UNITS}
                onSelect={op => handleSelection('homeMeasurementUnit', op)}
                label="Unidade de Medida Caseira"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            label="Validade em dias"
            name="expiration"
            value={formValues?.expiration ?? ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            label="Validade mínima para entrega"
            name="expirationCut"
            value={formValues?.expirationCut ?? ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            label="Aviso de alteração de receita"
            inputProps={{
              maxLength: 100,
            }}
            name="claims"
            value={formValues?.claims}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label="Mostrar o endereço na etiqueta"
            control={
              <Checkbox
                name="showAddressOnTag"
                checked={formValues?.showAddressOnTag}
                onChange={handleChange}
              />
            }
          />
        </Grid>
        {
          {
            true: <CircularProgress size={30} />,
            false: (
              <Grid container item direction="row">
                <ActionContainer onClick={handleCancel}>
                  <Cancel>Cancelar</Cancel>
                  <StyledCancelIcon />
                </ActionContainer>
                <ActionContainer onClick={onSave}>
                  <Save>Salvar</Save>
                  <SaveIcon />
                </ActionContainer>
              </Grid>
            ),
          }[String(isLoading)]
        }
      </Grid>
    </>
  )
}
