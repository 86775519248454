import {
  PRINT_NEW_TAG_REQUEST,
  PRINT_NEW_TAG_RESPONSE,
  PRINT_NEW_TAG_REFRESH,
  PRINT_NEW_TAG_ERROR,
  PRINT_NEW_TAG_DATE,
} from '../actionTypes'

const initialState = {
  printing: {
    data: {},
    loading: false,
    success: false,
    productionDate: null,
  },
  tags: {
    data: new Map(),
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRINT_NEW_TAG_REQUEST: {
      return {
        ...state,
        printing: {
          ...state.printing,
          data: action.payload,
          loading: true,
        },
      }
    }
    case PRINT_NEW_TAG_RESPONSE: {
      return {
        ...state,
        printing: {
          ...state.printing,
          loading: false,
          success: true,
        },
      }
    }
    case PRINT_NEW_TAG_DATE: {
      return {
        ...state,
        printing: {
          ...state.printing,
          productionDate: action.payload.date,
        },
      }
    }
    case PRINT_NEW_TAG_REFRESH: {
      return initialState
    }
    case PRINT_NEW_TAG_ERROR: {
      return initialState
    }
    default:
      return state
  }
}
