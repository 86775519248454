import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'

export default class ObservationDialog extends React.Component {
  constructor() {
    super()
    this.state = {
      message: '',
      error: false,
    }
  }

  handleChange = event => {
    const { value } = event.target
    this.setState({ message: value, error: false })
  }

  submit = () => {
    const { handleSubmit } = this.props
    const { message } = this.state
    if (!message || message === '') {
      this.setState({ error: true })
    } else {
      handleSubmit(message)
    }
  }

  render() {
    const { submitTitle, open, title, contentText, observationLabel, onClose } =
      this.props

    const { error } = this.state

    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          onClose={onClose}
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {contentText && (
              <DialogContentText>{contentText}</DialogContentText>
            )}
            <TextField
              margin="dense"
              id={observationLabel}
              multiline
              rows={3}
              rowsMax={4}
              label={observationLabel}
              value={this.state.message}
              onChange={this.handleChange}
              fullWidth
              style={{ width: '100%' }}
            />
            {error && (
              <FormHelperText style={{ color: 'red' }}>
                Esse campo não pode estar vazio
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              key={submitTitle}
              onClick={this.submit}
              color="submit"
              type="submit"
            >
              {submitTitle}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ObservationDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string,
  observationLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

ObservationDialog.defaultProps = {
  contentText: null,
}
