import React, { useState } from 'react'
import {
  DialogContent,
  DialogContentText,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'
import InputField from 'src/components/InputField'
import { Allergenic } from '../../../contracts/allergenic'
import {
  categoryCodes,
  Feedstock,
  FeedstockType,
} from '../../../contracts/feedstock'
import { LookupItem } from '../../../contracts/lookupItem'
import {
  mapAllergenicsToCheckboxItems,
  mapCheckboxItemsToAllergenics,
  mapSuppliers,
  mapTacoNutritionalInfoToData,
  nutritionalInfosToLookupItems,
  updateAllergenics,
  getSelectedKitchenInitialState,
  getSelectedKitchenId,
} from '../logic'
import Allergenics from '../organisms/allergenics'
import BasicInfo from '../organisms/basicInfo'
import Suppliers from '../organisms/suppliers'
import Transhipment from '../organisms/transhipment'
import {
  BasicInfoContainer,
  FeedstockRegisterButtonsContainer,
  FeedstockRegisterContainer,
  FeedstockRegisterNutritionalInfo,
  FeedstockRegisterSaveButton,
  StyledCircularProgress,
  ModalFormControl,
} from './styles'
import { IKitchen } from '../../../contracts/kitchens'
import { Button } from '../../../components/atoms/button'

export const accountingClassifications = [
  { value: 'own_production', label: 'Produção própria' },
  { value: 'resale', label: 'Revenda' },
  { value: 'consignation', label: 'Consignação' },
]

export const cstCodes = [
  { value: '41', label: '41 - Não tributada' },
  {
    value: '60',
    label: '60 - Cobrado anteriormente por substituição tributária',
  },
]

export const transferUnits = [
  { value: 'KG', label: 'Quilos' },
  { value: 'UN', label: 'Unidades' },
]

export const boxTypes = [
  { value: 'Caixas', label: 'Caixas' },
  { value: 'Fardos', label: 'Fardos' },
]

export interface FeedstockRegisterPageProps {
  feedstock?: Feedstock
  feedstockTypes: FeedstockType[]
  nutritionalInfos: any[]
  allergenics: Allergenic[]
  suppliers: LookupItem[]
  onSave: (data: any) => void
  getNutritionalInfo: (data: any, value: any) => void
  getNextAvailableCode: (data: string) => void
  feedstockCategories: LookupItem[]
  categoriesToGenerateCode: LookupItem[]
  nutritionalInfoSources: LookupItem[]
  nextAvailableCode?: string
  nextAvailableCodeLoading: boolean
  nutritionalInfosLoading: boolean
  saving: boolean
  deleting: boolean
  sendNotification: (data: any) => void
  authUser: any
  kitchens: IKitchen[]
  products: LookupItem[]
  onDeleteFeedstock: () => void
}

// tslint:disable:cyclomatic-complexity
const FeedstockRegisterPage = ({
  feedstock,
  allergenics,
  suppliers,
  feedstockTypes,
  nutritionalInfos,
  onSave,
  getNutritionalInfo,
  getNextAvailableCode,
  nextAvailableCode,
  feedstockCategories,
  categoriesToGenerateCode,
  nutritionalInfoSources,
  nextAvailableCodeLoading,
  nutritionalInfosLoading,
  sendNotification,
  saving,
  deleting,
  authUser,
  kitchens,
  products,
  onDeleteFeedstock,
  ...props
}: FeedstockRegisterPageProps) => {
  const [feedstockName, setFeedstockName] = useState(
    feedstock && feedstock.feedstockName
  )
  const [feedstockDensity, setFeedstockDensity] = useState(
    (feedstock && String(feedstock.density)) || '1'
  )
  const [currentPrice, setFeedstockPrice] = useState(
    (feedstock && String(feedstock.currentPrice)) || ''
  )
  const [stockType, setFeedstockType] = useState(
    feedstock && feedstock.stockType
  )
  const [transferable, setTransferable] = useState(
    feedstock && feedstock.transferable
  )
  const [ncm, setNcm] = useState(feedstock && feedstock.ncm)
  const [cest, setCest] = useState(feedstock && feedstock.cest)
  const [unitsPerBox, setUnitsPerBox] = useState(
    feedstock && feedstock.unitsPerBox
  )
  const [modalFsCode, setModalFsCode] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [active, setActive] = useState(feedstock && feedstock.active)

  const [originatingSku, setOriginatingSku] = useState(
    feedstock &&
      feedstock.originatingSku &&
      products.find(p => p.value === feedstock.originatingSku)
  )
  const [accountingClassification, setAccountingClassification] = useState(
    feedstock &&
      feedstock.accountingClassification &&
      accountingClassifications.find(
        acc => acc.label === feedstock.accountingClassification
      )
  )
  const [cst, setCst] = useState(
    feedstock && feedstock.cst && cstCodes.find(c => c.value === feedstock.cst)
  )
  const [transferUnit, setTransferUnit] = useState(
    feedstock &&
      feedstock.transferUnit &&
      transferUnits.find(u => u.value === feedstock.transferUnit)
  )
  const [boxType, setBoxType] = useState(
    feedstock &&
      feedstock.boxType &&
      boxTypes.find(c => c.value === feedstock.boxType)
  )
  const [kitchensOptions, setKitchenOptions] = useState(
    getSelectedKitchenInitialState(feedstock, kitchens)
  )

  const [allergenicsState, setAllergenics] = useState(
    mapAllergenicsToCheckboxItems(
      allergenics,
      (feedstock && feedstock.allergenics) || []
    )
  )
  const [hasAllergenics, setHasAllergenics] = useState(true)
  const [mainSupplier, setMainSupplier] = useState(
    feedstock
      ? suppliers.find(supplier =>
          feedstock.suppliers
            .filter(s => s.category === 'primary')
            .map(s => s.id)
            .find(id => id === supplier.value)
        )
      : null
  )
  const [otherSuppliers, setOtherSuppliers] = useState(
    feedstock
      ? feedstock.suppliers
          .filter(supplier => supplier.category !== 'primary')
          .map(supplier => suppliers.find(s => s.value === supplier.id))
      : []
  )
  const [suppliersOptions, setSuppliersOptions] = useState(suppliers)
  const [feedstockCategory, setFeedstockCategory] = useState(
    feedstock && feedstockCategories.find(item => item.label === feedstock.area)
  )
  const [nutritionalInfoSource, setNutritionalInfoSource] = useState(
    feedstock &&
      nutritionalInfoSources.find(
        option => option.label === feedstock.nutritionalInfoSource
      )
  )
  const [feedstockNutritionalInfo, setNutritionalInfo] = useState(
    feedstock && feedstock.nutritionalInfo
  )
  const formatFeedstock = () => ({
    ...(feedstock && { id: feedstock.id }),
    feedstockName,
    nutritionalInfoSource: nutritionalInfoSource.label,
    stockType,
    currentPrice: Number(currentPrice),
    density: Number(feedstockDensity),
    code: (feedstock && feedstock.code) || nextAvailableCode,
    area: feedstockCategory.label,
    suppliers: mapSuppliers(mainSupplier, otherSuppliers),
    nutritionalInfo: feedstockNutritionalInfo,
    allergenics: mapCheckboxItemsToAllergenics(allergenicsState, allergenics),
    kitchens: getSelectedKitchenId(kitchensOptions),
    active,
    transferable,
    ...(originatingSku &&
      originatingSku.value && { originatingSku: originatingSku.value }),
    ...(accountingClassification &&
      accountingClassification.value && {
        accountingClassification: accountingClassification.label,
      }),
    ...(ncm && { ncm }),
    ...(unitsPerBox && { unitsPerBox }),
    ...(cest && { cest }),
    ...(cst && cst.value && { cst: cst.value }),
    ...(transferUnit &&
      transferUnit.value && { transferUnit: transferUnit.value }),
    ...(boxType && boxType.value && { boxType: boxType.value }),
  })

  const handleNutritionalInfoChange = field => value => {
    setNutritionalInfo({ ...feedstockNutritionalInfo, [field]: value })
  }
  const handleCheck = (event: any): void => {
    const { value } = event.currentTarget
    const updatedAllergenics = updateAllergenics(allergenicsState, value)
    setAllergenics(updatedAllergenics)
  }

  const handleDelete = () => {
    if (!deleteModal) return setDeleteModal(true)
    if (onDeleteFeedstock) {
      setModalFsCode('')
      setDeleteModal(false)
      return onDeleteFeedstock()
    }
  }

  const handleSelect = (value: string) => {
    if (value === '0') {
      setHasAllergenics(true)
    } else {
      setAllergenics(
        allergenicsState.map(allergenic => ({
          ...allergenic,
          selected: false,
        }))
      )
      setHasAllergenics(false)
    }
  }

  const handleSelectedKitchen = (event: any) => {
    const index = Number(event.currentTarget.value)
    const newKitchenOptions = kitchensOptions.map((k, i) => ({
      id: k.id,
      text: k.text,
      selected: i === index ? !k.selected : k.selected,
    }))
    setKitchenOptions(newKitchenOptions)
  }

  const handleTransferableCheck = (event: any) => {
    setTransferable(!transferable)
  }

  const handleSelectSupplier = (option: any): void => {
    if (Array.isArray(option)) {
      const updatedSuppliers = option.map(supplierOption =>
        suppliers.find(supplier => supplier.value === supplierOption.value)
      )
      setOtherSuppliers(updatedSuppliers)
      setSuppliersOptions(
        suppliers.filter(
          supplier =>
            ![mainSupplier, ...updatedSuppliers].some(
              s => s.value === supplier.value
            )
        )
      )
    } else if (option.value) {
      const updatedSupplier = suppliers.find(s => s.value === option.value)
      setMainSupplier(updatedSupplier)
      setSuppliersOptions(
        suppliers.filter(
          supplier =>
            ![updatedSupplier, ...otherSuppliers].some(
              s => s.value === supplier.value
            )
        )
      )
    }
  }

  const handleSelectFeedstockCategory = (option: LookupItem): void => {
    setFeedstockCategory(option)
  }

  const handleSelectCategoryToGenerateCode = (option: LookupItem): void => {
    getNextAvailableCode(categoryCodes[option.label])
  }

  const handleFeedstockNameChange = (event: any): void => {
    const {
      currentTarget: { value },
    } = event
    setFeedstockName(value)
  }

  const handleFeedstockDensityChange = (event: any): void => {
    const {
      currentTarget: { value },
    } = event
    setFeedstockDensity(value)
  }

  const handleNcmChange = (event: any): void => {
    const {
      currentTarget: { value },
    } = event
    setNcm(value)
  }

  const handleCestChange = (event: any): void => {
    const {
      currentTarget: { value },
    } = event
    setCest(value)
  }

  const handleUnitsPerBoxChange = (event: any): void => {
    const {
      currentTarget: { value },
    } = event
    setUnitsPerBox(value)
  }

  const handleFeedstockPriceChange = (event: any): void => {
    const {
      currentTarget: { value },
    } = event
    const newValue = value.replace(/\./g, '')
    if (/^\d*$/.test(newValue)) {
      setFeedstockPrice(`${newValue.slice(0, -2)}.${newValue.slice(-2)}`)
    }
  }

  const handleSelectNutritionalInfoSource = (option: LookupItem): void => {
    if (option.label === 'Preenchimento manual' && !feedstockNutritionalInfo) {
      setNutritionalInfo({
        kcal: '',
        carbs: '',
        proteins: '',
        totalFats: '',
        satFats: '',
        transFats: '',
        sodium: '',
        fibers: '',
        totalSugars: '',
        addedSugars: '',
      })
    }
    setNutritionalInfoSource(option)
  }

  const handleFeedstockTypeSelect = (index: string): void => {
    setFeedstockType(feedstockTypes[index])
  }

  const handleNutritionalInfoSearch = (value: any): void => {
    getNutritionalInfo(nutritionalInfoSource.label, value)
  }

  const handleNutritionalInfoSelected = (option: LookupItem) => {
    if (option) {
      let nutritionalInfo = null
      if (nutritionalInfoSource.label === 'TACO') {
        ;({ nutritionalInfo } = nutritionalInfos.find(
          n => n.tacoId === option.value
        ))
      } else if (nutritionalInfoSource.label === 'USDA') {
        ;({ nutritionalInfo } = nutritionalInfos.find(
          n => n.ndbNumber === option.value
        ))
      }
      if (nutritionalInfo) {
        const updatedNutritionalInfo =
          mapTacoNutritionalInfoToData(nutritionalInfo)
        setNutritionalInfo(updatedNutritionalInfo)
      }
    }
  }
  const handleSave = () => {
    const missingFields = checkMissingFields()
    if (missingFields.length > 0) {
      let errorMessage = 'Campos faltantes:'
      missingFields.forEach(error => {
        errorMessage += ` ${error},`
      })
      errorMessage = `${errorMessage.slice(0, -1)}.`
      sendNotification({
        type: 'error',
        message: errorMessage,
        autoDismiss: true,
      })
      return
    }
    onSave(formatFeedstock())
  }

  const checkMissingFields = () => {
    const missingFields = []
    if (!feedstockName || feedstockName === '') {
      missingFields.push('nome')
    }
    if (
      currentPrice === null ||
      currentPrice === undefined ||
      currentPrice === ''
    ) {
      missingFields.push('preço')
    }
    if (!stockType) {
      missingFields.push('Tipo de estoque')
    }
    if (!feedstockCategory) {
      missingFields.push('categoria')
    }
    if (!feedstock && (!nextAvailableCode || nextAvailableCode === '')) {
      missingFields.push('código')
    }
    if (!nutritionalInfoSource || nutritionalInfoSource.label === '') {
      missingFields.push('fonte das informações nutricionais')
    }
    if (!kitchensOptions.find(k => k.selected)) {
      missingFields.push('cozinha')
    }
    if (
      transferable &&
      (!cst ||
        cst.label === '' ||
        !accountingClassification ||
        accountingClassification.label === '' ||
        !unitsPerBox ||
        !ncm ||
        !boxType ||
        boxType.value === '' ||
        !transferUnit ||
        transferUnit.value === '' ||
        !ncm ||
        (cst.value === '60' && !cest))
    ) {
      missingFields.push('informações de transbordo')
    }
    return missingFields
  }
  return (
    <div {...props}>
      <FeedstockRegisterContainer>
        <BasicInfoContainer>
          <BasicInfo
            feedstockName={feedstockName}
            feedstockDensity={feedstockDensity}
            currentPrice={currentPrice}
            feedstockTypes={feedstockTypes}
            stockType={stockType}
            onFeedstockTypeRadioSelected={handleFeedstockTypeSelect}
            selectedCategory={feedstockCategory}
            feedstockCategories={feedstockCategories}
            categoriesToGenerateCode={categoriesToGenerateCode}
            nextAvailableCode={
              (feedstock && feedstock.code) || nextAvailableCode
            }
            nextAvailableCodeLoading={nextAvailableCodeLoading}
            nutritionalInfoSources={nutritionalInfoSources}
            onFeedstockNameChange={handleFeedstockNameChange}
            onFeedstockDensityChange={handleFeedstockDensityChange}
            onFeedstockPriceChange={handleFeedstockPriceChange}
            onFeedstockCategorySelected={handleSelectFeedstockCategory}
            onCategoryToGenerateCodeSelected={
              handleSelectCategoryToGenerateCode
            }
            onNutritionalInfoSourceSelected={handleSelectNutritionalInfoSource}
            onNutritionalInfoSearch={handleNutritionalInfoSearch}
            nutritionalInfosLoading={nutritionalInfosLoading}
            nutritionalInfos={
              nutritionalInfos &&
              nutritionalInfosToLookupItems(
                nutritionalInfoSource.label,
                nutritionalInfos
              )
            }
            onNutritionalInfoSelected={handleNutritionalInfoSelected}
            nutritionalInfoSource={nutritionalInfoSource}
            existingFeedstock={feedstock && true}
            kitchenLabels={kitchensOptions}
            onKitchenSelected={handleSelectedKitchen}
            active={active}
            onActivate={() => setActive(!active)}
          />
        </BasicInfoContainer>

        <FeedstockRegisterNutritionalInfo
          nutritionalInfo={feedstockNutritionalInfo}
          onChange={handleNutritionalInfoChange}
        />
      </FeedstockRegisterContainer>
      <FeedstockRegisterContainer>
        <Allergenics
          allergenics={allergenicsState}
          onSelect={handleSelect}
          onCheck={handleCheck}
          hasAllergenics={hasAllergenics}
        />
      </FeedstockRegisterContainer>
      <FeedstockRegisterContainer>
        <Suppliers
          mainSupplier={mainSupplier}
          otherSuppliers={otherSuppliers}
          suppliersOptions={suppliersOptions}
          onSelectSupplier={handleSelectSupplier}
        />
      </FeedstockRegisterContainer>
      <FeedstockRegisterContainer>
        <Transhipment
          onTransferableCheck={handleTransferableCheck}
          transferable={transferable}
          originatingSku={originatingSku}
          onSelectOriginatingSku={setOriginatingSku}
          products={products}
          accountingClassification={accountingClassification}
          onSelectAccountingClassification={setAccountingClassification}
          onNcmChange={handleNcmChange}
          ncm={ncm}
          onCestChange={handleCestChange}
          cest={cest}
          unitsPerBox={unitsPerBox}
          onUnitsPerBoxChange={handleUnitsPerBoxChange}
          cst={cst}
          cstCodes={cstCodes}
          onSelectCst={setCst}
          transferUnit={transferUnit}
          onSelectTransferUnit={setTransferUnit}
          transferUnits={transferUnits}
          boxType={boxType}
          onSelectBoxType={setBoxType}
          boxTypes={boxTypes}
        />
      </FeedstockRegisterContainer>
      <FeedstockRegisterButtonsContainer>
        {saving || deleting ? (
          <StyledCircularProgress size={40} />
        ) : (
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={handleDelete}
          >
            Deletar
          </Button>
        )}
        {saving || deleting ? (
          <StyledCircularProgress size={40} />
        ) : (
          <FeedstockRegisterSaveButton
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleSave}
          >
            Salvar novo cadastro
          </FeedstockRegisterSaveButton>
        )}
      </FeedstockRegisterButtonsContainer>
      <Dialog open={deleteModal} onClose={() => setDeleteModal(false)}>
        <DialogTitle>Excluir Matéria-Prima</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você tem certeza que deseja deletar a matéria-prima{' '}
            {feedstock && feedstock.code}?
          </DialogContentText>
          <ModalFormControl>
            <Grid item xs={7}>
              Escreve aqui o código da MP:
            </Grid>
            <Grid item xs={5}>
              <InputField
                value={modalFsCode}
                onChange={(_, value) => setModalFsCode(value)}
                label="Código"
                fieldName="fsCode"
              />
            </Grid>
          </ModalFormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDeleteModal(false)}
            className="jr-btn text-blue-gray"
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            className="jr-btn text-blue-gray"
            disabled={modalFsCode !== (feedstock && feedstock.code)}
          >
            Sim, pode deletar.
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FeedstockRegisterPage
