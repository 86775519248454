import Grid from '@material-ui/core/Grid'
import React, { Component, Fragment } from 'react'
import ErrorBox from '../../../components/ErrorBox'
import {
  FeedstockSearchResults,
  FeedstockTable,
} from '../components/organisms/feedstockTable'
import defineMessages from '../messages'
import { FeedstockSearchPanel } from './../components/organisms/searchPanel'
import {
  StyledCircularProgress,
  StyledPaper,
} from '../components/organisms/feedstockTable/styles'
import { Typography } from '../../components/atoms/typography'
import { isKitchenAdmin } from '../../../helperFunctions/isKitchenAdmin'
import { IKitchen } from '../../contracts/kitchens'

export interface FeedstockSearchPageProps {
  feedstockSearchResults: FeedstockSearchResults
  authUser: any
  kitchens: IKitchen[]
  searchFeedstock: (nameOrCode: string, kitchen?: string) => void
  fetchKitchens: () => void
}
export class FeedstockSearchPage extends Component<FeedstockSearchPageProps> {
  constructor(props: FeedstockSearchPageProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.searchFeedstock(null)
    this.props.fetchKitchens()
  }

  submitFeedstockSearch = (searchParams: string, selectedKitchen: string) => {
    const { searchFeedstock, kitchens } = this.props
    const kitchenId =
      selectedKitchen &&
      kitchens &&
      kitchens.find(kitchen => kitchen.name === selectedKitchen).id
    searchFeedstock(searchParams, kitchenId)
  }

  renderFeedstockSearchPanel() {
    const { kitchens, authUser } = this.props
    const isAdmin =
      authUser && authUser.scopes && isKitchenAdmin(authUser.scopes)
    return (
      <FeedstockSearchPanel
        onFilterSubmit={(nameOrCode, selectedKitchen) =>
          this.submitFeedstockSearch(nameOrCode, selectedKitchen)
        }
        kitchens={isAdmin && kitchens ? kitchens : null}
      />
    )
  }

  renderFeedstockTable() {
    if (this.props.feedstockSearchResults.loading) {
      return (
        <Grid container justify="center">
          <StyledCircularProgress size={50} />
        </Grid>
      )
    }
    if (this.props.feedstockSearchResults.error) {
      return <ErrorBox message={this.props.feedstockSearchResults.error} />
    }
    if (this.props.feedstockSearchResults.data.length < 1) {
      return (
        <Fragment>
          <StyledPaper>
            <Typography fontSize="medium">
              {defineMessages.searchWithoutResults.defaultMessage}
            </Typography>
          </StyledPaper>
        </Fragment>
      )
    }
    return (
      <FeedstockTable
        feedstockSearchResults={this.props.feedstockSearchResults}
      />
    )
  }

  render = () => (
    <Fragment>
      {this.renderFeedstockSearchPanel()}
      {this.renderFeedstockTable()}
    </Fragment>
  )
}
