import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'

import { Ingredient, SaveIngredient } from '../../../api/dto/ingredients'
import HeaderfulContainer from '../../../components/molecules/headerfulContainer'
import { LookupItem } from '../../../contracts/lookupItem'
import { FeedstockSelect } from '../FeedstockSelect'
import { emptyIngredientValidations } from '../../validations/ingredient'
import SelectKitchens from 'src/v2/components/molecules/selectKitchens'
import { ActionsFooter, InputForm, SaveButton } from './styles'

export interface Props {
  ingredient?: Ingredient
  onSave: (value: SaveIngredient) => void
}

const emptyIngredient: SaveIngredient = {
  recipeName: '',
  tagName: '',
  active: true,
  waste: '',
  processingYield: '',
  kitchens: [],
  feedstock: null,
  composition: ''
}

export const IngredientForm: React.FC<Props> = props => {
  const { onSave, ingredient } = props;
  const [newIngredient, setNewIngredient] = useState<SaveIngredient>(emptyIngredient);

  useEffect(() => {
    setNewIngredient({
      ...emptyIngredient,
      ...(
        ingredient ? {
          recipeName: ingredient.recipeName,
          tagName: ingredient.tagName,
          code: ingredient.code,
          active: ingredient.active,
          waste: ingredient.waste,
          processingYield: ingredient.processingYield,
          kitchens: ingredient.kitchens,
          feedstock: ingredient.feedstock && {
            id: ingredient.feedstock.id,
            feedstockName: ingredient.feedstock.feedstockName,
          },
          composition: ingredient.composition,
        } : {})
    });
  }, [ingredient])

  const selectedFeedstockValue: LookupItem = useMemo(() => {
    return newIngredient.feedstock && {
      value: newIngredient.feedstock.id,
      label: newIngredient.feedstock.feedstockName,
    }
  }, [newIngredient.feedstock])

  const disabledSubmit: boolean = useMemo(
    () => emptyIngredientValidations(newIngredient).length > 0, [newIngredient]
  )

  const handleOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewIngredient({
      ...newIngredient,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  const handleOnChangeCheckbox = (event: React.FormEvent<{ name: string, checked: boolean }>) => {
    setNewIngredient({
      ...newIngredient,
      [event.currentTarget.name]: event.currentTarget.checked
    })
  }

  const handleSelectedKitchensChange = (items: LookupItem | LookupItem[], keys: string[]) => {
    setNewIngredient({
      ...newIngredient,
      kitchens: keys,
    })
  }

  const handleSelectedFeedstockChange = (item: LookupItem) => {
    setNewIngredient({
      ...newIngredient,
      feedstock: {
        id: item.value,
        feedstockName: item.label,
      },
    })
  }

  return (
    <>
      <HeaderfulContainer title='Informações básicas'>
        <Grid container direction='column' spacing={8}>
          <Grid item container direction='row' spacing={8}>
            <Grid item sm={10}>
              <FormControlLabel
                label='Ativar ingrediente'
                control={
                  <Checkbox
                    name='active'
                    checked={newIngredient.active}
                    onChange={handleOnChangeCheckbox}
                  />
                }
              />
            </Grid>
            {newIngredient.code && <Grid item sm={2}>
              <InputForm
                label='Code'
                name='code'
                disableUnderline
                disabled
                value={newIngredient.code ?? ""}
                type='text'
                onChange={handleOnChange}
              />
            </Grid>}
          </Grid>
          <Grid item container direction='row' spacing={8}>
            <Grid item sm={6}>
              <InputForm
                name='recipeName'
                value={newIngredient.recipeName}
                onChange={handleOnChange}
                label='Nome para receita'
                required
                type='text'
              />
            </Grid>
            <Grid item sm={6}>
              <InputForm
                name='tagName'
                value={newIngredient.tagName}
                onChange={handleOnChange}
                label='Nome na etiqueta'
                required
                type='text'
              />
            </Grid>
          </Grid>
          <Grid item container direction='row' spacing={8}>
            <Grid item sm={12}>
              <InputForm
                name='composition'
                value={newIngredient.composition}
                onChange={handleOnChange}
                label='Composição do ingrediente'
                required={false}
                type='text'
              />
            </Grid>
          </Grid>
          <Grid item container direction='row' spacing={8}>
            <Grid item sm={2}>
              <InputForm
                name='waste'
                value={`${newIngredient.waste}`}
                onChange={handleOnChange}
                label='Disperdício'
                required
                type='percentage'
              />
            </Grid>
            <Grid item sm={2}>
              <InputForm
                name='processingYield'
                value={`${newIngredient.processingYield}`}
                onChange={handleOnChange}
                label='Rendimento'
                required
                type='percentage'
              />
            </Grid>
            <Grid item sm={4}>
              <SelectKitchens
                selected={newIngredient.kitchens ?? []}
                onSelect={handleSelectedKitchensChange}
              />
            </Grid>
            <Grid item sm={4}>
              <FeedstockSelect
                initiallySelected={selectedFeedstockValue}
                onSelect={handleSelectedFeedstockChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </HeaderfulContainer>
      <ActionsFooter>
        <SaveButton
          variant='contained'
          color='primary'
          size='large'
          onClick={() => onSave(newIngredient as any)}
          disabled={disabledSubmit}
        >
          Salvar ingrediente
        </SaveButton>
      </ActionsFooter>
    </>
  )
}
