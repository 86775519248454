import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from '../Messages'

export const tableHeader = [
  <FormattedMessage {...defineMessages.versionNumber} />,
  <FormattedMessage {...defineMessages.inProduction} />,
  <FormattedMessage {...defineMessages.changeReason} />,
  <FormattedMessage {...defineMessages.personInCharge} />,
  <FormattedMessage {...defineMessages.originVersionNumber} />,
]

export const recipeVersioningHistoricMessage = (
  <FormattedMessage {...defineMessages.recipeVersioningHistoric} />
)

export const activeVersion = activeVersionNumber => (
  <FormattedMessage
    id="app.recipeVersioningHistoric.activeVersion"
    defaultMessage="Versão ativa: {activeVersionNumber}"
    values={{ activeVersionNumber }}
  />
)

export const changeReason = reason => (
  <FormattedMessage
    id="app.recipeVersioningHistoric.reason"
    defaultMessage="Razão da Mudança: {reason}"
    values={{ reason }}
  />
)

export const changeResponsible = responsible => (
  <FormattedMessage
    id="app.recipeVersioningHistoric.responsible"
    defaultMessage="Responsável: {responsible}"
    values={{ responsible }}
  />
)
