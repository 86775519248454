import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from './Messages'

export const recipesMessageHeader = (
  <FormattedMessage {...defineMessages.header} />
)

export const recipesMessageTitle = (
  <FormattedMessage {...defineMessages.title} />
)

export const costPerUnitMessage = (
  <FormattedMessage {...defineMessages.costPerUnit} />
)

export const nutritionFactsMessage = (
  <FormattedMessage {...defineMessages.nutritionFacts} />
)

export const dailyValueMessage = (
  <FormattedMessage {...defineMessages.dailyValue} />
)

export const recipesMessage = <FormattedMessage {...defineMessages.recipes} />

export const emptySubRecipes = (
  <FormattedMessage {...defineMessages.emptySubRecipes} />
)

export const subRecipeNameMessage = (
  <FormattedMessage {...defineMessages.subRecipeName} />
)

export const subRecipeQuantityMessage = (
  <FormattedMessage {...defineMessages.subRecipeQuantity} />
)

export const subRecipeCostPerUnitMessage = (
  <FormattedMessage {...defineMessages.subRecipeCostPerUnit} />
)

export const subRecipePercentPriceMessage = (
  <FormattedMessage {...defineMessages.subRecipePercentPrice} />
)

export const ingredientsMessage = (
  <FormattedMessage {...defineMessages.ingredients} />
)

export const emptyIngredients = (
  <FormattedMessage {...defineMessages.emptyIngredients} />
)

export const ingredientNameMessage = (
  <FormattedMessage {...defineMessages.ingredientName} />
)

export const ingredientCompositionMessage = (
  <FormattedMessage {...defineMessages.compositionName} />
)

export const ingredientQuantityMessage = (
  <FormattedMessage {...defineMessages.ingredientQuantity} />
)

export const ingredientPriceMessageSimulation = (
  <FormattedMessage {...defineMessages.ingredientPriceSimulation} />
)

export const ingredientPriceMessage = (
  <FormattedMessage {...defineMessages.ingredientPrice} />
)

export const ingredientCurrentPriceMessage = (
  <FormattedMessage {...defineMessages.ingredientCurrentPrice} />
)

export const ingredientPricePerUnitMessage = (
  <FormattedMessage {...defineMessages.ingredientPricePerUnit} />
)

export const ingredientPercentPriceMessage = (
  <FormattedMessage {...defineMessages.ingredientPercentPrice} />
)
