export const messages = {
  titles: {
    main: 'Informações básicas',
    stockKind: 'Tipo de estoque:',
    feedstockCategory: 'Selecione uma categoria para a matéria-prima:',
    nutritionalInfoSource: 'Selecione a fonte da informação nutricional:',
    kitchens: 'Selecione a cozinha desta matéria prima',
    active: 'Ativar/Desativar:',
  },
  inputs: {
    feedstockName: 'Nome da matéria-prima',
    feedstockDensity: 'Densidade (Kg/L)',
    feedstockPrice: 'Preço atual(obrigatório)',
    code: 'Código',
  },
  lookups: {
    categorySelect: 'Selecione a categoria',
    nextAvaiableCodeCategory: 'Selecione tipo de categoria para gerar código',
    nutritionalInfoSource: 'Fonte das informações nutricionais',
    sourceSearch: 'Buscar na fonte',
  },
  radioLabels: ['Refrigerado', 'Congelado', 'Seco'],
}
