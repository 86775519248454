import { defineMessages } from 'react-intl'

export default defineMessages({
  recipeSimulation: {
    id: 'app.recipeSimulation.title',
    defaultMessage: 'Simulação De Receitas',
  },
  subRecipeQuantity: {
    id: 'app.recipeSimulation.quantity',
    defaultMessage: 'Quantidade',
  },
  subRecipeCostPerUnit: {
    id: 'app.recipeSimulation.costPerUnit',
    defaultMessage: 'Custo por saquinho',
  },
  subRecipePercentPrice: {
    id: 'app.recipeSimulation.percentPrice',
    defaultMessage: 'Preço percentual',
  },
  referenceGrammage: {
    id: 'app.recipeSimulation.referenceGrammage',
    description: 'Quantidade (tabela nutricional)',
    defaultMessage: 'Porção regulamentação (legislação)',
  },
  newRecipe: {
    id: 'app.recipeSimulation.newRecipe',
    defaultMessage: 'Nova receita',
  },
  chooseRecipe: {
    id: 'app.recipeSimulation.chooseRecipe',
    defaultMessage: 'Escolha uma receita',
  },
  recipeInfo: {
    id: 'app.recipeSimulation.recipeInfo.title',
    defaultMessage: 'Informações básicas',
  },
  recipeName: {
    id: 'app.recipeSimulation.recipeInfo.name',
    defaultMessage: 'Nome',
  },
  recipeYield: {
    id: 'app.recipeSimulation.recipeInfo.yield',
    defaultMessage: 'Rendimento',
  },
  recipeWaste: {
    id: 'app.recipeSimulation.recipeInfo.waste',
    defaultMessage: 'Desperdício',
  },
  portionSize: {
    id: 'app.recipeSimulation.recipeInfo.portionSize',
    description: 'Tamanho da porção',
    defaultMessage: 'Peso líquido',
  },
  batchSize: {
    id: 'app.recipeSimulation.recipeInfo.batchSize',
    defaultMessage: 'Lote',
  },
  perHundred: {
    id: 'app.recipeSimulation.nutritionFacts.perHundred',
    defaultMessage: '100g',
  },
  subRecipeYield: {
    id: 'app.recipeSimulation.recipes.yield',
    defaultMessage: 'Rendimento da receita',
  },
  selectModalIngredientsTitle: {
    id: 'app.recipeSimulation.ingredients.modal.title',
    defaultMessage: 'Escolha o ingrediente',
  },
  selectModalIngredientsDropDown: {
    id: 'app.recipeSimulation.ingredients.modal.dropDown',
    defaultMessage: 'Ingredientes',
  },
  selectModalRecipesTitle: {
    id: 'app.recipeSimulation.recipes.modal.title',
    defaultMessage: 'Escolha a receita',
  },
  selectModalRecipesDropDown: {
    id: 'app.recipeSimulation.recipes.modal.dropDown',
    defaultMessage: 'Receitas',
  },
  selectModalConfirmButton: {
    id: 'app.recipeSimulation.modal.confirm',
    defaultMessage: 'Confirmar',
  },
  selectModalAddButton: {
    id: 'app.recipeSimulation.modal.add',
    defaultMessage: 'Adicionar',
  },
})
