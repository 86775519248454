import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import CardBox from 'components/CardBox'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorBox from 'components/ErrorBox'
import ResultsTable from 'components/ResultsTable'
import SearchBar from 'components/SearchBar'
import { fetchProductionRecipes } from 'actions/ProductionRecipe'
import { searchPlaceholder, tableHeader } from '../../formattedMessages'

class SearchProductionRecipeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchToken: '',
    }
  }

  componentDidMount() {
    this.props.fetchProductionRecipes()
  }

  getRecipesFieldsPath = () => ['sku', 'name']

  goToEditPage = selectedProductionRecipe => {
    const selectedSku = selectedProductionRecipe.sku
    this.props.history.push({
      pathname: `/app/productionRecipe/edit/${selectedSku}`,
    })
  }

  handleSearch = filter => this.setState({ searchToken: filter })

  filterProductionRecipe() {
    const { searchToken } = this.state
    if (!searchToken) {
      return this.props.recipes.data
    }
    const filterRegex = new RegExp(searchToken, 'i')
    return this.props.recipes.data.filter(el => el.name.match(filterRegex))
  }

  renderProductionRecipes() {
    const { recipes } = this.props
    if (recipes.isLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (recipes.error) {
      return <ErrorBox message={recipes.error} />
    }
    if (recipes.data) {
      return (
        <CardBox styleName="col-sm-10">
          <Grid container justify="center">
            <Grid item sm={6}>
              <ResultsTable
                tableHeader={tableHeader}
                results={this.filterProductionRecipe()}
                fieldsPath={this.getRecipesFieldsPath()}
                goToEditPage={this.goToEditPage}
              />
            </Grid>
          </Grid>
        </CardBox>
      )
    }
  }

  render() {
    return (
      <Fragment>
        <SearchBar
          onChange={this.handleSearch}
          searchPlaceholder={searchPlaceholder}
        />
        <Grid container justify="center">
          {this.renderProductionRecipes()}
        </Grid>
      </Fragment>
    )
  }
}

const mapActionsToProps = {
  fetchProductionRecipes,
}

const mapStateToProps = state => ({
  recipes: state.productionRecipes.allRecipes,
  productionRecipes: state.productionRecipes.allRecipes.data,
})

SearchProductionRecipeContainer.defaultProps = {
  recipes: {},
}

SearchProductionRecipeContainer.propTypes = {
  history: PropTypes.object.isRequired,
  recipes: PropTypes.object,
}

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(SearchProductionRecipeContainer)
)
