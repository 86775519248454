import moment from 'moment'

export function isToday(date: Date) {
  if (!date || isNaN(date.getTime())) {
    return false
  }

  const todayTime = moment().startOf('day').toDate().getTime()
  const toCompareTime = moment(date).startOf('day').toDate().getTime()

  return todayTime === toCompareTime
}
