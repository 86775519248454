import {
  UPDATE_CONFIG_REQUEST,
  UPDATE_CONFIG_RESPONSE,
  UPDATE_CONFIG_ERROR,
} from '../actionTypes'
import { sendNotification } from '../../../actions/Notifications'

export const updateConfig = input => async (dispatch, _getState, api) => {
  dispatch({
    type: UPDATE_CONFIG_REQUEST,
  })
  try {
    const response = await api.post(`/api/recipes/updateConfig`, input)
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Edição salva!',
        autoDismiss: true,
        soundOff: true,
      })
    )
    dispatch({
      type: UPDATE_CONFIG_RESPONSE,
      payload: { data: response.data },
    })
  } catch (err) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Erro ao editar',
        autoDismiss: true,
      })
    )
    dispatch({ type: UPDATE_CONFIG_ERROR })
  }
}

export const fetchSingleTagConfiguration = code => async (dispatch, _, api) => {
  try {
    const response = await api.get(`/api/tags/${code}/configuration`)
    return response
  } catch (err) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Erro ao carregar a configuração da etiqueta',
        autoDismiss: true,
      })
    )
  }
}
