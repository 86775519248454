import dotProp from 'dot-prop-immutable'

const REUSED_WASTE_INGREDIENTS = ['IR089']

export const calculateRecipeCost = (
  recipe,
  recipeQuantity,
  state,
  path,
  calculateRecipeResultingWeight
) => {
  const { recipeYield, recipeWaste, ingredients, subRecipes } = recipe
  let updatedState = state
  let cost = 0
  const resultingWeight = calculateRecipeResultingWeight(
    recipeYield,
    recipeWaste,
    ingredients,
    subRecipes
  )
  if (ingredients && ingredients.length > 0) {
    const { stateWithUpdatedIngredients, ingredientsTotalCost } =
      getIngredientTotal(
        recipeQuantity,
        resultingWeight.value,
        ingredients,
        state,
        path === '' ? `ingredients` : `${path}.ingredients`
      )
    cost += ingredientsTotalCost
    updatedState = stateWithUpdatedIngredients
  }
  if (subRecipes && subRecipes.length > 0) {
    updatedState = subRecipes.reduce((nextState, subRecipe, subRecipeIndex) => {
      const subRecipeQuantity =
        subRecipe.quantity * (recipeQuantity / resultingWeight.value)
      const { updatedState: stateWithUpdatedSubRecipe, recipeTotalCost } =
        calculateRecipeCost(
          subRecipe,
          subRecipeQuantity,
          nextState,
          path === ''
            ? `subRecipes.${subRecipeIndex}`
            : `${path}.subRecipes.${subRecipeIndex}`,
          calculateRecipeResultingWeight
        )
      cost += recipeTotalCost
      return dotProp.set(
        stateWithUpdatedSubRecipe,
        path === ''
          ? `subRecipes.${subRecipeIndex}.recipeTotalCost`
          : `${path}.subRecipes.${subRecipeIndex}.recipeTotalCost`,
        recipeTotalCost
      )
    }, updatedState)
  }
  return { updatedState, recipeTotalCost: cost }
}

export const getIngredientTotal = (
  recipeQuantity,
  resultingWeight,
  ingredients,
  state,
  path
) => {
  let cost = 0
  const stateWithUpdatedIngredients = ingredients.reduce(
    (nextState, ingredient, ingredientIndex) => {
      const ingredientTotalCost =
        (ingredient.quantity /
          (REUSED_WASTE_INGREDIENTS.includes(ingredient.code)
            ? 1
            : 1 - ingredient.waste / 100)) *
        (recipeQuantity / resultingWeight) *
        (ingredient.currentPrice / 1000)
      cost += ingredientTotalCost
      return dotProp.set(nextState, `${path}.${ingredientIndex}`, {
        ...ingredient,
        ingredientTotalCost,
      })
    },
    state
  )
  return {
    stateWithUpdatedIngredients,
    ingredientsTotalCost: cost,
  }
}
