import { Grid, MenuItem } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { read, utils } from 'xlsx'
import Select from '../../../../components/Select'
import { useAPI } from '../../../api'
import { Kitchen } from '../../../api/dto/kitchen'
import { Endpoints } from '../../../api/endpoints'
import { Button } from '../../../components/atoms/button'
import { Container, HiddenButton, Info, Subtitle, Title } from './styles'

const reader = new FileReader()
const errorFile = require('../../../../../public/sounds/error.mp3')
const beepFile = require('../../../../../public/sounds/beep.mp3')
const errorAudio = new Audio(errorFile)
const beepAudio = new Audio(beepFile)

interface Props {
  onSave: () => void
  onCreatePlanning: (value: any) => void
}

export const ImportPlanningsContainer: React.FC<Props> = ({
  onSave,
  onCreatePlanning,
}) => {
  const [fetchKitchens, isLoading] = useAPI()
  const [importProductionPlanning, isLoadingImportProducionPlanning] = useAPI()
  const { enqueueSnackbar } = useSnackbar()

  const [kitchens, setKitchens] = useState<Kitchen[]>([])
  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen>()
  const [productionPlanningCSV, setProductionPlanningCSV] = useState('')
  const [filename, setFileName] = useState('')
  const [disabled, setDisabled] = useState(true)
  const xlsxInput = useRef<HTMLInputElement>()

  const handleRequestError = (error: Error) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      preventDuplicate: true,
      autoHideDuration: 5000,
      onEnter: () => errorAudio.play(),
    })
  }

  const handleRequestSuccess = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      preventDuplicate: true,
      autoHideDuration: 5000,
      onEnter: () => beepAudio.play(),
    })
  }

  reader.onload = (event: ProgressEvent<FileReader>) => {
    const bstr = event.target.result
    const wb = read(bstr, { type: 'binary' })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const wscsv = utils.sheet_to_csv(ws, { FS: ';' })
    setProductionPlanningCSV(wscsv)
    setTimeout(() => {
      setDisabled(false)
    })
  }

  useEffect(() => {
    fetchKitchens({
      method: 'GET',
      uri: Endpoints.KITCHENS,
    })
      .then(data => {
        if (!data) {
          return
        }
        setKitchens(data)
        setSelectedKitchen(data[0])
      })
      .catch(handleRequestError)
  }, [])

  const handleKitchenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedKitchen(kitchens.find(k => k.code === event.target.value))
  }

  const handleUpload = () => {
    xlsxInput.current.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      files: { 0: file },
    } = event.target
    setFileName(file.name)
    setDisabled(true)
    reader.readAsBinaryString(file)
  }

  const handleSave = () => {
    importProductionPlanning({
      method: 'POST',
      uri: Endpoints.PRODUCTION_PLANNING,
      body: { data: productionPlanningCSV, kitchen: selectedKitchen.code },
    })
      .then(savedPlanningData => {
        const [initialPlanning, finalPlanning] = savedPlanningData
        onCreatePlanning({
          firstPlanningId: initialPlanning.id,
          secondPlanningId: finalPlanning.id,
        })
        onSave()
        handleRequestSuccess('Planejamento salvo com sucesso!')
      })
      .catch(handleRequestError)
  }

  return (
    <Container elevation={1}>
      <Grid container direction="column">
        <Title>Qual cozinha deseja subir?</Title>

        <Select
          id="kitchen"
          label={isLoading ? 'Carregando cozinhas, aguarde...' : ''}
          value={selectedKitchen && selectedKitchen.code}
          onChange={handleKitchenChange}
        >
          {kitchens.map(kitchen => (
            <MenuItem key={kitchen.code} value={kitchen.code}>
              {kitchen.name}
            </MenuItem>
          ))}
        </Select>
        <Subtitle>Nome do arquivo selecionado:</Subtitle>
        <Info>{filename ? filename : 'Nenhum arquivo selecionado'}</Info>
        <Grid container justify="space-between">
          <HiddenButton
            type="file"
            id="feedstocks-planning-upload"
            ref={xlsxInput}
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            color="success"
            size="large"
            onClick={handleUpload}
          >
            Selecionar Arquivo
          </Button>
          <Button
            disabled={disabled || isLoadingImportProducionPlanning}
            variant="contained"
            color="success"
            size="large"
            onClick={handleSave}
          >
            {isLoadingImportProducionPlanning ? 'Carregando...' : 'Salvar'}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
