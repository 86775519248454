import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import axios from 'axios'
import { fetchUser } from 'actions/Auth'
import createHistory from 'history/createHashHistory'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'react-router-redux'
import reducers from '../reducers'

import * as authToken from '../utils/authToken'

const history = createHistory()
const routeMiddleware = routerMiddleware(history)

export default () => {
  const axiosInstance = axios.create({
    baseURL: process.env.API_ADDRESS || 'http://localhost:3000',
  })

  axiosInstance.interceptors.request.use(async config => {
    const token = await authToken.getToken()
    if (!token) return config

    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: token,
      },
    }
  })
  const thunkMiddleware = thunk.withExtraArgument(axiosInstance)
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(routeMiddleware, thunkMiddleware))
  )
  store.dispatch(fetchUser(history))

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export { history }
