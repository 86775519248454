import React, { ReactNode } from 'react'
import { Body, Container, Header } from './styles'

interface HeaderfulContainerProps {
  children: ReactNode
  title: string
  variant?: 'purple' | 'default'
}

const HeaderfulContainer = ({
  children,
  title,
  variant,
  ...props
}: HeaderfulContainerProps) => {
  return (
    <Container {...props}>
      <Header fontSize="large" variant={variant}>
        {title}
      </Header>
      <Body>{children}</Body>
    </Container>
  )
}

export default HeaderfulContainer
