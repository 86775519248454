import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from '.'

export const configTags = <FormattedMessage {...defineMessages.configTag} />
export const printTags = <FormattedMessage {...defineMessages.printTag} />

export const productSelectionTitle = (
  <FormattedMessage {...defineMessages.selectProduct} />
)

export const productionDateTitle = (
  <FormattedMessage {...defineMessages.productionDate} />
)

export const printButtonTitle = (
  <FormattedMessage {...defineMessages.printAction} />
)
