import { VariantType, OptionsObject } from 'notistack'

const errorFile = 'public/sounds/error.mp3'
const beepFile = 'public/sounds/beep.mp3'

const errorAudio = new Audio(errorFile)
const beepAudio = new Audio(beepFile)

function handleSnackbarEnter(variant: VariantType) {
  if (variant === 'error') {
    errorAudio.play()
    return
  }

  beepAudio.play()
}

export function createSnackbarOptions(variant: VariantType): OptionsObject {
  return {
    variant,
    onEnter: () => handleSnackbarEnter(variant),
  }
}
