import { defineMessages } from 'react-intl'

export default defineMessages({
  searchResource: {
    id: 'a.register',
    defaultMessage: 'Buscar Recurso de Produção',
  },
  name: {
    id: 'a.name',
    defaultMessage: 'Nome',
  },
  limit: {
    id: 'a.limit',
    defaultMessage: 'Limite',
  },
  type: {
    id: 'a.type',
    defaultMessage: 'Tipo',
  },
  status: {
    id: 'a.status',
    defaultMessage: 'Status',
  },
  searchPlaceholder: {
    id: 'a.searchPlaceholder',
    defaultMessage: 'Digite o nome do Recurso de Produção',
  },
})
