import {
  SEARCH_FEEDSTOCK_RESPONSE,
  SEARCH_FEEDSTOCK_REQUEST,
  SEARCH_FEEDSTOCK_ERROR,
  FETCH_FEEDSTOCK_PRICES_ERROR,
  FETCH_FEEDSTOCK_PRICES_RESPONSE,
} from '../actionTypes'

const initialState = {
  search: {
    data: {},
    loading: true,
    error: null,
  },
  prices: {
    data: {},
    loading: false,
    error: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_FEEDSTOCK_REQUEST: {
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
        },
      }
    }
    case SEARCH_FEEDSTOCK_RESPONSE: {
      return {
        ...state,
        search: {
          ...state.search,
          data: action.payload,
          loading: false,
        },
      }
    }
    case SEARCH_FEEDSTOCK_ERROR: {
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          error: action.payload,
        },
      }
    }
    case FETCH_FEEDSTOCK_PRICES_RESPONSE: {
      return {
        ...state,
        prices: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_FEEDSTOCK_PRICES_ERROR: {
      return {
        ...state,
        prices: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    default:
      return state
  }
}
