import { NOTIFICATION_SEND, NOTIFICATION_RESET } from '../constants/ActionTypes'

export const errorNotification = err => async (dispatch, getState, api) => {
  const message =
    err.response && err.response.data && err.response.data
      ? err.response.data.message ||
        (err.response.data.output &&
          err.response.data.output.payload &&
          err.response.data.output.payload.message)
      : err.message
  dispatch({
    type: NOTIFICATION_SEND,
    payload: {
      type: 'error',
      message,
      autoDismiss: 10,
    },
  })
}

export const sendNotification =
  ({ type, message, autoDismiss, soundOff }) =>
  async (dispatch, getState, api) => {
    dispatch({
      type: NOTIFICATION_SEND,
      payload: {
        type,
        message,
        autoDismiss: 10,
        soundOff,
      },
    })
  }

export const resetNotification = () => async (dispatch, getState, api) => {
  dispatch({
    type: NOTIFICATION_RESET,
  })
}
