import styled from 'styled-components'
import MuiIconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { ComponentType } from 'react'

interface StyledIconButtonProps {
  fontColor?: string
}

export const StyledIconButton: ComponentType<
  IconButtonProps & StyledIconButtonProps
> = styled(MuiIconButton)<StyledIconButtonProps>`
  && {
    color: ${props => props.fontColor || props.theme.v2.palette.grayScale[80]};
    :hover {
      background-color: ${props => props.theme.v2.palette.transparent};
      color: ${props => props.theme.v2.palette.grayScale[40]};
    }
  }
`
