import { parseResourceType } from 'utils/parseResourceType'
import { parseResourceStatus } from 'utils/parseResourceStatus'
import { parseResourceLimit } from 'utils/parseResourceLimit'
import {
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_RESPONSE,
  FETCH_RESOURCES_ERROR,
  FETCH_SELECT_RESOURCES_REQUEST,
  FETCH_SELECT_RESOURCES_RESPONSE,
  FETCH_SELECT_RESOURCES_ERROR,
  CREATE_RESOURCES_REQUEST,
  CREATE_RESOURCES_RESPONSE,
  CREATE_RESOURCES_ERROR,
  EDIT_RESOURCES_REQUEST,
  EDIT_RESOURCES_RESPONSE,
  EDIT_RESOURCES_ERROR,
} from '../constants/ActionTypes'
import { handleRequestError } from './ErrorHandling'
import { errorNotification, sendNotification } from './Notifications'

const fetchRequestStarted = () => ({
  type: FETCH_RESOURCES_REQUEST,
})

const fetchRequestResponse = payload => ({
  type: FETCH_RESOURCES_RESPONSE,
  payload,
})

const fetchRequestError = error => ({
  type: FETCH_RESOURCES_ERROR,
  payload: error,
})

const parseResourceToSave = resource => ({
  name: resource.name,
  code: resource.code,
  description: resource.description,
  difficulty: resource.difficulty,
  status: {
    label: parseResourceStatus(resource.isActive),
    value: resource.isActive ? 'active' : 'inactive',
  },
  type: {
    label: parseResourceType(resource.type),
    value: resource.type,
  },
  limit: {
    label: parseResourceLimit(resource.limit.type),
    value: resource.limit.type,
  },
  resourceLimit: resource.limit.value,
})

export const fetchResources = () => async (dispatch, getState, api) => {
  dispatch(fetchRequestStarted())
  try {
    const response = await api.get('/api/ppcp/resources')
    const parsedResources = response && response.data.map(parseResourceToSave)
    dispatch(fetchRequestResponse(parsedResources))
  } catch (error) {
    dispatch(fetchRequestError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

const fetchSelectResourcesRequestStarted = () => ({
  type: FETCH_SELECT_RESOURCES_REQUEST,
})

const fetchSelectResourcesRequestResponse = payload => ({
  type: FETCH_SELECT_RESOURCES_RESPONSE,
  payload,
})

const fetchSelectResourcesRequestError = error => ({
  type: FETCH_SELECT_RESOURCES_ERROR,
  payload: error,
})

export const fetchSelectResources = () => async (dispatch, api) => {
  try {
    dispatch(fetchSelectResourcesRequestStarted())
    // TODO: remove mock and timeout
    const response = mockAPI
    const resources = response && response.resources
    setTimeout(
      () => dispatch(fetchSelectResourcesRequestResponse(resources)),
      3000
    )
  } catch (error) {
    dispatch(fetchSelectResourcesRequestError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

const createResourceRequest = () => ({
  type: CREATE_RESOURCES_REQUEST,
})

const createResourceResponse = () => ({
  type: CREATE_RESOURCES_RESPONSE,
})

const createResourceError = error => ({
  type: CREATE_RESOURCES_ERROR,
  payload: error,
})

export const createResource = resource => async (dispatch, getState, api) => {
  try {
    dispatch(createResourceRequest())
    await api.post('/api/ppcp/resources/create', resource)
    dispatch(createResourceResponse())
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Recurso de Produção criado com sucesso!',
        autoDismiss: true,
      })
    )
  } catch (error) {
    dispatch(createResourceError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

const editResourceRequest = () => ({
  type: EDIT_RESOURCES_REQUEST,
})

const editResourceResponse = payload => ({
  type: EDIT_RESOURCES_RESPONSE,
  payload,
})

const editResourceError = error => ({
  type: EDIT_RESOURCES_ERROR,
  payload: error,
})

export const editResource = resource => async (dispatch, getState, api) => {
  dispatch(editResourceRequest())
  try {
    const response = await api.post('api/ppcp/resources/edit', resource)
    dispatch(editResourceResponse(parseResourceToSave(response.data)))
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Recurso de Produção atualizado com sucesso!',
        autoDismiss: true,
      })
    )
  } catch (error) {
    dispatch(editResourceError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}
