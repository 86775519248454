import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'

class InfoIconTooltip extends PureComponent {
  render() {
    const { content, fontSize } = this.props
    return (
      <Tooltip placement="left-start" title={content}>
        <InfoIcon color="action" fontSize={fontSize} />
      </Tooltip>
    )
  }
}

InfoIconTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  fontSize: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
}

export default InfoIconTooltip
