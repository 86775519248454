import {
  ALLOWED_COOKING_TIMES_QUANTITY,
  COOKING_TIME_LIMIT_IN_SECONDS,
  REQUIRED_COOKING_TIMES_QUANTITY,
} from '../../constants/recipes'

function checkCookingTimesLength(cookingTimes) {
  if (!Array.isArray(cookingTimes)) {
    throw new Error('Informe ao menos um método de aquecimento')
  }

  if (cookingTimes.length < REQUIRED_COOKING_TIMES_QUANTITY) {
    throw new Error(
      `Informe ao menos ${REQUIRED_COOKING_TIMES_QUANTITY} método${
        REQUIRED_COOKING_TIMES_QUANTITY > 1 ? 's' : ''
      } de aquecimento`
    )
  }

  if (cookingTimes.length > ALLOWED_COOKING_TIMES_QUANTITY) {
    throw new Error(
      `Informe no máximo ${ALLOWED_COOKING_TIMES_QUANTITY} método${
        ALLOWED_COOKING_TIMES_QUANTITY > 1 ? 's' : ''
      } de aquecimento`
    )
  }
}

function checkCookingTimeType(cookingTime, index) {
  const cookingMethodReference = `${index + 1}º Método de Aquecimento`
  if (!cookingTime.type) {
    throw new Error(`Informe o ${cookingMethodReference}`)
  }
}

function checkCookingTimeRange(cookingTime, index) {
  const cookingMethodReference = `${index + 1}º Método de Aquecimento`

  if (!cookingTime.range) {
    throw new Error(
      `O intervalo de tempo de acquecimento do ${cookingMethodReference} deve ser informado`
    )
  }

  if (!cookingTime.range.min || cookingTime.range.min <= 0) {
    throw new Error(
      `O valor mínimo para o campo "Tempo Mínimo" do ${cookingMethodReference} deve ser maior que zero`
    )
  }

  if (!cookingTime.range.max || cookingTime.range.max <= 0) {
    throw new Error(
      `O valor mínimo para o campo "Tempo Máximo" do ${cookingMethodReference} deve ser maior que zero`
    )
  }

  if (cookingTime.range.min > COOKING_TIME_LIMIT_IN_SECONDS) {
    throw new Error(
      `O valor máximo para o campo "Tempo Mínimo" do ${cookingMethodReference} deve ser menor ou igual a ${COOKING_TIME_LIMIT_IN_SECONDS}`
    )
  }

  if (cookingTime.range.max > COOKING_TIME_LIMIT_IN_SECONDS) {
    throw new Error(
      `O valor máximo para o campo "Tempo Máximo" do ${cookingMethodReference} deve ser menor ou igual a ${COOKING_TIME_LIMIT_IN_SECONDS}`
    )
  }

  if (cookingTime.range.max < cookingTime.range.min) {
    throw new Error(
      `O valor do campo "Tempo Máximo" do ${cookingMethodReference} deve ser maior ou igual ao campo "Tempo Mínimo"`
    )
  }
}

export function validateRecipeCookingTimes(cookingTimes) {
  checkCookingTimesLength(cookingTimes)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookingTimes.length; i++) {
    const cookingTime = cookingTimes[i]
    checkCookingTimeType(cookingTime, i)
    checkCookingTimeRange(cookingTime, i)
  }
}

export function validateRecipeRequiredFields(recipe) {
  return (
    recipe.recipeInfo.sku &&
    recipe.recipeInfo.name &&
    recipe.recipeInfo.gluten &&
    recipe.recipeInfo.lactose &&
    recipe.recipeInfo.classification &&
    typeof recipe.recipeInfo.recipeYield === 'number' &&
    recipe.recipeInfo.recipeYield >= 0 &&
    typeof recipe.recipeInfo.recipeWaste === 'number' &&
    recipe.recipeInfo.recipeWaste >= 0 &&
    recipe.recipeInfo.kitchens instanceof Array &&
    recipe.recipeInfo.kitchens.length > 0 &&
    recipe.recipeInfo.productionLocation instanceof Object &&
    recipe.recipeInfo.productionLocation.id
  )
}

export function validateIngredientsAndSubrecipesKitchen(recipe) {
  const { recipeInfo, ingredients, subRecipes } = recipe
  const recipeKitchens = recipeInfo.kitchens
  const invalidIngredientsKitchens = ingredients.some(
    ingredient =>
      !ingredient.kitchens.some(kitchen => recipeKitchens.includes(kitchen))
  )
  const invalidSubRecipesKitchens = subRecipes.some(
    subRecipe =>
      !subRecipe.kitchens.some(kitchen => recipeKitchens.includes(kitchen))
  )
  return !invalidIngredientsKitchens && !invalidSubRecipesKitchens
}

export function validateNutricionalTable(recipe) {
  const { nutritionalInfo } = recipe

  const isTotalSugarsFilled =
    nutritionalInfo.totalSugars?.value &&
    nutritionalInfo.totalSugars?.dailyValue

  const isAddedSugarsFilled =
    nutritionalInfo.addedSugars?.value &&
    nutritionalInfo.addedSugars?.dailyValue

  if (isTotalSugarsFilled && !isAddedSugarsFilled) {
    throw new Error(
      'AVISO: A quantidade de açúcares adicionados não pode estar vazio'
    )
  }

  if (!isTotalSugarsFilled && isAddedSugarsFilled) {
    throw new Error(
      'AVISO: A quantidade de açúcares totais não pode estar vazio'
    )
  }

  if (isTotalSugarsFilled && isAddedSugarsFilled) {
    if (
      nutritionalInfo.addedSugars?.value > nutritionalInfo.totalSugars?.value
    ) {
      throw new Error(
        'AVISO: A quantidade de açúcares adicionados não podem ser maiores que açúcares totais'
      )
    }
  }
}
