import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableHeader from 'components/TableHeader'
import TextField from 'components/TextField'
import DropDown from 'components/DropDown'
import {
  celsiusAdornment,
  percentAdornment,
  quantityGramsAdornment,
} from 'components/InputAdornments'
import InputValidator from 'components/Form/validators/InputValidator'
import { required, positiveNumber } from 'components/Form/validations'
import Select from 'components/Select'
import {
  batchSizeTitle,
  cookingTimesTitle,
  ingredientsTypeTitle,
  packageTypeTitle,
  portionQuantityTitle,
  portionSizeTitle,
  productionLocationTitle,
  recipeInfoTitle,
  recipeNameTitle,
  recipeWasteTitle,
  recipeYieldTitle,
  referenceGrammageTitle,
  sealingFreezingQuantity,
  sealingSettings,
  sealingTemperatureTitle,
  skuButtonTitle,
  typeTitle,
  versionNumberTitle,
} from '../../formattedMessages'
import defineMessages from '../../Messages'
import styles from './styles'
import CookingTimes from '../CookingTimes'
import SelectKitchens from '../../../../../v2/components/molecules/selectKitchens'

class RecipeInfo extends PureComponent {
  constructor(props) {
    super(props)

    const allSealingOptions = [
      ...this.sealingTypeOptions.bag,
      ...this.sealingTypeOptions.tray,
    ]

    this.state = {
      sealingOption: allSealingOptions.find(
        option => option.value === this.props.sealingType
      ),
    }
  }

  sealingTypeOptions = {
    bag: [
      { value: 'P1', label: 'P1' },
      { value: 'P2', label: 'P2' },
      { value: 'P3', label: 'P3' },
      { value: 'P4', label: 'P4' },
      { value: 'P5', label: 'P5' },
      { value: 'P6', label: 'P6' },
      { value: 'P7', label: 'P7' },
      { value: 'P8', label: 'P8' },
      { value: 'P9', label: 'P9' },
    ],
    tray: [
      { value: 'S1', label: 'S1' },
      { value: 'S2', label: 'S2' },
    ],
  }

  handleChange = event => {
    const { onChange, ...rest } = this.props
    const { id, value } = event.target
    if (onChange) {
      if (id === 'referenceGrammage') {
        return onChange(dotProp.set(rest, id, Number(value)))
      }
      return onChange(dotProp.set(rest, id, value))
    }
  }

  handleTypeChange = event => {
    if (this.props.editing) {
      return
    }

    const parsedEvent = dotProp.set(event, 'target.value', event.target.value)
    this.handleChange(dotProp.set(parsedEvent, 'target.id', event.target.name))

    this.props.onDeleteSKU()
    this.props.onGenerateSKU(event.target.value)
  }

  handleCheckboxChange = event => {
    const parsedEvent = dotProp.set(event, 'target.value', event.target.value)
    this.handleChange(dotProp.set(parsedEvent, 'target.id', event.target.name))
  }

  handleKitchenSelectionChange = (_items, keys) => {
    const { onChange, ...rest } = this.props

    return onChange(dotProp.set(rest, 'kitchens', keys))
  }

  handleCookingTimesChange = cookingTimes => {
    const { onChange, ...rest } = this.props

    return onChange(dotProp.set(rest, 'cookingTimes', cookingTimes))
  }

  handleSealingMachineChange = event => {
    const { onChange, ...rest } = this.props

    this.setState({ sealingOption: undefined })

    if (!onChange) return
    if (event.target.value === 'box') {
      onChange({
        ...rest,
        [event.target.name]: event.target.value,
        sealingType: null,
      })
    } else {
      onChange({
        ...rest,
        [event.target.name]: event.target.value,
        sealingType: null,
        sealingTemperature: '',
      })
    }
  }

  handleSealingTypeChange = () => option => {
    const { onChange, ...rest } = this.props
    this.setState({ sealingOption: option })

    if (!onChange) return
    const optionsForSelectedMachine =
      this.sealingTypeOptions[this.props.sealingMachine] || []
    const selected = optionsForSelectedMachine.find(
      machine => machine.value === option.value
    )
    onChange({ ...rest, sealingType: selected.value })
  }

  render() {
    const {
      availableColors = [],
      availableIngredientsTypes = [],
      availableTypes = [],
      batchSize,
      classes,
      color,
      cookingTimes,
      cookingMethods,
      editing,
      freezingPosition,
      gluten,
      ingredientsType,
      kitchens,
      lactose,
      loadingSku,
      name,
      portionQuantity,
      portionSize,
      productionLocation,
      productionLocations,
      productionLocationsLoading,
      recipeWaste,
      recipeYield,
      referenceGrammage,
      sealingMachine,
      sealingTemperature,
      sku,
      skusPerFreezingContainer,
      type,
      versionNumber,
      classification,
    } = this.props
    const { errors } = defineMessages
    return (
      <div style={{ marginBottom: '12px' }}>
        <Paper className={classes.root} elevation={1}>
          <TableHeader title={recipeInfoTitle} />
          <Grid
            container
            direction="row"
            spacing={8}
            className={classes.infoFieldsContainer}
            alignItems="flex-start"
          >
            <Grid item xs={editing ? 5 : 4}>
              <InputValidator
                id="RecipeName"
                validations={[required(errors.required)]}
              >
                <TextField
                  label={recipeNameTitle}
                  id="name"
                  fullWidth
                  value={name}
                  handleChange={this.handleChange}
                />
              </InputValidator>
            </Grid>
            <Grid item xs={3}>
              <Select
                id="color"
                label={packageTypeTitle}
                value={color}
                onChange={this.handleChange}
              >
                {availableColors.map(({ id, text }) => (
                  <MenuItem key={id} value={text}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {!editing && (
              <Grid item xs={3}>
                <InputValidator
                  id="type"
                  validations={[required(errors.required)]}
                >
                  <Select
                    id="type"
                    label={typeTitle}
                    value={editing ? 'disabled' : type}
                    onChange={this.handleTypeChange}
                    disabled={this.props.editing}
                  >
                    {availableTypes.map(({ id, text }) => (
                      <MenuItem key={id} value={id}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </InputValidator>
              </Grid>
            )}
            <Grid item xs={2}>
              {loadingSku ? (
                <CircularProgress className={classes.activityIndicator} />
              ) : (
                <TextField
                  label={skuButtonTitle}
                  id="sku"
                  value={sku}
                  handleChange={this.handleChange}
                  readOnly
                />
              )}
            </Grid>
            {editing && (
              <Grid item xs={2}>
                <TextField
                  label={versionNumberTitle}
                  id="versionNumber"
                  value={versionNumber}
                  readOnly={editing}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            spacing={8}
            className={classes.infoFieldsContainer}
            alignItems="flex-start"
          >
            <Grid item sm={4} xs={4}>
              <Select
                id="ingredientsType"
                label={ingredientsTypeTitle}
                value={ingredientsType}
                onChange={this.handleChange}
              >
                {availableIngredientsTypes.map(({ id, text }) => (
                  <MenuItem key={id} value={id}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={2} xs={2}>
              <InputValidator
                id="RecipeYield"
                validations={[
                  required(errors.required),
                  positiveNumber(errors.positiveNumber),
                ]}
              >
                <TextField
                  label={recipeYieldTitle}
                  fullWidth
                  id="recipeYield"
                  type="number"
                  value={recipeYield}
                  handleChange={this.handleChange}
                  inputProps={{
                    ...percentAdornment,
                    min: 0,
                    max: 100,
                    style: { textAlign: 'right' },
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid item sm={2} xs={2}>
              <InputValidator
                id="RecipeWaste"
                validations={[
                  required(errors.required),
                  positiveNumber(errors.positiveNumber),
                ]}
                inputValue={recipeWaste}
              >
                <TextField
                  label={recipeWasteTitle}
                  fullWidth
                  id="recipeWaste"
                  type="number"
                  value={recipeWaste}
                  handleChange={this.handleChange}
                  inputProps={{
                    ...percentAdornment,
                    min: 0,
                    max: 100,
                    style: { textAlign: 'right' },
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid item sm={4} xs={4}>
              <InputValidator
                id="ProductionLocation"
                validations={[required(errors.required)]}
              >
                <Select
                  id="productionLocation"
                  label={productionLocationTitle}
                  value={productionLocation}
                  onChange={this.handleChange}
                  disabled={productionLocationsLoading}
                >
                  {productionLocations &&
                    productionLocations.map(location => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                </Select>
              </InputValidator>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={8}
            className={classes.infoFieldsContainer}
          >
            <Grid item sm={3} xs={3}>
              <InputValidator
                id="portionSizeValidator"
                validations={[
                  required(errors.required),
                  positiveNumber(errors.positiveNumber),
                ]}
              >
                <TextField
                  label={portionSizeTitle}
                  hint={portionSizeTitle.props.description}
                  fullWidth
                  id="portionSize"
                  type="number"
                  value={portionSize === 0 ? '' : portionSize}
                  handleChange={this.handleChange}
                  isInputNumber
                  inputProps={{
                    ...quantityGramsAdornment,
                    min: 0,
                    style: { textAlign: 'right' },
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid item sm={3} xs={3}>
              <InputValidator
                id="QuantityNutritionalTable"
                validations={[
                  required(errors.required),
                  positiveNumber(errors.positiveNumber),
                ]}
              >
                <TextField
                  label={referenceGrammageTitle}
                  hint={referenceGrammageTitle.props.description}
                  fullWidth
                  id="referenceGrammage"
                  type="number"
                  value={referenceGrammage}
                  handleChange={this.handleChange}
                  inputProps={{
                    ...quantityGramsAdornment,
                    min: 0,
                    style: { textAlign: 'right' },
                  }}
                  isInputNumber
                />
              </InputValidator>
            </Grid>
            <Grid item sm={3} xs={3}>
              <InputValidator
                id="portionQuantityValidator"
                validations={[required(errors.required)]}
              >
                <TextField
                  label={portionQuantityTitle}
                  fullWidth
                  id="portionQuantity"
                  hint={portionQuantityTitle.props.description}
                  type="text"
                  placeholder="Ex.: 1/2, 1/3"
                  value={portionQuantity}
                  handleChange={this.handleChange}
                  isRequired
                />
              </InputValidator>
            </Grid>
            <Grid item sm={3} xs={3}>
              <TextField
                label={batchSizeTitle}
                fullWidth
                id="batchSize"
                value={batchSize}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={8}
            className={classes.infoFieldsContainer}
            alignItems="flex-start"
          >
            <Grid item sm={3} xs={3}>
              <FormLabel component="legend">Glúten</FormLabel>
              <FormGroup style={{ marginLeft: '8px' }} column="1">
                <RadioGroup
                  aria-label="Lactose"
                  name="gluten"
                  className={classes.group}
                  value={gluten}
                  onChange={this.handleCheckboxChange}
                >
                  <FormControlLabel
                    id="contains"
                    value="contains"
                    control={<Radio />}
                    label="Contém"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    id="notcontains"
                    value="notcontains"
                    control={<Radio />}
                    label="Não Contém"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    id="none"
                    value="none"
                    control={<Radio />}
                    label="NA"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormLabel component="legend">Lactose</FormLabel>
              <FormGroup style={{ marginLeft: '8px' }} column="1">
                <RadioGroup
                  aria-label="Lactose"
                  name="lactose"
                  className={classes.group}
                  value={lactose}
                  onChange={this.handleCheckboxChange}
                >
                  <FormControlLabel
                    value="contains"
                    control={<Radio />}
                    label="Contém"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    id="notcontains"
                    value="notcontains"
                    control={<Radio />}
                    label="Não Contém"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="NA"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormLabel component="legend">Classificação</FormLabel>
              <FormGroup style={{ marginLeft: '8px' }} column="1">
                <RadioGroup
                  aria-label="Classificação"
                  name="classification"
                  className={classes.group}
                  value={classification}
                  onChange={this.handleCheckboxChange}
                >
                  <FormControlLabel
                    value="product"
                    control={<Radio />}
                    label="Item individual"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    id="packedmeal"
                    value="packedmeal"
                    control={<Radio />}
                    label="Marmita"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormLabel component="legend">Cozinha (*)</FormLabel>
              <FormGroup column="1">
                <SelectKitchens
                  selected={kitchens}
                  onSelect={this.handleKitchenSelectionChange}
                  showInactives
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <TableHeader title={cookingTimesTitle} />
          <CookingTimes
            value={cookingTimes}
            onChange={this.handleCookingTimesChange}
            cookingMethods={cookingMethods}
          />
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <TableHeader title={sealingSettings} />
          <Grid container spacing={8} className={classes.infoFieldsContainer}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                Selecione qual maquinário da selagem
              </FormLabel>
              <RadioGroup
                row
                aria-label="Selecione qual maquinário da selagem"
                name="sealingMachine"
                value={sealingMachine}
                onChange={this.handleSealingMachineChange}
              >
                <FormControlLabel
                  id="bag"
                  value="bag"
                  control={<Radio />}
                  label="Seladora de saquinhos"
                  labelPlacement="end"
                />
                <FormControlLabel
                  id="box"
                  value="box"
                  control={<Radio />}
                  label="Seladora de potinhos"
                  labelPlacement="end"
                />
                <FormControlLabel
                  id="tray"
                  value="tray"
                  control={<Radio />}
                  label="Seladora de bandejas"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            spacing={8}
            className={classes.infoFieldsContainer}
          >
            <Grid item xs={4}>
              <DropDown
                name="sealingType"
                label="Informar a programação"
                selectedItem={this.state.sealingOption}
                handleChange={this.handleSealingTypeChange}
                items={this.sealingTypeOptions[sealingMachine] || []}
                isVisualizing={!sealingMachine || sealingMachine === 'box'}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={sealingTemperatureTitle}
                id="sealingTemperature"
                type="number"
                inputProps={celsiusAdornment}
                value={sealingTemperature}
                handleChange={this.handleChange}
                disabled={
                  !sealingMachine ||
                  sealingMachine === 'bag' ||
                  sealingMachine === 'tray'
                }
                isInputNumber
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            spacing={8}
            className={classes.infoFieldsContainer}
          >
            <Grid container item xs={4} alignItems="center">
              <div>
                <FormLabel component="legend">
                  SKU precisa ser congelado em pé?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="SKU precisa ser congelado em pé?"
                  name="freezingPosition"
                  value={freezingPosition}
                  onChange={this.handleCheckboxChange}
                >
                  <FormControlLabel
                    id="freezingPositionV"
                    value="vertical"
                    control={<Radio />}
                    label="Sim"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    id="freezingPositionH"
                    value="horizontal"
                    control={<Radio />}
                    label="Não"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </div>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={sealingFreezingQuantity}
                id="skusPerFreezingContainer"
                type="number"
                value={skusPerFreezingContainer}
                handleChange={this.handleChange}
                isInputNumber
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}
RecipeInfo.propTypes = {
  allergenics: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  availableColors: PropTypes.array.isRequired,
  availableIngredientsTypes: PropTypes.array.isRequired,
  availableKitchens: PropTypes.array,
  availableTypes: PropTypes.array.isRequired,
  batchSize: PropTypes.number,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  cookingMethods: PropTypes.array.isRequired,
  cookingTimes: PropTypes.array,
  editing: PropTypes.bool,
  gluten: PropTypes.string,
  classification: PropTypes.string,
  ingredientsType: PropTypes.string.isRequired,
  kitchens: PropTypes.array,
  lactose: PropTypes.string,
  loadingSku: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteSKU: PropTypes.func.isRequired,
  onGenerateSKU: PropTypes.func.isRequired,
  portionQuantity: PropTypes.string,
  portionSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  productionLocation: PropTypes.string.isRequired,
  productionLocations: PropTypes.array.isRequired,
  productionLocationsLoading: PropTypes.bool.isRequired,
  recipeWaste: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recipeYield: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  referenceGrammage: PropTypes.number,
  sku: PropTypes.string,
  type: PropTypes.string.isRequired,
  versionNumber: PropTypes.number,
}

RecipeInfo.defaultProps = {
  availableKitchens: [],
  cookingTimes: [],
  batchSize: 0,
  editing: false,
  gluten: 'none',
  classification: 'product',
  kitchens: [],
  lactose: 'none',
  portionQuantity: '',
  referenceGrammage: null,
  sku: null,
  versionNumber: null,
  recipeWaste: null,
  recipeYield: null,
}

export default withStyles(styles)(RecipeInfo)
