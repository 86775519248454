import React, { Component, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from 'components/TextField'
import DropDown from 'components/DropDown'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styles from './styles'

class GroupInfo extends Component {
  toggleActivate = event => {
    const { onChange } = this.props
    const { id, checked } = event.target
    onChange(id, checked)
  }

  handleChange = event => {
    const { onChange } = this.props
    const { id, value } = event.target
    onChange(id, value)
  }

  render() {
    const { groupInfo, groupId, classes, isActive } = this.props
    return (
      <Fragment>
        <Grid className={classes.title}>
          <Typography variant="h6">{`Grupo ${groupId}`}</Typography>
        </Grid>
        <Grid container direction="row" spacing={16} alignItems="center">
          <Grid item xs={3}>
            <TextField
              label="Nome"
              margin="normal"
              id="name"
              value={groupInfo.name}
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Descrição"
              margin="normal"
              id="description"
              value={groupInfo.description}
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <DropDown name="Tipo" label="Tipo" />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch
                  id="isActive"
                  checked={isActive}
                  onChange={this.toggleActivate}
                  color="secondary"
                />
              }
              label="Ativar Grupo"
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

GroupInfo.propTypes = {
  groupInfo: PropTypes.object,
  groupId: PropTypes.string,
  onChange: PropTypes.func,
}

GroupInfo.defaultProps = {
  groupId: '',
  groupInfo: {},
  onChange: () => {},
}

export default withStyles(styles)(GroupInfo)
