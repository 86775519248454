import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'
import TablePaginationActions from 'components/TablePaginationActions'
import PropTypes from 'prop-types'
import CardBox from 'components/CardBox'
import SearchResultRow from './SearchResultRow'
import { styles } from '../styles'

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
    }
  }

  handleChangePage = (_, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  renderTableHead = () => {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell className={classes.tableHeader}>Código</TableCell>
        <TableCell className={classes.tableHeader}>Data Esperada</TableCell>
        <TableCell className={classes.tableHeader}>Fornecedor</TableCell>
        <TableCell className={classes.tableHeader}>
          Local de Recebimento
        </TableCell>
      </TableRow>
    )
  }

  renderResultsList = () => {
    const { data } = this.props.searchReceptions
    const { page, rowsPerPage } = this.state

    return data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(reception => (
        <SearchResultRow
          {...reception}
          classes={this.props.classes}
          onClick={this.props.onOpenEditReceptionPage}
        />
      ))
  }

  render() {
    const { data } = this.props.searchReceptions || []
    const { page, rowsPerPage } = this.state
    const { classes } = this.props
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    return (
      <CardBox styleName="col-sm-10" className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>{this.renderTableHead()}</TableHead>
            <TableBody>
              {this.renderResultsList()}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </CardBox>
    )
  }
}

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  searchReceptions: PropTypes.object,
  onOpenEditReceptionPage: PropTypes.func.isRequired,
}

SearchResults.defaultProps = {
  searchReceptions: {},
}

export default withStyles(styles)(SearchResults)
