import {
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_RESPONSE,
  FETCH_RESOURCES_ERROR,
  FETCH_SELECT_RESOURCES_REQUEST,
  FETCH_SELECT_RESOURCES_RESPONSE,
  FETCH_SELECT_RESOURCES_ERROR,
} from 'constants/ActionTypes'

const initialState = {
  fetchResources: {
    data: [],
    loading: false,
    error: null,
  },
  selectResources: {
    data: [],
    isLoading: false,
    error: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESOURCES_REQUEST: {
      return {
        ...state,
        fetchResources: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_RESOURCES_RESPONSE: {
      return {
        ...state,
        fetchResources: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_RESOURCES_ERROR: {
      return {
        ...state,
        fetchResources: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case FETCH_SELECT_RESOURCES_REQUEST: {
      return {
        ...state,
        selectResources: {
          data: [],
          isLoading: true,
          error: null,
        },
      }
    }
    case FETCH_SELECT_RESOURCES_RESPONSE: {
      return {
        ...state,
        selectResources: {
          data: action.payload.map(resource => ({
            label: resource.name,
            value: resource._id,
          })),
          isLoading: false,
          error: null,
        },
      }
    }
    case FETCH_SELECT_RESOURCES_ERROR: {
      return {
        ...state,
        selectResources: {
          data: [],
          isLoading: false,
          error: action.payload.error,
        },
      }
    }
    default:
      return state
  }
}
