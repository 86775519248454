import React from 'react'
import { array, func, number } from 'prop-types'
import CheckBox from '../../atoms/checkbox/index'
import { StyledCheckBoxGroup } from './styles'

const CheckBoxGroup = ({ columns, elements, onSelect, disabled, ...rest }) => (
  <StyledCheckBoxGroup columns={columns}>
    {elements.map((el, index) => (
      <CheckBox
        selected={!disabled ? el.selected : false}
        onSelect={onSelect}
        text={el.text}
        index={index}
        disabled={disabled}
        {...rest}
      />
    ))}
  </StyledCheckBoxGroup>
)

CheckBoxGroup.defaultProps = {
  columns: 2,
}

CheckBoxGroup.propTypes = {
  elements: array.isRequired,
  onSelect: func.isRequired,
  columns: number,
}

export default CheckBoxGroup
