import { registerRoutes } from './register/routes'
import FeedstockSearchScreen from '.'

export const feedstockRoutes = {
  path: 'feedstock',
  children: [
    ...registerRoutes,
    {
      path: 'search',
      component: FeedstockSearchScreen,
    },
  ],
}
