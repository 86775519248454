import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import VersioningHistoricContainer from '../VersioningHistoric/containers/VersioningHistoricContainer'
import { recipeVersioningHistoricMessage } from './formattedMessages'

class VersioningHistoricPage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={recipeVersioningHistoricMessage}
        />
        <VersioningHistoricContainer />
      </div>
    )
  }
}

export default VersioningHistoricPage
