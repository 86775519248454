import React, { ChangeEvent, MouseEvent, useState } from 'react'

import {
  ClickAwayListener,
  Grid,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'

import { OptionsList, PopperList } from './styles'

type SearchProps = {
  disabled: boolean
  loading: boolean
  label?: string
  options: SearchOption[]
  textFieldValue: string
  onSelect: (_option: SearchOption) => void
  onSearch: (_value: string) => void
  onChange: (_value: string) => void
}

export type SearchOption = {
  value: string
  label: string
}

const Search: React.FC<SearchProps> = ({
  disabled,
  onChange,
  textFieldValue,
  label,
  loading,
  options,
  onSelect,
  onSearch,
}) => {
  const [selected, setSelected] = useState<SearchOption>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setSelected(null)
    onSearch(value)
    onChange(value)
  }

  function handleClick({ target }: MouseEvent<HTMLElement>) {
    if (disabled) {
      return
    }

    setAnchorEl(target)
    setIsOpen(true)
  }

  function handleSelect(option: SearchOption) {
    setSelected(option)
    setIsOpen(false)
    onSelect(option)
  }

  function handleClickAway() {
    setIsOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid>
        <TextField
          disabled={disabled}
          onClick={handleClick}
          onChange={handleChange}
          value={textFieldValue}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <PopperList anchorEl={anchorEl} open={isOpen}>
          <Paper
            style={{
              width: anchorEl ? anchorEl.clientWidth : null,
            }}
          >
            <OptionsList>
              {!loading &&
                !!options &&
                options.map(opt => (
                  <ListItem
                    key={opt.value}
                    button
                    selected={selected && selected.value === opt.value}
                    onMouseDown={() => {
                      handleSelect(opt)
                    }}
                  >
                    <ListItemText primary={opt.label} />
                  </ListItem>
                ))}
              {(loading || !options) && (
                <ListItem>
                  <ListItemText
                    primary={
                      loading ? 'Carregando...' : 'Nenhuma opção encontrada.'
                    }
                  />
                </ListItem>
              )}
              {!textFieldValue && (
                <ListItem>
                  <ListItemText primary={'Insira o texto para a busca.'} />
                </ListItem>
              )}
            </OptionsList>
          </Paper>
        </PopperList>
      </Grid>
    </ClickAwayListener>
  )
}

export default Search
