import React from 'react'

const Footer = () => (
  <footer className="app-footer">
    <div className="d-flex flex-row justify-content-between">
      <div>
        <span> Liv Up &copy; 2018</span>
      </div>
    </div>
  </footer>
)

export default Footer
