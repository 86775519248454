// tslint:disable-next-line:no-submodule-imports
import { IconButtonProps } from '@material-ui/core/IconButton'
// tslint:disable-next-line:no-submodule-imports
import { PaperProps } from '@material-ui/core/Paper'
import { ComponentType } from 'react'
import styled from 'styled-components'
import { Grid } from '../../atoms'
import { StyledIconButton } from '../../atoms/iconButton/styles'
import { Paper } from '../../atoms/paper'

export const ModalContainer: ComponentType<PaperProps> = styled(Paper)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  && {
    padding: 0px;
  }
`

export const ModalContent = styled(Grid).attrs({
  container: true,
  direction: 'column',
  wrap: 'nowrap',
})`
  padding: ${props => props.theme.v2.spacing.g};
  align-items: stretch;
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.v2.palette.grayScale['10']};
`

export const ModalActions = styled(Grid).attrs({
  container: true,
  gutter: 'small',
  direction: 'row-reverse',
})`
  padding: ${props => props.theme.v2.spacing.xs};
`

export const StyledModalIconButton: ComponentType<IconButtonProps> = styled(
  StyledIconButton
)`
  align-self: flex-end;
  && {
    color: ${props => props.theme.v2.palette.primary.darker};
  }
`
