import {
  SEARCH_RECEPTIONS_REQUEST,
  SEARCH_RECEPTIONS_RESPONSE,
  SEARCH_RECEPTIONS_ERROR,
  EDIT_RECEPTIONS_REQUEST,
  EDIT_RECEPTIONS_RESPONSE,
  EDIT_RECEPTIONS_ERROR,
  FETCH_KITCHENS_REQUEST,
  FETCH_KITCHENS_RESPONSE,
  FETCH_KITCHENS_ERROR,
  FETCH_ALL_RECEPTIONS_REQUEST,
  FETCH_ALL_RECEPTIONS_RESPONSE,
  FETCH_ALL_RECEPTIONS_ERROR,
} from 'constants/ActionTypes'

const initialState = {
  searchReceptions: {
    data: null,
    loading: false,
    error: null,
  },
  editReception: {
    data: {},
    loading: false,
    error: null,
  },
  kitchens: {
    data: [],
    loading: false,
    error: null,
  },
  allReceptions: {
    data: [],
    loading: false,
    error: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RECEPTIONS_REQUEST: {
      return {
        ...state,
        searchReceptions: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case SEARCH_RECEPTIONS_RESPONSE: {
      return {
        ...state,
        searchReceptions: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case SEARCH_RECEPTIONS_ERROR: {
      return {
        ...state,
        searchReceptions: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case FETCH_ALL_RECEPTIONS_REQUEST: {
      return {
        ...state,
        allReceptions: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_ALL_RECEPTIONS_RESPONSE: {
      return {
        ...state,
        allReceptions: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_ALL_RECEPTIONS_ERROR: {
      return {
        ...state,
        allReceptions: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case EDIT_RECEPTIONS_REQUEST: {
      return {
        ...state,
        editReception: {
          data: {},
          loading: true,
          error: null,
        },
      }
    }
    case EDIT_RECEPTIONS_RESPONSE: {
      return {
        ...state,
        editReception: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case EDIT_RECEPTIONS_ERROR: {
      return {
        ...state,
        editReception: {
          data: {},
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case FETCH_KITCHENS_REQUEST: {
      return {
        ...state,
        kitchens: {
          data: state.kitchens.data,
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_KITCHENS_RESPONSE: {
      return {
        ...state,
        kitchens: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_KITCHENS_ERROR: {
      return {
        ...state,
        kitchens: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
      }
    }
    default:
      return state
  }
}
