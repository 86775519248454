import React from 'react'
import { StyledCheckbox, StyledFormControlLabel } from './styles'

interface CheckBoxProps {
  selected: boolean
  onSelect: (value: any) => void
  text: string
  index: number
  disabled?: boolean
  fontSize?: string
  color?: string
}
const CheckBox = React.memo(
  ({
    selected,
    onSelect,
    text,
    index,
    disabled,
    fontSize,
    color,
  }: CheckBoxProps) => (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          checked={selected}
          onChange={onSelect}
          value={index}
          disabled={disabled}
        />
      }
      disabled={disabled}
      fontSize={fontSize}
      label={text}
      color={color}
    />
  )
)

export default CheckBox
