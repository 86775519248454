import React from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { signIn, signOut, resetState } from 'actions/Auth'
import { withSnackbar } from 'notistack'

import IntlMessages from 'utils/IntlMessages'
import logo from '../assets/images/logo-livup.png'

class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
    }
  }
  handleFormSubmit = e => {
    e.preventDefault()
    const { email, password } = this.state
    this.props.signIn(email, password, this.props.history)
  }

  handleLogin = () => {
    this.props.enqueueSnackbar('Bem vindo ao Kitchen!', {
      variant: 'success',
      horizontal: 'left',
      preventDuplicate: true,
    })
    return <Redirect to="/" />
  }

  render() {
    const { email, password } = this.state
    const { loading, success, error, enqueueSnackbar } = this.props
    if (success) return this.handleLogin()
    if (error) {
      enqueueSnackbar('Usuário Inválido, verifique seu e-mail e senha', {
        variant: 'error',
        horizontal: 'left',
        preventDuplicate: true,
      })
      this.props.resetState()
    }
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={logo} alt="LivUp logo" title="LivUp" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.signInTitle" />
              </h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.handleFormSubmit}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button type="submit" variant="raised" color="primary">
                      <IntlMessages id="appModule.signInButton" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  error: PropTypes.object,
  history: PropTypes.object.isRequired,
  success: PropTypes.bool,
  resetState: PropTypes.func.isRequired,
}

Login.defaultProps = {
  loading: false,
  success: false,
  error: {},
  enqueueSnackbar: () => {},
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  success: state.auth.success,
})

export default connect(mapStateToProps, { signIn, signOut, resetState })(
  withRouter(withSnackbar(Login))
)
