import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from 'components/TextField'
import Button from '@material-ui/core/Button'
import Form, { FormContext } from 'components/Form'
import InputValidator from 'components/Form/validators/InputValidator'
import { required } from 'components/Form/validations'
import { withStyles } from '@material-ui/core'
import definedMessages from '../../Messages'
import styles from './styles'

class ProcessSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      process: { type: '', description: '' },
    }
  }

  handleTypeChange = event => {
    const type = event.target.value
    this.setState(({ process: { step = {}, description } }) =>
      type === step.type
        ? { process: { type, step, description } }
        : { process: { type, description } }
    )
  }

  handleChange =
    id =>
    ({ target: { value } }) =>
      this.setState(({ process }) => ({
        process: { ...process, [id]: value },
      }))
  handleChoose = validateAll => () => {
    const { onConfirm } = this.props
    if (this.state.process.step && validateAll() && onConfirm) {
      onConfirm(this.state.process)
    }
  }

  render() {
    const { types, steps, classes } = this.props
    const {
      type: selectedType,
      step: selectedStep = { name: '', type: '' },
      description,
    } = this.state.process
    const { errors } = definedMessages
    const stepsByType = steps.filter(
      s => !selectedType || s.type === selectedType
    )
    return (
      <Form>
        <Paper className={classes.root}>
          <Grid container direction="column">
            <Grid item>
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  id="modal-title"
                  className={classes.title}
                >
                  <b>Processos</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                className={classes.typeContainer}
                direction="row"
                alignItems="center"
                spacing={24}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={24}
                  >
                    <Grid item xs={4}>
                      <InputValidator
                        id="type"
                        validations={[required(errors.required)]}
                      >
                        <Select
                          id="type"
                          label="Tipo"
                          value={selectedType}
                          onChange={this.handleTypeChange}
                        >
                          {types.map(type => (
                            <MenuItem value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </InputValidator>
                    </Grid>
                    <Grid item xs={4}>
                      <InputValidator
                        id="step"
                        validations={[required(errors.required)]}
                      >
                        <Select
                          id="step"
                          label="Etapa"
                          value={selectedStep}
                          onChange={this.handleChange('step')}
                        >
                          {stepsByType.map(step => (
                            <MenuItem value={step}>{step.name}</MenuItem>
                          ))}
                        </Select>
                      </InputValidator>
                    </Grid>
                    <Grid item xs={4}>
                      <InputValidator
                        id="description"
                        validations={[required(errors.required)]}
                      >
                        <TextField
                          id="description"
                          multiline
                          rowsMax={3}
                          label="Descrição"
                          value={description}
                          onChange={this.handleChange('description')}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <FormContext.Consumer>
                      {({ validateAll }) => (
                        <Button
                          onClick={this.handleChoose(validateAll)}
                          className={classes.button}
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.buttonText}
                          >
                            ADICIONAR
                          </Typography>
                        </Button>
                      )}
                    </FormContext.Consumer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    )
  }
}

ProcessSelect.propTypes = {
  types: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(ProcessSelect)
