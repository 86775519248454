export const styles = () => ({
  form: {
    width: '100%',
  },

  small: {
    maxWidth: '90px',
    fontSize: '0.8125rem',
  },
  MuiInput: {
    fontSize: '0.8125rem',
  },
})
