import React from 'react'

import { Ingredient, PreparationProcess } from '../../../api/dto/ingredients'
import { FactoryProcesses } from '../../../api/dto/FactoryProcesses'
import IngredientProcessForm from '../IngredientProcessForm'
import IngredientView from '../IngredientView'
import { Card } from './styles'

interface Props {
  ingredient: Ingredient
  processes: FactoryProcesses
  editing: boolean
  loading: boolean
  onEdit: (ingredient: Ingredient) => void
  onCancel: (ingredient: Ingredient) => void
  onSave: (ingredient: Ingredient) => void
}

function IngredientCard(props: Props) {
  const { ingredient, processes, editing, loading, onEdit, onCancel, onSave } =
    props

  function handleEdit() {
    onEdit(ingredient)
  }

  function handleCancel() {
    onCancel(ingredient)
  }

  function handleSave(preparationProcesses: PreparationProcess[]) {
    onSave({ ...ingredient, preparationProcesses })
  }

  return (
    <Card elevation={1}>
      {editing ? (
        <IngredientProcessForm
          ingredient={ingredient}
          processes={processes}
          loading={loading}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      ) : (
        <IngredientView
          title={ingredient.recipeName}
          disableEdit={loading}
          onEdit={handleEdit}
        />
      )}
    </Card>
  )
}

export default IngredientCard
