import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from 'components/TextField'
import { withStyles } from '@material-ui/core/styles'
import { costPerUnitMessage } from '../../formattedMessages'
import styles from './styles'

const CostPerUnit = ({ value, loading, classes }) => {
  const costPerUnitProps = {
    startAdornment: (
      <InputAdornment position="start" className={classes.priceInputAdornment}>
        <div className={classes.priceInputAdornmentFontSize}>R$</div>
      </InputAdornment>
    ),
    classes: {
      input: classes.priceInput,
    },
  }
  return (
    <Paper className={classes.costPerUnitContainer} elevation={1}>
      {loading ? (
        <CircularProgress className={classes.activityIndicator} />
      ) : (
        <TextField
          label={costPerUnitMessage}
          fullWidth
          id="costPerUnit"
          value={value}
          readOnly
          inputProps={costPerUnitProps}
        />
      )}
    </Paper>
  )
}

CostPerUnit.propTypes = {
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CostPerUnit)
