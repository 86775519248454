import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from './Messages'

export const searchResource = (
  <FormattedMessage {...defineMessages.searchResource} />
)

export const searchPlaceholder = (
  <FormattedMessage {...defineMessages.searchPlaceholder} />
)

export const tableHeader = [
  <FormattedMessage {...defineMessages.name} />,
  <FormattedMessage {...defineMessages.limit} />,
  <FormattedMessage {...defineMessages.type} />,
  <FormattedMessage {...defineMessages.status} />,
]
