import React, { Component } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import PeriodSelector from 'components/PeriodSelector'
import { reactDates } from 'utils/customPropTypes'

class DateSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { sectionSubtitle, onDatesChange, startDate, endDate } = this.props
    return (
      <Grid container direction="row" spacing={16} alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">{sectionSubtitle}</Typography>
        </Grid>
        <Grid item>
          <PeriodSelector
            onDatesChange={onDatesChange}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>
    )
  }
}

DateSelector.propTypes = {
  sectionSubtitle: PropTypes.object.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  startDate: reactDates,
  endDate: reactDates,
}

DateSelector.defaultProps = {
  startDate: null,
  endDate: null,
}

export default DateSelector
