import React from 'react'

import { NavLink } from 'react-router-dom'

export const SideNavItem = ({ icon = null, link, message, disabled = false }) =>
  !disabled && (
    <li className="menu no-arrow">
      <NavLink to={link}>
        {icon}
        <span className="nav-text">{message}</span>
      </NavLink>
    </li>
  )
