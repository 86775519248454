import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import blueGrey from '@material-ui/core/colors/blueGrey'

export default {
  palette: {
    primary: {
      lightest: '#eceff19c',
      light: blueGrey[200],
      medium: blueGrey[500],
      main: blueGrey[700],
      dark: blueGrey[900],
    },
    secondary: {
      light: red[300],
      main: red.A200,
      dark: red[700],
    },
    cancel: {
      light: grey[200],
      main: grey[600],
      dark: grey[900],
    },
    active: '#558B2F',
    li: {
      primary: blueGrey[50],
      secondary: '#ffffff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    useNextVariants: true,
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
    h1: { fontSize: '30px' },
    h2: { fontSize: '22px' },
    large: { fontSize: '18px' },
    medium: { fontSize: '16px' },
    small: { fontSize: '14px' },
    xsmall: { fontSize: '12px' },
    caption: { fontSize: '10px' },
    fontFamily: 'Lato',
  },
}
