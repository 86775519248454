import { defineMessages } from 'react-intl'

export default defineMessages({
  searchReceptions: {
    id: 'a.searchReceptions',
    defaultMessage: 'Buscar Recebimentos',
  },
  addLinkedReception: {
    id: 'a.searchReceptions.addLinkedReception',
    defaultMessage: 'Recebimentos Vinculados:',
  },
  selectReceptionCode: {
    id: 'a.searchReceptions.selectReceptionCode',
    defaultMessage: 'Selecione código do recebimento',
  },
})
