import React, { Dispatch, SetStateAction } from 'react'
import { Typography } from '../../../../components/atoms/typography/index'
import CheckBox from 'src/v2/components/atoms/checkbox'
import { Container, Content, Item, StyledInput } from './styles'
import { Lookup } from '../../../../components/molecules/lookup/'
import { LookupItem } from 'src/v2/contracts/lookupItem'
import { accountingClassifications } from '../../pages/FeedstockRegisterPage'

export interface TranshipmentProps {
  paperVariant?: 'purple' | 'default'
  onTransferableCheck: (index: number) => void
  transferable: boolean
  originatingSku: LookupItem
  onSelectOriginatingSku: Dispatch<SetStateAction<LookupItem>>
  products: LookupItem[]
  ncm: string
  onUnitsPerBoxChange: (event: any) => void
  unitsPerBox: string
  onNcmChange: (event: any) => void
  onSelectAccountingClassification: Dispatch<SetStateAction<LookupItem>>
  accountingClassification: LookupItem
  cest: string
  onCestChange: (event: any) => void
  cstCodes: LookupItem[]
  cst: LookupItem
  onSelectCst: Dispatch<SetStateAction<LookupItem>>
  transferUnit: LookupItem
  onSelectTransferUnit: Dispatch<SetStateAction<LookupItem>>
  transferUnits: LookupItem[]
  boxType: LookupItem
  onSelectBoxType: Dispatch<SetStateAction<LookupItem>>
  boxTypes: LookupItem[]
}

const Transhipment = ({
  paperVariant,
  onTransferableCheck,
  transferable,
  originatingSku,
  onSelectOriginatingSku,
  products,
  onNcmChange,
  ncm,
  onSelectAccountingClassification,
  accountingClassification,
  onUnitsPerBoxChange,
  unitsPerBox,
  cest,
  onCestChange,
  onSelectCst,
  cstCodes,
  cst,
  transferUnit,
  transferUnits,
  onSelectTransferUnit,
  boxType,
  boxTypes,
  onSelectBoxType,
}: TranshipmentProps) => {
  return (
    <Container title="Transbordos" variant={paperVariant}>
      <Content direction="row">
        <Item xs={6} position="start">
          <Typography fontSize="medium" color="black">
            Pode ser enviado em transbordos:
          </Typography>
          <CheckBox
            selected={transferable}
            onSelect={onTransferableCheck}
            text="Sim"
          />
        </Item>
      </Content>
      <Content direction="row">
        <Item xs={4} position="start">
          <StyledInput
            onChange={onNcmChange}
            label="Código NCM"
            required={false}
            disabled={!transferable}
            type=""
            value={ncm}
          />
        </Item>
        <Item xs={4} position="middle">
          <StyledInput
            onChange={onCestChange}
            label="Código CEST"
            required={false}
            disabled={!transferable || !cst || cst.value !== '60'}
            type=""
            value={cest}
          />
        </Item>
        <Item xs={4} position="end">
          <StyledInput
            onChange={onUnitsPerBoxChange}
            label="Peso (kg) ou unidades por volume de transporte"
            required={false}
            disabled={!transferable}
            type=""
            value={unitsPerBox}
          />
        </Item>
      </Content>
      <Content direction="row">
        <Item xs={4} position="start">
          <Typography fontSize="medium" color="black">
            Unidade comercial:
          </Typography>
          <Lookup
            disabled={!transferable}
            isClearable
            selected={transferUnit}
            placeholder="Escolha a unidade comercial"
            options={transferUnits}
            onSelect={onSelectTransferUnit}
          />
        </Item>
        <Item xs={4} position="end">
          <Typography fontSize="medium" color="black">
            Tipo de volume:
          </Typography>
          <Lookup
            disabled={!transferable}
            isClearable
            selected={boxType}
            placeholder="Escolha o tipo de volume"
            options={boxTypes}
            onSelect={onSelectBoxType}
          />
        </Item>
      </Content>
      <Content direction="column">
        <Item xs={6} position="start">
          <Typography fontSize="medium" color="black">
            Produto origem:
          </Typography>
          <Lookup
            disabled={!transferable}
            isClearable
            selected={originatingSku}
            placeholder="Escolha o produto origem dessa MP"
            options={products}
            onSelect={onSelectOriginatingSku}
          />
        </Item>
        <Item xs={6} position="start">
          <Typography fontSize="medium" color="black">
            Classificação contábil:
          </Typography>
          <Lookup
            disabled={!transferable}
            isClearable
            selected={accountingClassification}
            placeholder="Escolha a classificação contábil dessa MP"
            options={accountingClassifications}
            onSelect={onSelectAccountingClassification}
          />
        </Item>
        <Item xs={6} position="start">
          <Typography fontSize="medium" color="black">
            CST:
          </Typography>
          <Lookup
            disabled={!transferable}
            isClearable
            selected={cst}
            placeholder="Escolha o CST"
            options={cstCodes}
            onSelect={onSelectCst}
          />
        </Item>
      </Content>
    </Container>
  )
}

export default Transhipment
