import React from 'react'
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { Title, NoResultsFound } from './styles'

interface IngredientsShopListProps {
  ingredients: Array<Record<string, string>>
}

function IngredientsShopList(props: IngredientsShopListProps) {
  const { ingredients } = props

  if (!ingredients.length) {
    return (
      <Grid container justify="center" spacing={24}>
        <Grid item>
          <NoResultsFound>Nenhum resultado encontrado.</NoResultsFound>
        </Grid>
      </Grid>
    )
  }

  const ingredientsShopKeys = Object.keys(ingredients[0])

  return (
    <Card elevation={1} style={{ overflow: 'auto', height: 500 }}>
      <Title>Confirme os dados:</Title>

      <Table>
        <TableHead>
          <TableRow>
            {ingredientsShopKeys.map(label => (
              <TableCell key={`header${label}`}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients.map((ingredient, index) => (
            <TableRow key={`row${index}`} hover>
              {ingredientsShopKeys.map(key => (
                <TableCell key={`cell${ingredient}`}>
                  {ingredient[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

export default IngredientsShopList
