/* eslint-disable no-shadow */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { FIXED_DRAWER, COLLAPSED_DRAWER } from 'constants/ActionTypes'
import { toggleDrawerType } from 'actions/Setting'
import {
  createRecipe,
  clearGeneratedRecipeSKU,
  generateRecipeSKU,
  deleteRecipeSKU,
  handleChangeRecipeInfo,
  calculateNutritionalTable,
  calculateRecipeCost,
  resetMessagesState,
  fetchAvailableRecipeTypes,
  fetchCookingMethods,
  fetchRecipeAllergenics,
  fetchIngredientAllergenics,
  removeAllergenic,
  updateRecipeNutritionalInfo,
  updateRecipeManuallyCalculatedValue,
  fetchProductionLocations,
} from 'actions/Recipes'
import { fetchKitchens } from '../../../../actions/Reception'
import CreateRecipeForm from '../components/CreateRecipeForm'

class CreateRecipeContainer extends Component {
  componentWillMount() {
    this.props.fetchAvailableRecipeTypes()
    this.props.fetchCookingMethods()
    this.props.fetchKitchens()
    this.props.fetchProductionLocations()
    this.props.toggleDrawerType(COLLAPSED_DRAWER)
  }

  componentWillUnmount() {
    this.props.toggleDrawerType(FIXED_DRAWER)
    this.props.clearGeneratedRecipeSKU()
  }

  handleNotification() {
    const { error, success, enqueueSnackbar } = this.props
    if (error) {
      enqueueSnackbar(`Erro: ${error}`, {
        variant: 'error',
        horizontal: 'left',
        preventDuplicate: true,
      })
      this.props.resetMessagesState()
    }
    if (success) {
      enqueueSnackbar(`Receita criada com sucesso`, {
        variant: 'success',
        horizontal: 'left',
        preventDuplicate: true,
      })
      this.props.resetMessagesState()
    }
  }

  render() {
    const {
      allergenics,
      allergenicsLoading,
      availableRecipeTypes,
      calculateNutritionalTable,
      calculateRecipeCost,
      costDirty,
      createRecipe,
      cookingMethods,
      deleteRecipeSKU,
      dirty,
      error,
      fetchIngredientAllergenics,
      fetchRecipeAllergenics,
      generateRecipeSKU,
      handleChangeRecipeInfo,
      kitchens,
      loadingNutritionalTable,
      loadingSku,
      productionLocations,
      productionLocationsLoading,
      recipe,
      removeAllergenic,
      updateRecipeManuallyCalculatedValue,
      updateRecipeNutritionalInfo,
    } = this.props
    this.handleNotification()
    return (
      <CreateRecipeForm
        allergenics={allergenics}
        allergenicsLoading={allergenicsLoading}
        availableRecipeTypes={availableRecipeTypes}
        calculateNutritionalTable={calculateNutritionalTable}
        cookingMethods={cookingMethods}
        costDirty={costDirty}
        deleteRecipeSku={deleteRecipeSKU}
        dirty={dirty}
        error={error}
        fetchIngredientAllergenics={fetchIngredientAllergenics}
        fetchRecipeAllergenics={fetchRecipeAllergenics}
        generateSKU={generateRecipeSKU}
        handleChangeRecipeInfo={handleChangeRecipeInfo}
        handleSubmit={createRecipe}
        kitchens={kitchens.data}
        loadingNutritionalTable={loadingNutritionalTable}
        loadingSku={loadingSku}
        onManuallyCalculatedValueChange={updateRecipeManuallyCalculatedValue}
        onNutritionalFactsChange={updateRecipeNutritionalInfo}
        productionLocations={productionLocations}
        productionLocationsLoading={productionLocationsLoading}
        recipe={recipe}
        removeAllergenic={removeAllergenic}
        updateRecipeCost={calculateRecipeCost}
      />
    )
  }
}

CreateRecipeContainer.propTypes = {
  recipe: PropTypes.shape({
    recipeInfo: PropTypes.shape({
      sku: PropTypes.string,
      costPerUnit: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      type: PropTypes.string,
      ingredientsType: PropTypes.string,
      recipeYield: PropTypes.number,
      recipeWaste: PropTypes.number,
      portionSize: PropTypes.number,
      allergenics: PropTypes.shape({
        contains: PropTypes.array,
        mayContain: PropTypes.array,
      }),
    }),
    nutritionalInfo: PropTypes.shape({
      kcal: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      carbs: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      proteins: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      satFats: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      transFats: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      totalFats: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      fibers: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      sodium: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      totalSugars: {
        name: PropTypes.string,
        portion: PropTypes.number,
      },
      addedSugars: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
    }),
    ingredients: PropTypes.array,
    subRecipes: PropTypes.array,
    processes: PropTypes.array,
  }),
  allergenics: PropTypes.string,
  availableRecipeTypes: PropTypes.array,
  calculateNutritionalTable: PropTypes.func.isRequired,
  calculateRecipeCost: PropTypes.func.isRequired,
  clearGeneratedRecipeSKU: PropTypes.func.isRequired,
  createRecipe: PropTypes.func.isRequired,
  cookingMethods: PropTypes.array,
  deleteRecipeSKU: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  enqueueSnackbar: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchAvailableRecipeTypes: PropTypes.func.isRequired,
  fetchKitchens: PropTypes.func.isRequired,
  fetchProductionLocations: PropTypes.func.isRequired,
  generateRecipeSKU: PropTypes.func.isRequired,
  handleChangeRecipeInfo: PropTypes.func.isRequired,
  kitchens: PropTypes.object,
  loadingNutritionalTable: PropTypes.bool,
  loadingSku: PropTypes.bool,
  productionLocations: PropTypes.array,
  productionLocationsLoading: PropTypes.bool,
  resetMessagesState: PropTypes.func.isRequired,
  success: PropTypes.bool,
  toggleDrawerType: PropTypes.func.isRequired,
}

CreateRecipeContainer.defaultProps = {
  allergenics: '',
  availableRecipeTypes: [],
  cookingMethods: [],
  dirty: false,
  error: {},
  kitchens: {},
  loadingNutritionalTable: false,
  loadingSku: false,
  productionLocations: [],
  productionLocationsLoading: false,
  recipe: {},
  success: false,
}

const mapActionsToProps = {
  createRecipe,
  clearGeneratedRecipeSKU,
  generateRecipeSKU,
  toggleDrawerType,
  deleteRecipeSKU,
  resetMessagesState,
  fetchIngredientAllergenics,
  fetchCookingMethods,
  fetchRecipeAllergenics,
  fetchAvailableRecipeTypes,
  updateRecipeNutritionalInfo,
  updateRecipeManuallyCalculatedValue,
  removeAllergenic,
  fetchKitchens,
  fetchProductionLocations,
  handleChangeRecipeInfo: handleChangeRecipeInfo(false),
  calculateNutritionalTable: calculateNutritionalTable(false),
  calculateRecipeCost: calculateRecipeCost(false),
}

const mapStateToProps = state => ({
  allergenics:
    state.recipes.allergenics &&
    Array.from(
      new Set( // removes the duplicates
        Object.values(state.recipes.allergenics).reduce(
          (prev, curr) => [...prev, ...curr.allergenicsNames],
          []
        )
      )
    ),
  allergenicsLoading: state.recipes.allergenicsLoading,
  availableRecipeTypes: state.recipes.availableRecipeTypes,
  cookingMethods: state.recipes.cookingMethods,
  costDirty: state.recipes.recipe.costDirty,
  dirty: state.recipes.recipe.dirty,
  error: state.recipes.recipe.error,
  kitchens: state.receptions.kitchens,
  loadingNutritionalTable: state.recipes.recipe.loadingNutritionalTable,
  loadingSku: state.recipes.recipe.loadingSku,
  productionLocations: state.recipes.productionLocations,
  productionLocationsLoading: state.recipes.productionLocationsLoading,
  recipe: state.recipes.recipe.data,
  success: state.recipes.recipe.success,
})

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withSnackbar(CreateRecipeContainer))
