import { defineMessages } from 'react-intl'

export default defineMessages({
  resourceName: {
    id: 'resource.register.name',
    defaultMessage: 'Nome do Recurso',
  },
  resourceDescription: {
    id: 'resource.register.description',
    defaultMessage: 'Descrição',
  },
  resourceLimit: {
    id: 'resource.register.limit',
    defaultMessage: 'Limite',
  },
  resourceDifficulty: {
    id: 'resource.register.diffculty',
    defaultMessage: 'Dificuldade',
  },
  resourceLimitType: {
    id: 'resource.register.resourceLimitType',
    defaultMessage: 'Tipo de Limite',
  },
  resourceType: {
    id: 'resource.register.type',
    defaultMessage: 'Tipo',
  },
  resourceStatus: {
    id: 'resource.register.status',
    defaultMessage: 'Status',
  },
  save: {
    id: 'resource.register.save',
    defaultMessage: 'Salvar',
  },
  edit: {
    id: 'resource.register.edit',
    defaultMessage: 'Editar',
  },
  registerResource: {
    id: 'resource.register.register',
    defaultMessage: 'Cadastrar Recursos',
  },
  errors: {
    required: {
      id: 'forms.errors.required',
      defaultMessage: 'Obrigatório',
    },
    positiveNumber: {
      id: 'form.errors.positiveNumber',
      defaultMessage: 'Somente números positivo',
    },
  },
})
