import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import CardBox from 'components/CardBox'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import GroupInfo from '../../components/GroupInfo'
import GroupProperties from '../../components/GroupProperties'
import ActionButtons from '../../../../ProductionRecipe/Register/components/ActionButtons'
import styles from './styles'

class RegisterProductiongroupContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productionGroup: {
        name: '',
        description: '',
        type: '',
        includedSku: [],
        rules: [],
        isActive: false,
      },
    }
  }

  handleChange = (id, value) => {
    this.setState(state => ({
      ...state,
      productionGroup: {
        ...state.productionGroup,
        [id]: value,
      },
    }))
  }

  addSkuToGroup = () => {
    this.setState(state => ({
      ...state,
      productionGroup: {
        ...state.productionGroup,
        includedSku: [...state.productionGroup.includedSku, ''],
      },
    }))
  }

  addRuleToGroup = () => {
    this.setState(state => ({
      ...state,
      productionGroup: {
        ...state.productionGroup,
        rules: [...state.productionGroup.rules, ''],
      },
    }))
  }

  render() {
    const { productionGroup } = this.state
    const { classes } = this.props
    return (
      <CardBox styleName="col-sm-12">
        <div>
          <GroupInfo
            groupInfo={productionGroup}
            groupId="M01"
            onChange={this.handleChange}
            isActive={productionGroup.isActive}
          />
          <Grid container direction="row" spacing={32}>
            <Grid item sm={6}>
              <GroupProperties
                addProperty={this.addSkuToGroup}
                propertyName="includedSku"
                propertiesList={productionGroup.includedSku}
                onChange={this.handleChange}
                onRemove={this.handleChange}
              />
            </Grid>
            <Grid item sm={6}>
              <GroupProperties
                addProperty={this.addRuleToGroup}
                propertiesList={productionGroup.rules}
                propertyName="rules"
                onChange={this.handleChange}
                onRemove={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.actions}>
            <ActionButtons isRegistering />
          </Grid>
        </div>
      </CardBox>
    )
  }
}

const mapStateToProps = state => ({})

const mapActionsToProps = {}

RegisterProductiongroupContainer.propTypes = {}

RegisterProductiongroupContainer.defaultProps = {}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(RegisterProductiongroupContainer))
)
