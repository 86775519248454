import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import FormHelperText from '@material-ui/core/FormHelperText'
import PureTextField from '../PureTextField'
import { Tooltip } from '@material-ui/core'

const styles = _theme => ({
  helperTextAndTextFieldContainer: {
    height: '100%',
  },
  textField: {
    marginTop: '5px',
  },
  root: {
    borderRadius: '4px',
  },
  input: {
    padding: '20px 12px 17px',
  },
})

class TextField extends PureComponent {
  constructor(props) {
    super(props)
    this.inputProps = {
      ...props.inputProps,
      ...(props.readOnly && {
        readOnly: true,
        disableUnderline: true,
        classes: {
          input: classNames(
            props.classes.input,
            props.inputProps?.classes?.input
          ),
          root: props.classes.root,
        },
      }),
    }
  }

  handleInputChange = event => {
    const { handleChange, type } = this.props
    switch (type) {
      case 'number':
        handleChange({
          ...event,
          target: {
            target: event.target,
            id: event.target.id,
            value: Number(event.target.value),
          },
        })
        return
      default:
        handleChange(event)
    }
  }

  render() {
    const {
      label,
      id,
      fullWidth,
      multiline,
      disabled,
      value,
      readOnly,
      classes,
      error,
      hint,
      isInputNumber,
      ...otherProps
    } = this.props

    delete otherProps.handleChange

    return (
      <Grid
        container
        className={classes.helperTextAndTextFieldContainer}
        justify="flex-end"
        direction="column"
      >
        <FormHelperText error={error}>{label}</FormHelperText>

        <Tooltip title={hint}>
          <PureTextField
            id={id}
            type={isInputNumber ? 'number' : 'string'}
            margin="normal"
            fullWidth={fullWidth}
            value={value}
            multiline={multiline}
            disabled={disabled}
            onChange={this.handleInputChange}
            variant={readOnly ? 'filled' : 'outlined'}
            className={classes.textField}
            InputProps={this.inputProps}
            error={error}
            {...otherProps}
          />
        </Tooltip>
      </Grid>
    )
  }
}

TextField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  align: PropTypes.string,
  hint: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  isInputNumber: PropTypes.bool,
}

TextField.defaultProps = {
  name: undefined,
  type: undefined,
  align: undefined,
  hint: '',
  label: '',
  id: '',
  multiline: false,
  disabled: false,
  fullWidth: false,
  handleChange: undefined,
  readOnly: false,
  inputProps: {},
  error: false,
  value: '',
  isInputNumber: false,
}

export default withStyles(styles)(TextField)
