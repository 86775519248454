import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import {
  ALLOWED_COOKING_TIMES_QUANTITY,
  REQUIRED_COOKING_TIMES_QUANTITY,
} from 'src/constants/recipes'

import CookingTimesItem from '../CookingTimesItem'

function CookingTimes({ cookingMethods, value, onChange }) {
  const [firstValue, ...otherValues] = value || []
  const defaultValue = { type: null, range: { min: null, max: null } }

  const [cookingTimes, setCookingTimes] = useState([
    firstValue || defaultValue,
    ...otherValues,
  ])

  const [addItemDisabled, setAddItemDisabled] = useState(false)
  const [removeItemDisabled, setRemoveItemDisabled] = useState(false)

  const getAvailableCookingMethods = (
    currentCookingTime,
    selectedCookingMethodIds
  ) =>
    cookingMethods.filter(
      cookingMethod =>
        !selectedCookingMethodIds
          .filter(id => id !== currentCookingTime.type)
          .includes(cookingMethod.id)
    )

  const handleCookingTimeChange = (index, cookingTime) => {
    const newCookingTimes = [...cookingTimes];
    newCookingTimes[index] = cookingTime

    setCookingTimes(newCookingTimes)
    onChange(newCookingTimes)
  }

  const handleCookingTimeAdd = index => {
    if (cookingTimes.length > ALLOWED_COOKING_TIMES_QUANTITY) {
      return
    }

    const newCookingTimes = [...cookingTimes]
    newCookingTimes.splice(index + 1, 0, {
      ...defaultValue,
    })

    setCookingTimes(newCookingTimes)
    onChange(newCookingTimes)
  }

  const handleCookingTimeDelete = index => {
    if (cookingTimes.length <= Math.max(REQUIRED_COOKING_TIMES_QUANTITY, 0)) {
      return
    }
    const newCookingTimes = [...cookingTimes]
    newCookingTimes.splice(index, 1)

    setCookingTimes(newCookingTimes)
    onChange(newCookingTimes)
  }

  useEffect(() => {
    setAddItemDisabled(cookingTimes.length >= ALLOWED_COOKING_TIMES_QUANTITY)
    setRemoveItemDisabled(
      cookingTimes.length <= REQUIRED_COOKING_TIMES_QUANTITY
    )
  }, [
    cookingTimes.length,
    ALLOWED_COOKING_TIMES_QUANTITY,
    REQUIRED_COOKING_TIMES_QUANTITY,
  ])

  const renderCookingTimeItems = () => {
    const selectedCookingMethodIds = cookingTimes.map(ct => ct.type)

    return cookingTimes.map((cookingTime, index) => (
      <CookingTimesItem
        key={`cooking_time_${index + 1}`}
        value={cookingTime}
        index={index}
        onAddItem={handleCookingTimeAdd}
        onDeleteItem={handleCookingTimeDelete}
        onChange={handleCookingTimeChange}
        addItemDisabled={addItemDisabled}
        removeItemDisabled={removeItemDisabled}
        cookingMethods={getAvailableCookingMethods(
          cookingTime,
          selectedCookingMethodIds
        )}
      />
    ))
  }

  return <Grid>{renderCookingTimeItems()}</Grid>
}

CookingTimes.propTypes = {
  cookingMethods: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

CookingTimes.defaultProps = {
  cookingMethods: [],
  value: [],
}

export default CookingTimes
