import { Grid } from '@material-ui/core'
import React from 'react'

import { handleRequestError } from 'src/actions/ErrorHandling'
import { useAPI } from 'src/v2/api'
import { Endpoints } from 'src/v2/api/endpoints'
import { Button } from 'src/v2/components/atoms/button'
import { ProductionPlanningIds } from '../../pages/SaveProductionPlanningPage'
import { Container, Info, Title } from './styles'

interface Props {
  disabled: boolean
  productionPlanning: ProductionPlanningIds
}

export const PrintPlanningsContainer: React.FC<Props> = ({
  disabled,
  productionPlanning,
}) => {
  const [loadPrintPage] = useAPI()

  const handlePrint = () => {
    loadPrintPage({
      method: 'GET',
      uri:
        `${Endpoints.RECIPE_PRINT}` +
        `?firstPlanning=${productionPlanning.firstPlanningId}` +
        `&secondPlanning=${productionPlanning.secondPlanningId}`,
      contentType: 'text/html',
    })
      .then(data => {
        if (!data) {
          return
        }
        const newPage = window.open()
        newPage.document.body.insertAdjacentHTML('beforeend', data)
      })
      .catch(handleRequestError)
  }

  const info = disabled ? (
    <Info>
      Você ainda não subiu nenhum planejamento para criar e imprimir requisição
    </Info>
  ) : (
    <Grid container direction="column" alignItems="flex-start">
      <Grid justify="flex-end" container>
        <Button
          onClick={handlePrint}
          variant="contained"
          color="success"
          size="large"
        >
          Imprimir
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <Container elevation={1}>
      <Grid container direction="column">
        <Title>Imprimir Requisição</Title>
        {info}
      </Grid>
    </Container>
  )
}
