export default _theme => ({
  tablesContainer: {
    width: '100%',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  button: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  stickyGrid: {
    position: 'sticky',
    top: '0',
  },
})
