import styled from 'styled-components'

import { Paper } from 'src/v2/components/atoms/paper'

export const Card = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    margin-bottom: 8px;
  }
`
