export default theme => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 3 * theme.spacing.unit,
  },
  infoFieldsContainer: {
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
  },
  helperTextAndTextFieldContainer: {
    marginLeft: '16px',
    width: '10%',
  },
})
