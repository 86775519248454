import {
  SEARCH_FEEDSTOCK_ERROR,
  SEARCH_FEEDSTOCK_REQUEST,
  SEARCH_FEEDSTOCK_RESPONSE,
  FETCH_FEEDSTOCK_PRICES_RESPONSE,
  FETCH_FEEDSTOCK_PRICES_ERROR,
} from '../actionTypes'
import { handleRequestError } from '../../../actions/ErrorHandling'
import { errorNotification } from '../../../actions/Notifications'
import { fromDTOToFeedstocksArray } from '../adapters'

export const searchFeedstock =
  (nameOrCode, selectedKitchen = undefined) =>
  async (dispatch, _, api) => {
    dispatch({
      type: SEARCH_FEEDSTOCK_REQUEST,
    })
    try {
      const responseByName = await api.get(`/api/feedstocks/`, {
        params: {
          name: nameOrCode || '',
          kitchens: selectedKitchen,
        },
      })

      dispatch({
        type: SEARCH_FEEDSTOCK_RESPONSE,
        payload: fromDTOToFeedstocksArray(responseByName.data),
      })
    } catch (err) {
      console.log(`err on feedstock fetch`)
      console.log(err)
      const errorMessage = err.response
        ? err.response.data.message
        : err.message
      dispatch({
        type: SEARCH_FEEDSTOCK_ERROR,
        payload: {
          error: errorMessage,
        },
      })
      dispatch(handleRequestError(err))
      dispatch(errorNotification(err))
    }
  }

export const fetchFeedstockPrices = () => async (dispatch, _, api) => {
  try {
    const response = await api.get('api/feedstocks/prices')
    dispatch({
      type: FETCH_FEEDSTOCK_PRICES_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    console.log(err)
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_FEEDSTOCK_PRICES_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}
