import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import RecipeSearchContainer from './containers/RecipeSearchContainer'
import { searchRecipesTitle } from './formattedMessages'

class RecipeSearchPage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={searchRecipesTitle}
        />
        <RecipeSearchContainer />
      </div>
    )
  }
}

export default RecipeSearchPage
