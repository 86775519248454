import { defineMessages } from 'react-intl'

export default defineMessages({
  recipeSteps: {
    id: 'productionRecipe.recipeSteps',
    defaultMessage: 'Etapas da Receita',
  },
  addStep: {
    id: 'productionRecipe.addStep',
    defaultMessage: 'Adicionar Etapa +',
  },
  removeStep: {
    id: 'productionRecipe.removeStep',
    defaultMessage: 'Excluir',
  },
  sku: {
    id: 'productionRecipe.sku',
    defaultMessage: 'SKU',
  },
  minLots: {
    id: 'productionRecipe.minLots',
    defaultMessage: 'Número mínimo de lotes',
  },
  maxLots: {
    id: 'productionRecipe.maxLots',
    defaultMessage: 'Número máximo de lotes',
  },
  lotSize: {
    id: 'productionRecipe.lotSize',
    defaultMessage: 'Tamanho do Lote',
  },
  minInventory: {
    id: 'productionRecipe.minInventory',
    defaultMessage: 'Dias de estoque mínimo',
  },
  maxInventory: {
    id: 'productionRecipe.maxInventory',
    defaultMessage: 'Dias de estoque máximo',
  },
  productionResource: {
    id: 'productionRecipe.productionResource',
    defaultMessage: 'Recurso de produção',
  },
  setupTime: {
    id: 'productionRecipe.setupTime',
    defaultMessage: 'Tempo de setup',
  },
  complexity: {
    id: 'productionRecipe.complexity',
    defaultMessage: 'Dificuldade',
  },
  save: {
    id: 'productionRecipe.save',
    defaultMessage: 'Salvar',
  },
  edit: {
    id: 'productionRecipe.edit',
    defaultMessage: 'Editar',
  },
  searchPlaceholder: {
    id: 'productionRecipe.searchPlaceholder',
    defaultMessage: 'Digite o nome da Receita de Produção',
  },
  name: {
    id: 'productionRecipe.name',
    defaultMessage: 'Nome',
  },
  errors: {
    required: {
      id: 'forms.errors.required',
      defaultMessage: 'Obrigatório',
    },
    positiveNumber: {
      id: 'form.errors.positiveNumber',
      defaultMessage: 'Somente números positivo',
    },
  },
})
