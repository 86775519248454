import React from 'react'
import ContainerHeader from '../../components/ContainerHeader'
import SaveProductionPlanningPage from './pages/SaveProductionPlanningPage'

function SaveProductionPlanning(props) {
  return (
    <div className="app-wrapper">
      <ContainerHeader
        location={props.location}
        title="Salvar planejamento da produção"
      />
      <SaveProductionPlanningPage />
    </div>
  )
}

export default SaveProductionPlanning
