const getIngredientResultingWeight = ingredients =>
  ingredients
    .map(ingredient => (ingredient.quantity * ingredient.processingYield) / 100)
    .reduce((prev, next) => prev + next, 0)

const calculateSubRecipeResultingWeight = subRecipes =>
  subRecipes
    .map(subRecipe => Number(subRecipe.quantity))
    .reduce((prev, next) => prev + next, 0)

export const calculateRecipeResultingWeight = (
  recipeYield,
  recipeWaste,
  ingredients,
  subRecipes
) => {
  const recipeConstantWithWaste = (recipeYield / 100) * (1 - recipeWaste / 100)
  const recipeConstant = recipeYield / 100
  const recipeIngredientsResultingWeight =
    ingredients && ingredients.length > 0
      ? getIngredientResultingWeight(ingredients)
      : 0
  const recipeSubRecipesResultingWeight =
    subRecipes && subRecipes.length > 0
      ? calculateSubRecipeResultingWeight(subRecipes)
      : 0
  return {
    valueWithoutWaste:
      recipeConstant *
      (recipeIngredientsResultingWeight + recipeSubRecipesResultingWeight),
    value:
      recipeConstantWithWaste *
      (recipeIngredientsResultingWeight + recipeSubRecipesResultingWeight),
  }
}
