export const PRODUCT_TAG_PREVIEW_MUTATION = `
  mutation ProductTagPreview($input: TagInput!) {
    productTagPreview(input: $input) {
      preview
      zpl
    }
  }
`

export const PRODUCT_BATCH_MUTATION = `
  mutation ProductBatch($input: ProductBatchInput!) {
    productBatch(input: $input) {
      code
    }
  }
`
