import React from 'react'
import { Container, ContentContainer } from './styles'

export interface FilterContainerProps {
  title?: string
  children: React.ReactNode
}

export const FilterContainer: React.FC<FilterContainerProps> = props => {
  const { title, children } = props
  return (
    <Container title={title || 'Filtros'}>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}
