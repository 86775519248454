import { defineMessages } from 'react-intl'

export default defineMessages({
  ppcpStart: {
    id: 'ppcp.ppcpStart',
    defaultMessage: 'Input de dados',
  },
  sku: {
    id: 'ppcp.sku',
    defaultMessage: 'SKU',
  },
  name: {
    id: 'ppcp.name',
    defaultMessage: 'Nome',
  },
  inventory: {
    id: 'ppcp.inventory',
    defaultMessage: 'Estoque',
  },
  demand: {
    id: 'ppcp.demand',
    defaultMessage: 'Demanda',
  },
  production: {
    id: 'ppcp.production',
    defaultMessage: 'Produção',
  },
  productionSchedule: {
    id: 'ppcp.productionSchedule',
    defaultMessage: 'Janela de Produção',
  },
  start: {
    id: 'ppcp.start',
    defaultMessage: 'Iniciar',
  },
  ppcpResults: {
    id: 'ppcp.ppcpResults',
    defaultMessage: 'Resultados da PPCP',
  },
  ppcpHistoric: {
    id: 'ppcp.ppcpHistoric',
    defaultMessage: 'Histórico',
  },
  date: {
    id: 'ppcp.date',
    defaultMessage: 'Data',
  },
  planningBeginning: {
    id: 'ppcp.planningBeginning',
    defaultMessage: 'Início do Planejamento',
  },
  planningEnd: {
    id: 'ppcp.planningEnd',
    defaultMessage: 'Fim do planejamento',
  },
  planningCode: {
    id: 'ppcp.planningCode',
    defaultMessage: 'Código',
  },
  searchMessage: {
    id: 'ppcp.searchMessage',
    defaultMessage: 'Selecione o período para buscar:',
  },
})
