import HeaderfulContainer from 'src/v2/components/molecules/headerfulContainer'
import styled from 'styled-components'

export const Container = styled(HeaderfulContainer)`
  && {
  }
`

export const ContentContainer = styled.div`
  && {
    display: flex;
  }
`
