import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { searchFeedstock } from '../actions'
import { FeedstockSearchPage } from '../pages/feedstockSearchPage'
import { fetchKitchens } from '../../../actions/Reception'

const mapStateToProps = ({ feedstocks, auth, receptions }) => ({
  feedstockSearchResults: feedstocks.search,
  authUser: auth.authUser,
  kitchens: receptions.kitchens.data,
})

const mapActionsToProps = {
  searchFeedstock,
  fetchKitchens,
}

export const FeedstockSearchContainer = withRouter(
  connect(mapStateToProps, mapActionsToProps)(FeedstockSearchPage)
)
