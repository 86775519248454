import {
  FETCH_PRODUCTION_DATA_REQUEST,
  FETCH_PRODUCTION_DATA_RESPONSE,
  FETCH_PRODUCTION_DATA_ERROR,
  FETCH_PRODUCTION_PLANNING_REQUEST,
  FETCH_PRODUCTION_PLANNING_RESPONSE,
  FETCH_PRODUCTION_PLANNING_ERROR,
  FETCH_PLANNING_HISTORIC_REQUEST,
  FETCH_PLANNING_HISTORIC_RESPONSE,
  FETCH_PLANNING_HISTORIC_ERROR,
  RUN_PPCP_REQUEST,
  RUN_PPCP_RESPONSE,
  RUN_PPCP_ERROR,
  FETCH_PPCP_TASK_STATUS_RESPONSE,
  FETCH_PPCP_TASK_STATUS_ERROR,
} from 'constants/ActionTypes'

const initialState = {
  fetchProduction: {
    data: [],
    loading: false,
    error: null,
  },
  productionPlanning: {
    data: [],
    loading: false,
    error: null,
  },
  historic: {
    data: [],
    loading: false,
    error: null,
  },
  run: {
    taskId: null,
    taskStatus: {
      isFinished: false,
    },
    loading: false,
    running: false,
    error: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTION_DATA_REQUEST: {
      return {
        ...state,
        fetchProduction: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_DATA_RESPONSE: {
      return {
        ...state,
        fetchProduction: {
          data: action.payload,
          // data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_DATA_ERROR: {
      return {
        ...state,
        fetchProduction: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case FETCH_PRODUCTION_PLANNING_REQUEST: {
      return {
        ...state,
        productionPlanning: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_PLANNING_RESPONSE: {
      return {
        ...state,
        productionPlanning: {
          productionDates: action.payload.productionDates,
          data: action.payload.productionBySku,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_PLANNING_ERROR: {
      return {
        ...state,
        productionPlanning: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case FETCH_PLANNING_HISTORIC_REQUEST: {
      return {
        ...state,
        historic: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_PLANNING_HISTORIC_RESPONSE: {
      return {
        ...state,
        historic: {
          data: action.payload.response,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_PLANNING_HISTORIC_ERROR: {
      return {
        ...state,
        historic: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case RUN_PPCP_REQUEST:
      return {
        ...state,
        run: {
          ...state.run,
          taskId: null,
          running: false,
          loading: true,
          error: null,
        },
      }
    case RUN_PPCP_RESPONSE:
      return {
        ...state,
        run: {
          ...state.run,
          taskId: action.payload.taskId,
          running: true,
          loading: false,
          error: null,
        },
      }
    case RUN_PPCP_ERROR:
      return {
        ...state,
        run: {
          ...state.run,
          taskId: null,
          loading: false,
          error: action.payload.error,
        },
      }
    case FETCH_PPCP_TASK_STATUS_RESPONSE:
      return {
        ...state,
        run: {
          ...state.run,
          taskStatus: {
            isFailed: action.payload.taskStatus.isFailed,
            isFinished: action.payload.taskStatus.isFinished,
            ppcpCode: action.payload.taskStatus.ppcp_code,
          },
          running: !(
            action.payload.taskStatus.isFailed ||
            action.payload.taskStatus.isFinished
          ),
        },
      }
    case FETCH_PPCP_TASK_STATUS_ERROR:
      return {
        ...state,
        run: {
          ...state.run,
          taskStatus: {
            isFailed: true,
            isFinished: false,
            ppcpCode: null,
          },
          running: false,
        },
      }

    default:
      return state
  }
}
