import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from './Messages'

export const resourceName = (
  <FormattedMessage {...defineMessages.resourceName} />
)

export const resourceDescription = (
  <FormattedMessage {...defineMessages.resourceDescription} />
)

export const resourceLimit = (
  <FormattedMessage {...defineMessages.resourceLimit} />
)

export const resourceDifficulty = (
  <FormattedMessage {...defineMessages.resourceDifficulty} />
)

export const resourceType = (
  <FormattedMessage {...defineMessages.resourceType} />
)

export const resourceStatus = (
  <FormattedMessage {...defineMessages.resourceStatus} />
)

export const save = <FormattedMessage {...defineMessages.save} />

export const edit = <FormattedMessage {...defineMessages.edit} />

export const registerResource = (
  <FormattedMessage {...defineMessages.registerResource} />
)

export const limitType = (
  <FormattedMessage {...defineMessages.resourceLimitType} />
)
