import React from 'react'

// eslint-disable-next-line react/display-name
export const withConsumer = context => component => props =>
  (
    <context.Consumer>
      {contextValue => {
        const propsWithContext = { ...props, ...contextValue }
        return React.createElement(component, propsWithContext)
      }}
    </context.Consumer>
  )
