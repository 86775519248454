import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'

class Button extends React.Component {
  render() {
    const { isDefault, children, onClick, className, ...rest } = this.props
    return (
      <MuiButton
        className={className}
        onClick={onClick}
        variant="contained"
        color={isDefault ? 'secondary' : 'primary'}
        {...rest}
      >
        {children}
      </MuiButton>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isDefault: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  isDefault: false,
  onClick: () => {},
}

export default Button
