import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import CardBox from 'components/CardBox'
import 'react-dates/lib/css/_datepicker.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import {
  fetchProductionRecipe,
  runPPCP,
  fetchPPCPTaskStatus,
} from 'actions/PPCP'
import { productionProduct } from 'utils/customPropTypes'
import Button from 'components/Button'
import DateSelector from '../components/DateSelector'
import ProductionTable from '../components/ProductionTable'
import {
  productionTableHeader,
  productionSubtitle,
  productionSchedule,
  start,
} from '../../formattedMessages'

const styles = () => ({
  runProductionButton: {
    marginTop: '2rem',
  },
})

class PppcpInputContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
    }
  }

  componentDidMount() {
    this.props.fetchProductionRecipe()
    this.timerId = setInterval(() => this.pollPPCPTaskStatus(), 2000)
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  pollPPCPTaskStatus = () => {
    const { taskId, running } = this.props

    if (running && taskId) {
      this.props.fetchPPCPTaskStatus(taskId)
    }
  }

  handleDatesChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate })

  redirectToPage = () => {
    this.props.history.push({
      pathname: '/app/ppcp/results',
    })
  }

  handleRunPPCPClick = () => {
    const { startDate, endDate } = this.state
    this.props.runPPCP({ startDate, endDate })
  }

  renderProductionTable() {
    const { production } = this.props
    if (production.loading) {
      return <CircularProgress size={50} />
    }
    return (
      <ProductionTable
        production={production}
        header={productionTableHeader}
        sectionTitle={productionSubtitle}
      />
    )
  }

  renderDateSelector() {
    const { production } = this.props
    if (!production || production.loading) {
      return null
    }
    return (
      <DateSelector
        sectionSubtitle={productionSchedule}
        onDatesChange={this.handleDatesChange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
      />
    )
  }

  renderRunning() {
    const { running } = this.props
    if (!running) {
      return null
    }

    return (
      <React.Fragment>
        <LinearProgress size={50} />
        <div>Calculando a PPCP, isso pode demorar alguns minutos</div>
      </React.Fragment>
    )
  }

  renderSubmitButton() {
    const { classes, running } = this.props
    const { startDate, endDate } = this.state
    if (running) {
      return null
    }
    return (
      <Button
        className={classes.runProductionButton}
        onClick={this.handleRunPPCPClick}
        disabled={!startDate || !endDate}
      >
        {start}
      </Button>
    )
  }

  renderFinishRunning() {
    const { taskStatus } = this.props
    if (!taskStatus.isFinished) {
      return null
    }
    return (
      <Typography variant="body2" gutterBottom>
        PPCP finalizada.
      </Typography>
    )
  }

  render() {
    return (
      <div>
        <CardBox styleName="col-sm-12">
          <React.Fragment>
            {this.renderDateSelector()}
            {this.renderProductionTable()}
            {this.renderSubmitButton()}
            {this.renderRunning()}
            {this.renderFinishRunning()}
          </React.Fragment>
        </CardBox>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  production: state.production.fetchProduction.data,
  runLoading: state.production.run.loading,
  running: state.production.run.running,
  taskId: state.production.run.taskId,
  taskStatus: state.production.run.taskStatus,
})

const mapActionsToProps = {
  fetchProductionRecipe,
  runPPCP,
  fetchPPCPTaskStatus,
}

PppcpInputContainer.propTypes = {
  fetchProductionRecipe: PropTypes.func.isRequired,
  runPPCP: PropTypes.func.isRequired,
  production: productionProduct,
  fetchPPCPTaskStatus: PropTypes.func.isRequired,
  running: PropTypes.bool.isRequired,
  taskId: PropTypes.string.isRequired,
  taskStatus: PropTypes.shape({
    finished: PropTypes.bool,
  }).isRequired,
  classes: PropTypes.object.isRequired,
}

PppcpInputContainer.defaultProps = {
  production: [],
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(PppcpInputContainer))
)
