import React from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loading } from '../Loading'

export default class ListDialog extends React.Component {
  render() {
    const {
      title,
      open,
      subTitle,
      items,
      checkbox,
      onCheckboxChange,
      actions,
      onClose,
      children,
      loading,
      scrollType,
    } = this.props

    const handleCheckboxChange = item => event => {
      onCheckboxChange(item.secondaryText, event.target.checked)
    }

    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          onClose={onClose}
          maxWidth={'md'}
          scroll={scrollType || 'body'}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
            {items && (
              <List>
                {checkbox
                  ? items.map(item => (
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={item.checked}
                            onChange={handleCheckboxChange(item)}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={item.secondaryText}
                          primary={item.primaryText}
                          secondary={item.secondaryText}
                        />
                      </ListItem>
                    ))
                  : items.map(item => (
                      <ListItem alignItems="flex-start" disableGutters>
                        <ListItemText
                          primary={item.primaryText}
                          secondary={item.secondaryText}
                          onClick={item.onClick}
                        />
                      </ListItem>
                    ))}
              </List>
            )}
            {children}
            {loading && <Loading />}
          </DialogContent>
          <DialogActions>
            {!loading &&
              actions.map(action => (
                <Button
                  key={action.text}
                  onClick={action.handler}
                  color={action.color || 'submit'}
                  type={action.type || 'button'}
                >
                  {action.text}
                </Button>
              ))}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
