import React, { ChangeEvent } from 'react'

import { InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core'

import { StyledFormControl } from './styles'
import { LookupItem } from '../../../contracts/lookupItem'

export interface SelectProps {
  label: string
  options: LookupItem[]
  onSelect: (option: LookupItem) => void
  selected?: LookupItem
  disabled?: boolean
  fullWidth?: boolean
}

function CustomSelect(props: SelectProps) {
  const { selected, onSelect, options, fullWidth, label } = props

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    onSelect(options.find(opt => opt.value === event.target.value))
  }

  return (
    <StyledFormControl variant="outlined" fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={selected?.value ?? ''}
        onChange={handleChange}
        input={<OutlinedInput labelWidth={0} />}
      >
        {options.map(opt => (
          <MenuItem key={`${opt.value} ${opt.label}`} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default CustomSelect
