import React, { useState } from 'react'

import { useSnackbar } from 'notistack'

import Search, { SearchOption } from '../../../../components/Search'
import { Product } from '../../../tags/components/print-settings/interfaces'
import useDebounce from '../../../hooks/useDebounce'
import { useAPI } from '../../../api'
import { PRODUCT_SEARCH_QUERY } from './constants'

interface ProductSearchProps {
  disabled?: boolean
  onChange: (_value: string) => void
  onSelect: (_product: Product) => void
  value: string
}

export const ProductSearch: React.FC<ProductSearchProps> = ({
  disabled,
  onChange,
  onSelect,
  value,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [products, setProducts] = useState<Product[]>([])
  const [options, setOptions] = useState<SearchOption[]>([])
  const [fetch, loading] = useAPI()

  const handleSearch = useDebounce(([searchString]: [searchString: string]) => {
    fetch({
      baseUrl: process.env.KITCHEN_SERVICE_URL,
      uri: '/graphql',
      method: 'POST',
      body: {
        query: PRODUCT_SEARCH_QUERY,
        operationName: 'ProductsByNameORSku',
        variables: {
          search: searchString,
        },
      },
    })
      .then(response => {
        const products: Product[] = response?.data?.productsByNameORSku || []
        const options: SearchOption[] = products.map(product => ({
          value: product.sku,
          label: `${product.sku} - ${product.name}`,
        }))
        setProducts(products)
        setOptions(options)
      })
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        })
      })
  }, 500)

  function handleSelect(option: SearchOption) {
    const product = products.find(product => product.sku === option.value)
    onSelect(product)
  }

  return (
    <Search
      textFieldValue={value}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      label="Produto ou SKU"
    />
  )
}
