import styled from 'styled-components'

import { Typography } from 'src/v2/components/atoms'

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
`

export const LeftColumn = styled.div`
  width: 60%;
`

export const FiltersWrapper = styled.div`
  padding: 0 12px 12px 0;
`

export const PrintListWrapper = styled.div`
  position: --webkit-sticky;
  position: sticky;

  padding: 0 0 12px 12px;
  width: 40%;
  top: 24px;
`

export const Recipe = styled.div`
  && {
    flex-direction: column;
    align-items: stretch;
    display: flex;

    margin-bottom: 12px;
  }

  && > * {
    margin-bottom: 12px;
  }
`

export const RecipeInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const RecipeActions = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  display: flex;
`

export const RecipesWrapper = styled.div`
  padding: 12px 12px 12px 0;
`

export const RecipeName = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-weight: bold;
    cursor: pointer;
  }

  &&:hover {
    text-decoration: underline;
    color: #004d69;
  }
`

export const PrintListItem = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;

  margin-bottom: 12px;
`

export const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
`

export const Hint = styled(Typography)`
  && {
    margin-bottom: 24px;
  }
`

export const BagsInfo = styled.div`
  align-items: center;
  display: flex;
`

export const PrintListAction = styled.div`
  flex-direction: column;
  align-items: flex-end;
  display: flex;
`
