import {
  SIGNIN_REQUEST,
  SIGNIN_RESPONSE,
  SIGNIN_ERROR,
  SIGNOUT_REQUEST,
  SIGNOUT_RESPONSE,
  SIGNOUT_ERROR,
  SET_USER,
} from 'constants/ActionTypes'

const initialState = {
  authUser: null,
  loading: false,
  error: null,
  success: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case SIGNIN_RESPONSE: {
      return {
        ...state,
        loading: false,
        success: true,
      }
    }
    case SET_USER: {
      return {
        ...state,
        loading: false,
        authUser: action.payload.data,
      }
    }
    case SIGNIN_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case SIGNOUT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case SIGNOUT_RESPONSE: {
      return {
        ...initialState,
      }
    }
    case SIGNOUT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}
