import { Typography } from 'src/v2/components/atoms'
import { Paper } from 'src/v2/components/atoms/paper'
import EditIcon from '@material-ui/icons/Edit'
import Check from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import { Lookup } from 'src/v2/components/molecules/lookup'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
export const Container = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    margin-bottom: 8px;
  }
`

export const KindContainer = styled.div`
  && {
    width: 20%;
  }
`
export const KindSelect = styled(Lookup)`
  && {
    width: 100%;
    max-width: 250px;
  }
`

export const Name = styled(Typography)`
  && {
    flex: 1;
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-weight: bold;
  }
`
export const Info = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-weight: bold;
  }
`

export const ActionsContainer = styled.div`
  && {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    :hover {
      cursor: pointer;
    }
  }
`

export const ActionContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      cursor: pointer;
    }
  }
`
export const Edit = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props => props.theme.v2.palette.primary.darker};
    font-weight: bold;
  }
`
export const StyledEditIcon = styled(EditIcon)`
  && {
    color: ${props => props.theme.v2.palette.primary.darker};
    margin-left: 4px;
  }
`

export const Save = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props => props.theme.v2.palette.primary.darker};
    font-weight: bold;
    border-left: 1px solid #d5d5d5;
    margin-left: 16px;
    padding-left: 16px;
  }
`
export const SaveIcon = styled(Check)`
  && {
    color: ${props => props.theme.v2.palette.primary.darker};
    margin-left: 4px;
  }
`

export const Cancel = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: ${props => props.theme.v2.palette.secondary.regular};
    font-weight: bold;
  }
`
export const StyledCancelIcon = styled(CancelIcon)`
  && {
    color: ${props => props.theme.v2.palette.secondary.regular};
    margin-left: 4px;
  }
`

export const StyledHomeMeasuerementQuantityContainer = styled(Grid)`
  && {
    margin-top: 3px;
  }
`
