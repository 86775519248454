import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.recipes.header',
    defaultMessage: 'Receitas',
  },
  title: {
    id: 'app.recipes.title',
    defaultMessage: 'Gerenciamento de Receitas LivUp',
  },
  costPerUnit: {
    id: 'app.recipes.costPerUnit',
    defaultMessage: 'Custo por Saquinho',
  },
  nutritionFacts: {
    id: 'app.recipe.nutritionFacts.title',
    defaultMessage: 'Tabela Nutricional',
  },
  dailyValue: {
    id: 'app.recipe.nutritionFacts.dailyValue',
    defaultMessage: 'VD (%)',
  },
  recipes: {
    id: 'app.recipe.recipes.title',
    defaultMessage: 'Receitas',
  },
  emptySubRecipes: {
    id: 'app.recipe.recipes.empty',
    defaultMessage: 'Ainda não há sub-receitas. Clique para adicionar.',
  },
  subRecipeName: {
    id: 'app.recipe.recipes.name',
    defaultMessage: 'Nome',
  },
  subRecipeQuantity: {
    id: 'app.recipe.recipes.quantity',
    defaultMessage: 'Quantidade',
  },
  subRecipeCostPerUnit: {
    id: 'app.recipe.recipes.costPerUnit',
    defaultMessage: 'Custo por saquinho',
  },
  subRecipePercentPrice: {
    id: 'app.recipe.recipes.percentPrice',
    defaultMessage: 'Preço percentual',
  },
  ingredients: {
    id: 'app.recipe.ingredients.title',
    defaultMessage: 'Ingredientes',
  },
  emptyIngredients: {
    id: 'app.recipe.ingredients.empty',
    defaultMessage: 'Ainda não há ingredientes. Clique para adicionar.',
  },
  ingredientName: {
    id: 'app.recipeSimulation.ingredients.name',
    defaultMessage: 'Nome',
  },
  compositionName: {
    id: 'app.recipeSimulation.ingredients.composition',
    defaultMessage: 'Composição',
  },
  ingredientQuantity: {
    id: 'app.recipeSimulation.ingredients.quantity',
    defaultMessage: 'Quantidade',
  },
  ingredientPriceSimulation: {
    id: 'app.recipeSimulation.ingredients.price',
    defaultMessage: 'Preço no sistema',
  },
  ingredientPrice: {
    id: 'app.recipeCreation.ingredients.price',
    defaultMessage: 'Preço por Kg',
  },
  ingredientCurrentPrice: {
    id: 'app.recipeSimulation.ingredients.currentPrice',
    defaultMessage: 'Preço simulação',
  },
  ingredientPricePerUnit: {
    id: 'app.recipeSimulation.ingredients.pricePerUnit',
    defaultMessage: 'Custo por saquinho',
  },
  ingredientPercentPrice: {
    id: 'app.recipeSimulation.ingredients.percentPrice',
    defaultMessage: 'Preço percentual',
  },
})
