import styled from 'styled-components'

import { Typography } from '../../../components/atoms'
import { Button } from '../../../components/atoms/button'
import HeaderfulContainer from '../../../components/molecules/headerfulContainer'

export const Container = styled(HeaderfulContainer)`
  height: 100%;
`

export const TagProduct = styled.div`
  && {
    position: relative;
  }
`

export const Message = styled(Typography)`
  && {
    margin-bottom: 16px;
    font-size: ${props => props.theme.typography.large.fontSize};
  }
`

export const ProductsSelect = styled.div`
  && {
    margin: 16px 0;
    width: 100%;
  }
`

export const Print = styled.div`
  && {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`

export const PrintButton = styled(Button)`
  && {
    right: 0;
    bottom: 0;
    justify-content: flex-end;
  }
`

export const LoadingContainer = styled.div`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const ErrorMessage = styled.span`
  && {
    color: red;
  }
`
