import React, { Component } from 'react'
import ContainerHeader from '../../components/ContainerHeader'
import { feedstockSearchTitle } from './messages/formattedMessages'
import { FeedstockSearchContainer } from './containers/feedstockSearchContainer'

class FeedstockSearchScreen extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={feedstockSearchTitle}
        />
        <FeedstockSearchContainer />
      </div>
    )
  }
}
export default FeedstockSearchScreen
