import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { ppcpResults } from '../formattedMessages'
import PpcpResultsContainer from './containers/PpcpResultsContainer'

class PpcpResults extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader location={this.props.location} title={ppcpResults} />
        <PpcpResultsContainer />
      </div>
    )
  }
}

export default PpcpResults
