// tslint:disable-next-line:no-submodule-imports
import { PaperProps } from '@material-ui/core/Paper'
import { ComponentType } from 'react'
import styled from 'styled-components'
import { Paper } from '../../atoms/paper'
import { Typography } from '../../atoms/typography'

export const Container: ComponentType<PaperProps> = styled(Paper)`
  && {
    width: 100%;
    padding: 0;
  }
`

export const Header = styled(Typography)<{ variant: 'purple' | 'default' }>`
  && {
    color: ${props => props.theme.v2.palette.background};
    padding: 15px 22px 15px 22px;
    background-color: ${props =>
      props.variant === 'purple'
        ? '#47114B'
        : props.theme.v2.palette.primary.darker};
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
`

export const Body = styled.div`
  && {
    padding: 24px;
  }
`
