import {
  FETCH_PRODUCTION_RECIPES_REQUEST,
  FETCH_PRODUCTION_RECIPES_RESPONSE,
  FETCH_PRODUCTION_RECIPES_ERROR,
  FETCH_PRODUCTION_RECIPE_REQUEST,
  FETCH_PRODUCTION_RECIPE_RESPONSE,
  FETCH_PRODUCTION_RECIPE_ERROR,
  CREATE_PRODUCTION_RECIPE_REQUEST,
  CREATE_PRODUCTION_RECIPE_RESPONSE,
  CREATE_PRODUCTION_RECIPE_ERROR,
  EDIT_PRODUCTION_RECIPE_REQUEST,
  EDIT_PRODUCTION_RECIPE_RESPONSE,
  EDIT_PRODUCTION_RECIPE_ERROR,
  ADD_RECIPE_STEP,
  REMOVE_RECIPE_STEP,
  CHANGE_RECIPE_STEP,
  CHANGE_RECIPE_DATA,
  SELECT_RECIPE_STEP,
  SET_EMPTY_RECIPE,
} from 'constants/ActionTypes'

const initialState = {
  allRecipes: {
    data: [],
    loading: false,
    error: null,
  },
  editRecipe: {
    data: {
      sku: '',
      minLot: '',
      maxLot: '',
      lotSize: '',
      minInventory: '',
      maxInventory: '',
      stepsByResource: [
        {
          timeSetup: '',
          complexity: {},
        },
      ],
    },
    loading: false,
    error: null,
  },
  registerRecipe: {
    data: {
      sku: '',
      minLot: '',
      maxLot: '',
      lotSize: '',
      minInventory: '',
      maxInventory: '',
      stepsByResource: [
        {
          timeSetup: '',
          complexity: {},
        },
      ],
    },
    loading: false,
    error: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTION_RECIPES_REQUEST: {
      return {
        ...state,
        allRecipes: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_RECIPES_RESPONSE: {
      return {
        ...state,
        allRecipes: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_RECIPES_ERROR: {
      return {
        ...state,
        allRecipes: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case FETCH_PRODUCTION_RECIPE_REQUEST: {
      return {
        ...state,
        editRecipe: {
          data: {},
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_RECIPE_RESPONSE: {
      return {
        ...state,
        editRecipe: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_PRODUCTION_RECIPE_ERROR: {
      return {
        ...state,
        editRecipe: {
          data: {},
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case EDIT_PRODUCTION_RECIPE_REQUEST: {
      return {
        ...state,
        editRecipe: {
          data: {},
          loading: true,
          error: null,
        },
      }
    }
    case EDIT_PRODUCTION_RECIPE_RESPONSE: {
      return {
        ...state,
        editRecipe: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    }
    case EDIT_PRODUCTION_RECIPE_ERROR: {
      return {
        ...state,
        editRecipe: {
          data: {},
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case CREATE_PRODUCTION_RECIPE_REQUEST: {
      return {
        ...state,
        registerRecipe: {
          data: { ...state.registerRecipe.data },
          loading: true,
          error: null,
        },
      }
    }
    case CREATE_PRODUCTION_RECIPE_RESPONSE: {
      return {
        ...state,
        registerRecipe: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    }
    case CREATE_PRODUCTION_RECIPE_ERROR: {
      return {
        ...state,
        registerRecipe: {
          data: {},
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case ADD_RECIPE_STEP: {
      const current = action.payload ? state.editRecipe : state.registerRecipe
      const currentName = action.payload ? 'editRecipe' : 'registerRecipe'
      return {
        ...state,
        [currentName]: {
          ...current,
          data: {
            ...current.data,
            stepsByResource:
              current.data.stepsByResource &&
              current.data.stepsByResource.length > 0
                ? [
                    ...current.data.stepsByResource,
                    { resource: {}, timeSetup: '', complexity: {} },
                  ]
                : [{ resource: {}, timeSetup: '', complexity: {} }],
          },
        },
      }
    }
    case REMOVE_RECIPE_STEP: {
      const current = action.payload.isEditing
        ? state.editRecipe
        : state.registerRecipe
      const currentName = action.payload.isEditing
        ? 'editRecipe'
        : 'registerRecipe'
      return {
        ...state,
        [currentName]: {
          ...current,
          data: {
            ...current.data,
            stepsByResource: [
              ...current.data.stepsByResource.slice(0, action.payload.index),
              ...current.data.stepsByResource.slice(action.payload.index + 1),
            ],
          },
        },
      }
    }
    case CHANGE_RECIPE_DATA: {
      const current = action.payload.isEditing
        ? state.editRecipe
        : state.registerRecipe
      const currentName = action.payload.isEditing
        ? 'editRecipe'
        : 'registerRecipe'
      return {
        ...state,
        [currentName]: {
          ...current,
          data: {
            ...current.data,
            [action.payload.id]: action.payload.value,
          },
        },
      }
    }
    case CHANGE_RECIPE_STEP: {
      const current = action.payload.isEditing
        ? state.editRecipe
        : state.registerRecipe
      const currentName = action.payload.isEditing
        ? 'editRecipe'
        : 'registerRecipe'
      return {
        ...state,
        [currentName]: {
          ...current,
          data: {
            ...current.data,
            stepsByResource: current.data.stepsByResource.map((step, idx) => {
              if (idx !== action.payload.index) {
                return step
              }
              return {
                ...step,
                [action.payload.id]: action.payload.value,
              }
            }),
          },
        },
      }
    }
    case SELECT_RECIPE_STEP: {
      const current = action.payload.isEditing
        ? state.editRecipe
        : state.registerRecipe
      const currentName = action.payload.isEditing
        ? 'editRecipe'
        : 'registerRecipe'
      return {
        ...state,
        [currentName]: {
          ...current,
          data: {
            ...current.data,
            stepsByResource: current.data.stepsByResource.map((step, idx) => {
              if (idx !== action.payload.index) {
                return step
              }
              if (action.payload.name === 'name') {
                return {
                  ...step,
                  resource: {
                    ...step.resource,
                    [action.payload.name]: action.payload.value,
                  },
                }
              }
              return {
                ...step,
                [action.payload.name]: action.payload.value,
              }
            }),
          },
        },
      }
    }
    case SET_EMPTY_RECIPE: {
      return {
        ...state,
        registerRecipe: {
          data: {},
          isLoading: false,
          error: null,
        },
      }
    }
    default:
      return state
  }
}
