import React from 'react'
import { LookupItem } from '../../../../contracts/lookupItem'
import {
  SuppliersContainer,
  SuppliersContainerGrid,
  SuppliersContainerInstruction,
  SuppliersContainerSupplierLookup,
  SuppliersContainerSupplierType,
} from './styles'

export interface SuppliersProps {
  paperVariant?: 'purple' | 'default'
  suppliersOptions: LookupItem[]
  onSelectSupplier: (option: LookupItem) => void
  mainSupplier: LookupItem
  otherSuppliers: LookupItem[]
}

const Suppliers = ({
  paperVariant,
  suppliersOptions,
  onSelectSupplier,
  mainSupplier,
  otherSuppliers,
}: SuppliersProps) => {
  return (
    <SuppliersContainer title="Fornecedores" variant={paperVariant}>
      <SuppliersContainerInstruction fontSize="medium">
        Indique os principais fornecedores para esta materia prima
      </SuppliersContainerInstruction>
      <SuppliersContainerGrid container direction="row">
        <SuppliersContainerGrid item xs={4}>
          <SuppliersContainerSupplierType fontSize="medium">
            Selecione o fornecedor primário
          </SuppliersContainerSupplierType>
        </SuppliersContainerGrid>
        <SuppliersContainerGrid item xs>
          <SuppliersContainerSupplierLookup
            selected={mainSupplier}
            options={suppliersOptions}
            onSelect={onSelectSupplier}
            placeholder="Selecione os fornecedores"
          />
        </SuppliersContainerGrid>
      </SuppliersContainerGrid>
      {mainSupplier && (
        <SuppliersContainerGrid container direction="row">
          <SuppliersContainerGrid item xs={4}>
            <SuppliersContainerSupplierType fontSize="medium">
              Selecione outros fornecedores
            </SuppliersContainerSupplierType>
          </SuppliersContainerGrid>
          <SuppliersContainerGrid item xs>
            <SuppliersContainerSupplierLookup
              selected={otherSuppliers}
              options={suppliersOptions}
              onSelect={onSelectSupplier}
              placeholder="Selecione os fornecedores"
              isMulti
              isClearable
            />
          </SuppliersContainerGrid>
        </SuppliersContainerGrid>
      )}
    </SuppliersContainer>
  )
}

export default Suppliers
