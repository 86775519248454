import SaveProductionPlanning from './'

export const productionPlanningRoutes = {
  path: 'production-planning',
  restricted: false,
  children: [
    {
      path: 'import',
      component: SaveProductionPlanning,
    },
  ],
}
