import React from 'react'
import CheckBoxGroup from '../../../../components/molecules/checkboxGroup/index'
import { CheckboxItem } from '../../../../contracts/checkboxItem'
import { AllergenicsContainer, AllergenicsRadio, StyledDivider } from './styles'

export interface AllergenicsProps {
  paperVariant?: 'purple' | 'default'
  allergenics: CheckboxItem[]
  onSelect: (event: any) => void
  onCheck: (event: any) => void
  hasAllergenics: boolean
}

const labels = [
  'Contém alergênicos (selecione quais abaixo)',
  'Não contém alergênicos',
]
const Allergenics = ({
  paperVariant,
  allergenics,
  onCheck,
  onSelect,
  hasAllergenics,
}: AllergenicsProps) => {
  return (
    <AllergenicsContainer title="Alergênicos" variant={paperVariant}>
      <AllergenicsRadio
        direction="row"
        labels={labels}
        onSelect={onSelect}
        selected={hasAllergenics ? labels[0] : labels[1]}
        fontSize="16"
      />
      <StyledDivider />
      <CheckBoxGroup
        disabled={!hasAllergenics}
        elements={allergenics}
        onSelect={onCheck}
        columns={2}
      />
    </AllergenicsContainer>
  )
}

export default Allergenics
