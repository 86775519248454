import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const SearchResultRow = ({
  code,
  receiveDayString,
  supplier,
  kitchenOrigin,
  onClick,
  classes,
}) => (
  <TableRow
    key={code}
    onClick={() => onClick(code)}
    className={classes.tableRow}
    hover
  >
    <TableCell className={`${classes.tableCell} ${classes.tableCell}`}>
      {code}
    </TableCell>
    <TableCell className={classes.tableCell}>{receiveDayString}</TableCell>
    <TableCell className={classes.tableCell}>
      {supplier ? supplier.name : ''}
    </TableCell>
    <TableCell className={classes.tableCell}>
      {kitchenOrigin ? kitchenOrigin.name : ''}
    </TableCell>
  </TableRow>
)

export default SearchResultRow

SearchResultRow.propTypes = {
  code: PropTypes.number,
  receiveDayString: PropTypes.string,
  kitchenOrigin: PropTypes.string,
  supplier: PropTypes.object,
  onClick: PropTypes.func,
  classes: PropTypes.object,
}

SearchResultRow.defaultProps = {
  code: 0,
  receiveDayString: null,
  kitchenOrigin: null,
  supplier: undefined,
  onClick: null,
  classes: {},
}
