import styled from 'styled-components'

export const Divider = styled.div`
  && {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #d5d5d5;
  }
`

export const ErrorMessage = styled.p`
  margin: 0;
`
