import React, { useEffect } from 'react'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { Container, ContentContainer, NameFilterInput } from './styles'

export interface FilterContainerProps {
  debounce?: number
  onChange: (value: string) => void
}
const subject = new Subject()

export const FilterContainer: React.FC<FilterContainerProps> = props => {
  const handleChange = (event: any) => {
    subject.next(event.target.value)
  }

  useEffect(() => {
    const subs = subject
      .pipe(debounceTime(props.debounce || 0))
      .subscribe(props.onChange)

    return () => {
      subs.unsubscribe()
    }
  })

  return (
    <Container title="Filtros">
      <ContentContainer>
        <NameFilterInput
          onChange={handleChange}
          label="Nome do Ingrediente"
          required={false}
          disabled={false}
          type="text"
        />
      </ContentContainer>
    </Container>
  )
}
