import React, { useEffect } from 'react'

import moment from 'moment'
import { Grid, TextField } from '@material-ui/core'

import { ProductSearch } from '../../../components/molecules/productSearch'
import {
  Container,
  ErrorMessage,
  Message,
  Print,
  PrintButton,
  ProductsSelect,
  TagProduct,
} from './styles'
import { PrintSettingsFormProps } from './interfaces'
import { usePrintSettings } from './hook'
import { isToday } from './logic'

export const PrintSettingsForm: React.FC<PrintSettingsFormProps> = ({
  initialValue,
  onChange,
  onPrintButtonClick,
  printButtonEnabled,
}) => {
  const {
    handleDateChange,
    handleQuantityChange,
    handleSelectProduct,
    isPrintSettingsValid,
    printSettings,
    searchFieldValue,
    setSearchFieldValue,
  } = usePrintSettings({ initialValue })

  function handlePrintButtonClick() {
    if (isPrintSettingsValid()) {
      onPrintButtonClick(printSettings)
    }
  }

  useEffect(() => {
    onChange(printSettings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    printSettings.sku,
    printSettings.manufacturing,
    printSettings.quantity,
    printSettings.ready,
  ])

  return (
    <Container title="Informações do produto">
      <TagProduct>
        <Grid container direction="column">
          <Grid item>
            <Message>
              Insira as informações para imprimir as etiquetas do produto
            </Message>
          </Grid>
          <Grid item>
            <ProductsSelect>
              <ProductSearch
                value={searchFieldValue}
                onChange={setSearchFieldValue}
                onSelect={handleSelectProduct}
              ></ProductSearch>
            </ProductsSelect>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              type="date"
              name="manufacturingDate"
              label="Qual a data de fabricação do produto?"
              value={moment(printSettings.manufacturing).format('YYYY-MM-DD')}
              onChange={handleDateChange}
              fullWidth
            ></TextField>
            {!isToday(printSettings.manufacturing) ? (
              <ErrorMessage>A data escolhida não é a de hoje</ErrorMessage>
            ) : null}
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              type="string"
              name="ingredients"
              label="Ingredientes"
              value={printSettings.ingredients}
              fullWidth
              disabled
              multiline
              rows={2}
              rowsMax={2}
              inputProps={{ style: { fontSize: 10, lineHeight: 'initial' } }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              type="string"
              name="allergenics"
              label="Alergênicos"
              value={printSettings.allergenics}
              fullWidth
              disabled
              multiline
              rows={2}
              rowsMax={2}
              inputProps={{ style: { fontSize: 10, lineHeight: 'initial' } }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              type="string"
              name="maycontain"
              label="Pode conter"
              value={printSettings.mayContain}
              fullWidth
              disabled
              multiline
              rows={2}
              rowsMax={2}
              inputProps={{ style: { fontSize: 10, lineHeight: 'initial' } }}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="space-between"
            style={{ marginTop: '20px' }}
          >
            <Grid item sm={6}>
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                name="quantity"
                label="Quantidade de Etiquetas"
                value={printSettings.quantity}
                onChange={handleQuantityChange}
                fullWidth
                inputMode="numeric"
              />
            </Grid>
            <Grid item sm={6}>
              <Print>
                <PrintButton
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={handlePrintButtonClick}
                  disabled={!printButtonEnabled}
                >
                  Imprimir Etiqueta
                </PrintButton>
              </Print>
            </Grid>
          </Grid>
        </Grid>
      </TagProduct>
    </Container>
  )
}
