import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

export const stick = Component => {
  class Stickier extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        sticky: false,
        limitReached: false,
        parentHeight: '',
        style: {
          position: '',
          top: '',
          left: '',
          width: '',
        },
      }
    }

    componentDidMount() {
      const { scrollTarget } = this.props
      const body = document.getElementById(scrollTarget)
      ;(body || window).addEventListener(
        'scroll',
        () => {
          this.onScroll(this)
        },
        false
      )
      window.addEventListener(
        'resize',
        () => {
          this.onResize(this)
        },
        false
      )
    }
    componentWillUnmount() {
      const { scrollTarget } = this.props
      const body = document.getElementById(scrollTarget)
      ;(body || window).removeEventListener(
        'scroll',
        () => {
          this.onScroll(this)
        },
        false
      )
      window.removeEventListener(
        'resize',
        () => {
          this.onResize(this)
        },
        false
      )
    }

    onResize(reactElement) {
      const parent = ReactDOM.findDOMNode(reactElement)
      const { left, width } = parent.getBoundingClientRect()
      reactElement.setState(prevState => ({
        ...prevState,
        style: {
          ...prevState.style,
          left: `${left}px`,
          width: `${width}px`,
        },
      }))
    }

    onScroll(reactElement) {
      const { verticalOffset } = reactElement.props
      const container = ReactDOM.findDOMNode(reactElement)
      const element = container.lastChild
      const {
        top: elementTop,
        bottom: elementBottom,
        height: elementHeight,
      } = element.getBoundingClientRect()
      const {
        top: containerTop,
        width: containerWidth,
        height: containerHeight,
        bottom: containerBottom,
      } = container.getBoundingClientRect()
      if (
        (elementTop < verticalOffset &&
          containerHeight > elementHeight &&
          !reactElement.state.sticky) ||
        (reactElement.state.limitReached && elementTop >= verticalOffset)
      ) {
        reactElement.setState({
          sticky: true,
          limitReached: false,
          containerHeight,
          style: {
            top: `${verticalOffset}px`,
            position: 'fixed',
            width: `${containerWidth}px`,
            pointerEvents: 'none',
          },
        })
      } else if (reactElement.state.sticky && containerTop >= verticalOffset) {
        reactElement.setState({
          sticky: false,
          limitReached: false,
          style: {},
        })
      } else if (
        !reactElement.state.limitReached &&
        reactElement.state.sticky &&
        containerBottom <= elementBottom
      ) {
        reactElement.setState({
          sticky: true,
          limitReached: true,
          style: {},
        })
      }
    }

    render() {
      const { sticky, style, containerHeight, limitReached } = this.state
      const { verticalOffset, scrollTarget, ...propsRest } = this.props
      return (
        <Grid
          container
          style={{ height: '100%' }}
          alignItems={limitReached && 'flex-end'}
        >
          {sticky && !limitReached && (
            <div
              style={{
                width: style.width,
                height: containerHeight,
              }}
            />
          )}
          <Grid item xs style={{ height: 'fit-content', ...style }}>
            <Component {...propsRest} />
          </Grid>
        </Grid>
      )
    }
  }
  Stickier.propTypes = {
    verticalOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    scrollTarget: PropTypes.string,
  }
  Stickier.defaultProps = {
    verticalOffset: 0,
    scrollTarget: undefined,
  }
  return Stickier
}
