export default () => ({
  searchBar: {
    maxHeight: '3.125rem',
  },
  cardBox: {
    padding: '0.125rem',
  },
  resize: {
    fontSize: '12pt',
  },
})
