export const nutritionFactsNames = {
  kcal: 'Calorias (kcal)',
  carbs: 'Carboidratos (g)',
  proteins: 'Proteínas (g)',
  totalFats: 'Gorduras totais (g)',
  satFats: 'Gorduras saturadas (g)',
  transFats: 'Gorduras trans (g)',
  fibers: 'Fibras (g)',
  sodium: 'Sódio (mg)',
}

export const calculateRecipeNutritionFacts = (
  ingredients,
  subRecipes,
  resultingWeight,
  recipeQuantity,
  nutritionFacts = {},
  calculateRecipeResultingWeight
) => {
  let nutritionalTable = nutritionFacts
  if (ingredients && ingredients.length > 0) {
    nutritionalTable = getNutritionalValuesFromIngredients(
      ingredients,
      recipeQuantity,
      resultingWeight,
      nutritionFacts
    )
  }
  if (subRecipes && subRecipes.length > 0) {
    return subRecipes.reduce((acc, subRecipe) => {
      const {
        ingredients: subRecipeIngredients,
        subRecipes: subRecipeSubRecipes,
        recipeYield,
        recipeWaste,
      } = subRecipe
      const subRecipeResultingWeight = calculateRecipeResultingWeight(
        recipeYield,
        recipeWaste,
        subRecipeIngredients,
        subRecipeSubRecipes
      )
      return calculateRecipeNutritionFacts(
        subRecipeIngredients,
        subRecipeSubRecipes,
        subRecipeResultingWeight.valueWithoutWaste,
        subRecipe.quantity * (recipeQuantity / resultingWeight),
        acc,
        calculateRecipeResultingWeight
      )
    }, nutritionalTable)
  }
  return nutritionalTable
}

const getNutritionalValuesFromIngredients = (
  ingredients,
  recipeQuantity,
  resultingWeight,
  nutritionFacts = {}
) => {
  const factor = recipeQuantity / resultingWeight
  return ingredients.reduce(
    (acc, ingredient) =>
      Object.keys(ingredient.nutritionFacts).reduce(
        (acc1, field) => ({
          ...acc1,
          [field]: {
            portion:
              ((acc[field] && acc[field].portion) || 0) +
              (ingredient.quantity *
                factor *
                ingredient.nutritionFacts[field]) /
                100,
          },
        }),
        acc
      ),
    nutritionFacts
  )
}
