const base64toUtf8 = base64 => Buffer.from(base64, 'base64').toString('utf8')
const parseJson = string => JSON.parse(string)

export const getJwtPayload = jwtToken => {
  try {
    return parseJson(base64toUtf8(jwtToken.split('.')[1]))
  } catch (err) {
    return null
  }
}
