import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import { Link } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes'
import { toggleCollapsedNav } from 'actions/Setting'
import logo from '../../assets/images/logo-livup.png'

class Header extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  render() {
    const { drawerType, locale } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none'

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton
            className="jr-menu-icon"
            aria-label="Menu"
            onClick={this.onToggleCollapsedNav}
          >
            <span className="menu-icon" />
          </IconButton>

          <Link className="app-logo" to="/">
            <img src={logo} alt="LivUp logo" title="LivUp" />
          </Link>
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, locale } = settings
  return { drawerType, locale }
}

Header.propTypes = {
  navCollapsed: PropTypes.bool,
  // Store
  drawerType: PropTypes.string,
  locale: PropTypes.object,
  // Actions
  toggleCollapsedNav: PropTypes.func.isRequired,
}

Header.defaultProps = {
  navCollapsed: false,
  // Store
  drawerType: '',
  locale: {},
}

export default connect(mapStateToProps, { toggleCollapsedNav })(Header)
