export const Endpoints = {
  INGREDIENTS: '/api/ingredients',
  GET_INGREDIENT: (code: string) => `/api/ingredients/item/${code}`,
  KITCHENS: '/api/receptions/fetchKitchens',
  PRODUCTION_PLANNING: '/api/pre-production-requisition',
  SEARCH_INGREDIENTS: '/api/ingredients/search',
  SEARCH_FEEDSTOCKS: '/api/feedstocks',
  FETCH_PROCESSES: '/api/factory-processes',
  RECIPE_PRINT: '/api/pre-production-requisition/print-ingredients',
  SAVE_PROCESSES: (code: string) =>
    `/api/ingredients/${code}/preparation-processes`,
  EXPORT_INGREDIENTS_SHOP_LIST: '/api/shop/export-ingredients-shop-list',
}
