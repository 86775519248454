import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from '../Messages/index'

export const subRecipeQuantityMessage = (
  <FormattedMessage {...defineMessages.subRecipeQuantity} />
)

export const subRecipePercentPriceMessage = (
  <FormattedMessage {...defineMessages.subRecipePercentPrice} />
)

export const subRecipeCostPerUnitMessage = (
  <FormattedMessage {...defineMessages.subRecipeCostPerUnit} />
)

export const recipeSimulationMessage = (
  <FormattedMessage {...defineMessages.recipeSimulation} />
)

export const newRecipeMessage = (
  <FormattedMessage {...defineMessages.newRecipe} />
)

export const chooseRecipeMessage = (
  <FormattedMessage {...defineMessages.chooseRecipe} />
)

export const recipeInfoMessage = (
  <FormattedMessage {...defineMessages.recipeInfo} />
)

export const recipeNameMessage = (
  <FormattedMessage {...defineMessages.recipeName} />
)

export const recipeYieldMessage = (
  <FormattedMessage {...defineMessages.recipeYield} />
)

export const recipeWasteMessage = (
  <FormattedMessage {...defineMessages.recipeWaste} />
)

export const portionSizeMessage = (
  <FormattedMessage {...defineMessages.portionSize} />
)

export const batchSizeMessage = (
  <FormattedMessage {...defineMessages.batchSize} />
)

export const referenceGrammageMessage = (
  <FormattedMessage {...defineMessages.referenceGrammage} />
)

export const perHundredMessage = (
  <FormattedMessage {...defineMessages.perHundred} />
)

export const subRecipeYieldMessage = (
  <FormattedMessage {...defineMessages.subRecipeYield} />
)

export const selectModalIngredientsTitleMessage = (
  <FormattedMessage {...defineMessages.selectModalIngredientsTitle} />
)

export const selectModalIngredientsDropDownMessage = (
  <FormattedMessage {...defineMessages.selectModalIngredientsDropDown} />
)

export const selectModalRecipesTitleMessage = (
  <FormattedMessage {...defineMessages.selectModalRecipesDropDown} />
)

export const selectModalRecipesDropDownMessage = (
  <FormattedMessage {...defineMessages.selectModalRecipesDropDown} />
)

export const selectModalConfirmButtonMessage = (
  <FormattedMessage {...defineMessages.selectModalConfirmButton} />
)
export const selectModalAddButtonMessage = (
  <FormattedMessage {...defineMessages.selectModalAddButton} />
)
