import CreateIngredientPage from "./pages/CreateIngredientPage";
import SearchIngredientsPage from "./pages/SearchIngredientsPage";

export const ingredients = {
  path: 'ingredients',
  restricted: false,
  children: [
    {
      path: 'search',
      component: SearchIngredientsPage,
    },
    {
      path: 'create',
      component: CreateIngredientPage,
    },
    {
      path: 'edit',
      component: CreateIngredientPage,
      params: [
        {
          name: 'code',
          optional: false,
        },
      ],
    },
  ],
}
