import { NOTIFICATION_SEND, NOTIFICATION_RESET } from '../constants/ActionTypes'

const initialState = {
  message: null,
  type: null,
  autoDismiss: null,
  soundOff: null,
}

const NotificationCenter = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SEND:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
        autoDismiss: action.payload.autoDismiss,
        soundOff: action.payload.soundOff,
      }
    case NOTIFICATION_RESET:
      return {
        ...state,
        message: null,
        type: null,
        autoDismiss: null,
        soundOff: null,
      }
    default:
      return state
  }
}

export default NotificationCenter
