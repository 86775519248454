export default theme => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
  },
  title: {
    marginTop: theme.spacing.unit,
  },
  typeContainer: {
    padding: 3 * theme.spacing.unit,
    minWidth: '800px',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    width: '100%',
  },
  buttonText: { color: theme.palette.common.white },
  inputContainer: {
    position: 'relative',
  },
})
