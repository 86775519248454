import React, { useEffect, useState } from 'react'

import { fetchRecipes } from 'src/actions/Recipes'
import { LookupItem } from 'src/v2/contracts/lookupItem'
import { RECIPE_KINDS } from '../../constants'
import { Recipe } from '../../pages/config-page'
import { ClosedCardComponent } from './ClosedCardComponent'
import { EditCardComponent, RecipeTagFormFields } from './EditCardComponet'
import { Container } from './styles'
import { useSnackbar } from 'notistack'
import { validateBarcode } from './logic'

export interface RecipeCardProps {
  recipe: Recipe
  fetchTagConfiguration: (code: string) => any
  onSave: (input: {
    code: string
    kind: string
    expiration: number
    barcode: string
    claims: string
    expirationCut?: number
    denomination?: string
    showAddressOnTag?: boolean
    tagLayout?: string
    homeMeasurementUnit?: string
    homeMeasurementQuantity?: string
  }) => Promise<void>
}

export function RecipeCard({
  recipe,
  fetchTagConfiguration,
  onSave,
}: RecipeCardProps) {
  const [code] = recipe.code?.split('_') ?? []
  const [editing, setEditing] = useState(false)
  const [kind, setKind] = useState<LookupItem>(null)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [initialFormValues, setInitialFormValues] =
    useState<RecipeTagFormFields>()

  useEffect(() => {
    setKind(RECIPE_KINDS.find(k => k.value === recipe.kind))
  }, [])

  function handleEdit() {
    setLoading(true)

    fetchTagConfiguration(code)
      .then(({ data: tagConfiguration }: { data: any }) => {
        setInitialFormValues({
          kind: recipe.kind,
          denomination: recipe.productInfo?.denomination,
          tagLayout: tagConfiguration.layout,
          expiration:
            recipe.productInfo?.expirationInDays ??
            recipe.productInfo?.expiration,
          expirationCut: recipe.productInfo?.expirationCut,
          claims: recipe.claims,
          barcode: recipe.productInfo.barcode?.toString(),
          homeMeasurementUnit: recipe.homeMeasurementUnit,
          homeMeasurementQuantity: recipe.homeMeasurementQuantity,
          showAddressOnTag: tagConfiguration.withAddress,
        })
      })
      .finally(() => {
        setLoading(false)
        setEditing(true)
      })
  }

  function validateForm(form: RecipeTagFormFields): boolean {
    if (!form) {
      return false
    }
    if (form.barcode && !validateBarcode(form.barcode, 13)) {
      enqueueSnackbar('O código de barras digitado não parece um EAN13 válido.')
      return false
    }
    return true
  }

  function handleSave(form: RecipeTagFormFields) {
    if (!validateForm(form)) {
      return
    }

    setLoading(true)
    onSave({
      code,
      ...form,
    }).finally(() => {
      setLoading(false)
      fetchRecipes()
    })
  }

  return (
    <Container elevation={1}>
      {editing ? (
        <EditCardComponent
          code={recipe.code}
          name={recipe.name}
          initialState={initialFormValues}
          isLoading={loading}
          handleSave={handleSave}
          handleCancel={() => setEditing(false)}
        />
      ) : (
        <ClosedCardComponent
          name={recipe.name}
          code={recipe.code}
          kind={kind?.label}
          isLoading={loading}
          handleEdit={handleEdit}
        />
      )}
    </Container>
  )
}
