import { Typography } from '../../../components/atoms'
import { Paper } from '../../../components/atoms/paper'
import styled from 'styled-components'

export const Container = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    margin-bottom: 8px;
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.v2.palette.grayScale['10']};
`

export const HiddenButton = styled.input`
  && {
    display: none;
  }
`

export const Title = styled(Typography)`
  padding: 8px 0;
  && {
    font-family: Roboto;
    font-size: ${props => props.theme.v2.typography.fontSize.large};
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.v2.palette.grayScale['80']};
  }
`

export const Subtitle = styled(Typography)`
  && {
    font-family: Roboto;
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.v2.palette.grayScale['80']};
  }
`

export const Info = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.v2.palette.grayScale['40']};
    margin-bottom: 20px;
  }
`
