import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import CurrencyFormat from 'react-currency-format'
import { styles } from './styles'

class InputField extends Component {
  handleChange = event => {
    const newValue = this.props.isPositiveOnly
      ? event.target.value.replace(/^-/, '')
      : event.target.value
    return (
      this.props.onChange && this.props.onChange(this.props.fieldName, newValue)
    )
  }

  renderLabel = () =>
    this.props.label && (
      <InputLabel htmlFor={`${this.props.fieldName}-input`}>
        {this.props.label}
      </InputLabel>
    )

  renderCurrencyInput() {
    return (
      <CurrencyFormat
        value={this.props.value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        renderText={value => (
          <Input
            onBlur={this.props.onBlur}
            onChange={this.handleChange}
            disabled={this.props.disabled}
            type={this.props.type}
            id={`${this.props.fieldName}-input`}
            className={this.props.classes[this.props.className]}
            margin="normal"
            value={value}
          />
        )}
      />
    )
  }

  renderRegularInput() {
    return (
      <Input
        onBlur={this.props.onBlur}
        value={this.props.value}
        id={`${this.props.fieldName}-input`}
        onChange={this.handleChange}
        disabled={this.props.disabled}
        type={this.props.type}
        className={this.props.classes[this.props.className]}
      />
    )
  }

  render() {
    return (
      <FormControl fullWidth>
        {this.renderLabel()}
        {this.props.isCurrency
          ? this.renderCurrencyInput()
          : this.renderRegularInput()}
      </FormControl>
    )
  }
}

InputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  isCurrency: PropTypes.bool,
  isPositiveOnly: PropTypes.bool,
}

InputField.defaultProps = {
  value: undefined,
  disabled: false,
  type: 'text',
  className: '',
  isCurrency: false,
  isPositiveOnly: false,
}
export default withStyles(styles)(InputField)
