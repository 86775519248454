import React, { PureComponent } from 'react'

export const purify = WrappedComponent => {
  class Purifier extends PureComponent {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  return Purifier
}
