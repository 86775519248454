import React from 'react'
import { anyToString } from '../../../feedstock/register/logic'
import {
  NutritionaInfoDataNames,
  NutritionalInfo,
} from '../../../contracts/nutritionalInfo'
import { Typography } from '../../atoms/typography/index'
import {
  CellContainer,
  NutrientNamesCellContainer,
  NutritionalInfoContainer,
  NutritionalInfoTableHeader,
  StyledInput,
  TableRow,
} from './styles'

export interface NutritionalInfoProps {
  paperVariant?: 'purple' | 'default'
  nutritionalInfo: NutritionalInfo
  onChange: (field: NutritionaInfoDataNames) => (value: string) => void
}

const NutritionalInfoForm = React.memo(
  ({ nutritionalInfo, ...props }: NutritionalInfoProps) => {
    const handleChange = field => event => {
      const {
        target: { value },
      } = event
      const newValue = value.replace(/\./g, '')
      if (/^\d*$/.test(newValue)) {
        props.onChange(field)(`${newValue.slice(0, -2)}.${newValue.slice(-2)}`)
      }
    }
    return (
      <NutritionalInfoContainer
        {...props}
        title="Tabela nutricional"
        variant={props.paperVariant}
      >
        <TableRow>
          <NutrientNamesCellContainer />
          <NutritionalInfoTableHeader>
            <Typography fontSize="small" fontWeight="bold">
              Porção (100g)
            </Typography>
          </NutritionalInfoTableHeader>
        </TableRow>
        {nutritionalInfo && (
          <>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Calorias (kcal)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.kcal)}
                  onChange={handleChange('kcal')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Carboidratos (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.carbs)}
                  onChange={handleChange('carbs')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Proteínas (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.proteins)}
                  onChange={handleChange('proteins')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Gorduras totais (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.totalFats)}
                  onChange={handleChange('totalFats')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Gorduras trans (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.transFats)}
                  onChange={handleChange('transFats')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Gorduras sat. (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.satFats)}
                  onChange={handleChange('satFats')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Fibras (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.fibers)}
                  onChange={handleChange('fibers')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Sódio (mg)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.sodium)}
                  onChange={handleChange('sodium')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Açúcares totais (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.totalSugars)}
                  onChange={handleChange('totalSugars')}
                />
              </CellContainer>
            </TableRow>
            <TableRow>
              <NutrientNamesCellContainer>
                <Typography fontSize="small">Aç adicionados (g)</Typography>
              </NutrientNamesCellContainer>
              <CellContainer>
                <StyledInput
                  value={anyToString(nutritionalInfo.addedSugars)}
                  onChange={handleChange('addedSugars')}
                />
              </CellContainer>
            </TableRow>
          </>
        )}
      </NutritionalInfoContainer>
    )
  }
)

export default NutritionalInfoForm
