import { NutritionalInfo, stringLiteralArray } from './nutritionalInfo'

export const areas = stringLiteralArray([
  'FLV',
  'Produtos Terceiros',
  'Proteínas (Vegetais e Animais) e Derivados de Animais',
  'Produtos Secos',
])

export type Area = typeof areas[number]

export const feedstockTypes = stringLiteralArray([
  'Refrigerado',
  'Congelado',
  'Seco',
])

export type FeedstockType = typeof feedstockTypes[number]

export const categoryCodes = {
  PROTEINA: 'RP',
  VEGETAL: 'RL',
  MASSA: 'MA',
  'LEITE/DERIVADO': 'RS',
  SECO: 'SE',
}

export const categories = Object.keys(categoryCodes)

export type Categories = typeof categories[number]

export enum AccountingClassification {
  own_production = 'Produção própria',
  resale = 'Revenda',
  consignation = 'Consignação',
}

export interface Feedstock {
  id: string
  code: string
  feedstockName: string
  density: number
  stockType: FeedstockType
  area: Area
  suppliers: any[]
  nutritionalInfo: NutritionalInfo
  currentPrice: number
  allergenics: number[]
  nutritionalInfoSource: string
  kitchens: string[]
  transferable?: boolean
  originatingSku?: string
  unitsPerBox?: string
  accountingClassification?: AccountingClassification
  ncm?: string
  cest?: string
  cst?: string
  transferUnit?: string
  boxType?: string
  active: boolean
}
