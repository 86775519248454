import IngredientsShopList from '.'

export const ingredientsShopListRoutes = {
  path: 'ingredients-shop',
  restricted: false,
  children: [
    {
      path: 'export',
      component: IngredientsShopList,
    },
  ],
}
