import React from 'react'
import { RouteComponentProps } from 'react-router'

import IngredientsShopListPage from './pages/IngredientsShopListPage'
import ContainerHeader from 'src/components/ContainerHeader'

function IngredientsShopList(props: RouteComponentProps) {
  return (
    <div className="app-wrapper">
      <ContainerHeader location={props.location} title="Lista de compras" />
      <IngredientsShopListPage />
    </div>
  )
}

export default IngredientsShopList
