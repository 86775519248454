import { feedstockRoutes } from './feedstock/routes'
import { ingredients } from './ingredients/routes'
import { ingredientsProcessRoutes } from './ingredient-process/routes'
import { productionPlanningRoutes } from './production-planning/routes'
import { ingredientsShopListRoutes } from './ingredients-shop/routes'
import { tagsRoutes } from './tags/routes'

export const v2appRoutes = [
  feedstockRoutes,
  tagsRoutes,
  ingredients,
  ingredientsProcessRoutes,
  productionPlanningRoutes,
  ingredientsShopListRoutes,
]
