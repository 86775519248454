import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    width: '48px',
    transition: 'width 0.3s',
    overflow: 'hidden',
    borderRadius: '4px',
    marginBottom: '2px',
    position: 'relative',
  },
  containerExpanded: {
    width: 24 * theme.spacing.unit,
  },
  expandable: {
    padding: theme.spacing.unit,
    position: 'absolute',
    width: 24 * theme.spacing.unit,
    left: '48px',
    borderRadius: '4px',
    overflowX: 'scroll',
    height: '100%',
    transition: 'left 0.3s',
  },
  expanded: {
    left: 0,
  },
  actionButton: {
    width: '48px',
    height: '48px',
    transition: 'transform 0.1s',
    transform: 'rotate(90deg)',
  },
  expandedButton: {
    transform: 'rotate(-90deg)',
  },
  collapsedButton: {
    transform: 'rotate(90deg)',
  },
  subRecipeLink: {
    padding: '16px',
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer',
      color: '#02004b',
      textDecoration: 'underline',
    },
  },
  separator: {
    borderBottom: '1px solid',
    marginLeft: '16px',
    marginRight: '16px',
    opacity: 0.2,
  },
  expandIcon: {
    marginTop: '-3px',
    fontSize: '32px',
  },
})

class SubRecipesSideNav extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.subRecipes || nextProps.subRecipes.length === 0) {
      return {
        expanded: false,
      }
    }
    return prevState
  }
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  handleRecipeClick = event => {
    const { id } = event.target
    this.props.handleRecipeClick(id)
  }

  handleExpandClick = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  render() {
    const { classes, subRecipes, path } = this.props
    const { expanded } = this.state
    return (
      <div
        className={classNames(
          classes.root,
          expanded && classes.containerExpanded
        )}
      >
        <IconButton
          className={classNames(
            classes.actionButton,
            expanded && classes.expandedButton
          )}
          onClick={this.handleExpandClick}
          disabled={subRecipes.length === 0}
        >
          <ExpandMoreIcon className={classes.expandIcon} />
        </IconButton>
        <Grid
          container
          direction="column"
          className={classNames(
            classes.expandable,
            expanded && classes.expanded
          )}
        >
          {subRecipes.map((subRecipe, subRecipeIndex) => (
            <React.Fragment key={subRecipeIndex}>
              {subRecipeIndex > 0 && <div className={classes.separator} />}
              <Typography
                onClick={this.handleRecipeClick}
                variant="subtitle2"
                id={
                  path
                    ? `${path}.subRecipes.${subRecipeIndex}`
                    : `subRecipes.${subRecipeIndex}`
                }
                className={classes.subRecipeLink}
              >
                {subRecipe}
              </Typography>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    )
  }
}
SubRecipesSideNav.propTypes = {
  classes: PropTypes.object.isRequired,
  subRecipes: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  handleRecipeClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(SubRecipesSideNav)
