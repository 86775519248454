import { pathOr } from 'ramda'
import { sendNotification } from '../../../../actions/Notifications'
import * as t from '../actionTypes/index'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const getAllergenicsRequest = () => ({
  type: t.GET_ALLERGENICS_REQUEST,
})

const getAllergenicsResponse = data => ({
  type: t.GET_ALLERGENICS_RESPONSE,
  payload: {
    data,
  },
})

const getAllergenicsError = error => ({
  type: t.GET_ALLERGENICS_ERROR,
  payload: {
    error,
  },
})

export const getAllergenics = () => async (dispatch, _, api) => {
  dispatch(getAllergenicsRequest())
  try {
    const response = await api.get(`api/allergenics/`)
    dispatch(getAllergenicsResponse(response.data.allergenics))
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao buscar os alergênicos',
        autoDismiss: true,
      })
    )
    dispatch(getAllergenicsError(error))
    console.error(error)
  }
}

const getSuppliersRequest = () => ({
  type: t.GET_SUPPLIERS_REQUEST,
})

const getSuppliersResponse = data => ({
  type: t.GET_SUPPLIERS_RESPONSE,
  payload: {
    data,
  },
})

const getSuppliersError = error => ({
  type: t.GET_SUPPLIERS_ERROR,
  payload: {
    error,
  },
})

export const getSuppliers = () => async (dispatch, _, api) => {
  dispatch(getSuppliersRequest())
  try {
    const response = await api.get(`api/suppliers/`)
    dispatch(
      getSuppliersResponse(
        response.data.map(supplier => ({
          id: supplier.id,
          name: supplier.name,
        }))
      )
    )
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao buscar os fornecedores',
        autoDismiss: true,
      })
    )
    dispatch(getSuppliersError(error))
    console.error(error)
  }
}

const getNutritionalInfoRequest = () => ({
  type: t.GET_NUTRITIONAL_INFO_REQUEST,
})

const getNutritionalInfoResponse = data => ({
  type: t.GET_NUTRITIONAL_INFO_RESPONSE,
  payload: {
    data,
  },
})

const getNutritionalInfoError = error => ({
  type: t.GET_NUTRITIONAL_INFO_ERROR,
  payload: {
    error,
  },
})

export const getNutritionalInfo =
  (font, search) => async (dispatch, _, api) => {
    dispatch(getNutritionalInfoRequest())
    try {
      const response = await api.post(`api/feedstocks/${font}`, {
        search: search.split(' '),
      })
      dispatch(getNutritionalInfoResponse(response.data.feedstock))
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message
      dispatch(
        sendNotification({
          type: 'error',
          message: errorMessage,
          autoDismiss: true,
        })
      )
      dispatch(getNutritionalInfoError(error))
      console.error(error)
    }
  }

const saveFeedstockRequest = () => ({
  type: t.SAVE_FEEDSTOCK_REQUEST,
})

const saveFeedstockResponse = () => ({
  type: t.SAVE_FEEDSTOCK_RESPONSE,
})

const saveFeedstockError = error => ({
  type: t.SAVE_FEEDSTOCK_ERROR,
  payload: {
    error,
  },
})

export const saveFeedstock = data => async (dispatch, _, api) => {
  dispatch(saveFeedstockRequest())
  try {
    await api.post(`api/feedstocks/`, data)
    dispatch(saveFeedstockResponse())
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Matéria-prima salva',
        autoDismiss: true,
      })
    )
    dispatch(getFeedstockResponse())
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao salvar',
        autoDismiss: true,
      })
    )
    dispatch(saveFeedstockError(error))
    console.error(error)
  }
}

const getNextAvailableCodeRequest = () => ({
  type: t.GET_NEXT_AVAILABLE_CODE_REQUEST,
})

const getNextAvailableCodeResponse = data => ({
  type: t.GET_NEXT_AVAILABLE_CODE_RESPONSE,
  payload: {
    data,
  },
})

const getNextAvailableCodeError = error => ({
  type: t.GET_NEXT_AVAILABLE_CODE_ERROR,
  payload: {
    error,
  },
})

export const getNextAvailableCode = prefix => async (dispatch, _, api) => {
  dispatch(getNextAvailableCodeRequest())
  try {
    const response = await api.get(`api/feedstocks/nextCode/?prefix=${prefix}`)
    dispatch(getNextAvailableCodeResponse(response.data.code))
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao buscar o próximo código disponível',
        autoDismiss: true,
      })
    )
    dispatch(getNextAvailableCodeError(error))
    console.error(error)
  }
}

const getFeedstockRequest = () => ({
  type: t.GET_FEEDSTOCK_REQUEST,
})

const getFeedstockResponse = response => ({
  type: t.GET_FEEDSTOCK_RESPONSE,
  payload: {
    data: response ? response.data.feedstocks[0] : null,
  },
})

const getFeedstockError = error => ({
  type: t.GET_FEEDSTOCK_ERROR,
  payload: {
    error,
  },
})

export const getFeedstock = code => async (dispatch, _, api) => {
  dispatch(getFeedstockRequest())
  try {
    const response = await api.get(`api/feedstocks/?code=${code}`)
    dispatch(getFeedstockResponse(response))
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao buscar essa matéria-prima',
        autoDismiss: true,
      })
    )
    dispatch(getFeedstockError(error))
    console.error(error)
  }
}

export const getFeedstockProduct = code => async (dispatch, _, api) => {
  dispatch({ type: t.GET_FEEDSTOCK_PRODUCT_REQUEST })
  try {
    const response = await api.get(`api/feedstocks/${code}/product`)
    dispatch({
      type: t.GET_FEEDSTOCK_PRODUCT_RESPONSE,
      payload: {
        data: response ? response.data : null,
      },
    })
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao buscar o produto dessa matéria-prima',
        autoDismiss: true,
      })
    )
    dispatch({
      type: t.GET_FEEDSTOCK_PRODUCT_ERROR,
      payload: { error },
    })
    console.error(error)
  }
}

const getProductsRequest = () => ({
  type: t.GET_PRODUCTS_REQUEST,
})

const getProductsResponse = response => ({
  type: t.GET_PRODUCTS_RESPONSE,
  payload: {
    data: response ? response.data : null,
  },
})

const getProductsError = error => ({
  type: t.GET_PRODUCTS_ERROR,
  payload: {
    error,
  },
})

export const getProducts = params => async (dispatch, _, api) => {
  dispatch(getProductsRequest())
  try {
    const response = await api.get(`api/products/food-service`)
    dispatch(getProductsResponse(response))
  } catch (error) {
    dispatch(
      sendNotification({
        type: 'error',
        message: 'Ocorreu um erro ao buscar essa matéria prima',
        autoDismiss: true,
      })
    )
    dispatch(getProductsError(error))
    console.error(error)
  }
}

export const deleteFeedstock = code => async (dispatch, _, api) => {
  dispatch({
    type: t.DELETE_FEEDSTOCK_REQUEST,
  })
  try {
    await api.delete(`api/feedstocks/${code}`)
    dispatch({
      type: t.DELETE_FEEDSTOCK_RESPONSE,
    })
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Matéria-prima deletada',
        autoDismiss: true,
      })
    )
  } catch (error) {
    dispatch({
      type: t.DELETE_FEEDSTOCK_ERROR,
      payload: { error },
    })
    dispatch(
      sendNotification({
        type: 'error',
        message: pathOr(
          'Ocorreu um erro ao salvar',
          ['response', 'data', 'output', 'payload', 'message'],
          error
        ),
        autoDismiss: true,
      })
    )
  }
}

export const clearStore = () => dispatch => {
  dispatch({
    type: t.CLEAR_FEEDSTOCK_STORE,
  })
}
