import React from 'react'

import { ActionsContainer, EditIcon, EditLabel, Title } from './styles'

interface Props {
  title: string
  disableEdit: boolean
  onEdit: () => void
}

function IngredientView(props: Props) {
  function handleEdit() {
    if (props.disableEdit) {
      return
    }

    props.onEdit()
  }

  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <ActionsContainer onClick={handleEdit}>
        <EditLabel>Editar</EditLabel>
        <EditIcon />
      </ActionsContainer>
    </React.Fragment>
  )
}

export default IngredientView
