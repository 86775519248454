import ls from 'local-storage'

export const storeToken = async token => {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined' ||
    token === 'no-user'
  )
    return

  try {
    const currentToken = ls.get('_jwt')
    if (currentToken) ls.remove('_jwt')
    ls.set('_jwt', token)

    return true
  } catch (e) {
    return false
  }
}

export const getToken = async () => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return

  try {
    const token = ls.get('_jwt')
    if (!token || token === '') return null

    return `Bearer ${token}`
  } catch (e) {
    return null
  }
}

export const discardToken = async () => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return
  try {
    ls.remove('_jwt')
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
