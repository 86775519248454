import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Loading } from 'components/Loading'
import TableHeader from 'components/TableHeader'
import SubRecipe from './SubRecipe'
import { recipesMessage, emptySubRecipes } from '../formattedMessages'

const styles = theme => ({
  root: {
    width: '100%',
  },
  modal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  headerContainer: {
    borderTop: 0,
    backgroundColor: theme.palette.primary.medium,
  },
  headerTitle: {
    color: theme.palette.common.white,
    fontSize: 16,
    opacity: 0.9,
  },
  subRecipesContainer: {
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  emptySubRecipes: {
    marginTop: 2 * theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    opacity: 0.5,
    cursor: 'pointer',
    textAlign: 'center',
  },
})

class SubRecipesTable extends PureComponent {
  constructor(props) {
    super(props)
    this.headerClasses = {
      headerContainer: props.classes.headerContainer,
      actionButton: props.classes.actionButton,
      headerTitle: props.classes.headerTitle,
    }
  }
  handleAddModalOpen = () => {
    const { path, handleAddModalOpen } = this.props
    handleAddModalOpen(path)('recipesModalOpen')
  }

  renderSubRecipes = () => {
    const {
      classes,
      subRecipes,
      handleChange,
      path,
      handleAddModalOpen,
      batchSize,
      totalCost,
      handleRemove,
      recipeWeight,
      dirty,
    } = this.props
    return (
      <Fragment>
        {subRecipes &&
          subRecipes.length > 0 &&
          subRecipes.map((subRecipe, subRecipeIndex) => (
            <SubRecipe
              handleRemove={handleRemove}
              batchSize={batchSize}
              totalCost={totalCost}
              key={subRecipeIndex}
              subRecipe={subRecipe}
              handleChange={handleChange}
              subRecipeIndex={subRecipeIndex}
              path={path}
              handleAddModalOpen={handleAddModalOpen}
              dirty={dirty}
              recipeWeight={recipeWeight}
            />
          ))}
        {subRecipes && subRecipes.length === 0 && (
          <Typography
            variant="h6"
            className={classes.emptySubRecipes}
            onClick={this.handleAddModalOpen}
          >
            {emptySubRecipes}
          </Typography>
        )}
      </Fragment>
    )
  }

  render() {
    const { classes, isLoading } = this.props
    return (
      <Grid className={classes.root}>
        <TableHeader
          title={recipesMessage}
          addIcon
          handleAddClick={this.handleAddModalOpen}
          classes={{
            headerContainer: classes.headerContainer,
            actionButton: classes.actionButton,
            headerTitle: classes.headerTitle,
          }}
        />
        <Grid className={classes.subRecipesContainer}>
          {isLoading ? <Loading size={50} /> : this.renderSubRecipes()}
        </Grid>
      </Grid>
    )
  }
}
SubRecipesTable.propTypes = {
  subRecipes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  handleAddModalOpen: PropTypes.func.isRequired,
  batchSize: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  recipeWeight: PropTypes.number,
  dirty: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

SubRecipesTable.defaultProps = {
  recipeWeight: 0,
}

export default withStyles(styles)(SubRecipesTable)
