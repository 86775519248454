import {
  FETCH_PRODUCTION_RECIPE_REQUEST,
  FETCH_PRODUCTION_RECIPE_RESPONSE,
  FETCH_PRODUCTION_RECIPE_ERROR,
  CREATE_PRODUCTION_RECIPE_REQUEST,
  CREATE_PRODUCTION_RECIPE_RESPONSE,
  CREATE_PRODUCTION_RECIPE_ERROR,
  EDIT_PRODUCTION_RECIPE_REQUEST,
  EDIT_PRODUCTION_RECIPE_RESPONSE,
  EDIT_PRODUCTION_RECIPE_ERROR,
  FETCH_PRODUCTION_RECIPES_REQUEST,
  FETCH_PRODUCTION_RECIPES_RESPONSE,
  FETCH_PRODUCTION_RECIPES_ERROR,
  ADD_RECIPE_STEP,
  REMOVE_RECIPE_STEP,
  CHANGE_RECIPE_DATA,
  CHANGE_RECIPE_STEP,
  SELECT_RECIPE_STEP,
  SET_EMPTY_RECIPE,
} from '../constants/ActionTypes'
import { handleRequestError } from './ErrorHandling'
import { errorNotification, sendNotification } from './Notifications'
import { productionRecipes } from '../../mock/mockApi'

export const fetchProductionRecipes = () => async (dispatch, _, api) => {
  try {
    dispatch({
      type: FETCH_PRODUCTION_RECIPES_REQUEST,
    })
    // const response = await api.get('');
    const response = productionRecipes
    dispatch({
      type: FETCH_PRODUCTION_RECIPES_RESPONSE,
      payload: response,
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_PRODUCTION_RECIPES_ERROR,
      payload: errorMessage,
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchProductionRecipe = recipeCode => async (dispatch, _, api) => {
  try {
    dispatch({
      type: FETCH_PRODUCTION_RECIPE_REQUEST,
    })
    // const response = await api.get('', {
    // 	recipeCode,
    // });
    const response = productionRecipes.find(recipe => recipe.sku === recipeCode)
    dispatch({
      type: FETCH_PRODUCTION_RECIPE_RESPONSE,
      payload: response,
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_PRODUCTION_RECIPE_ERROR,
      payload: errorMessage,
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

const formatProductionRecipe = productionRecipe => ({
  ...productionRecipe,
  stepsByResource: {
    ...productionRecipe.stepsByResource.map(step => ({
      ...step,
      resource: step.resource.name.value,
    })),
  },
})

export const createProductionRecipe = () => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: CREATE_PRODUCTION_RECIPE_REQUEST,
    })
    const productionRecipe = getState().productionRecipes.registerRecipe.data
    const recipeToSave = formatProductionRecipe(productionRecipe)
    const response = await api.post('api/ppcp/production-recipes', {
      recipeToSave,
    })
    dispatch({
      type: CREATE_PRODUCTION_RECIPE_RESPONSE,
      payload: {
        data: response.data,
      },
    })
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Receita de Produção criada com sucesso!',
        autoDismiss: true,
      })
    )
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: CREATE_PRODUCTION_RECIPE_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const editProductionRecipe = () => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: EDIT_PRODUCTION_RECIPE_REQUEST,
    })
    const productionRecipe = getState().productionRecipes.recipe.data
    const identifier = getState().productionRecipes.recipe.data.sku
    const response = await api.post(`url/${identifier}`, {
      productionRecipe,
    })
    dispatch({
      type: EDIT_PRODUCTION_RECIPE_RESPONSE,
      payload: {
        data: response.data,
      },
    })
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Receita de Produção editada com sucesso!',
        autoDismiss: true,
      })
    )
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: EDIT_PRODUCTION_RECIPE_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const addRecipeStep = isEditing => async dispatch => {
  dispatch({
    type: ADD_RECIPE_STEP,
    payload: isEditing,
  })
}

export const removeRecipeStep = (index, isEditing) => async dispatch => {
  dispatch({
    type: REMOVE_RECIPE_STEP,
    payload: {
      index,
      isEditing,
    },
  })
}

export const handleChangeRecipeData =
  (id, value, isEditing) => async dispatch => {
    dispatch({
      type: CHANGE_RECIPE_DATA,
      payload: {
        id,
        value,
        isEditing,
      },
    })
  }

export const handleChangeRecipeStep =
  (id, value, index, isEditing) => async dispatch => {
    dispatch({
      type: CHANGE_RECIPE_STEP,
      payload: {
        id,
        value,
        index,
        isEditing,
      },
    })
  }

export const handleSelectRecipeStep =
  (name, value, index, isEditing) => async dispatch => {
    dispatch({
      type: SELECT_RECIPE_STEP,
      payload: {
        name,
        value,
        index,
        isEditing,
      },
    })
  }

export const setRecipeToRegister = () => async dispatch => {
  dispatch({
    type: SET_EMPTY_RECIPE,
    payload: null,
  })
}
