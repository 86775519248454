import { shape, string, number, arrayOf, bool, object, func } from 'prop-types'

export const productionProduct = arrayOf(
  shape({
    name: string.isRequired,
    inventory: string.isRequired,
    sku: string.isRequired,
    demand: string.isRequired,
  })
)

export const reactDates = shape({
  isMomentObject: bool,
  isUTC: func.isRequired,
  isValid: func.isRequired,
  pf: object,
  locale: func.isRequired,
  demand: string,
})

export const stepsByResource = arrayOf(
  shape({
    resource: object,
    timeSetup: number,
    timeByLot: number,
    complexity: object,
  })
)

export const productionRecipe = shape({
  isActive: bool,
  lotSize: number,
  maxInventory: number,
  maxLot: number,
  minInventory: number,
  minLot: number,
  name: string,
  sku: string,
  stepsByResource,
})
