import ContainerHeader from '../../../../components/ContainerHeader/index'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import FeedstockRegisterContainer from '../containers/FeedstockRegisterContainer'

class FeedstockRegisterScreen extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title="Cadastro de matéria-prima"
        />
        <FeedstockRegisterContainer />
      </div>
    )
  }
}

export default withRouter(FeedstockRegisterScreen)
