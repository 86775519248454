import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import TableHeader from 'components/TableHeader'
import FlipMove from 'react-flip-move'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Process from '../Process'
import styles from './styles'

class ProcessTable extends PureComponent {
  render() {
    const {
      classes,
      processes = [],
      onAdd,
      onRemove,
      onMoveProcess,
    } = this.props

    return (
      <Grid elevation={1} className={classes.root}>
        <TableHeader
          title="Processos"
          addIcon
          handleAddClick={onAdd}
          classes={{
            headerContainer: classes.headerContainer,
            actionButton: classes.actionButton,
            headerTitle: classes.headerTitle,
          }}
        />
        <Grid className={classes.processesContainer}>
          <FlipMove duration={300} easing="ease-out">
            {processes.length > 0 ? (
              processes.map((process, index) => (
                <Process
                  process={process}
                  key={`${process.types}${process.step}${process.description}`}
                  onDelete={onRemove(index)}
                  first={index === 0}
                  last={index === processes.length - 1}
                  onMoveUp={onMoveProcess(index, index - 1)}
                  onMoveDown={onMoveProcess(index, index + 1)}
                />
              ))
            ) : (
              <Typography
                variant="h6"
                className={classes.emptyProcesses}
                onClick={onAdd}
              >
                Ainda não há processos. Clique para adicionar
              </Typography>
            )}
          </FlipMove>
        </Grid>
      </Grid>
    )
  }
}

ProcessTable.propTypes = {
  classes: PropTypes.object.isRequired,
  processes: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMoveProcess: PropTypes.func.isRequired,
}

export default withStyles(styles)(ProcessTable)
