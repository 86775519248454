import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import { Typography } from '../../../components/atoms'

export const StyledCircularProgress = styled(CircularProgress)`
  margin-top: 100px;
`

export const NoResultsFound = styled.p`
  margin-top: 100px;
  font-size: ${props => props.theme.v2.typography.fontSize.medium};
  color: ${props => props.theme.v2.palette.grayScale['40']};
`

export const Header = styled.div`
  && {
    align-items: center;
    padding: 24px;
  }
`

export const HeaderTitle = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.small};
    color: ${props => props.theme.v2.palette.primary.darker};
    font-weight: bold;
  }
`
