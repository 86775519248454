import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'

import DropDown from 'src/components/DropDown'
import TextField from 'src/components/TextField'
import { required } from 'src/components/Form/validations'
import InputValidator from 'src/components/Form/validators/InputValidator'

import {
  cookingTimeMaxTitle,
  cookingTimeMinTitle,
} from '../../formattedMessages'

import defineMessages from '../../Messages'

import {
  StyledGrid,
  StyledDeleteButton,
  StyledAddButton,
  WrapIcons,
} from './styles'

function CookingTimesItem({
  addItemDisabled,
  cookingMethods,
  index,
  onAddItem,
  onChange,
  onDeleteItem,
  removeItemDisabled,
  value,
}) {
  const [cookingTime, setCookingTime] = useState(value || {})
  const [selectedOption, setSelectedOption] = useState(null)
  const [cookingMethodsOptions, setCookingMethodsOptions] = useState([])
  const { errors } = defineMessages

  const getCookingMethodsOptions = () =>
    cookingMethods.map(itm => ({
      value: itm.id,
      label: itm.name,
    }))

  const getSelectedOption = () =>
    cookingMethodsOptions.find(
      cookingMethodsOption => cookingMethodsOption.value === cookingTime.type
    )

  const handleCookingMethodChange = _name => option => {
    const newCookingTime = {
      ...cookingTime,
      type: option.value,
    }

    setSelectedOption(option)
    setCookingTime(newCookingTime)
    onChange(index, newCookingTime)
  }

  const handleCookingTimeChange = event => {
    const field = event.target.target.name
    const { value: currentValue } = event.target

    const newCookingTime = {
      ...cookingTime,
      range: {
        ...cookingTime.range,
        [field]: currentValue,
      },
    }

    setCookingTime(newCookingTime)
    onChange(index, newCookingTime)
  }

  const handleItemAdd = () => {
    onAddItem(index)
  }

  const handleItemDelete = () => {
    onDeleteItem(index)
  }

  useEffect(() => {
    setCookingTime(value)
  }, [value])

  useEffect(() => {
    setCookingMethodsOptions(getCookingMethodsOptions())
  }, [cookingMethods])

  useEffect(() => {
    setSelectedOption(getSelectedOption())
  }, [cookingTime, cookingMethodsOptions])

  return (
    <StyledGrid
      container
      direction="row"
      spacing={8}
      alignItems="center"
      justify="flex-start"
    >
      <StyledGrid item xs={4}>
        <InputValidator
          id={`cookingMethod_${index}`}
          validations={[required(errors.required)]}
        >
          <DropDown
            name="cookingMethod"
            label={`${index + 1}º Método de Aquecimento (*)`}
            items={cookingMethodsOptions}
            selectedItem={selectedOption}
            handleChange={handleCookingMethodChange}
          />
        </InputValidator>
      </StyledGrid>
      <StyledGrid item xs={3}>
        <InputValidator
          id={`cookingTimeMin_${index}`}
          validations={[required(errors.required)]}
        >
          <TextField
            label={cookingTimeMinTitle}
            name="min"
            type="number"
            value={cookingTime?.range?.min}
            handleChange={handleCookingTimeChange}
            inputProps={{
              min: 0,
              max: 45 * 60,
              style: { textAlign: 'right' },
              endAdornment: <InputAdornment position="end">sec</InputAdornment>,
            }}
            isInputNumber
          />
        </InputValidator>
      </StyledGrid>
      <StyledGrid item xs={3}>
        <InputValidator
          id={`cookingTimeMax_${index}`}
          validations={[required(errors.required)]}
        >
          <TextField
            label={cookingTimeMaxTitle}
            name="max"
            type="number"
            align="right"
            value={cookingTime?.range?.max}
            handleChange={handleCookingTimeChange}
            inputProps={{
              min: 0,
              max: 45 * 60,
              style: { textAlign: 'right' },
              endAdornment: <InputAdornment position="end">sec</InputAdornment>,
            }}
            isInputNumber
          />
        </InputValidator>
      </StyledGrid>
      <StyledGrid item xs={2}>
        <WrapIcons>
          <StyledDeleteButton
            onClick={handleItemDelete}
            disabled={removeItemDisabled}
          />
          <StyledAddButton onClick={handleItemAdd} disabled={addItemDisabled} />
        </WrapIcons>
      </StyledGrid>
    </StyledGrid>
  )
}

CookingTimesItem.propTypes = {
  addItemDisabled: PropTypes.bool.isRequired,
  removeItemDisabled: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  cookingMethods: PropTypes.array,
  value: PropTypes.shape({
    type: PropTypes.string,
    range: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  onChange: PropTypes.func.isRequired,
}

CookingTimesItem.defaultProps = {
  cookingMethods: [],
  value: [],
}

export default CookingTimesItem
