import React from 'react'
import { LookupItem } from '../../../contracts/lookupItem'
import { v2 } from '../../../theme'
import { DropdownIndicator, Placeholder, StyledSelect } from './styles'
import { Props as SelectProps } from 'react-select'

export interface LookupProps extends SelectProps {
  placeholder: string
  options: LookupItem[]
  onSelect: (option: LookupItem | LookupItem[]) => void
  isClearable?: boolean
  selected?: LookupItem | LookupItem[]
  defaultValue?: LookupItem
  disabled?: boolean
}

export const Lookup = React.memo(
  ({
    onSelect,
    isClearable,
    selected,
    disabled,
    defaultValue,
    placeholder,
    ...props
  }: Partial<LookupProps>) => {
    const handleChange = (option: LookupItem | LookupItem[]) => {
      onSelect(option)
    }

    return (
      <StyledSelect
        {...props}
        isDisabled={disabled}
        defaultValue={defaultValue}
        value={selected}
        components={{ DropdownIndicator, Placeholder }}
        theme={(theme: any) => ({ ...theme, palette: v2.palette })}
        onChange={handleChange}
        isClearable={isClearable}
        placeholder={placeholder}
      />
    )
  }
)
