import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import dotProp from 'dot-prop-immutable'
import {
  fetchIngredients,
  addIngredient,
  removeIngredient,
} from 'actions/Recipes'
import Modal from '@material-ui/core/Modal'
import IngredientsTable from '../../components/IngredientsTable'
import SelectModal from '../../Simulate/components/SelectModal'
import { getItemPath } from '../../helpers'

class Ingredients extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.fetchIngredients()
  }

  handleChange = path => value => {
    const { ingredients, onChange, editing } = this.props
    const { ingredients: updatedIngredients } = dotProp.set(
      { ingredients },
      path,
      value
    )
    if (onChange) {
      onChange(updatedIngredients)
    }
    if (this.props.addIngredient) {
      const updatedIngredientPath = getItemPath(path)
      const updatedIngredient = dotProp.get(
        { ingredients: updatedIngredients },
        updatedIngredientPath
      )
      this.props.addIngredient(
        updatedIngredient.code,
        updatedIngredient.quantity,
        updatedIngredient.showCompositionOnTag,
        editing
      )
    }
  }

  handleAdd = ingredientCode => _type => quantity => {
    const {
      ingredients,
      allIngredients,
      editing,
      addIngredient,
      fetchIngredientAllergenics,
      onChange,
    } = this.props

    const ingredientToAdd = allIngredients.find(
      ingredient => ingredient && ingredient.code === ingredientCode
    )

    if (!ingredientToAdd) {
      return
    }

    if (onChange) {
      onChange([...ingredients, { ...ingredientToAdd, quantity }])
    }

    if (addIngredient) {
      addIngredient(ingredientToAdd.code, quantity, false, editing)
    }

    fetchIngredientAllergenics(ingredientToAdd.id)
    this.setState({ isModalOpen: false })
  }

  handleRemove = path => {
    const { ingredients, onChange, editing } = this.props
    const { ingredients: updatedIngredients } = dotProp.delete(
      { ingredients },
      path
    )
    if (onChange) {
      onChange(updatedIngredients)
    }
    const index = path.split('.')[1]
    const ingredientToRemove = ingredients[index]
    this.props.removeAllergenic(ingredientToRemove.id)
    if (this.props.removeIngredient) {
      this.props.removeIngredient(ingredientToRemove.code, editing)
    }
  }

  handleAddModalOpen = () => {
    const { allIngredientsError, enqueueSnackBar, recipeKitchens } = this.props
    if (allIngredientsError) {
      console.error(allIngredientsError) // TODO: Essa validação não está mais ocorrendo
      enqueueSnackBar('Ocorreu um erro carregando os ingredientes', {
        variant: 'error',
        horizontal: 'left',
        preventDuplicate: true,
      })
      return
    }
    if (!recipeKitchens || recipeKitchens === '') {
      console.error('Selecione uma cozinha')
      enqueueSnackBar('Selecione uma cozinha para esta receita', {
        variant: 'error',
        horizontal: 'left',
        preventDuplicate: false,
      })
      return
    }

    this.setState({ isModalOpen: true })
  }

  handleClose = () => this.setState({ isModalOpen: false })

  renderIngredientModal() {
    const { allIngredients, allIngredientsLoading, recipeKitchens } = this.props
    const selectedKitchenIngredients = allIngredients.filter(
      ing =>
        ing.kitchens &&
        ing.kitchens.some(kitchen => recipeKitchens.includes(kitchen))
    )

    return (
      <Modal open={this.state.isModalOpen} onClose={this.handleClose}>
        <SelectModal
          items={selectedKitchenIngredients.map(ingredient => ({
            label: ingredient.recipeName,
            value: ingredient.code,
          }))}
          step={1}
          label="Escolha o Ingrediente"
          title="Ingredientes"
          handleConfirm={this.handleAdd}
          type="ingredients"
          buttonMessage="Adicionar"
          itemsLoading={allIngredientsLoading}
        />
      </Modal>
    )
  }

  render() {
    const { ingredients, totalCost, batchSize, recipeWeight, dirty } =
      this.props
    return (
      <React.Fragment>
        <IngredientsTable
          ingredients={ingredients}
          recipeWeight={recipeWeight}
          handleChange={this.handleChange}
          handleRemove={this.handleRemove}
          path="ingredients"
          handleAddModalOpen={this.handleAddModalOpen}
          totalCost={totalCost}
          batchSize={batchSize}
          dirty={dirty}
        />
        {this.renderIngredientModal()}
      </React.Fragment>
    )
  }
}

Ingredients.propTypes = {
  ingredients: PropTypes.array,
  allIngredients: PropTypes.array.isRequired,
  totalCost: PropTypes.number.isRequired,
  batchSize: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  addIngredient: PropTypes.func,
  removeIngredient: PropTypes.func,
  fetchIngredients: PropTypes.func,
  enqueueSnackBar: PropTypes.func,
  allIngredientsError: PropTypes.bool,
  allIngredientsLoading: PropTypes.bool,
  recipeWeight: PropTypes.number,
  dirty: PropTypes.bool.isRequired,
  recipeKitchens: PropTypes.array,
}

Ingredients.defaultProps = {
  ingredients: [],
  onChange: () => null,
  addIngredient: () => null,
  removeIngredient: () => null,
  fetchIngredients: () => null,
  enqueueSnackBar: () => null,
  allIngredientsError: false,
  allIngredientsLoading: false,
  recipeWeight: 0,
  recipeKitchens: [''],
}

const mapDispatchToProps = {
  fetchIngredients,
  addIngredient,
  removeIngredient,
}

const mapStateToProps = state => ({
  allIngredients: state.recipes.ingredients.data,
  allIngredientsLoading: state.recipes.ingredients.loading,
  allIngredientsError: state.recipes.ingredients.error,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Ingredients))
