import React, { FormEvent } from 'react'
import Grid from '@material-ui/core/Grid'

import { StyledFormControlLabel, StyledRadio } from './styles'

export interface RadioProps {
  labels: string[]
  direction: 'row' | 'column'
  onSelect: (value: number) => void
  fontSize?: string
  selected: string
}

export const Radio = ({
  labels,
  direction,
  onSelect,
  fontSize,
  selected,
  ...props
}: RadioProps) => {
  const handleSelect = (event: FormEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget
    onSelect(Number(value))
  }

  return (
    <div {...props}>
      <Grid container direction={direction}>
        {labels.map((label, index) => (
          <Grid key={label.trim()} item xs={12} sm={6} md={3} lg={2}>
            <StyledFormControlLabel
              control={
                <StyledRadio
                  checked={selected === label}
                  onChange={handleSelect}
                  value={index}
                />
              }
              label={label}
              fontSize={fontSize}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
