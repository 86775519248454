import { FeedstockData } from '../components/organisms/feedstockTable'

export interface FeedstockDTO {
  nutritionalInfo: object
  currentPrice: number
  active: boolean
  id: object
  code: string
  updatedAt: object
  feedstockName: string
  stockType: string
  area: string
  suppliersInfo: object[]
  v: number
  createdAt: object
  allergenics: number[]
  suppliers: object[]
  type: string
}

export interface CheckboxKitchensList {
  id: string
  text: string
  selected?: boolean
}

export interface FeedstockSearchDTO {
  feedstocks: FeedstockDTO[]
}

export const fromDTOToFeedstocksArray: (
  feedstockSearchDTO: FeedstockSearchDTO
) => FeedstockData[] = feedstockSearchDTO => {
  return feedstockSearchDTO.feedstocks.map((feedstock: FeedstockDTO) => {
    const { currentPrice, code, feedstockName, stockType, type } = feedstock
    return {
      code,
      stockType,
      type,
      name: feedstockName,
      price: currentPrice,
    }
  })
}
