import { update } from 'ramda'

export const getTimeInSeconds = (minutes: number, seconds: number): number =>
  minutes * 60 + seconds

export const updateRecipesStore = (recipeSearchResults: any, data: any) =>
  data.reduce((recipes: any, updatedRecipe: any) => {
    const index = recipes.findIndex(
      (recipe: any) => recipe.id === updatedRecipe.id
    )
    return update(index, updatedRecipe, recipes)
  }, recipeSearchResults.data)

export function shouldUpdateNutritionalInfo(recipe: {
  recipeInfo: { sku: string }
  manuallyCalculated: boolean
}) {
  return recipe && recipe.recipeInfo && !recipe.manuallyCalculated
}
