import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSnackbar } from 'notistack'

import { createSnackbarOptions } from '../../../logic/notification'
import ContainerHeader from '../../../../components/ContainerHeader'
import {
  IngredientResponse,
  Ingredient,
  SaveIngredient,
} from '../../../api/dto/ingredients'
import { IngredientForm } from '../../components/IngredientForm'
import { Endpoints } from 'src/v2/api/endpoints'
import { useAPI } from 'src/v2/api'
import { CircularProgress, Grid } from '@material-ui/core'
import { ingredientValidations } from '../../validations/ingredient'

function CreateIngredientPage(props: RouteComponentProps<{ code: string }>) {
  const {
    match: {
      params: { code },
    },
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const [fetchData, loading] = useAPI()
  const [ingredient, setIngredient] = useState<Ingredient>()
  const [saveIngredient] = useAPI()

  const handleRequestError = useCallback(
    (error: Error) =>
      enqueueSnackbar(error.message, createSnackbarOptions('error')),
    [enqueueSnackbar]
  )

  const handleFormErrors = useCallback(
    (errors: string[]) => {
      errors.forEach(err =>
        enqueueSnackbar(err, createSnackbarOptions('error'))
      )
    },
    [enqueueSnackbar]
  )

  const getIngredient = useCallback(() => {
    fetchData({
      uri: Endpoints.GET_INGREDIENT(code),
      method: 'GET',
    })
      .then((data: IngredientResponse) => setIngredient(data.ingredient))
      .catch(handleRequestError)
  }, [fetchData, code])

  useEffect(() => {
    code && getIngredient()
  }, [code])

  function handleSave(newIngredient: SaveIngredient) {
    const errors = ingredientValidations(newIngredient)
    if (errors.length > 0) {
      handleFormErrors(errors)
      return
    }

    saveIngredient({
      uri: Endpoints.INGREDIENTS,
      method: 'POST',
      body: { ...newIngredient, feedstock: newIngredient.feedstock?.id },
    })
      .then((response: IngredientResponse) => {
        if (!response.ingredient) {
          return
        }

        setIngredient(response.ingredient)
        enqueueSnackbar(
          'Ingrediente cadastrado com sucesso!',
          createSnackbarOptions('success')
        )
      })
      .catch(handleRequestError)
  }

  return (
    <div className="app-wrapper">
      <ContainerHeader location={props.location} title={'Criar ingrediente'} />
      {loading ? (
        <Grid container justify="center" spacing={24}>
          <CircularProgress size={35} />
        </Grid>
      ) : (
        <IngredientForm onSave={handleSave} ingredient={ingredient} />
      )}
    </div>
  )
}

export default CreateIngredientPage
