import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  BagsInfo,
  Content,
  FiltersWrapper,
  Hint,
  LeftColumn,
  LoadingContainer,
  PrintListAction,
  PrintListItem,
  PrintListWrapper,
  RecipesWrapper,
} from './styles'
import RecipeItem from './RecipeItem'
import { searchRecipes as searchRecipesAction } from 'src/actions/Recipes'
import Input from 'src/v2/components/atoms/input'
import ContainerHeader from 'src/components/ContainerHeader'
import HeaderfulContainer from 'src/v2/components/molecules/headerfulContainer'

function RecipePrint(props) {
  const { searchRecipes } = props

  const [filter, setFilter] = useState('')
  const [printQueue, setPrintQueue] = useState([])

  useEffect(() => {
    searchRecipes({
      recipe: '',
      includeInactives: false,
    })
  }, [searchRecipes])

  function handleSearch(e) {
    setFilter(e.target.value)
  }

  function handleRecipeRemove(recipe) {
    return () => {
      setPrintQueue(printQueue.filter(item => item !== recipe))
    }
  }

  function filterByRegex(recipe) {
    const trimmedFilter = filter.trim()

    return trimmedFilter
      ? new RegExp(trimmedFilter, 'i').test(`${recipe.code}${recipe.name}`)
      : true
  }

  function handleAddRecipe(recipe) {
    setPrintQueue([...printQueue, recipe])
  }

  function handlePrint() {
    const printParams = printQueue
      .map(recipe => ({
        recipeId: recipe.id,
        desiredQuantity: recipe.quantity,
      }))
      .map(JSON.stringify)
      .join('||')

    window.open(
      `${process.env.OLD_KITCHEN_URL}/recipes/processRecipesPrintBatch/${printParams}`
    )
  }

  const addedRecipes = printQueue.map(recipe => recipe.code)

  return (
    <div className="app-wrapper">
      <ContainerHeader location={props.location} title="Imprimir Etiquetas" />
      <Content>
        <LeftColumn>
          <FiltersWrapper>
            <HeaderfulContainer title="Buscar receita">
              <Input
                label="Nome ou SKU"
                onChange={handleSearch}
                type="search"
                fullWidth
              />
            </HeaderfulContainer>
          </FiltersWrapper>

          <RecipesWrapper>
            <HeaderfulContainer title="Resultados">
              <Hint>
                Selecione a receita desejada clicando sobre o nome, informe a
                quantidade e clique em adicionar para incluir na lista de
                impressão
              </Hint>
              {props.recipes.loading && (
                <LoadingContainer>
                  <CircularProgress />
                </LoadingContainer>
              )}
              {props.recipes &&
                props.recipes.data &&
                filter.trim().length >= 4 &&
                props.recipes.data
                  .filter(
                    recipe =>
                      filterByRegex(recipe) &&
                      !addedRecipes.includes(recipe.code)
                  )
                  .map(recipe => (
                    <RecipeItem
                      key={recipe.code}
                      recipe={recipe}
                      onAdd={handleAddRecipe}
                    />
                  ))}
            </HeaderfulContainer>
          </RecipesWrapper>
        </LeftColumn>
        <PrintListWrapper>
          <HeaderfulContainer title="Lista de impressão">
            {printQueue.length ? (
              <>
                {printQueue.map(recipe => (
                  <PrintListItem key={recipe.code}>
                    <div>
                      {recipe.code} - {recipe.name}
                    </div>
                    <BagsInfo>
                      {recipe.quantity} saquinhos
                      <IconButton onClick={handleRecipeRemove(recipe)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </BagsInfo>
                  </PrintListItem>
                ))}
                <PrintListAction>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                  >
                    Imprimir
                  </Button>
                </PrintListAction>
              </>
            ) : (
              <Hint>
                Adicione pelo menos uma receita para que a lista de impressão
                seja exibida
              </Hint>
            )}
          </HeaderfulContainer>
        </PrintListWrapper>
      </Content>
    </div>
  )
}

function mapStateToProps({ recipes }) {
  return {
    recipes: recipes.recipeSearchResults,
  }
}

const mapActionsToProps = {
  searchRecipes: searchRecipesAction,
}

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(RecipePrint)
)
