import { List, Popper } from '@material-ui/core';
import styled from 'styled-components';

export const OptionsList = styled(List)`
  && {
    max-height: 40vh;
    position: relative;
    overflow: auto;
  }
`;

export const PopperList = styled(Popper)`
  && {
    z-index: 2;
  }
`;
