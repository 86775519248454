import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

const styles = theme => ({
  headerContainer: {
    borderTop: `6px solid ${theme.palette.primary.main}`,
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
  },
  headerTitle: {
    color: theme.palette.common.black,
    fontWeight: 'bolder',
    fontSize: 20,
    opacity: 0.8,
    padding: `${2 * theme.spacing.unit}px 0 ${2 * theme.spacing.unit}px ${
      theme.spacing.unit
    }px`,
  },
  actionButton: {
    color: theme.palette.common.white,
    opacity: 0.9,
    width: '48px',
    height: '48px',
  },
  expandClosed: {
    transform: 'rotate(270deg)',
  },
})

class TableHeader extends PureComponent {
  render() {
    const {
      title,
      classes,
      expandIcon,
      addIcon,
      handleAddClick,
      handleExpandClick,
      expanded,
    } = this.props
    return (
      <Grid
        container
        justify="space-between"
        direction="row"
        className={classes.headerContainer}
        alignItems="center"
      >
        <Grid item>
          <Grid
            container
            justify="flex-start"
            direction="row"
            alignItems="center"
          >
            {expandIcon && (
              <IconButton
                className={classNames(classes.actionButton, {
                  [classes.expandClosed]: !expanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
            <Typography className={classes.headerTitle}>{title}</Typography>
          </Grid>
        </Grid>
        {addIcon && (
          <IconButton className={classes.actionButton} onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        )}
      </Grid>
    )
  }
}

TableHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  expandIcon: PropTypes.bool,
  addIcon: PropTypes.bool,
  handleAddClick: PropTypes.func,
  handleExpandClick: PropTypes.func,
  expanded: PropTypes.bool,
  classes: PropTypes.object.isRequired,
}

TableHeader.defaultProps = {
  expandIcon: false,
  addIcon: false,
  handleAddClick: undefined,
  handleExpandClick: undefined,
  expanded: true,
}

export default withStyles(styles)(TableHeader)
