import { ALT_BLUE } from 'constants/ThemeColors'

export default theme => ({
  root: {
    width: '100%',
  },
  headerContainer: {
    borderTop: 0,
    backgroundColor: ALT_BLUE,
  },
  headerTitle: {
    color: theme.palette.common.white,
    fontSize: 16,
    opacity: 0.9,
  },
  processContianer: {
    paddingleft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
  },
  emptyProcesses: {
    marginTop: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    opacity: 0.5,
    cursor: 'pointer',
    textAlign: 'center',
  },
})
