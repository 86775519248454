import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from 'components/TextField'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Up from '@material-ui/icons/ExpandLess'
import Down from '@material-ui/icons/ExpandMore'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import Divider from '@material-ui/core/Divider'

import PureTextField from 'components/PureTextField'
import styles from './styles'

class Process extends Component {
  render() {
    const {
      process = {},
      first,
      last,
      onMoveUp,
      onMoveDown,
      onDelete,
      classes,
    } = this.props
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={24}
          className={classes.processContainer}
        >
          <Grid item xs={10}>
            <Grid container spacing={24} alignItems="flex-start">
              <Grid item xs={6}>
                <TextField
                  label="Tipo"
                  fullWidth
                  readOnly
                  value={process.type}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Etapa"
                  fullWidth
                  readOnly
                  value={process.step.name}
                />
              </Grid>
              <Grid item xs={12}>
                <FormHelperText>Descrição</FormHelperText>
                <PureTextField
                  margin="normal"
                  fullWidth
                  value={process.description}
                  variant="filled"
                  multiline
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    classes: { root: classes.inputRoot },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <IconButton disabled={first} onClick={onMoveUp}>
                      <Up />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled={last} onClick={onMoveDown}>
                      <Down />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <IconButton onClick={onDelete}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!last ? <Divider /> : null}
      </React.Fragment>
    )
  }
}

Process.propTypes = {
  process: PropTypes.object.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

Process.defaultProps = {
  first: false,
  last: false,
}

export default withStyles(styles)(Process)
