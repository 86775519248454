import styled from 'styled-components'
import { Grid } from '../../../../components/atoms/grid/'
import { Typography } from '../../../../components/atoms/typography/index'
import HeaderfulContainer from '../../../../components/molecules/headerfulContainer/index'
import { Lookup } from '../../../../components/molecules/lookup/'

export const SuppliersContainer = styled(HeaderfulContainer)``

export const SuppliersContainerInstruction = styled(Typography)`
  color: ${props => props.theme.v2.palette.grayScale[80]};
  margin-bottom: 16px;
`

export const SuppliersContainerSupplierType = styled(Typography)`
  color: ${props => props.theme.v2.palette.grayScale[80]};
`

export const SuppliersContainerGrid = styled(Grid)`
  && {
    width: 100%;
    margin: 0;
    padding: 8px 0 8x 0;
  }
`

export const SuppliersContainerSupplierLookup = styled(Lookup)``
