import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Form from 'components/Form'

const styles = theme => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
  },
  title: {
    marginTop: theme.spacing.unit,
  },
  itemsContainer: {
    padding: 3 * theme.spacing.unit,
    minWidth: '600px',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    width: '100%',
  },
  buttonText: { color: theme.palette.common.white },
  inputContainer: {
    position: 'relative',
  },
  input: {
    width: '100%',
    border: `1px solid #cccccc`,
    borderRadius: '4px',
    paddingLeft: '8px',
    height: `38px`,
    '&:hover': {
      borderColor: '#b3b3b3',
    },
  },
  placeHolderAndLabel: {
    position: 'absolute',
    fontFamily: `Roboto, sans-serif`,
    pointerEvents: 'none',
    transition: 'font-size 0.1s, top 0.1s',
    color: '#808080',
  },
  placeholder: {
    fontSize: '14px',
    left: '10px',
    top: '9px',
    opacity: 0.9,
  },
  label: {
    left: '6px',
    paddingLeft: '4px',
    paddingRight: '4px',
    backgroundColor: theme.palette.common.white,
    opacity: 1,
    top: '-9px',
    fontSize: '11px',
  },
  adornment: {
    position: 'absolute',
    fontFamily: `Roboto, sans-serif`,
    pointerEvents: 'none',
    color: '#000000',
    opacity: 0.8,
    top: '10px',
    left: '137px',
  },
})

class SelectModal extends Component {
  state = {
    type: '',
    selected: '',
    nameInputValue: '',
    quantityInputValue: '',
    focusedQuantityInput: false,
  }

  handleSelect = selected => {
    this.setState({ type: selected.type, selected: selected.value })
  }

  handleChoose = () => {
    const { handleConfirm, step, newRecipe } = this.props
    const { type, selected, quantityInputValue, nameInputValue } = this.state
    if (step === 1 && quantityInputValue !== '') {
      handleConfirm(selected)(type)(quantityInputValue)
    } else if (newRecipe && nameInputValue !== '') {
      handleConfirm(nameInputValue)
    } else if (!step && !newRecipe) {
      handleConfirm(selected)(type)
    }
  }

  formatPositiveRealsInput(currentValue, value) {
    if (value === '.') {
      return '0.'
    } else if (!isNaN(value)) {
      const valueArray = value.split('')
      if (!valueArray.some(char => char === 'e')) {
        if (value < 0) {
          return 0
        } else if (
          valueArray[0] === '0' &&
          valueArray[1] &&
          valueArray[1] !== '.' &&
          valueArray[1] !== ' '
        ) {
          return value.slice(1)
        } else if (value.endsWith(' ')) {
          return value.slice(0, value.length - 1)
        }
        return value
      }
    }
    return currentValue
  }

  handleChange = event => {
    const { value, id } = event.target

    this.setState(prevState => ({
      ...prevState,
      ...(id === 'quantityInput' && {
        quantityInputValue: this.formatPositiveRealsInput(
          prevState.quantityInputValue,
          value
        ),
      }),
      ...(id === 'nameInput' && {
        nameInputValue: value,
      }),
    }))
  }

  handleFocus = () => {
    this.setState(prevState => {
      if (prevState.quantityInputValue === '') {
        return {
          ...prevState,
          focusedQuantityInput: !prevState.focusedQuantityInput,
        }
      }
    })
  }

  render() {
    const {
      classes,
      label,
      title,
      items,
      type,
      buttonMessage,
      step,
      newRecipe,
      isLoading,
      itemsLoading,
    } = this.props

    return (
      <Paper className={classes.root}>
        <Form submitText="Adicionar Quantidade">
          <Grid container direction="column">
            <Grid item xs>
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  id="modal-title"
                  className={classes.title}
                >
                  <b>{title}</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={24}
                className={classes.itemsContainer}
              >
                <Grid item xs>
                  {newRecipe ? (
                    <input
                      placeholder="Nome da receita"
                      id="nameInput"
                      type="text"
                      onFocus={this.handleFocus}
                      onBlur={this.handleFocus}
                      className={classNames(classes.input)}
                      onChange={this.handleChange}
                      value={this.state.nameInputValue}
                    />
                  ) : itemsLoading ? (
                    <CircularProgress />
                  ) : (
                    <Select
                      placeholder={label}
                      options={
                        items
                          ?.map(item => ({ ...item, type }))
                          ?.sort((a, b) => (a.label > b.label ? 1 : -1)) || []
                      }
                      onChange={this.handleSelect}
                    />
                  )}
                </Grid>
                {step === 1 && (
                  <Grid item xs={4}>
                    <div className={classes.inputContainer}>
                      <input
                        id="quantityInput"
                        type="text"
                        onFocus={this.handleFocus}
                        onBlur={this.handleFocus}
                        className={classNames(classes.input)}
                        onChange={this.handleChange}
                        value={this.state.quantityInputValue}
                      />
                      <span
                        className={classNames(
                          classes.placeHolderAndLabel,
                          this.state.focusedQuantityInput
                            ? classes.label
                            : classes.placeholder
                        )}
                      >
                        Quantidade
                      </span>
                      <span className={classes.adornment}>g</span>
                    </div>
                  </Grid>
                )}
                <Grid item xs={3}>
                  {isLoading ? (
                    <CircularProgress size={50} />
                  ) : (
                    <Button
                      onClick={this.handleChoose}
                      className={classes.button}
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.buttonText}
                      >
                        {buttonMessage}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    )
  }
}
SelectModal.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  buttonMessage: PropTypes.object.isRequired,
  newRecipe: PropTypes.bool,
  step: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  itemsLoading: PropTypes.bool,
}

SelectModal.defaultProps = {
  newRecipe: false,
  isLoading: false,
  itemsLoading: false,
}

export default withStyles(styles)(SelectModal)
