import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'

export const celsiusAdornment = {
  endAdornment: (
    <InputAdornment position="end">
      <div style={{ fontSize: 16 }}>°C</div>
    </InputAdornment>
  ),
}

export const percentAdornment = {
  endAdornment: (
    <InputAdornment position="end">
      <div style={{ fontSize: 18 }}>%</div>
    </InputAdornment>
  ),
}

export const quantityGramsAdornment = {
  endAdornment: (
    <InputAdornment position="end">
      <div style={{ fontSize: 16 }}>g</div>
    </InputAdornment>
  ),
}

export const timeSecondsAdornment = {
  endAdornment: (
    <InputAdornment position="end">
      <div style={{ fontSize: 16 }}>seg</div>
    </InputAdornment>
  ),
}

export const priceAdornment = {
  startAdornment: (
    <InputAdornment position="start">
      <div style={{ fontSize: 16 }}>R$</div>
    </InputAdornment>
  ),
}
