import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import Paper from '@material-ui/core/Paper'
import TextField from 'components/TextField'
import TableHeader from 'components/TableHeader'
import IngredientsTable from '../../components/IngredientsTable'
import SubRecipesTable from '../../components/SubRecipesTable'
import { validatePositiveNumber } from '../../../../helperFunctions/validateInput'
import {
  subRecipeCostPerUnitMessage,
  subRecipePercentPriceMessage,
  subRecipeQuantityMessage,
  subRecipeYieldMessage,
} from '../formattedMessages'
import {
  percentAdornment,
  quantityGramsAdornment,
} from '../../../../components/InputAdornments'

const styles = theme => ({
  root: {},
  headerContainer: {
    borderTop: 0,
    backgroundColor: theme.palette.primary.main,
  },
  headerTitle: {
    color: theme.palette.common.white,
    fontSize: 16,
    opacity: 0.9,
  },
  recipeInfoContainer: {
    padding: '0 32px 0 32px',
  },
  priceInput: {
    padding: '20px 12px 17px 0',
  },
  inputAdornment: {
    marginBottom: '14px',
  },
  inputAdornmentFontSize: {
    fontSize: 16,
  },
  percentPriceInputAdornment: {
    marginTop: '4px',
  },
  percentPriceInputAdornmentFontSize: {
    fontSize: 18,
  },
  table: {
    overflow: 'hidden',
  },
})

class Recipe extends PureComponent {
  constructor(props) {
    super(props)
    this.headerClasses = {
      headerContainer: props.classes.headerContainer,
      headerTitle: props.classes.headerTitle,
    }
    this.priceInputProps = {
      startAdornment: (
        <InputAdornment
          position="start"
          className={props.classes.inputAdornment}
        >
          <div className={props.classes.inputAdornmentFontSize}>R$</div>
        </InputAdornment>
      ),
      classes: {
        input: props.classes.priceInput,
      },
    }
    this.percentPriceInputProps = {
      endAdornment: (
        <InputAdornment
          position="end"
          className={props.classes.percentPriceInputAdornment}
        >
          <div className={props.classes.percentPriceInputAdornmentFontSize}>
            %
          </div>
        </InputAdornment>
      ),
    }
  }

  handleChange = event => {
    const { handleChange } = this.props
    const { id, value } = event.target
    let validatedInput
    if (id.endsWith('name')) {
      validatedInput = value
    } else if (id.endsWith('subRecipeYield')) {
      validatedInput = validatePositiveNumber(value)
    } else {
      validatedInput = validatePositiveNumber(value)
    }
    if (validatedInput !== null) {
      handleChange(id)(validatedInput)
    }
  }

  render() {
    const {
      classes,
      selectedRecipe,
      path,
      handleChange,
      handleRemove,
      handleAddModalOpen,
      totalCost,
      batchSize,
    } = this.props
    const { ingredients, subRecipes, recipeTotalCost } = selectedRecipe
    const costPerUnit = recipeTotalCost / batchSize
    const percentCost = ((recipeTotalCost * 100) / totalCost).toFixed(2)
    return (
      <Grid className={classes.root} container direction="column" spacing={8}>
        {path !== '' && (
          <Grid item xs>
            <Paper elevation={1} className={classes.table}>
              <TableHeader
                title={selectedRecipe.name}
                classes={this.headerClasses}
              />
              <Grid
                container
                direction="row"
                spacing={8}
                className={classes.recipeInfoContainer}
              >
                <Grid item xs>
                  <TextField
                    label={subRecipeYieldMessage}
                    fullWidth
                    id={`${path}.recipeYield`}
                    value={selectedRecipe.recipeYield}
                    handleChange={this.handleChange}
                    inputProps={percentAdornment}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label={subRecipeQuantityMessage}
                    fullWidth
                    id={`${path}.quantity`}
                    value={selectedRecipe.quantity}
                    handleChange={this.handleChange}
                    inputProps={quantityGramsAdornment}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label={subRecipeCostPerUnitMessage}
                    fullWidth
                    id={`${path}.costPerUnit`}
                    value={
                      !isNaN(costPerUnit)
                        ? costPerUnit.toFixed(2)
                        : (0.0).toFixed(2)
                    }
                    readOnly
                    inputProps={this.priceInputProps}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label={subRecipePercentPriceMessage}
                    fullWidth
                    id={`${path}.percentPrice`}
                    value={!isNaN(percentCost) ? percentCost : (0.0).toFixed(2)}
                    readOnly
                    inputProps={this.percentPriceInputProps}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {ingredients && (
          <Grid item xs>
            <Paper elevation={1} className={classes.table}>
              <IngredientsTable
                handleRemove={handleRemove}
                recipeQuantity={selectedRecipe.quantity}
                batchSize={batchSize}
                totalCost={costPerUnit}
                ingredients={ingredients}
                handleChange={handleChange}
                path={path ? `${path}.ingredients` : 'ingredients'}
                handleAddModalOpen={handleAddModalOpen}
                simulation
              />
            </Paper>
          </Grid>
        )}
        {subRecipes && (
          <Grid item xs>
            <Paper elevation={1} className={classes.table}>
              <SubRecipesTable
                handleRemove={handleRemove}
                batchSize={batchSize}
                totalCost={costPerUnit}
                subRecipes={subRecipes}
                handleChange={handleChange}
                path={path ? `${path}.subRecipes` : 'subRecipes'}
                handleAddModalOpen={handleAddModalOpen}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    )
  }
}
Recipe.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedRecipe: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleAddModalOpen: PropTypes.func.isRequired,
  totalCost: PropTypes.number.isRequired,
  batchSize: PropTypes.number.isRequired,
}

export default withStyles(styles)(Recipe)
