import {
  FIXED_DRAWER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_DISABLED_NAV,
  TOGGLE_FULL_SCREEN,
  TOGGLE_DRAWER_TYPE,
  WINDOW_WIDTH,
} from 'constants/ActionTypes'

const initialSettings = {
  navCollapsed: false,
  navDisabled: false,
  fullScreen: false,
  drawerType: FIXED_DRAWER,
  width: $(window).width(),
  locale: {
    languageId: 'portuguese',
    locale: 'pt',
    name: 'Portuguese',
    icon: 'br',
  },
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      }
    case TOGGLE_DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType,
      }
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed,
      }
    case TOGGLE_DISABLED_NAV:
      return {
        ...state,
        navDisabled: action.isNavDisabled,
      }
    case TOGGLE_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.isFullScreen,
      }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      }
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      }
    default:
      return state
  }
}

export default settings
