import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Stars } from '@material-ui/icons'
import defaultTheme from '../../../../containers/themes/defaultTheme'
import {
  activeVersion,
  changeReason,
  changeResponsible,
} from '../formattedMessages'
import InputField from 'components/InputField'
import { withSnackbar } from 'notistack'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@material-ui/core'
import { extractSkuFromRecipeCode } from '../logic'

const styles = theme => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 3 * theme.spacing.unit,
  },
  bold: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: defaultTheme.palette.active,
  },
  icon: {
    marginLeft: theme.spacing.unit * 2,
  },
  formControl: {
    flexDirection: 'row',
  },
  skuLabel: {
    paddingTop: 20,
  },
})

class RecipeVersioningHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openConfirmation: false,
      sku: undefined,
    }
  }

  handleChange = (fieldName, field) => {
    const state = dotProp.set(this.state, fieldName, field)
    this.setState(state)
  }

  handleDelete = () => {
    if (!this.props.validateRecipeForDeletion()) {
      this.props.enqueueSnackbar(
        'Não é possível deletar uma sub receita ou uma receita que possui super-receitas.',
        {
          variant: 'error',
          horizontal: 'left',
          preventDuplicate: true,
        }
      )
      return this.setState({
        openConfirmation: false,
      })
    }
    if (this.props.onDeleteRecipe) {
      this.props.onDeleteRecipe()
    }
  }

  openConfirmationDialog = () => {
    this.setState({ openConfirmation: true })
  }

  closeConfirmationDialog = () => {
    this.setState({ openConfirmation: false })
  }

  render() {
    const { name, versionNumber, changingCommit } = this.props
    return (
      <Grid container spacing={16}>
        <Grid item xs={10}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              {name}
            </Typography>
            <Typography
              variant="body1"
              color="default"
              className={this.props.classes.bold}
              gutterBottom
            >
              {activeVersion(versionNumber)}{' '}
              <Stars
                className={this.props.classes.icon}
                style={{ color: defaultTheme.palette.active }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="primary">
              {changeReason(changingCommit.message)}
            </Typography>
            <Typography variant="body1" color="primary" gutterBottom>
              {changeResponsible(changingCommit.responsible)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.openConfirmationDialog}
            className="jr-btn text-blue-gray"
          >
            Excluir Receita
          </Button>
          <Dialog
            open={this.state.openConfirmation}
            onClose={this.closeConfirmationDialog}
          >
            <DialogTitle>{'Excluir Receita'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você tem certeza que deseja deletar essa receita?
              </DialogContentText>
              <FormControl className={this.props.classes.formControl}>
                <Grid item xs={7} className={this.props.classes.skuLabel}>
                  Escreve aqui o código do SKU:
                </Grid>
                <Grid item xs={5}>
                  <InputField
                    value={this.state.sku}
                    onChange={this.handleChange}
                    label="SKU"
                    fieldName="sku"
                  />
                </Grid>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={this.closeConfirmationDialog}
                className="jr-btn text-blue-gray"
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleDelete}
                className="jr-btn text-blue-gray"
                disabled={
                  extractSkuFromRecipeCode(this.props.sku) !== this.state.sku
                }
              >
                Sim, pode deletar.
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    )
  }
}

RecipeVersioningHeader.propTypes = {
  name: PropTypes.string,
  versionNumber: PropTypes.number,
  changingCommit: PropTypes.shape({
    message: '',
    responsible: '',
  }),
  deleteRecipe: PropTypes.func.isRequired,
  emitSnackbar: PropTypes.func.isRequired,
}

RecipeVersioningHeader.defaultProps = {
  name: '-',
  versionNumber: 0,
  changingCommit: PropTypes.shape({
    message: '-',
    responsible: '-',
  }),
}

export default withStyles(styles)(withSnackbar(RecipeVersioningHeader))
