import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { FormattedMessage } from 'react-intl'
import defineMessages from './Messages'
import ReceptionSearchContainer from './containers/ReceptionSearchContainer'

class ReceptionsPage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={<FormattedMessage {...defineMessages.searchReceptions} />}
        />
        <ReceptionSearchContainer />
      </div>
    )
  }
}

export default ReceptionsPage
