import {
  FETCH_PRODUCTION_DATA_REQUEST,
  FETCH_PRODUCTION_DATA_ERROR,
  FETCH_PRODUCTION_DATA_RESPONSE,
  FETCH_PRODUCTION_PLANNING_REQUEST,
  FETCH_PRODUCTION_PLANNING_RESPONSE,
  FETCH_PRODUCTION_PLANNING_ERROR,
  FETCH_PLANNING_HISTORIC_REQUEST,
  FETCH_PLANNING_HISTORIC_RESPONSE,
  FETCH_PLANNING_HISTORIC_ERROR,
  RUN_PPCP_REQUEST,
  RUN_PPCP_RESPONSE,
  RUN_PPCP_ERROR,
  FETCH_PPCP_TASK_STATUS_REQUEST,
  FETCH_PPCP_TASK_STATUS_RESPONSE,
  FETCH_PPCP_TASK_STATUS_ERROR,
} from '../constants/ActionTypes'
import { handleRequestError } from './ErrorHandling'
import { errorNotification } from './Notifications'
import { productionPlanning, planningHistoric } from '../../mock/mockApi'

const fetchProductionRecipeStarted = () => ({
  type: FETCH_PRODUCTION_DATA_REQUEST,
})

const fetchProductionRecipeResponse = payload => ({
  type: FETCH_PRODUCTION_DATA_RESPONSE,
  payload,
})

const fetchProductionRecipeError = error => ({
  type: FETCH_PRODUCTION_DATA_ERROR,
  payload: error,
})

export const fetchProductionRecipe = () => async (dispatch, getState, api) => {
  try {
    dispatch(fetchProductionRecipeStarted())
    // const response = mockAPI;
    const response = await api.get('/api/ppcp/production-recipes')
    const productionRecipes = response && response.data
    dispatch(fetchProductionRecipeResponse(productionRecipes))
  } catch (error) {
    dispatch(fetchProductionRecipeError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

const parseProductionLots = results =>
  Object.entries(results).map(([date, production]) =>
    production.reduce((acc, value) => {
      if (value.sku in acc) {
        return {
          ...acc,
          [value.sku]: [
            ...acc.sku,
            {
              name: value.name,
              date,
              units: value.units,
              lots: value.lots,
            },
          ],
        }
      }
      return {
        ...acc,
        [value.sku]: [
          { name: value.name, date, units: value.units, lots: value.lots },
        ],
      }
    }, {})
  )

function mergeProductionLots(production) {
  return production.reduce((acc, productionBySku) =>
    Object.entries(productionBySku).reduce(
      (innerAcc, [key, value]) => ({
        ...innerAcc,
        [key]: [...innerAcc[key], ...value],
      }),
      acc
    )
  )
}

const convertDate = dates =>
  dates.map(date => {
    const splittedDate = date.split('-')
    return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`
  })

const fetchPPCPResultsStarted = () => ({
  type: FETCH_PRODUCTION_PLANNING_REQUEST,
})

const fetchPPCPResultsResponse = payload => ({
  type: FETCH_PRODUCTION_PLANNING_RESPONSE,
  payload,
})

const fetchPPCPResultsError = error => ({
  type: FETCH_PRODUCTION_PLANNING_ERROR,
  payload: error,
})

export const fetchPPCPResults = ppcpId => async (dispatch, api) => {
  try {
    dispatch(fetchPPCPResultsStarted())
    // TODO: remove mock
    // const response = await api.get('url', {
    // 	ppcpId,
    // });
    const response = productionPlanning
    const production = response && response.productionLotsByDate
    const productionDates = response && convertDate(response.dates)
    const productionDatesBySku = parseProductionLots(production)
    const productionBySku = mergeProductionLots(productionDatesBySku)
    dispatch(fetchPPCPResultsResponse({ productionDates, productionBySku }))
  } catch (error) {
    dispatch(fetchPPCPResultsError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

const formatSelectedPeriod = period => {
  if (period.startDate && period.endDate) {
    return {
      from: period.startDate.format('YYYY-MM-DD'),
      until: period.endDate.format('YYYY-MM-DD'),
    }
  }
  return null
}

const fetchPPCPHistoricStarted = () => ({
  type: FETCH_PLANNING_HISTORIC_REQUEST,
})

const fetchPPCPHistoricResponse = payload => ({
  type: FETCH_PLANNING_HISTORIC_RESPONSE,
  payload,
})

const fetchPPCPHistoricError = error => ({
  type: FETCH_PLANNING_HISTORIC_ERROR,
  payload: error,
})

export const fetchPPCPHistoric = period => async (dispatch, api) => {
  try {
    dispatch(fetchPPCPHistoricStarted())
    const selectedPeriod = formatSelectedPeriod(period)
    // TODO: remove mock
    // const response = await api.get('url', {
    // 	selectedPeriod,
    // });
    const response = planningHistoric
    dispatch(fetchPPCPHistoricResponse({ response }))
  } catch (error) {
    dispatch(fetchPPCPHistoricError(error))
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

export const runPPCP = dates => async (dispatch, getState, api) => {
  dispatch({
    type: RUN_PPCP_REQUEST,
    dates,
  })

  try {
    const response = await api.post('/api/ppcp/run-ppcp/async', {
      date: {
        from: dates.startDate,
        until: dates.endDate,
      },
    })

    dispatch({
      type: RUN_PPCP_RESPONSE,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: RUN_PPCP_ERROR,
      payload: { error },
    })
    dispatch(handleRequestError(error))
    dispatch(errorNotification(error))
  }
}

export const fetchPPCPTaskStatus =
  taskId => async (dispatch, getState, api) => {
    dispatch({
      type: FETCH_PPCP_TASK_STATUS_REQUEST,
      taskId,
    })

    try {
      const response = await api.get(`/api/ppcp/tasks/${taskId}`)

      dispatch({
        type: FETCH_PPCP_TASK_STATUS_RESPONSE,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: FETCH_PPCP_TASK_STATUS_ERROR,
        error,
      })
      dispatch(handleRequestError(error))
    }
  }
