import React, { useCallback, useState, useEffect } from 'react'
import { Button } from '../../../../components/atoms/button'
import { Grid } from '../../../../components/atoms/grid'
import Input from '../../../../components/atoms/input'
import { Paper } from '../../../../components/atoms/paper'
import defineMessages from '../../../messages'
import { Radio } from '../../../../components/atoms/radio'
import { IKitchen } from '../../../../contracts/kitchens'

export interface FeedstockSearchPanelProps {
  onFilterSubmit: (nameOrCode: string, selectedKitchen?: string) => void
  kitchens?: IKitchen[]
}

export const FeedstockSearchPanel = ({
  ...props
}: FeedstockSearchPanelProps) => {
  const { kitchens } = props
  let kitchenLabels = kitchens && kitchens.map(kitchen => kitchen.name)
  const [nameOrCode, setNameOrCode] = useState(null)
  const [selectedKitchen, setSelectedKitchen] = useState(null)

  const handleNameOrCodeInput = useCallback((event: any) => {
    setNameOrCode(event.target.value)
  }, [])
  const handleFilterSubmit = () => {
    props.onFilterSubmit(nameOrCode, selectedKitchen)
  }
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      props.onFilterSubmit(nameOrCode, selectedKitchen)
    }
  }

  const handleSelectedKitchen = (index: number) => {
    setSelectedKitchen(kitchenLabels[index])
  }
  useEffect(() => {
    kitchenLabels =
      kitchens && kitchens.length > 0 && kitchens.map(kitchen => kitchen.name)
    if (kitchenLabels) {
      handleSelectedKitchen(0)
    }
  }, [kitchens])

  return (
    <Paper>
      <Grid justify="center" container direction="row" gutter="large">
        <Grid item xs={4}>
          <Input
            type=""
            label={defineMessages.filterPlaceholder.defaultMessage}
            onChange={handleNameOrCodeInput}
            disabled={false}
            required={false}
            fullWidth
            value={nameOrCode}
            onKeyPress={handleKeyPress}
          />
        </Grid>
      </Grid>
      {kitchens && (
        <Grid container direction="row" gutter="large" justify="flex-start">
          <Grid item>
            <div>Selecione a cozinha:</div>
            <Radio
              labels={kitchenLabels}
              direction="row"
              selected={selectedKitchen}
              onSelect={handleSelectedKitchen}
            />
          </Grid>
        </Grid>
      )}
      <Grid container direction="row" gutter="large" justify="center">
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={handleFilterSubmit}
          >
            {defineMessages.filterButtonTitle.defaultMessage}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
