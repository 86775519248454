import { useRef } from 'react'

export default function useDebounce(
  fn: (...args: any) => void,
  delay: number = 300
) {
  const timeoutRef = useRef<NodeJS.Timeout>()

  function debauncedFn(...args: any) {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => fn(args), delay)
  }

  return debauncedFn
}
