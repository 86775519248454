import React, { useState } from 'react'

import Button from '@material-ui/core/Button'

import { Recipe, RecipeActions, RecipeInfo, RecipeName } from './styles'
import Input from 'src/v2/components/atoms/input'

function RecipeItem(props) {
  const { recipe, onAdd } = props

  const [selected, setSelected] = useState(false)
  const [quantity, setQuantity] = useState('')

  function handleSelect() {
    setSelected(true)
  }

  function handleCancel() {
    setSelected(false)
    setQuantity('')
  }

  function handleQuantity(e) {
    setQuantity(e.target.value)
  }

  function handleAdd() {
    onAdd &&
      onAdd({
        id: recipe.id,
        code: recipe.code,
        name: recipe.name,
        quantity,
      })
  }

  const quantityAsNumber = Number(quantity)
  const isValid = quantityAsNumber && quantityAsNumber > 0

  return (
    <Recipe>
      <RecipeInfo>
        <RecipeName onClick={handleSelect}>
          {recipe.code} - {recipe.name}
        </RecipeName>
        {selected ? (
          <Input label="Saquinhos" onChange={handleQuantity} type="number" />
        ) : null}
      </RecipeInfo>
      {selected ? (
        <RecipeActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            onClick={handleAdd}
            disabled={!isValid}
            variant="contained"
            color="primary"
          >
            Adicionar
          </Button>
        </RecipeActions>
      ) : null}
    </Recipe>
  )
}

export default RecipeItem
