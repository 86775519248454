import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import CardBox from 'components/CardBox'
import { withStyles } from '@material-ui/core/styles'
import ResultsTable from 'components/ResultsTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorBox from 'components/ErrorBox'
import { fetchPPCPHistoric } from 'actions/PPCP'
import styles from './styles'
import DateSelector from '../../components/DateSelector/index'
import { historicTableHeader, searchMessage } from '../../../formattedMessages'

class PpcpHistoricContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
    }
  }

  componentDidMount() {
    this.props.fetchPPCPHistoric({})
  }

  getProductionFieldsPath = () => ['date', 'beginningDate', 'endDate', 'id']
  handleDatesChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate })

  searchPpcpHistoric = () => {
    const { startDate, endDate } = this.state
    this.props.fetchPPCPHistoric({ startDate, endDate })
  }

  goToPpcpResultsPage = selectedVersion => {
    // const { id } = selectedVersion.id;
    const id = 'PPCP001'
    this.props.history.push({
      pathname: '/app/ppcp/results',
      id,
    })
  }

  renderPlanningHistoric() {
    const { historic, planningHistoric, classes } = this.props
    if (historic.isLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (historic.error) {
      return <ErrorBox message={historic.error} />
    }
    if (historic.data) {
      return (
        <Fragment>
          <DateSelector
            onDatesChange={this.handleDatesChange}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            title={searchMessage}
            onSearchClick={this.searchPpcpHistoric}
          />
          <Grid container justify="center">
            <Grid item xs={9} className={classes.table}>
              <ResultsTable
                goToEditPage={this.goToPpcpResultsPage}
                tableHeader={historicTableHeader}
                results={planningHistoric}
                fieldsPath={this.getProductionFieldsPath()}
                isStickyHeader
              />
            </Grid>
          </Grid>
        </Fragment>
      )
    }
  }
  render() {
    return (
      <CardBox styleName="col-sm-12">{this.renderPlanningHistoric()}</CardBox>
    )
  }
}

const mapActionsToProps = {
  fetchPPCPHistoric,
}

const mapStateToProps = state => ({
  historic: state.production.historic,
  planningHistoric: state.production.historic.data,
  isLoading: state.production.historic.loading,
})

PpcpHistoricContainer.propTypes = {
  historic: PropTypes.object,
  planningHistoric: PropTypes.array,
  fetchPPCPHistoric: PropTypes.func.isRequired,
}

PpcpHistoricContainer.defaultProps = {
  historic: {},
  planningHistoric: [],
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(PpcpHistoricContainer))
)
