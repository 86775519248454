import { useSnackbar } from 'notistack'
import { ChangeEvent, useEffect, useState } from 'react'
import moment from 'moment'

import { useAPI } from '../../../api'
import { PrintSettings, Product, ProductTag } from './interfaces'
import { PRODUCT_TAG_QUERY } from './constants'

export function usePrintSettings({
  initialValue,
}: {
  initialValue: PrintSettings
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [fetch] = useAPI()

  const [searchFieldValue, setSearchFieldValue] = useState('')
  const [printSettings, setPrintSettings] = useState(initialValue)

  function handleSelectProduct(selectedProduct: Product) {
    setSearchFieldValue(`${selectedProduct.sku} - ${selectedProduct.name}`)
    setPrintSettings({
      ...printSettings,
      sku: selectedProduct.sku,
      name: selectedProduct.name,
    })
  }

  function handleDateChange(event: ChangeEvent<HTMLInputElement>) {
    const choosenDate = moment(event.target.value, 'YYYY-MM-DD').toDate()
    setPrintSettings({ ...printSettings, manufacturing: choosenDate })
  }

  function handleQuantityChange(event: ChangeEvent<HTMLInputElement>) {
    setPrintSettings({
      ...printSettings,
      quantity: Math.max(Number(event.target.value) || 1, 1),
    })
  }

  function isPrintSettingsValid() {
    if (!printSettings.sku) {
      enqueueSnackbar(`Opa, parece que não há um produto selecionado!`, {
        variant: 'error',
      })
      return false
    }
    if (!printSettings.manufacturing) {
      enqueueSnackbar(`Opa, parece que o campo data está incompleto!`, {
        variant: 'error',
      })
      return false
    }
    if (printSettings.quantity <= 0) {
      enqueueSnackbar(
        `Selecione a quantidade de etiquetas que deseja imprimir!`,
        {
          variant: 'error',
        }
      )
      return false
    }
    return true
  }

  function getTagDetails() {
    if (!printSettings.sku) {
      return
    }

    fetch({
      baseUrl: process.env.KITCHEN_SERVICE_URL,
      uri: '/graphql',
      method: 'POST',
      body: {
        query: PRODUCT_TAG_QUERY,
        operationName: 'ProductTag',
        variables: { sku: printSettings.sku },
      },
    })
      .then(response => {
        const productTag: ProductTag = response?.data?.productTag
        if (!productTag) {
          return
        }
        setPrintSettings({
          ...printSettings,
          allergenics: productTag.nutritionalTable.allergenics,
          expiration: productTag.expiration,
          ingredients: productTag.nutritionalTable.ingredients,
          mayContain: productTag.nutritionalTable.mayContain,
          ready: true,
          storageType: productTag.storage.type,
        })
      })
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    setPrintSettings({
      ...printSettings,
      allergenics: '',
      ingredients: '',
      expiration: 0,
      storageType: null,
      ready: false,
    })
    getTagDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printSettings.sku])

  return {
    handleDateChange,
    handleQuantityChange,
    handleSelectProduct,
    isPrintSettingsValid,
    printSettings,
    searchFieldValue,
    setSearchFieldValue,
  }
}
