const HOUR = 3600
const MINUTE = 60
const SECOND = 1

export const COOKING_TIME_LIMIT_IN_SECONDS =
  23 * HOUR + 59 * MINUTE + 59 * SECOND

export const REQUIRED_COOKING_TIMES_QUANTITY = 1
export const ALLOWED_COOKING_TIMES_QUANTITY = 2

export const FACTS_ALIASES = {
  'addedSugars': 'Aç Adicionados',
}
