export default () => ({
  delete: {
    alignSelf: 'center',
  },
  fab: {
    maxWidth: '40px',
    maxHeight: '40px',
    marginTop: '15px',
  },
  icon: {
    fontSize: '16px',
  },
})
