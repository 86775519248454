import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableHeader from '../../../../components/TableHeader'
import TextField from '../../../../components/TextField'
import {
  recipeInfoMessage,
  recipeNameMessage,
  recipeYieldMessage,
  recipeWasteMessage,
  portionSizeMessage,
  batchSizeMessage,
  referenceGrammageMessage,
} from '../formattedMessages/'
import {
  percentAdornment,
  quantityGramsAdornment,
} from '../../../../components/InputAdornments'
import { validatePositiveNumber } from '../../../../helperFunctions/validateInput'

const styles = theme => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 3 * theme.spacing.unit,
  },
  infoFieldsContainer: {
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
  },
})

class RecipeInfo extends PureComponent {
  handleChange = event => {
    const { handleChange } = this.props
    const { id, value } = event.target
    let validatedInput
    if (id.endsWith('name')) {
      validatedInput = value
    } else if (id.endsWith('recipeYield') || id.endsWith('recipeWaste')) {
      validatedInput = validatePositiveNumber(value)
    } else if (id.endsWith('portionSize') || id.endsWith('referenceGrammage')) {
      validatedInput = validatePositiveNumber(value, { integer: true })
    }
    if (validatedInput !== null) {
      handleChange(id)(validatedInput)
    }
  }

  render() {
    const {
      name,
      recipeYield,
      recipeWaste,
      portionSize,
      referenceGrammage,
      batchSize,
      classes,
    } = this.props

    return (
      <Paper className={classes.root} elevation={1}>
        <TableHeader title={recipeInfoMessage} />
        <Grid
          container
          direction="row"
          spacing={8}
          className={classes.infoFieldsContainer}
        >
          <Grid item xs={4}>
            <TextField
              label={recipeNameMessage}
              id="name"
              fullWidth
              value={name}
              readOnly
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label={recipeYieldMessage}
              fullWidth
              id="recipeYield"
              value={recipeYield}
              handleChange={this.handleChange}
              inputProps={percentAdornment}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label={recipeWasteMessage}
              fullWidth
              id="recipeWaste"
              value={recipeWaste}
              handleChange={this.handleChange}
              inputProps={percentAdornment}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label={portionSizeMessage}
              hint={portionSizeMessage.props.description}
              fullWidth
              id="portionSize"
              value={portionSize}
              handleChange={this.handleChange}
              inputProps={quantityGramsAdornment}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label={referenceGrammageMessage}
              hint={referenceGrammageMessage.props.description}
              fullWidth
              id="referenceGrammage"
              type="number"
              value={referenceGrammage}
              handleChange={this.handleChange}
              inputProps={quantityGramsAdornment}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label={batchSizeMessage}
              fullWidth
              id="batchSize"
              value={batchSize}
              readOnly
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
RecipeInfo.propTypes = {
  name: PropTypes.string.isRequired,
  recipeYield: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  recipeWaste: PropTypes.number.isRequired,
  portionSize: PropTypes.number.isRequired,
  referenceGrammage: PropTypes.number.isRequired,
  batchSize: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(RecipeInfo)
