import { isEmptyOrNil } from "../../../utils/isEmptyOrNil"
import { SaveIngredient } from "../../../v2/api/dto/ingredients"

export function emptyIngredientValidations(ingredient: SaveIngredient): string[] {
    const errors = []
    if (isEmptyOrNil(ingredient.recipeName)) {
        errors.push('Informe um nome para receita.')
    }

    if (isEmptyOrNil(ingredient.tagName)) {
        errors.push('Informe o nome na etiqueta do ingrediente.')
    }

    if (isEmptyOrNil(ingredient.waste)) {
        errors.push('Informe o desperdício para o ingrediente.')
    }

    if (isEmptyOrNil(ingredient.processingYield)) {
        errors.push('Informe o rendimento para o ingrediente.')
    }

    if (isEmptyOrNil(ingredient.kitchens)) {
        errors.push('Selecione uma ou mais cozinhas para o ingrediente.')
    }

    if (isEmptyOrNil(ingredient.feedstock?.id)) {
        errors.push('Selecione a matéria prima do ingrediente.')
    }

    return errors
}


export function ingredientValidations(ingredient: SaveIngredient): string[] {
    const errors = emptyIngredientValidations(ingredient)

    if (isNaN(Number(ingredient.waste))) {
        errors.push('Informe um valor válido para o desperdício do ingrediente.')
    }

    if (ingredient.waste < 0 || ingredient.waste > 100) {
        errors.push('Informe um valor para o desperdício ente 0% e 100%.')
    }

    if (isNaN(Number(ingredient.processingYield))) {
        errors.push('Informe um valor válido para o rendimento do ingrediente.')
    }

    return errors
}
