import { REUSED_WASTE_INGREDIENTS } from '../constants/NutritionalFacts'

export const calculateIngredientTotalCost = ({
  quantity,
  code,
  price,
  waste,
}) =>
  ((Number(quantity) /
    (REUSED_WASTE_INGREDIENTS.includes(code) ? 1 : 1 - waste / 100)) *
    price) /
  1000 // price/kg
