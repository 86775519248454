import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  ActionContainer,
  ActionsContainer,
  Edit,
  Info,
  KindContainer,
  Name,
  StyledEditIcon,
} from './styles'

interface ClosedCardComponentProps {
  code: string
  name: string
  kind?: string
  isLoading?: boolean
  handleEdit: (e: any) => void
}

export function ClosedCardComponent({
  code,
  name,
  kind,
  isLoading,
  handleEdit,
}: ClosedCardComponentProps) {
  return (
    <>
      <Name>
        {code} - {name}
      </Name>
      <KindContainer>
        <Info>{kind || ''}</Info>
      </KindContainer>
      <ActionsContainer>
        {
          {
            true: <CircularProgress size={30} />,
            false: (
              <ActionContainer onClick={handleEdit}>
                <Edit>Editar</Edit>
                <StyledEditIcon />
              </ActionContainer>
            ),
          }[String(isLoading)]
        }
      </ActionsContainer>
    </>
  )
}
