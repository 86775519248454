import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withConsumer } from 'helperFunctions/withConsumer'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import styles from './styles'
import { FormContext } from '../Form'

class DefaultSubmit extends Component {
  handleSubmit = () => {
    const { validateAll, onSubmit } = this.props
    if (validateAll() && onSubmit) {
      onSubmit()
    }
  }

  renderErrorMessage() {
    const { errors } = this.props
    if (errors.length === 0) {
      return null
    }
    return (
      <Typography variant="body1" color="error">
        <FormattedMessage
          id="app.form.defaultSubmit.error"
          defaultMessage="Corrija os erros para continuar"
        />
      </Typography>
    )
  }

  render() {
    const { submitText, classes, fullWidth, disabled } = this.props
    return (
      <Grid container>
        <Grid item>{this.renderErrorMessage()}</Grid>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          className={(fullWidth && classes.fullWidth) || undefined}
        >
          {submitText}
        </Button>
      </Grid>
    )
  }
}

DefaultSubmit.propTypes = {
  submitText: PropTypes.string.isRequired,
  errors: PropTypes.array,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  validateAll: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

DefaultSubmit.defaultProps = {
  errors: [],
  onSubmit: null,
  fullWidth: false,
  disabled: false,
}

export default withConsumer(FormContext)(withStyles(styles)(DefaultSubmit))
