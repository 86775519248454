import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import { resetNotification } from 'actions/Notifications'
import { BREAKPOINT_MOBILE } from 'constants/Layout'
import { STYLE_DESKTOP, STYLE_MOBILE } from './notificationStyle'

import SUCCESS_SOUND from '../../../public/sounds/beep.mp3'
import ERROR_SOUND from '../../../public/sounds/error.mp3'

const DEFAULT_AUTO_DISMISS = 5

const playSound = level => {
  if (level === 'success') {
    new Audio(SUCCESS_SOUND).play()
  } else if (level === 'error') {
    new Audio(ERROR_SOUND).play()
  }
}

const getIcon = level => {
  switch (level) {
    case 'info':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          style={{ transform: 'translate(50%, -32%)' }}
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M10.324 0h12.932c2.754 0 5.39 1.114 7.307 3.09a10.268 10.268 0 0 1 3.09 7.308v12.907c-.05 5.719-4.678 10.34-10.397 10.38H10.324a10.385 10.385 0 0 1-7.307-3.091A10.157 10.157 0 0 1 0 23.305V10.373a10.225 10.225 0 0 1 3.017-7.282A10.355 10.355 0 0 1 10.324 0zm21.47 8.333a48.657 48.657 0 0 0-14.485.816h-.618C12.14 8.024 5.749 7.913 2.02 8.333l-.358.563A9.056 9.056 0 0 1 4.136 4.19a8.723 8.723 0 0 1 6.181-2.553h12.939a8.735 8.735 0 0 1 6.182 2.553 9.093 9.093 0 0 1 2.355 4.142zm-18.355 8.432c1.397-.562 1.892-2.3 1.892-2.3s.618-1.434 1.076-2.812c0-.155.309-.31.457-.458.155.149.464.149.464.458a30.568 30.568 0 0 0 1.125 2.812s.618 1.738 1.854 2.3c1.434.662 2.658.662 4.705.662a18.966 18.966 0 0 0 3.937-.458s1.342-.278 1.96-2.658c.618-2.38.816-2.967.816-2.967.053-.155.142-.295.26-.408v12.363a8.729 8.729 0 0 1-8.729 8.748H10.324a8.729 8.729 0 0 1-8.741-8.742V10.942c.12.12.225.258.309.408.383.963.691 1.956.92 2.967.619 2.35 2.04 2.658 2.04 2.658 1.277.288 2.58.441 3.89.458a12.667 12.667 0 0 0 4.697-.662v-.006zm-3.709 4.6v1.279c3.375 3.37 10.781 3.37 14.218 0v-1.28a10.67 10.67 0 0 1-7.133 2.553c-2.6.042-5.125-.866-7.103-2.553h.018z"
          />
        </svg>
      )
    case 'success':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          style={{ transform: 'translate(50%, -32%)' }}
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M19.627 13.34l.921.878 2.659-2.658 2.707 2.658.921-.871-3.628-3.63-3.58 3.623zm-5.675 0l-3.628-3.628-3.58 3.628.872.878 2.708-2.658 2.707 2.658.921-.878zm2.863 9.273c3.832 0 5.773-1.28 5.773-1.28a6.021 6.021 0 0 1-5.773 3.883 6.046 6.046 0 0 1-5.824-3.883s1.941 1.28 5.824 1.28zm6.441-21.018H10.324a8.55 8.55 0 0 0-6.182 2.609 8.543 8.543 0 0 0-2.56 6.181v12.92a8.716 8.716 0 0 0 2.56 6.182 8.939 8.939 0 0 0 6.182 2.553h12.932a8.945 8.945 0 0 0 6.182-2.553 8.716 8.716 0 0 0 2.56-6.182V10.373a8.543 8.543 0 0 0-2.56-6.182 8.556 8.556 0 0 0-6.182-2.608v.012zM10.324 0h12.932c5.707.054 10.32 4.667 10.373 10.373v12.932c-.027 5.71-4.663 10.324-10.373 10.324H10.324a10.453 10.453 0 0 1-7.307-3.01A10.324 10.324 0 0 1 0 23.305V10.373a10.138 10.138 0 0 1 3.017-7.282A10.268 10.268 0 0 1 10.324 0z"
          />
        </svg>
      )
    case 'warning':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          style={{ transform: 'translate(50%, -32%)' }}
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M22.838 11.11a1.294 1.294 0 1 0 1.293 1.288 1.25 1.25 0 0 0-1.293-1.288zm-3.925 12.398A1.95 1.95 0 0 0 17 21.546a1.994 1.994 0 0 0-1.962 1.962A2.031 2.031 0 0 0 17 25.47a1.987 1.987 0 0 0 1.913-1.962zm1.343 0a3.256 3.256 0 1 1-6.512 0 3.256 3.256 0 0 1 6.512 0zm-7.8-11.11a1.325 1.325 0 0 1-1.343 1.293 1.294 1.294 0 1 1 0-2.58 1.325 1.325 0 0 1 1.343 1.287zM23.513 1.6H10.438A8.832 8.832 0 0 0 1.6 10.436v13.072a8.823 8.823 0 0 0 8.838 8.836h13.075a8.826 8.826 0 0 0 8.837-8.836V10.436A8.829 8.829 0 0 0 23.513 1.6zM10.438 0h13.075C29.283.007 33.966 4.667 34 10.436v13.072C33.93 29.271 29.276 33.926 23.513 34H10.438a10.476 10.476 0 0 1-7.388-3.124A10.241 10.241 0 0 1 0 23.508V10.436a10.422 10.422 0 0 1 3.05-7.387A10.626 10.626 0 0 1 10.438 0z"
          />
        </svg>
      )
    case 'error':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          style={{ transform: 'translate(50%, -32%)' }}
        >
          <path
            fill="#FFF"
            fillRule="evenodd"
            d="M9.761 23.868v1.28a10.51 10.51 0 0 1 7.103-2.56c2.587-.05 5.1.863 7.053 2.56v-1.28a10.46 10.46 0 0 0-7.053-2.553 10.51 10.51 0 0 0-7.103 2.553zm12.877-8.995a1.28 1.28 0 1 0-1.274-1.273 1.236 1.236 0 0 0 1.274 1.28v-.007zM9.76 13.6a1.28 1.28 0 1 1 1.28 1.28 1.236 1.236 0 0 1-1.28-1.28zM23.305 1.583H10.373a8.735 8.735 0 0 0-8.741 8.74v12.933a8.655 8.655 0 0 0 2.56 6.182 8.76 8.76 0 0 0 6.181 2.609h12.932a8.747 8.747 0 0 0 6.182-2.61 8.617 8.617 0 0 0 2.56-6.181V10.324a8.735 8.735 0 0 0-8.742-8.741zM10.373 0h12.932a10.423 10.423 0 0 1 7.307 3.017 10.311 10.311 0 0 1 3.017 7.307v12.932a10.132 10.132 0 0 1-3.017 7.307 10.256 10.256 0 0 1-7.307 3.09H10.373a10.181 10.181 0 0 1-7.282-3.09A10.175 10.175 0 0 1 0 23.256V10.324C.034 4.617 4.666.007 10.373 0z"
          />
        </svg>
      )
    default:
      return null
  }
}

class NotificationCenter extends React.Component {
  constructor(props) {
    super(props)
    this.notificationSystem = null
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type && nextProps.type !== '') {
      this.addNotification(
        nextProps.type,
        nextProps.message,
        nextProps.autoDismiss,
        nextProps.soundOff
      )
      this.props.resetNotification()
    }
  }

  addNotification(level, message, autoDismiss, soundOff) {
    if (!soundOff) {
      playSound(level)
    }
    if (message && message !== '') {
      this.notificationSystem.addNotification({
        autoDismiss: autoDismiss || DEFAULT_AUTO_DISMISS,
        message,
        level,
        position: 'tc',
        children: getIcon(level),
      })
    }
  }

  render() {
    let style = STYLE_DESKTOP
    if (window.innerWidth <= BREAKPOINT_MOBILE) {
      style = STYLE_MOBILE
    }
    return (
      <NotificationSystem
        ref={ref => {
          this.notificationSystem = ref
        }}
        style={style}
      />
    )
  }
}

NotificationCenter.propTypes = {
  // States:
  message: PropTypes.string,
  type: PropTypes.string,
  autoDismiss: PropTypes.number,
  soundOff: PropTypes.bool,
  // Actions
  resetNotification: PropTypes.func.isRequired,
}

NotificationCenter.defaultProps = {
  // States:
  message: null,
  type: null,
  autoDismiss: null,
  soundOff: null,
}

function mapStateToProps(state) {
  return {
    type: state.notificationCenter.type,
    message: state.notificationCenter.message,
    autoDismiss: state.notificationCenter.autoDismiss,
    soundOff: state.notificationCenter.soundOff,
  }
}

export default connect(mapStateToProps, { resetNotification })(
  NotificationCenter
)
