import Home from './Home'
import ReceptionsSearch from './Receptions/Search'
import Recipes from './Recipes'
import RecipeCreate from './Recipes/Create'
import RecipeVersioningHistoric from './Recipes/VersioningHistoric'
import RecipeSearch from './Recipes/Search'
import RecipeEdit from './Recipes/Edit'
import ResourcesSearch from './Resources/Search'
import ResourcesRegister from './Resources/Register'
import PPCPInput from './PPCP/Input'
import PPCPResults from './PPCP/Results'
import PPCPHistoric from './PPCP/Historic'
import ProductionRecipeRegister from './ProductionRecipe/Register'
import ProductionRecipeSearch from './ProductionRecipe/Search'
import ProductionGroupsRegister from './ProductionGroups/Register'
import RecipePrint from './Recipes/Print'
import RecipeSimulate from './Recipes/Simulate'

export default [
  {
    path: '',
    component: Home,
    restricted: false,
  },
  {
    path: 'receptions',
    restricted: false,
    children: [
      {
        path: 'search',
        component: ReceptionsSearch,
        restricted: false,
      },
    ],
  },
  {
    path: 'recipes',
    component: Recipes,
    restricted: false,
    children: [
      {
        path: 'create',
        component: RecipeCreate,
      },
      /**
       * To use routes from v2 uncomment this next line and remove route config from old simulation
       */
      // simulationRoutes,
      {
        path: 'simulate',
        component: RecipeSimulate,
      },
      {
        path: 'historic',
        component: RecipeVersioningHistoric,
        params: [
          {
            name: 'code',
            optional: false,
          },
        ],
      },
      {
        path: 'search',
        component: RecipeSearch,
      },
      {
        path: 'edit',
        component: RecipeEdit,
        params: [
          {
            name: 'code',
            optional: false,
          },
        ],
      },
      {
        path: 'print',
        component: RecipePrint,
      },
    ],
  },
  {
    path: 'resources',
    restricted: false,
    children: [
      {
        path: 'search',
        component: ResourcesSearch,
        restricted: false,
      },
      {
        path: 'register',
        component: ResourcesRegister,
        restricted: false,
      },
    ],
  },
  {
    path: 'ppcp',
    restricted: false,
    children: [
      {
        path: 'input',
        component: PPCPInput,
        restricted: false,
      },
      {
        path: 'results',
        component: PPCPResults,
        restricted: false,
      },
      {
        path: 'historic',
        component: PPCPHistoric,
        restricted: false,
      },
    ],
  },
  {
    path: 'productionRecipe',
    restricted: false,
    children: [
      {
        path: 'register',
        component: ProductionRecipeRegister,
        restricted: false,
      },
      {
        path: 'search',
        component: ProductionRecipeSearch,
        restricted: false,
      },
      {
        path: 'edit',
        component: ProductionRecipeRegister,
        restricted: false,
        params: [
          {
            name: 'sku',
            optional: false,
          },
        ],
      },
    ],
  },
  {
    path: 'productionGroups',
    restricted: false,
    children: [
      {
        path: 'register',
        component: ProductionGroupsRegister,
        restricted: false,
      },
    ],
  },
]
