function validateBarcodeDVMod10(barcodeDigits: number[], barcodeDV: number) {
  const digitSum = barcodeDigits.reduce(
    (acc, value, index) => {
      acc[(index + 1) % 2] += value
      return acc
    },
    [0, 0]
  )

  const calculatedDV =
    digitSum.reduce(
      (total, value, index) => total + value * (index === 0 ? 3 : 1),
      0
    ) % 10

  const barcodeCalculatedDV =
    calculatedDV === 0 ? calculatedDV : 10 - calculatedDV

  return barcodeDV === barcodeCalculatedDV
}

export function validateBarcode(value: string, length: number) {
  if (!length || length < 2 || !Number.isInteger(length)) {
    throw Error(
      'Invalid paramaeter: "length" must be a positive integer greater than 1.'
    )
  }
  if (!value) {
    return false
  }

  // eslint-disable-next-line security/detect-non-literal-regexp
  const barcodeParts = new RegExp(`^([0-9]{${length - 1}})([0-9])$`, 'g').exec(
    value
  )
  console.log(barcodeParts)
  if (!barcodeParts) {
    return false
  }

  const barcodeDigits = barcodeParts[1].split('').map(digit => Number(digit))
  const barcodeOriginalDV = Number(barcodeParts[2])

  return validateBarcodeDVMod10(barcodeDigits, barcodeOriginalDV)
}
