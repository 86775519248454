import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { ppcpHistoric } from '../formattedMessages'
import PpcpHistoricContainer from './containers/PpcpHistoric/index'

class PpcpHistoric extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader location={this.props.location} title={ppcpHistoric} />
        <PpcpHistoricContainer />
      </div>
    )
  }
}

export default PpcpHistoric
