import { Button, Modal as MuiModal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { ReactNode } from 'react'
import { Grid } from '../../atoms'
import {
  Divider,
  ModalActions,
  ModalContainer,
  ModalContent,
  StyledModalIconButton,
} from './styles'

export interface ModalAction {
  type: 'success' | 'neutral'
  text: string
  onClick: (event: any) => void
}

export interface ModalProps {
  open: boolean
  actions: ModalAction[]
  onClose?: React.ReactEventHandler<{}>
  children: ReactNode
}

export const Modal = ({ open, actions, onClose, children }: ModalProps) => (
  <MuiModal open={open} onClose={onClose}>
    <ModalContainer>
      <ModalContent>
        <StyledModalIconButton onClick={onClose}>
          <Close />
        </StyledModalIconButton>
        {children}
      </ModalContent>
      <Divider />
      <ModalActions>
        {actions.map(action => (
          <Grid item gutter="small">
            {/* change this button to our button once finished */}
            <Button /* add button type here */ onClick={action.onClick}>
              {action.text}
            </Button>
          </Grid>
        ))}
      </ModalActions>
    </ModalContainer>
  </MuiModal>
)
