import { equals } from 'ramda'

export const compareArray = (array1, array2) => equals(array1, array2)

export const moveArrayElementUp = (array, index) => {
  if (index >= array.length - 1 || index < 0) {
    return array
  }
  const beginning = array.slice(0, index)
  const end = array.slice(index + 1, array.length)
  const element = array[index]
  beginning.push(end[0])
  end.shift()
  beginning.push(element)
  return beginning.concat(end)
}

export const moveArrayElementDown = (array, index) => {
  if (index <= 0 || index >= array.length) {
    return array
  }
  const beginning = array.slice(0, index)
  const end = array.slice(index + 1, array.length)
  const element = array[index]
  end.unshift(beginning[beginning.length - 1])
  beginning.pop()
  end.unshift(element)
  return beginning.concat(end)
}
