import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchRecipes } from '../../../actions/Recipes'
import { updateConfig, fetchSingleTagConfiguration } from '../actions'
import { ConfigPage } from '../pages/config-page'
import { fetchKitchens } from '../../../actions/Reception'

const mapStateToProps = ({ recipes, receptions }) => ({
  recipes: recipes.recipeSearchResults,
  kitchens: receptions.kitchens,
})

const mapActionsToProps = {
  fetchRecipes,
  fetchKitchens,
  updateConfig,
  fetchSingleTagConfiguration,
}

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(ConfigPage)
)
