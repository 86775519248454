import { defineMessages } from 'react-intl'

export default defineMessages({
  receptions: {
    id: 'menu.receptions',
    defaultMessage: 'Recebimentos',
  },
  tags: {
    id: 'menu.tags',
    defaultMessage: 'Etiquetas',
  },
  configTags: {
    id: 'menu.tags.config',
    defaultMessage: 'Configurar',
  },
  printTags: {
    id: 'menu.tags.print',
    defaultMessage: 'Imprimir',
  },
  feedstock: {
    id: 'menu.feedstock',
    defaultMessage: 'Matérias Primas',
  },
  feedstockSearch: {
    id: 'menu.feedstock.search',
    defaultMessage: 'Buscar matérias primas',
  },
  recipes: {
    id: 'menu.recipes',
    defaultMessage: 'Receitas',
  },
  simulateRecipes: {
    id: 'menu.recipes.simulateRecipes',
    defaultMessage: 'Simulação de Receitas',
  },
  createRecipes: {
    id: 'menu.recipes.createRecipe',
    defaultMessage: 'Criar Receita',
  },
  printRecipes: {
    id: 'menu.recipes.printRecipes',
    defaultMessage: 'Impressão de Receitas',
  },
  searchRecipes: {
    id: 'menu.recipes.searchRecipes',
    defaultMessage: 'Buscar Receitas',
  },
  ppcp: {
    id: 'menu.ppcp',
    defaultMessage: 'PPCP',
  },
  ppcpStart: {
    id: 'menu.ppcpStart',
    defaultMessage: 'Iniciar Planejamento',
  },
  searchResources: {
    id: 'menu.searchResources',
    defaultMessage: 'Buscar Recursos',
  },
  registerResources: {
    id: 'menu.registerResources',
    defaultMessage: 'Cadastrar Recursos',
  },
  planningHistoric: {
    id: 'menu.planningHistoric',
    defaultMessage: 'Histórico de Planejamentos',
  },
  registerProductionRecipe: {
    id: 'menu.registerProductionRecipe',
    defaultMessage: 'Cadastrar Receita de Produção',
  },
  searchProductionRecipe: {
    id: 'menu.searchProductionRecipe',
    defaultMessage: 'Buscar Receita de Produção',
  },
  registerProductionGroup: {
    id: 'registerProductionGroup',
    defaultMessage: 'Cadastrar Grupos de Produção',
  },
  quality: {
    id: 'quality',
    defaultMessage: 'Qualidade',
  },
  rejected: {
    id: 'rejected',
    defaultMessage: 'Não Conformes',
  },
  feedstock: {
    id: 'feedstock',
    defaultMessage: 'Matéria-prima',
  },
  registerFeedstock: {
    id: 'feedstock',
    defaultMessage: 'Cadastro',
  },
  productionPlanning: {
    id: 'productionPlanning',
    defaultMessage: 'Planejamento da Produção',
  },
  processConfig: {
    id: 'processConfig',
    defaultMessage: 'Cadastro de Processos',
  },
  shop: {
    id: 'shop',
    defaultMessage: 'Compras',
  },
  ingredientsShop: {
    id: 'ingredientsShop',
    defaultMessage: 'Lista de Compras',
  },
  importProductionPlanning: {
    id: 'importProductionPlanning',
    defaultMessage: 'Salvar Planejamento',
  },
  ingredients: {
    id: 'ingredients',
    defaultMessage: 'Ingredientes',
  },
  searchIngredients: {
    id: 'menu.ingredients.searchIngredient',
    defaultMessage: 'Buscar ingredientes',
  },
  createIngredient: {
    id: 'menu.ingredients.createIngredient',
    defaultMessage: 'Criar ingrediente',
  },
})
