import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dotProp from 'dot-prop-immutable'
import Modal from '@material-ui/core/Modal'
import {
  addRecipeProcess,
  replaceRecipeProcesses,
  fetchRecipeProcesses,
} from 'actions/Recipes'
import ProcessSelect from '../components/ProcessSelector'
import ProcessTable from '../components/ProcessTable'

class Processes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.fetchRecipeProcesses()
  }

  handleAdd = process => {
    const { addProcess, editing } = this.props
    if (!addProcess) {
      return
    }
    addProcess(process, editing)
    this.setState({ isModalOpen: false })
  }

  handleRemove = index => () => {
    const { replaceProcesses, processes, editing } = this.props
    if (!processes || !replaceProcesses) {
      return
    }
    const newProcesses = processes.filter(
      (_process, processIndex) => processIndex !== index
    )
    replaceProcesses(newProcesses, editing)
  }

  handleMoveProcess = (prevIndex, nextIndex) => () => {
    const { replaceProcesses, processes, editing } = this.props
    if (!processes || !replaceProcesses) {
      return
    }
    const reorderedProcesses = dotProp.set(
      dotProp.set(processes, nextIndex, processes[prevIndex]),
      prevIndex,
      processes[nextIndex]
    )
    replaceProcesses(reorderedProcesses, editing)
  }

  toggleModal = isModalOpen => () => this.setState({ isModalOpen })

  renderProcessesModal() {
    const { processesConstants } = this.props
    return (
      <Modal open={this.state.isModalOpen} onClose={this.toggleModal(false)}>
        <ProcessSelect
          types={processesConstants.types}
          steps={processesConstants.relations}
          onConfirm={this.handleAdd}
        />
      </Modal>
    )
  }

  render() {
    const { processes } = this.props
    return (
      <React.Fragment>
        <ProcessTable
          processes={processes}
          onAdd={this.toggleModal(true)}
          onRemove={this.handleRemove}
          onMoveProcess={this.handleMoveProcess}
        />
        {this.renderProcessesModal()}
      </React.Fragment>
    )
  }
}

Processes.propTypes = {
  processes: PropTypes.array,
  processesConstants: PropTypes.shape({
    relations: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
  }).isRequired,
  addProcess: PropTypes.func.isRequired,
  replaceProcesses: PropTypes.func.isRequired,
  fetchRecipeProcesses: PropTypes.func.isRequired,
}

Processes.defaultProps = {
  processes: [],
}

const mapActionsToProps = {
  addProcess: addRecipeProcess,
  replaceProcesses: replaceRecipeProcesses,
  fetchRecipeProcesses,
}

const mapStateToProps = state => ({
  processesConstants: state.recipes.processesConstants,
})

export default connect(mapStateToProps, mapActionsToProps)(Processes)
