import { Allergenic } from '../../../contracts/allergenic'
import { CheckboxItem } from '../../../contracts/checkboxItem'
import { LookupItem } from '../../../contracts/lookupItem'
import {
  NutritionaInfoDataNames,
  NutritionaInfoDisplayNames,
  NutritionalInfo,
  NutritionalInfoDisplayObject,
  nutritionalInfoMap,
  NutritionalInfoSources,
} from '../../../contracts/nutritionalInfo'
import { Feedstock } from '../../../contracts/feedstock'
import { IKitchen } from '../../../contracts/kitchens'
import { CheckboxKitchensList } from '../../adapters'

export const updateAllergenics = (
  allergenics: CheckboxItem[],
  value: number
): CheckboxItem[] =>
  // tslint:disable-next-line:prefer-object-spread
  Object.assign([...allergenics], {
    [value]: { ...allergenics[value], selected: !allergenics[value].selected },
  })

const findNutritionalInfoDisplayName = (
  name: NutritionaInfoDataNames
): NutritionaInfoDisplayNames =>
  nutritionalInfoMap.find(nutrientTuple => nutrientTuple[0] === name)[1]

const findNutritionalInfoDocumentName = (
  name: NutritionaInfoDisplayNames
): NutritionaInfoDataNames =>
  nutritionalInfoMap.find(nutrientTuple => nutrientTuple[1] === name)[0]

export const mapNutritionalInfoDisplayToData = (
  nutritionalInfo: NutritionalInfoDisplayObject[]
): Partial<NutritionalInfo> =>
  nutritionalInfo.reduce(
    (
      currentNutritionalInfo: Partial<NutritionalInfo>,
      next: NutritionalInfoDisplayObject
    ): Partial<NutritionalInfo> => ({
      ...currentNutritionalInfo,
      [findNutritionalInfoDocumentName(next.name)]: Number(next.portionValue),
    }),
    {}
  )

export const mapCheckboxItemsToAllergenics = (
  allergenicCheckboxItems: CheckboxItem[],
  allergenics: Allergenic[]
): number[] =>
  allergenicCheckboxItems
    .filter(allergenicCheckboxItem => allergenicCheckboxItem.selected)
    .map(allergenicCheckboxItem =>
      allergenics.find(
        allergenic => allergenic.name === allergenicCheckboxItem.text
      )
    )
    .map(allergenic => allergenic.id)

export const mapSuppliers = (
  mainSupplier: LookupItem,
  otherSuppliers: LookupItem[]
) =>
  mainSupplier && [
    { id: mainSupplier.value, category: 'primary' },
    ...otherSuppliers.map(supplier => ({
      id: supplier.value,
      category: 'secondary',
    })),
  ]

export const anyToString = (field: string | number): string => {
  if (typeof field === 'string') {
    return field === 'Tr' || field === 'NA' ? '0.00' : field
  }
  if (typeof field === 'number') {
    return field.toFixed(2)
  }
  return '-'
}
export const mapNutritionalInfoDataToDisplay = (
  nutritionalInfo: NutritionalInfo
): NutritionalInfoDisplayObject[] =>
  nutritionalInfo &&
  Object.keys(nutritionalInfo).reduce(
    (
      currentNutritionalInfo: NutritionalInfoDisplayObject[],
      next: NutritionaInfoDataNames
    ): NutritionalInfoDisplayObject[] => [
      ...currentNutritionalInfo,
      {
        id: next,
        name: findNutritionalInfoDisplayName(next),
        portionValue: anyToString(nutritionalInfo[next]),
      },
    ],
    []
  )

export const mapAllergenicsToCheckboxItems = (
  allergenics: Allergenic[],
  feedstockAllergenics?: number[]
): CheckboxItem[] =>
  allergenics.map(
    (allergenic: Allergenic): CheckboxItem => ({
      text: allergenic.name,
      selected: feedstockAllergenics.some(
        feedstockAllergenic => feedstockAllergenic === allergenic.id
      ),
    })
  )

export const arrayToLookupItem = (array: any[]): LookupItem[] =>
  array.map((item: any, index: number) => ({
    value: `${index}`,
    label: item,
  }))

export const supplierToLookupItem = (supplier: any): LookupItem => {
  return {
    value: supplier.id,
    label: supplier.name,
  }
}

export const suppliersToLookupItems = (suppliers: any[]): LookupItem[] =>
  suppliers.map((item: any) => supplierToLookupItem(item))

export const nutritionalInfosToLookupItems = (
  nutritionalInfoSource: NutritionalInfoSources,
  nutritionalInfos: any[]
) => {
  if (nutritionalInfoSource === 'TACO') {
    return nutritionalInfos.map(nutritionalInfo => ({
      value: nutritionalInfo.tacoId,
      label: nutritionalInfo.description,
    }))
  }
  if (nutritionalInfoSource === 'USDA') {
    return nutritionalInfos.map(nutritionalInfo => ({
      value: nutritionalInfo.ndbNumber,
      label: nutritionalInfo.name,
    }))
  }
  return []
}

export const mapTacoNutritionalInfoToData = (
  tacoNutritionalInfo: NutritionalInfo
): NutritionalInfo => {
  const initial: Partial<NutritionalInfo> = {}
  const nutritionalInfo =
    tacoNutritionalInfo &&
    Object.keys(tacoNutritionalInfo).reduce(
      (
        currentNutritionalInfo: Partial<NutritionalInfo>,
        next: NutritionaInfoDataNames
      ) => ({
        ...currentNutritionalInfo,
        [next]: anyToString(tacoNutritionalInfo[next].quantity),
      }),
      initial
    )
  return nutritionalInfo as NutritionalInfo
}

export const validateNumberInput = (value: string): string =>
  /^\d$/.exec(value)[0]

export const getSelectedKitchenInitialState = (
  feedstock: Feedstock,
  kitchens: IKitchen[]
): CheckboxKitchensList[] =>
  kitchens.map(k => ({
    id: k.id,
    text: k.name,
    selected: !!(
      feedstock &&
      feedstock.kitchens &&
      feedstock.kitchens.find(kit => kit === k.id)
    ),
  }))

export const getSelectedKitchenId = (
  selectedKitchens: CheckboxKitchensList[]
): string[] => {
  return selectedKitchens.filter(k => k.selected).map(k => k.id)
}
