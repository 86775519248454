import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  getSearchResults,
  fetchReceptions,
  fetchKitchens,
  updateReception,
  fetchSingleReception,
} from 'actions/Reception'
import { parseCurrencyStringToFloat } from 'utils/parsers'
import dotProp from 'dot-prop-immutable'
import ErrorBox from 'components/ErrorBox'
import { fetchFeedstockPrices } from '../../../../v2/feedstock/actions'
import EditReception from '../components/EditReception'
import SearchPanel from '../components/SearchPanel'
import SearchResults from '../components/SearchResults'

class ReceptionSearchContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditPage: false,
    }
  }

  componentDidMount() {
    this.props.fetchKitchens()
    this.props.fetchReceptions()
    this.props.fetchFeedstockPrices()
  }

  getSearchResults = params => {
    this.props.getSearchResults(params)
  }

  handleCloseEditReceptionPage = () => {
    this.setState({
      showEditPage: false,
    })
  }

  handleOpenEditReceptionPage = code => {
    this.setState({
      showEditPage: true,
    })
    this.props.fetchSingleReception(code)
  }

  handleUpdateReception = async reception => {
    const formattedInfo = reception.receptionsInfo.map(info => ({
      ...info,
      expectedQuantity:
        info.expectedQuantity &&
        parseCurrencyStringToFloat(info.expectedQuantity),
      receivedQuantity:
        info.receivedQuantity &&
        parseCurrencyStringToFloat(info.receivedQuantity),
      invoiceQuantity:
        info.invoiceQuantity &&
        parseCurrencyStringToFloat(info.invoiceQuantity),
      total: info.total && parseCurrencyStringToFloat(info.total),
    }))
    await this.props.updateReception(
      dotProp.set(reception, 'receptionsInfo', formattedInfo)
    )
    if (!this.props.editReception.error) {
      this.handleCloseEditReceptionPage()
      this.props.fetchReceptions()
    }
  }

  renderEditReception = () => {
    if (this.props.editReception.loading) return <CircularProgress size={50} />
    return (
      <EditReception
        editReception={this.props.editReception}
        onUpdateReception={this.handleUpdateReception}
        onCloseEditReceptionPage={this.handleCloseEditReceptionPage}
        feedstockPrices={this.props.feedstockPrices}
        receptionsCodes={this.props.allReceptions.data.map(reception => ({
          value: reception.id,
          label: reception.code,
        }))}
        linkedReceptions={
          this.props.editReception.data.linkedReceptions
            ? this.props.allReceptions.data
                .filter(receptions =>
                  this.props.editReception.data.linkedReceptions.some(
                    element => element === receptions.id
                  )
                )
                .map(receptions => ({
                  value: receptions.id,
                  label: receptions.code,
                }))
            : null
        }
      />
    )
  }

  renderSearchPanel() {
    const { searchReceptions, kitchens } = this.props
    if (searchReceptions.error) {
      return <ErrorBox message={searchReceptions.error} />
    }
    return (
      <SearchPanel
        onSearchSubmit={this.getSearchResults}
        kitchens={kitchens && kitchens.data}
        loadingKitchens={kitchens && kitchens.loading}
      >
        <CircularProgress size={50} />
      </SearchPanel>
    )
  }

  renderSearchResults() {
    const { searchReceptions, allReceptions } = this.props
    if (searchReceptions.loading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (searchReceptions.error) {
      return <ErrorBox message={searchReceptions.error} />
    }
    return (
      <SearchResults
        searchReceptions={
          searchReceptions.data ? searchReceptions : allReceptions
        }
        onOpenEditReceptionPage={this.handleOpenEditReceptionPage}
      />
    )
  }

  render = () =>
    this.state.showEditPage ? (
      <Grid container justify="center">
        {this.renderEditReception()}
      </Grid>
    ) : (
      <Grid container justify="center">
        {this.renderSearchPanel()}
        {this.renderSearchResults()}
      </Grid>
    )
}

const mapStateToProps = state => ({
  searchReceptions: state.receptions.searchReceptions,
  allReceptions: state.receptions.allReceptions,
  editReception: state.receptions.editReception,
  feedstockPrices: state.feedstocks.prices,
  kitchens: state.receptions.kitchens,
})

ReceptionSearchContainer.propTypes = {
  searchReceptions: PropTypes.object,
  allReceptions: PropTypes.object,
  editReception: PropTypes.object,
  kitchens: PropTypes.object,
  feedstockPrices: PropTypes.object,
  getSearchResults: PropTypes.func.isRequired,
  fetchReceptions: PropTypes.func.isRequired,
  fetchKitchens: PropTypes.func.isRequired,
  updateReception: PropTypes.func.isRequired,
  fetchSingleReception: PropTypes.func.isRequired,
  fetchFeedstockPrices: PropTypes.func.isRequired,
}

ReceptionSearchContainer.defaultProps = {
  searchReceptions: null,
  allReceptions: {},
  editReception: {},
  feedstockPrices: {},
  kitchens: {},
}

export default withRouter(
  connect(mapStateToProps, {
    getSearchResults,
    fetchReceptions,
    fetchKitchens,
    updateReception,
    fetchSingleReception,
    fetchFeedstockPrices,
  })(ReceptionSearchContainer)
)
