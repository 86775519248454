import FeedstockRegisterScreen from './screen/FeedstockRegisterScreen'

export const registerRoutes = [
  {
    path: 'register',
    children: [{ path: 'regular', component: FeedstockRegisterScreen }],
  },
  {
    path: 'regular',
    component: FeedstockRegisterScreen,
    params: [
      {
        name: 'code',
        optional: true,
      },
    ],
  },
]
