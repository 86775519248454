import React, { useEffect, useState } from 'react'
import { useAPI } from '../../../api'
import { Endpoints } from '../../../api/endpoints'
import { LookupItem } from '../../../contracts/lookupItem'
import { Feedstock } from '../../../contracts/feedstock'

import { FeedstockCombo } from './styles'
import useDebounce from '../../../hooks/useDebounce'
import { Props as SelectProps } from 'react-select'

export interface FeedstockSelectProps
  extends Omit<
  SelectProps,
  'options' | 'onInputChange' | 'selected' | 'onSelect'
  > {
  initiallySelected?: LookupItem | LookupItem[]
  onSelect: (selected: LookupItem | LookupItem[]) => void
}

export const FeedstockSelect: React.FC<FeedstockSelectProps> = ({
  initiallySelected,
  onSelect,
  ...props
}) => {
  const [selectedItems, setSelectedItems] = useState<LookupItem | LookupItem[]>(initiallySelected)
  const [options, setOptions] = useState<LookupItem[]>([])
  const [search, isLoading] = useAPI()

  const debouncedSearch = useDebounce((value: string) =>
    search({
      uri: `${Endpoints.SEARCH_FEEDSTOCKS}/?name=${value}`,
      method: `GET`,
    }).then((data: { feedstocks: Feedstock[] }) => {
      setOptions(
        data.feedstocks.map((ft, index) => ({
          value: ft.id,
          label: `${ft.code} - ${ft.feedstockName}`,
          index,
        }))
      )
    })
  )

  function handleSelect(values: LookupItem | LookupItem[]) {
    setSelectedItems(values)
    onSelect(values)
  }

  function handleInputChange(value: string, { action }: { action: string }) {
    if (action !== 'input-change') {
      return
    }

    if (!value) {
      setOptions([])
      return
    }

    debouncedSearch(value)
  }

  useEffect(() => {
    setSelectedItems(initiallySelected);
  }, [initiallySelected])

  return (
    <FeedstockCombo
      {...props}
      selected={selectedItems}
      options={options}
      onSelect={handleSelect}
      onInputChange={handleInputChange}
      placeholder="Busque por matéria prima"
      isLoading={isLoading}
      isClearable
    />
  )
}
