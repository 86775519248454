import HeaderfulContainer from 'src/v2/components/molecules/headerfulContainer'
import styled from 'styled-components'
import Input from 'src/v2/components/atoms/input'

export const Container = styled(HeaderfulContainer)`
  && {
  }
`

export const ContentContainer = styled.div`
  && {
    display: flex;
  }
`

export const NameFilterInput = styled(Input)`
  && {
    width: 100%;
  }
`
