import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import OptionCard from './OptionCard'
import { newRecipeMessage, chooseRecipeMessage } from '../formattedMessages/'

const styles = theme => ({
  root: {},
  recipesIcon: {
    height: '75%',
    opacity: 0.6,
    marginTop: 4 * theme.spacing.unit,
  },
  newRecipeIcon: {
    height: '100%',
    opacity: 0.6,
  },
})

class Options extends Component {
  render() {
    const { classes, handleClickNewRecipe, handleClickChooseRecipe } =
      this.props
    return (
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={1} />
        <Grid item xs>
          <OptionCard
            name={newRecipeMessage}
            handleClick={handleClickNewRecipe}
          >
            <img
              id="newRecipeButton"
              src="../../../../assets/images/new-recipe.svg"
              alt={newRecipeMessage}
              className={classes.newRecipeIcon}
            />
          </OptionCard>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs>
          <OptionCard
            name={chooseRecipeMessage}
            handleClick={handleClickChooseRecipe}
          >
            <img
              id="chooseRecipeButton"
              src="../../../../assets/images/recipes.svg"
              alt={chooseRecipeMessage}
              className={classes.recipesIcon}
            />
          </OptionCard>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    )
  }
}
Options.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickNewRecipe: PropTypes.func.isRequired,
  handleClickChooseRecipe: PropTypes.func.isRequired,
}

export default withStyles(styles)(Options)
