import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { recipeCreationTitle } from './formattedMessages/'
import CreateRecipeContainer from './containers/CreateRecipeContainer'

class CreateRecipePage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={recipeCreationTitle}
        />
        <CreateRecipeContainer />
      </div>
    )
  }
}

export default CreateRecipePage
