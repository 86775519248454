import Papa from 'papaparse'
import { downloadFile } from './urlFunctions'

export const parseIfPossible = str => {
  try {
    const parsed = JSON.parse(str)
    return parsed
  } catch (e) {
    return null
  }
}

export const parseCSV = (file, options, onComplete) =>
  Papa.parse(file, {
    complete: onComplete,
    delimeter: ',',
    trimHeader: true,
    skipEmptyLines: true,
    header: true,
    ...options,
  })

export const parseJsonToCSV = (
  { data, fields },
  filename = 'spreadsheet.csv'
) => {
  const csv = Papa.unparse(
    {
      fields: fields || null,
      data,
    },
    {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ';',
      header: true,
      newline: '\r\n',
    }
  )
  downloadFile(csv, 'text/csv;charset=utf-8', filename)
}

export const parseOrdersListToCSV = (data, filename) => {
  const fields = [
    'routeNumber',
    'order',
    'frozenLocation',
    'clientName',
    'observations',
    'address',
    'paymentStatusText',
    'billingTotal',
    'paymentMethod',
    'paymentMethodBrand',
    'productTypes',
    'routeStatus',
    'frozenStatusText',
    'amountOfFrozenPackages',
    'snacksStatusText',
    'amountOfSnacksPackages',
    'routeTypeText',
    'deliveryKind',
    'snacksLocation',
    'clientPhone',
  ]
  parseJsonToCSV({ data, fields }, filename)
}

export const parseCurrencyStringToFloat = value =>
  value && typeof value === 'string'
    ? parseFloat(value.replace('.', '').replace(',', '.'))
    : value
