import React, { useCallback } from 'react'

import { InputProps as MuiInputProps } from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import { TextField } from './styles'

export interface InputProps extends MuiInputProps {
  type?: string
  label?: string
  disabled?: boolean
  required?: boolean
  value?: string
  fullWidth?: boolean
  readOnly?: boolean
  hint?: string
}

const Input = React.memo(({ readOnly, ...props }: InputProps) => {
  const handleType = useCallback((type: string) => {
    switch (type) {
      case 'weight':
        return 'Kg'
      case 'currency':
        return 'R$'
      case 'percentage':
        return '%'
      case 'search':
        return <SearchIcon />
      default:
        return ''
    }
  }, [])

  return (
    <TextField
      {...props}
      variant="outlined"
      defaultValue={props.value}
      InputProps={{
        readOnly,
        endAdornment: (
          <InputAdornment position="end">
            {handleType(props.type)}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: readOnly,
      }}
    />
  )
})

export default Input
