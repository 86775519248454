export default theme => ({
  processContainer: {
    borderRadius: `${0.5 * theme.spacing.unit}px`,
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    marginTop: '4px',
    marginBottom: '4px',
  },
  trashCan: {
    marginBottom: '10px',
  },
  inputRoot: { borderRadius: '4px', padding: '27px 12px 27px' },
})
