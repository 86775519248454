import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { FormattedMessage } from 'react-intl'
import defineMessages from './containers/Messages'
import SearchResourcesContainer from './containers/SearchResourcesContainer'

class SearchResources extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={<FormattedMessage {...defineMessages.searchResource} />}
        />
        <SearchResourcesContainer />
      </div>
    )
  }
}

export default SearchResources
