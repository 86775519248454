import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dotProp from 'dot-prop-immutable'
import { withSnackbar } from 'notistack'
import {
  fetchRecipes,
  addSubRecipe,
  removeSubRecipe,
  fetchRecipeToAdd,
} from 'actions/Recipes'
import Modal from '@material-ui/core/Modal'
import SubRecipesTable from '../../components/SubRecipesTable'
import SelectModal from '../../Simulate/components/SelectModal'
import { getItemPath } from '../../helpers'

class SubRecipes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.fetchRecipes()
  }

  componentDidUpdate(prevProps) {
    if (this.props.recipeToAdd.recipe !== prevProps.recipeToAdd.recipe) {
      const { editing, recipeToAdd } = this.props
      this.props.addSubRecipe(
        recipeToAdd.recipe.code,
        recipeToAdd.quantity,
        editing
      )
    }
  }

  handleChange = path => value => {
    const { subRecipes, onChange, editing } = this.props
    const { subRecipes: updatedSubRecipes } = dotProp.set(
      { subRecipes },
      path,
      value
    )
    if (onChange) {
      onChange(updatedSubRecipes)
    }
    if (this.props.addSubRecipe) {
      const updatedSubRecipePath = getItemPath(path)
      const updatedSubRecipe = dotProp.get(
        { subRecipes: updatedSubRecipes },
        updatedSubRecipePath
      )
      this.props.addSubRecipe(
        updatedSubRecipe.code,
        updatedSubRecipe.quantity,
        editing
      )
    }
  }

  handleAdd = recipeCode => _type => quantity => {
    const { subRecipes, allRecipes, onChange, editing } = this.props
    const subRecipeToAdd = allRecipes.find(recipe => recipe.code === recipeCode)
    if (!subRecipeToAdd) {
      return
    }
    if (onChange) {
      onChange([...subRecipes, { ...subRecipeToAdd, quantity }])
    }
    this.props.fetchRecipeAllergenics(subRecipeToAdd.code)
    if (this.isFoundRecipePopulated(subRecipeToAdd)) {
      this.props.addSubRecipe(subRecipeToAdd.code, quantity, editing)
    } else {
      this.props.fetchRecipeToAdd(subRecipeToAdd.code, quantity)
    }
    this.setState({ isModalOpen: false })
  }

  handleRemove = path => {
    const { subRecipes, onChange, editing } = this.props
    const { subRecipes: updatedSubRecipes } = dotProp.delete(
      { subRecipes },
      path
    )
    if (onChange) {
      onChange(updatedSubRecipes)
    }
    const index = path.split('.')[1]
    const subRecipeToRemove = subRecipes[index]
    this.props.removeAllergenic(subRecipeToRemove.code)
    if (this.props.removeSubRecipe) {
      this.props.removeSubRecipe(subRecipeToRemove.code, editing)
    }
  }

  handleAddModalOpen = () => {
    const { allRecipesError, enqueueSnackbar, recipeKitchens } = this.props
    if (allRecipesError) {
      console.error(allRecipesError)
      enqueueSnackbar('Ocorreu um erro carregando as receitas', {
        variant: 'error',
        horizontal: 'left',
        preventDuplicate: true,
      })
    } else if (!recipeKitchens || recipeKitchens === '') {
      console.error('Selecione uma cozinha')
      enqueueSnackBar('Selecione uma cozinha para esta receita', {
        variant: 'error',
        horizontal: 'left',
        preventDuplicate: false,
      })
    } else {
      this.setState({ isModalOpen: true })
    }
  }

  handleClose = () => this.setState({ isModalOpen: false })

  isFoundRecipePopulated = foundRecipe =>
    (foundRecipe.ingredients &&
      foundRecipe.ingredients.length > 0 &&
      foundRecipe.ingredients[0].ingredient.code) ||
    (foundRecipe.recipesInfo &&
      foundRecipe.recipesInfo.length > 0 &&
      foundRecipe.recipesInfo[0].recipe.code)

  renderSubRecipesModal() {
    const { allRecipes, allRecipesLoading, recipeKitchens } = this.props
    const selectedKitchenRecipes = allRecipes.filter(recipe =>
      recipe.kitchens.some(kitchen => recipeKitchens.includes(kitchen))
    )

    return (
      <Modal open={this.state.isModalOpen} onClose={this.handleClose}>
        <SelectModal
          items={selectedKitchenRecipes.map(recipe => ({
            label: `${recipe.code.split('_')[0]} - ${recipe.name}`,
            value: recipe.code,
          }))}
          step={1}
          label="Escolha a Sub Receita"
          title="Sub Receitas"
          handleConfirm={this.handleAdd}
          type="subReceitas"
          buttonMessage="Adicionar"
          itemsLoading={allRecipesLoading}
        />
      </Modal>
    )
  }

  render() {
    const {
      subRecipes,
      totalCost,
      batchSize,
      recipeWeight,
      dirty,
      recipeToAdd,
      allRecipesLoading,
    } = this.props
    return (
      <React.Fragment>
        <SubRecipesTable
          subRecipes={subRecipes}
          handleChange={this.handleChange}
          handleRemove={this.handleRemove}
          path="subRecipes"
          handleAddModalOpen={this.handleAddModalOpen}
          totalCost={totalCost}
          batchSize={batchSize}
          dirty={dirty}
          recipeWeight={recipeWeight}
          isLoading={recipeToAdd.loading || allRecipesLoading}
        />
        {this.renderSubRecipesModal()}
      </React.Fragment>
    )
  }
}

SubRecipes.propTypes = {
  subRecipes: PropTypes.array,
  allRecipes: PropTypes.array.isRequired,
  totalCost: PropTypes.number.isRequired,
  batchSize: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  addSubRecipe: PropTypes.func,
  removeSubRecipe: PropTypes.func,
  fetchRecipes: PropTypes.func,
  allRecipesError: PropTypes.bool,
  allRecipesLoading: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  recipeWeight: PropTypes.number,
  dirty: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  recipeToAdd: PropTypes.object,
  fetchRecipeToAdd: PropTypes.func,
  recipeKitchens: PropTypes.array,
}

SubRecipes.defaultProps = {
  onChange: () => null,
  addSubRecipe: () => null,
  removeSubRecipe: () => null,
  fetchRecipes: () => null,
  enqueueSnackbar: () => null,
  fetchRecipeToAdd: () => null,
  allRecipesError: false,
  allRecipesLoading: false,
  subRecipes: [],
  recipeWeight: 0,
  recipeToAdd: {
    recipe: {},
    error: null,
    loading: false,
    quantity: 0,
  },
  recipeKitchens: [''],
}

const mapDispatchToProps = {
  fetchRecipes,
  addSubRecipe,
  removeSubRecipe,
  fetchRecipeToAdd,
}

const mapStateToProps = state => ({
  allRecipes: state.recipes.recipeSearchResults.data,
  allRecipesLoading: state.recipes.recipeSearchResults.loading,
  allRecipesError: state.recipes.recipeSearchResults.error,
  recipeToAdd: state.recipes.lastRecipeInfoAdded,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SubRecipes))
