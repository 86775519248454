import { defineMessages } from 'react-intl'

export default defineMessages({
  recipeCreation: {
    id: 'app.recipeCreation.title',
    defaultMessage: 'Criação de Receitas',
  },
  recipeInfo: {
    id: 'app.recipeCreation.recipeInfo.title',
    defaultMessage: 'Informações básicas',
  },
  recipeName: {
    id: 'app.recipeCreation.recipeInfo.name',
    defaultMessage: 'Nome (*)',
  },
  recipeYield: {
    id: 'app.recipeCreation.recipeInfo.yield',
    defaultMessage: 'Rendimento (*)',
  },
  recipeWaste: {
    id: 'app.recipeCreation.recipeInfo.waste',
    defaultMessage: 'Desperdício (*)',
  },
  portionSize: {
    id: 'app.recipeCreation.recipeInfo.portionSize',
    description: 'Tamanho da porção',
    defaultMessage: 'Peso líquido',
  },
  portionQuantity: {
    id: 'app.recipeCreation.recipeInfo.portionQuantity',
    description: 'Quantidade da porção',
    defaultMessage: 'Porção por embalagem (*)',
  },
  referenceGrammage: {
    id: 'app.recipeCreation.recipeInfo.referenceGrammage',
    description: 'Quantidade (tabela nutricional)',
    defaultMessage: 'Porção regulamentação (legislação) (*)',
  },
  batchSize: {
    id: 'app.recipeCreation.recipeInfo.batchSize',
    defaultMessage: 'Lote',
  },
  packageType: {
    id: 'app.recipeCreation.recipeInfo.packageType',
    defaultMessage: 'Tipo de Embalagem',
  },
  type: {
    id: 'app.recipeCreation.recipeInfo.type',
    defaultMessage: 'Tipo do Saquinho',
  },
  ingredientsType: {
    id: 'app.recipeCreation.recipeInfo.ingredientstype',
    defaultMessage: 'Tipo de ingredientes',
  },
  sku: {
    id: 'app.recipeCreation.skuButton.title',
    defaultMessage: 'SKU (*)',
  },
  versionNumber: {
    id: 'app.recipeEdit.version.number',
    defaultMessage: 'Versão',
  },
  skuAction: {
    id: 'app.recipeCreation.skuButton.action',
    defaultMessage: 'GERAR',
  },
  contains: {
    id: 'app.recipeCreation.recipeInfo.contains',
  },
  gluten: {
    id: 'app.recipeCreation.recipeInfo.gluten',
  },
  lactose: {
    id: 'app.recipeCreation.recipeInfo.lactose',
  },
  classification: {
    id: 'app.recipeCreation.recipeInfo.classification',
  },
  allergenics: {
    id: 'app.recipeCreation.recipeInfo.allergenics',
  },
  allergenicsContains: {
    id: 'app.recipeCreation.recipeInfo.allergenics.contains',
  },
  allergenicsMayContain: {
    id: 'app.recipeCreation.recipeInfo.allergenics.mayContain',
  },
  sealingSettings: {
    id: 'app.recipeCreation.recipeInfo.sealingSettings',
    defaultMessage: 'Configuração de selagem',
  },
  sealingType: {
    id: 'app.recipeCreation.recipeInfo.sealingType',
    defaultMessage: 'Informar a programação',
  },
  sealingTemperature: {
    id: 'app.recipeCreation.recipeInfo.sealingTemperature',
    defaultMessage: 'Informar a temperatura',
  },
  sealingFreezingQuantity: {
    id: 'app.recipeCreation.recipeInfo.sealingFreezingQuantity',
    defaultMessage: 'Quantidade de congelamento por CAIXA BRANCA',
  },
  cookingTimes: {
    id: 'app.recipeCreation.recipeInfo.cookingTimes',
    defaultMessage: 'Tempo de aquecimento',
  },
  cookingTimeMin: {
    id: 'app.recipeCreation.recipeInfo.cookingTimeMin',
    defaultMessage: 'Tempo Mínimo (*)',
  },
  cookingTimeMax: {
    id: 'app.recipeCreation.recipeInfo.cookingTimeMax',
    defaultMessage: 'Tempo Máximo (*)',
  },
  productionLocation: {
    id: 'app.recipeCreation.recipeInfo.productionLocation',
    defaultMessage: 'Local de produção (*)',
  },
  errors: {
    required: {
      id: 'forms.errors.required',
      defaultMessage: 'Obrigatório',
    },
    positiveNumber: {
      id: 'form.errors.positiveNumber',
      defaultMessage: 'Valor precisa ser um número positivo',
    },
  },
})
