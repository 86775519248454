import React from 'react'
import PropTypes from 'prop-types'
import MuiMenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import InputValidator from 'components/Form/validators/InputValidator'
import styles from './styles'

class DropDown extends React.Component {
  handleChange = event => {
    const { handleChange } = this.props
    const { name, value } = event.target
    const label = this.handleSelectedValue(value)

    handleChange(name)({ value, label })
  }

  handleSelectedValue = value => {
    const { items } = this.props
    const selectedItem = items.find(el => el.value === value)
    return selectedItem.label
  }

  renderInput = () => {
    const { name, fullWidth, selectedItem, isVisualizing, items, classes } =
      this.props
    return (
      <TextField
        name={name}
        fullWidth={fullWidth}
        value={selectedItem && selectedItem.value ? selectedItem.value : ''}
        margin="normal"
        onChange={this.handleChange}
        select
        variant="outlined"
        className={classes.textField}
        disabled={isVisualizing}
      >
        {items &&
          items.map(option => (
            <MuiMenuItem key={option.value} value={option.value}>
              {option.label}
            </MuiMenuItem>
          ))}
      </TextField>
    )
  }

  render() {
    const { label, classes, name, error, validations } = this.props
    return (
      <Grid
        container
        className={classes.helperTextAndTextFieldContainer}
        justify="flex-end"
        direction="column"
      >
        <FormHelperText error={error}>{label}</FormHelperText>
        {validations && validations.length > 0 ? (
          <InputValidator id={name} validations={validations}>
            {this.renderInput()}
          </InputValidator>
        ) : (
          this.renderInput()
        )}
      </Grid>
    )
  }
}

DropDown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedItem: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.array,
}

DropDown.defaultProps = {
  name: undefined,
  label: '',
  selectedItem: {},
  items: [],
}

export default withStyles(styles)(DropDown)
