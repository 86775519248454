import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { recipesMessageHeader, recipesMessageTitle } from './formattedMessages'

class RecipesPage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={recipesMessageHeader}
        />
        <div className="d-flex justify-content-center">
          <h1>{recipesMessageTitle}</h1>
        </div>
      </div>
    )
  }
}

export default RecipesPage
