import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import RegisterProductionRecipeContainer from './containers/RegisterProductionRecipe/index'

class RegisterProductionRecipePage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title="Cadastrar Receita de Produção"
        />
        <RegisterProductionRecipeContainer />
      </div>
    )
  }
}

export default RegisterProductionRecipePage
