import { useCallback, useState } from 'react'

type ApiCallOptions = {
  uri?: string
  method?: string
  body?: any
  contentType?: string
  baseUrl?: string
}

type ApiHook = [(_options: ApiCallOptions) => Promise<any>, boolean]

const apiAddress = process.env.API_ADDRESS

export class ResponseError extends Error {
  response: Response
  constructor(response: Response) {
    super(response.statusText)
    this.response = response
  }
}

export function useAPI(): ApiHook {
  const [loading, setLoading] = useState(false)

  const apiCall = useCallback(
    async ({ baseUrl, uri, method, body, contentType }: ApiCallOptions) => {
      const rawToken = localStorage.getItem('_jwt')
      const token = rawToken ? rawToken.replace(/"/g, '') : ''
      setLoading(true)
      return fetch(`${baseUrl || apiAddress}${uri}`, {
        method,
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          'Content-Type': contentType || 'application/json',
        },
        ...(body && { body: JSON.stringify(body) }),
      })
        .finally(() => setLoading(false))
        .then(async response => {
          if (!response.ok) {
            if (response.status === 401) {
              localStorage.removeItem('_jwt')
              document.location.reload()
            }
            throw new ResponseError(response)
          }
          const textResponse = await response.text()
          if (contentType === 'text/html') {
            return textResponse
          }
          return textResponse ? JSON.parse(textResponse) : {}
        })
    },
    []
  )
  return [apiCall, loading]
}
