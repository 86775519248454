import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'
import { ComponentType } from 'react'
import styled from 'styled-components'
import Input from '../../../../components/atoms/input/index'
import { Typography } from '../../../../components/atoms/typography/styles'
import { Lookup } from '../../../../components/molecules/lookup/'
import { Radio } from '../../../../components/atoms/radio'
import CheckBoxGroup from '../../../../components/molecules/checkboxGroup'

export interface DivProps {
  xs: number
}

export const StyledTypography = styled(Typography)`
  && {
    margin: 0 0 12px 0;
  }
`

export const StyledRadio = styled(Radio)`
  && {
    margin: 0 0 20px 0;
  }
`

export const StyledCheckBoxGroup = styled(CheckBoxGroup)`
  && {
    margin: 0 0 20px 0;
  }
`

export const BasicInfoContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    margin: 0 0 24px 0;
    height: 56px;
  }
`

export const BasicInfoItemStart = styled.div<DivProps>`
  && {
    flex-basis: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    max-width: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    padding-right: 6px;
  }
`

export const BasicInfoItemMiddle = styled.div<DivProps>`
  && {
    flex-basis: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    max-width: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    padding-left: 6px;
    padding-right: 6px;
  }
`

export const BasicInfoItemEnd = styled.div<DivProps>`
  && {
    flex-basis: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    max-width: ${props => (props.xs && (props.xs / 12) * 100) || 100}%;
    padding-left: 6px;
  }
`

export const BasicInfoInput = styled(Input)`
  && {
    width: 100%;
  }
`

export const BasicInfoLookup = styled(Lookup)`
  && {
    margin-bottom: 24px;
  }
`

export const StyledCircularProgress: ComponentType<CircularProgressProps> = styled(
  CircularProgress
)`
  && {
    position: absolute;
    left: 36%;
  }
`

export const CircularProgressContainer = styled.div`
  && {
    position: relative;
  }
`
