import SearchIcon from '@material-ui/icons/Search'
import React, { SyntheticEvent } from 'react'
import { Typography } from '../../../../components/atoms/typography/index'
import { ActionInput } from '../../../../components/molecules/actionInput/'
import HeaderfulContainer from '../../../../components/molecules/headerfulContainer/index'
import { Lookup } from '../../../../components/molecules/lookup/'
import { LookupItem } from '../../../../contracts/lookupItem'
import { NutritionalInfoSources } from '../../../../contracts/nutritionalInfo'
import { messages } from './messages'
import {
  BasicInfoContainer,
  BasicInfoInput,
  BasicInfoItemEnd,
  BasicInfoItemStart,
  BasicInfoLookup,
  CircularProgressContainer,
  StyledCircularProgress,
  StyledRadio,
  StyledTypography,
  StyledCheckBoxGroup,
} from './styles'
import { CheckboxKitchensList } from 'src/v2/feedstock/adapters'
import { Switch } from '@material-ui/core'

export interface BasicInfoProps {
  paperVariant?: 'purple' | 'default'
  feedstockName: string
  currentPrice: string
  feedstockDensity: string
  stockType: string
  onFeedstockTypeRadioSelected?: (index: string) => void
  selectedCategory: LookupItem
  feedstockCategories: LookupItem[]
  categoriesToGenerateCode: LookupItem[]
  nextAvailableCode: string
  nextAvailableCodeLoading: boolean
  nutritionalInfoSources: LookupItem[]
  feedstockTypes: string[]
  onFeedstockNameChange?: (event: SyntheticEvent) => void
  onFeedstockDensityChange?: (event: SyntheticEvent) => void
  onFeedstockPriceChange?: (event: any) => void
  onFeedstockCategorySelected?: (option: LookupItem) => void
  onCategoryToGenerateCodeSelected?: (option: LookupItem) => void
  onNutritionalInfoSourceSelected?: (option: LookupItem) => void
  onNutritionalInfoSearch?: (value: string) => void
  nutritionalInfosLoading: boolean
  nutritionalInfos?: LookupItem[]
  onNutritionalInfoSelected?: (option: LookupItem) => void
  nutritionalInfoSource: NutritionalInfoSources
  existingFeedstock: boolean
  kitchenLabels: CheckboxKitchensList[]
  onKitchenSelected: (index: number) => void
  active: boolean
  onActivate: () => void
}

const BasicInfo = (props: BasicInfoProps) => (
  <HeaderfulContainer title={messages.titles.main} variant={props.paperVariant}>
    <BasicInfoContainer>
      <BasicInfoItemStart xs={4}>
        <BasicInfoInput
          onChange={props.onFeedstockNameChange}
          label={messages.inputs.feedstockName}
          required={false}
          disabled={false}
          type=""
          value={props.feedstockName}
        />
      </BasicInfoItemStart>
      <BasicInfoItemEnd xs={4}>
        <BasicInfoInput
          onChange={props.onFeedstockPriceChange}
          label={messages.inputs.feedstockPrice}
          required={false}
          readOnly={props.existingFeedstock}
          type=""
          value={props.currentPrice}
        />
      </BasicInfoItemEnd>
      <BasicInfoItemEnd xs={4}>
        <BasicInfoInput
          onChange={props.onFeedstockDensityChange}
          label={messages.inputs.feedstockDensity}
          required={false}
          disabled={false}
          type=""
          value={props.feedstockDensity}
        />
      </BasicInfoItemEnd>
    </BasicInfoContainer>
    <BasicInfoContainer>
      <BasicInfoItemStart xs={10}>
        <Typography fontSize="medium" color="black">
          {messages.titles.stockKind}
        </Typography>
        <StyledRadio
          labels={props.feedstockTypes}
          direction="row"
          selected={props.stockType}
          onSelect={props.onFeedstockTypeRadioSelected}
        />
      </BasicInfoItemStart>
      <BasicInfoItemEnd xs={2}>
        <Typography fontSize="medium" color="black">
          {messages.titles.active}
        </Typography>
        <Switch
          color="primary"
          checked={props.active}
          onChange={props.onActivate}
          value="checkedA"
        />
      </BasicInfoItemEnd>
    </BasicInfoContainer>

    <Typography fontSize="medium" color="black">
      {messages.titles.kitchens}
    </Typography>
    <StyledCheckBoxGroup
      columns={3}
      elements={props.kitchenLabels}
      onSelect={props.onKitchenSelected}
    />
    <StyledTypography fontSize="medium" color="black">
      {messages.titles.feedstockCategory}
    </StyledTypography>
    <BasicInfoLookup
      selected={props.selectedCategory}
      placeholder={messages.lookups.categorySelect}
      options={props.feedstockCategories}
      onSelect={props.onFeedstockCategorySelected}
    />
    <BasicInfoContainer>
      <BasicInfoItemStart xs={9}>
        <Lookup
          disabled={props.existingFeedstock}
          placeholder={messages.lookups.nextAvaiableCodeCategory}
          options={props.categoriesToGenerateCode}
          onSelect={props.onCategoryToGenerateCodeSelected}
        />
      </BasicInfoItemStart>
      <BasicInfoItemEnd xs={3}>
        {props.nextAvailableCodeLoading ? (
          <CircularProgressContainer>
            <StyledCircularProgress size={50} />
          </CircularProgressContainer>
        ) : (
          <BasicInfoInput
            label={messages.inputs.code}
            value={props.nextAvailableCode}
            readOnly
            type=""
          />
        )}
      </BasicInfoItemEnd>
    </BasicInfoContainer>
    <StyledTypography fontSize="medium" color="black">
      {messages.titles.nutritionalInfoSource}
    </StyledTypography>
    <BasicInfoContainer>
      <BasicInfoItemStart xs={6}>
        <Lookup
          selected={props.nutritionalInfoSource}
          placeholder={messages.lookups.nutritionalInfoSource}
          options={props.nutritionalInfoSources}
          onSelect={props.onNutritionalInfoSourceSelected}
        />
      </BasicInfoItemStart>
      <BasicInfoItemEnd xs={6}>
        {props.nutritionalInfosLoading ? (
          <CircularProgressContainer>
            <StyledCircularProgress size={50} />
          </CircularProgressContainer>
        ) : (
          <ActionInput
            placeholder={messages.lookups.sourceSearch}
            icon={<SearchIcon />}
            onIconClick={props.onNutritionalInfoSearch}
            options={props.nutritionalInfos}
            onSelect={props.onNutritionalInfoSelected}
            nutritionalInfoSource={props.nutritionalInfoSource}
          />
        )}
      </BasicInfoItemEnd>
    </BasicInfoContainer>
  </HeaderfulContainer>
)

export default BasicInfo
