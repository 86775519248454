import dotProp from 'dot-prop-immutable'

import { compareArray } from '../utils/arrayUtils'

import {
  FETCH_RECIPE_REQUEST,
  FETCH_RECIPE_RESPONSE,
  FETCH_RECIPE_ERROR,
  FETCH_RECIPE_VERSIONS_REQUEST,
  FETCH_RECIPE_VERSIONS_RESPONSE,
  FETCH_RECIPE_VERSIONS_ERROR,
  FETCH_RECIPES_REQUEST,
  FETCH_RECIPES_RESPONSE,
  FETCH_RECIPES_ERROR,
  FETCH_PROCESSES_CONSTANTS_RESPONSE,
  SEARCH_RECIPES_REQUEST,
  SEARCH_RECIPES_RESPONSE,
  SEARCH_RECIPES_ERROR,
  GET_INGREDIENTS_REQUEST,
  GET_INGREDIENTS_RESPONSE,
  GET_INGREDIENTS_ERROR,
  CLEAR_GENERATED_RECIPE_SKU,
  GENERATE_RECIPE_SKU_REQUEST,
  GENERATE_RECIPE_SKU_RESPONSE,
  GENERATE_RECIPE_SKU_ERROR,
  CHANGE_RECIPE_INFORMATION,
  CHANGE_CURRENT_RECIPE_INFORMATION,
  DELETE_RECIPE_SKU,
  ADD_RECIPE_PROCESS,
  REPLACE_RECIPE_PROCESSES,
  ADD_CURRENT_RECIPE_PROCESS,
  REPLACE_CURRENT_RECIPE_PROCESSES,
  RECIPE_TO_SIMULATE_REQUEST,
  RECIPE_TO_SIMULATE_RESPONSE,
  RECIPE_TO_SIMULATE_ERROR,
  RECIPE_TO_ADD_REQUEST,
  RECIPE_TO_ADD_RESPONSE,
  RECIPE_TO_ADD_ERROR,
  ADD_INGREDIENT,
  REMOVE_INGREDIENT,
  ADD_SUBRECIPE,
  REMOVE_SUBRECIPE,
  ADD_CURRENT_RECIPE_INGREDIENT,
  REMOVE_CURRENT_RECIPE_INGREDIENT,
  ADD_CURRENT_RECIPE_SUBRECIPE,
  REMOVE_CURRENT_RECIPE_SUBRECIPE,
  CALCULATE_NUTRITIONAL_TABLE_REQUEST,
  CALCULATE_NUTRITIONAL_TABLE_RESPONSE,
  CALCULATE_CURRENT_RECIPE_NUTRITIONAL_TABLE_RESPONSE,
  CALCULATE_NUTRITIONAL_TABLE_ERROR,
  CALCULATE_RECIPE_COST_RESPONSE,
  CALCULATE_CURRENT_RECIPE_COST_RESPONSE,
  CALCULATE_RECIPE_COST_ERROR,
  CREATE_RECIPE_RESPONSE,
  CREATE_RECIPE_REQUEST,
  CREATE_RECIPE_ERROR,
  CREATE_RECIPE_RESET,
  FETCH_COOKING_METHODS_RESPONSE,
  FETCH_RECIPE_USAGE_REQUEST,
  FETCH_RECIPE_USAGE_RESPONSE,
  FETCH_RECIPE_USAGE_ERROR,
  FETCH_RECIPE_RELATED_PRODUCTS_REQUEST,
  FETCH_RECIPE_RELATED_PRODUCTS_RESPONSE,
  FETCH_RECIPE_RELATED_PRODUCTS_ERROR,
  DELETE_RECIPE_REQUEST,
  DELETE_RECIPE_RESPONSE,
  DELETE_RECIPE_ERROR,
  UPDATE_RECIPE_VERSION_REQUEST,
  UPDATE_RECIPE_VERSION_RESPONSE,
  UPDATE_RECIPE_VERSION_ERROR,
  ADD_ALLERGENIC,
  REMOVE_ALLERGENIC,
  REMOVE_ALL_ALLERGENICS,
  DISPLAY_ALLERGENICS_START,
  FETCH_AVAILABLE_RECIPE_TYPES_RESPONSE,
  UPDATE_RECIPE_NUTRITIONAL_INFO,
  UPDATE_CURRENT_RECIPE_NUTRITIONAL_INFO,
  UPDATE_CURRENT_RECIPE_MANUALLY_CALCULATED,
  UPDATE_RECIPE_MANUALLY_CALCULATED,
  PRODUCTION_LOCATIONS_REQUEST_START,
  PRODUCTION_LOCATIONS_REQUEST_SUCCESS,
  PRODUCTION_LOCATIONS_REQUEST_END,
} from 'constants/ActionTypes'

import { NUTRITIONAL_INFO_NAMES } from 'constants/NutritionalFacts'

import { getRecipeColor } from '../v2/recipe/adapters/getRecipeColor'
import { updateRecipesStore } from '../routes/Recipes/logic'

const UPDATE_CONFIG_RESPONSE = 'UPDATE_CONFIG_RESPONSE'

const initialState = {
  allRecipeVersions: {
    data: [],
    loading: false,
    error: null,
  },
  recipeSearchResults: {
    data: [],
    loading: false,
    error: null,
  },
  ingredients: {
    data: [],
  },
  recipe: {
    data: {
      recipeInfo: {
        sku: '',
        name: '',
        color: '',
        type: '',
        ingredientsType: '',
        cookingTimes: [],
        kitchens: [],
        gluten: 'none',
        lactose: 'none',
        classification: 'product',
        recipeYield: null,
        recipeWaste: null,
        portionSize: null,
        costPerUnit: 0,
        allergenics: {
          contains: [],
          mayContain: [],
        },
      },
      nutritionalInfo: Object.keys(NUTRITIONAL_INFO_NAMES).reduce(
        (prev, next) => ({
          ...prev,
          [next]: {
            name: NUTRITIONAL_INFO_NAMES[next],
            portion: 0,
            dailyValue: 0,
          },
        }),
        {}
      ),
      manuallyCalculated: false,
      ingredients: [],
      subRecipes: [],
      processes: [],
    },
    loading: false,
    loadingSku: false,
    loadingNutritionalTable: false,
    error: null,
    dirty: true,
    costDirty: true,
    success: false,
  },
  processesConstants: {
    relations: [],
    types: [],
  },
  recipeToSimulate: {
    data: {},
    loading: false,
    error: null,
  },
  lastRecipeInfoAdded: {
    recipe: {},
    quantity: 0,
    loading: false,
    error: null,
  },
  currentRecipe: {
    data: null,
    error: null,
    loading: false,
    loadingSku: false,
    loadingNutritionalTable: false,
    dirty: true,
    costDirty: true,
    recipeUsages: [],
    loadingRecipeUsage: false,
    activationSuccess: false,
    relatedProducts: [],
    loadingRelatedProducts: false,
    relatedProductsError: false,
    manuallyCalculated: false,
  },
  allergenics: [],
  allergenicsLoading: false,
  availableRecipeTypes: [],
  productionLocations: [],
  productionLocationsLoading: false,
  cookingMethods: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALLERGENIC: {
      return {
        ...state,
        allergenics: {
          ...state.allergenics,
          [action.payload.allergenics.id]: {
            allergenicsNames: action.payload.allergenics.allergenics,
          },
        },
        allergenicsLoading: false,
      }
    }
    case REMOVE_ALLERGENIC: {
      return {
        ...state,
        allergenics: Object.keys(state.allergenics)
          .filter(key => key !== action.payload.id)
          .reduce((prev, curr) => {
            prev[curr] = state.allergenics[curr]
            return prev
          }, {}),
        allergenicsLoading: false,
      }
    }
    case REMOVE_ALL_ALLERGENICS: {
      return {
        ...state,
        allergenics: [],
      }
    }
    case DISPLAY_ALLERGENICS_START: {
      return {
        ...state,
        allergenicsLoading: true,
      }
    }
    case PRODUCTION_LOCATIONS_REQUEST_START:
      return {
        ...state,
        productionLocationsLoading: true,
      }
    case PRODUCTION_LOCATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        productionLocations:
          action.payload?.map(location => ({
            id: location.id,
            name: location.tradingName,
          })) ?? [],
      }
    case PRODUCTION_LOCATIONS_REQUEST_END:
      return {
        ...state,
        productionLocationsLoading: false,
      }
    case FETCH_RECIPE_REQUEST: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          recipeUsages: initialState.currentRecipe.recipeUsages,
          data: null,
          error: null,
          loading: true,
          activationSuccess: false,
        },
      }
    }
    case FETCH_RECIPE_RESPONSE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          recipeUsages: initialState.currentRecipe.recipeUsages,
          data: {
            ...action.payload.data,
            recipeInfo: {
              ...action.payload.data.recipeInfo,
              productionLocation:
                action.payload.data.recipeInfo.productionLocation?.id,
              color: getRecipeColor(action.payload.data.recipeInfo.color),
            },
          },
          error: null,
          loading: false,
          loadingNutritionalTable: false,
        },
      }
    }
    case FETCH_RECIPE_ERROR: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          recipeUsages: initialState.currentRecipe.recipeUsages,
          data: null,
          error: action.payload.error,
          loading: false,
        },
      }
    }
    case UPDATE_CURRENT_RECIPE_NUTRITIONAL_INFO: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            nutritionalInfo: action.payload.nutritionalInfo,
          },
        },
      }
    }
    case UPDATE_RECIPE_NUTRITIONAL_INFO: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            nutritionalInfo: action.payload.nutritionalInfo,
          },
        },
      }
    }
    case UPDATE_CURRENT_RECIPE_MANUALLY_CALCULATED: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            manuallyCalculated: action.payload.manuallyCalculated,
          },
          dirty: true,
          costDirty: true,
        },
      }
    }
    case UPDATE_RECIPE_MANUALLY_CALCULATED: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            manuallyCalculated: action.payload.manuallyCalculated,
          },
          dirty: true,
          costDirty: true,
        },
      }
    }
    case FETCH_COOKING_METHODS_RESPONSE: {
      return {
        ...state,
        cookingMethods: action.payload.data,
      }
    }
    case FETCH_RECIPE_USAGE_REQUEST: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          loadingRecipeUsage: true,
        },
      }
    }
    case FETCH_RECIPE_USAGE_RESPONSE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          recipeUsages: action.payload.data,
          error: null,
          loadingRecipeUsage: false,
        },
      }
    }
    case FETCH_RECIPE_USAGE_ERROR: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          error: action.payload.error,
          loadingRecipeUsage: false,
        },
      }
    }
    case FETCH_RECIPE_RELATED_PRODUCTS_REQUEST: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          relatedProducts: [],
          loadingRelatedProducts: true,
        },
      }
    }
    case FETCH_RECIPE_RELATED_PRODUCTS_RESPONSE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          relatedProducts: action.payload.data,
          loadingRelatedProducts: false,
        },
      }
    }
    case FETCH_RECIPE_RELATED_PRODUCTS_ERROR: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          relatedProducts: [],
          loadingRelatedProducts: false,
          relatedProductsError: true,
        },
      }
    }
    case UPDATE_RECIPE_VERSION_REQUEST: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          loading: true,
        },
      }
    }
    case DELETE_RECIPE_REQUEST: {
      return {
        ...state,
        allRecipeVersions: {
          ...state.allRecipeVersions,
          loading: true,
        },
      }
    }
    case DELETE_RECIPE_RESPONSE: {
      return {
        ...state,
        allRecipeVersions: {
          ...state.allRecipeVersions,
          loading: false,
          deleted: true,
        },
      }
    }
    case DELETE_RECIPE_ERROR: {
      return {
        ...state,
        allRecipeVersions: {
          ...state.allRecipeVersions,
          loading: false,
          errorObject: action.payload.errorObject,
        },
      }
    }
    case UPDATE_RECIPE_VERSION_RESPONSE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          recipeUsages: initialState.currentRecipe.recipeUsages,
          loading: false,
          activationSuccess: true,
        },
      }
    }
    case UPDATE_RECIPE_VERSION_ERROR: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          error: action.payload.error,
          loading: false,
        },
      }
    }
    case FETCH_RECIPE_VERSIONS_REQUEST: {
      return {
        ...state,
        allRecipeVersions: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_RECIPE_VERSIONS_RESPONSE: {
      return {
        ...state,
        allRecipeVersions: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_RECIPE_VERSIONS_ERROR: {
      return {
        ...state,
        allRecipeVersions: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case FETCH_RECIPES_REQUEST: {
      return {
        ...state,
        recipeSearchResults: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case FETCH_RECIPES_RESPONSE: {
      return {
        ...state,
        recipeSearchResults: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_RECIPES_ERROR: {
      return {
        ...state,
        ...state,
        recipeSearchResults: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case UPDATE_CONFIG_RESPONSE: {
      return {
        ...state,
        recipeSearchResults: {
          data: updateRecipesStore(
            state.recipeSearchResults,
            action.payload.data
          ),
          loading: false,
          error: null,
        },
      }
    }
    case FETCH_PROCESSES_CONSTANTS_RESPONSE: {
      return {
        ...state,
        processesConstants: {
          relations: action.payload.data.relations,
          types: action.payload.data.types,
        },
      }
    }
    case FETCH_AVAILABLE_RECIPE_TYPES_RESPONSE: {
      return {
        ...state,
        availableRecipeTypes: action.payload.data,
      }
    }
    case SEARCH_RECIPES_REQUEST: {
      return {
        ...state,
        recipeSearchResults: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case SEARCH_RECIPES_RESPONSE: {
      return {
        ...state,
        recipeSearchResults: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case SEARCH_RECIPES_ERROR: {
      return {
        ...state,
        recipeSearchResults: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case GET_INGREDIENTS_REQUEST: {
      return {
        ...state,
        ingredients: {
          data: [],
          loading: true,
          error: null,
        },
      }
    }
    case GET_INGREDIENTS_RESPONSE: {
      return {
        ...state,
        ingredients: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case GET_INGREDIENTS_ERROR: {
      return {
        ...state,
        ingredients: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case CLEAR_GENERATED_RECIPE_SKU: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: {
              ...state.recipe.data.recipeInfo,
              sku: '',
            },
          },
        },
      }
    }
    case GENERATE_RECIPE_SKU_REQUEST: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          loadingSku: true,
        },
      }
    }
    case GENERATE_RECIPE_SKU_RESPONSE: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: {
              ...state.recipe.data.recipeInfo,
              sku: action.payload.data,
            },
          },
          loadingSku: false,
        },
      }
    }
    case GENERATE_RECIPE_SKU_ERROR: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: {
              ...state.recipe.data.recipeInfo,
              sku: '',
            },
          },
          loadingSku: false,
          error: action.payload.error,
        },
      }
    }
    case DELETE_RECIPE_SKU: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: {
              ...state.recipe.data.recipeInfo,
              sku: '',
            },
          },
          loadingSku: false,
        },
      }
    }
    case CHANGE_RECIPE_INFORMATION: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: action.payload.data,
          },
          dirty:
            state.recipe.data.recipeInfo.recipeYield !==
              action.payload.data.recipeYield ||
            state.recipe.data.recipeInfo.portionSize !==
              action.payload.data.portionSize ||
            state.recipe.data.recipeInfo.referenceGrammage !==
              action.payload.data.referenceGrammage ||
            state.recipe.data.recipeInfo.recipeWaste !==
              action.payload.data.recipeWaste
              ? true
              : state.recipe.dirty,
          costDirty:
            state.recipe.data.recipeInfo.recipeYield !==
              action.payload.data.recipeYield ||
            state.recipe.data.recipeInfo.portionSize !==
              action.payload.data.portionSize ||
            state.recipe.data.recipeInfo.referenceGrammage !==
              action.payload.data.referenceGrammage ||
            state.recipe.data.recipeInfo.recipeWaste !==
              action.payload.data.recipeWaste
              ? true
              : state.recipe.costDirty,
        },
      }
    }
    case CHANGE_CURRENT_RECIPE_INFORMATION: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            recipeInfo: action.payload.data,
          },
          dirty:
            state.currentRecipe.data.recipeInfo.recipeYield !==
              action.payload.data.recipeYield ||
            state.currentRecipe.data.recipeInfo.recipeWaste !==
              action.payload.data.recipeWaste ||
            state.currentRecipe.data.recipeInfo.portionSize !==
              action.payload.data.portionSize ||
            state.currentRecipe.data.recipeInfo.referenceGrammage !==
              action.payload.data.referenceGrammage ||
            !compareArray(
              state.currentRecipe.data.recipeInfo.cookingTimes,
              action.payload.data.cookingTimes
            )
              ? true
              : state.currentRecipe.dirty,
          costDirty:
            state.currentRecipe.data.recipeInfo.recipeYield !==
              action.payload.data.recipeYield ||
            state.currentRecipe.data.recipeInfo.recipeWaste !==
              action.payload.data.recipeWaste ||
            state.currentRecipe.data.recipeInfo.portionSize !==
              action.payload.data.portionSize ||
            state.currentRecipe.data.recipeInfo.referenceGrammage !==
              action.payload.data.referenceGrammage ||
            !compareArray(
              state.currentRecipe.data.recipeInfo.cookingTimes,
              action.payload.data.cookingTimes
            )
              ? true
              : state.currentRecipe.costDirty,
        },
      }
    }
    case ADD_RECIPE_PROCESS: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            processes: [...state.recipe.data.processes, action.payload.data],
          },
          error: null,
        },
      }
    }
    case ADD_CURRENT_RECIPE_PROCESS: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            processes: [
              ...state.currentRecipe.data.processes,
              action.payload.data,
            ],
          },
        },
      }
    }
    case REPLACE_RECIPE_PROCESSES: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            processes: action.payload.data,
          },
          error: null,
        },
      }
    }
    case REPLACE_CURRENT_RECIPE_PROCESSES: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            processes: action.payload.data,
          },
        },
      }
    }
    case CALCULATE_NUTRITIONAL_TABLE_REQUEST: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          error: null,
          loadingNutritionalTable: true,
        },
        currentRecipe: {
          ...state.currentRecipe,
          error: null,
          loadingNutritionalTable: true,
        },
      }
    }
    case CALCULATE_NUTRITIONAL_TABLE_RESPONSE: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            nutritionalInfo: {
              ...state.recipe.data.nutritionalInfo,
              ...Object.keys(NUTRITIONAL_INFO_NAMES).reduce(
                (prev, next) => ({
                  ...prev,
                  [next]: {
                    name: NUTRITIONAL_INFO_NAMES[next],
                    ...action.payload.data[next],
                  },
                }),
                {}
              ),
              totalSugars: state.recipe.data.nutritionalInfo['totalSugars'],
              addedSugars: state.recipe.data.nutritionalInfo['addedSugars'],
            },
          },
          dirty: !state.recipe.data.recipeInfo.sku,
          costDirty: !state.recipe.data.recipeInfo.sku,
          error: null,
          loadingNutritionalTable: false,
        },
      }
    }
    case CALCULATE_CURRENT_RECIPE_NUTRITIONAL_TABLE_RESPONSE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            nutritionalInfo: {
              ...state.currentRecipe.data.nutritionalInfo,
              ...Object.keys(NUTRITIONAL_INFO_NAMES).reduce(
                (prev, next) => ({
                  ...prev,
                  [next]: {
                    name: NUTRITIONAL_INFO_NAMES[next],
                    ...action.payload.data[next],
                  },
                }),
                {}
              ),
              totalSugars:
                state.currentRecipe.data.nutritionalInfo['totalSugars'],
              addedSugars:
                state.currentRecipe.data.nutritionalInfo['addedSugars'],
            },
          },
          dirty: false,
          costDirty: false,
          error: null,
          loadingNutritionalTable: false,
        },
      }
    }
    case CALCULATE_NUTRITIONAL_TABLE_ERROR: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            nutritionalInfo: Object.keys(NUTRITIONAL_INFO_NAMES).reduce(
              (prev, next) => ({
                ...prev,
                [next]: {
                  name: NUTRITIONAL_INFO_NAMES[next],
                  portion: 0,
                  dailyValue: 0,
                },
              }),
              {}
            ),
          },
          error: action.payload.error,
          loadingNutritionalTable: false,
        },
      }
    }
    case CALCULATE_RECIPE_COST_RESPONSE: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: {
              ...state.recipe.data.recipeInfo,
              costPerUnit: action.payload.data && action.payload.data.cost,
            },
          },
          error: null,
          costDirty: false,
        },
      }
    }
    case CALCULATE_CURRENT_RECIPE_COST_RESPONSE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            recipeInfo: {
              ...state.currentRecipe.data.recipeInfo,
              costPerUnit: action.payload.data && action.payload.data.cost,
            },
          },
          costDirty: false,
        },
      }
    }
    case CALCULATE_RECIPE_COST_ERROR: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            recipeInfo: {
              ...state.recipe.data.recipeInfo,
              costPerUnit: 0,
            },
          },
          error: action.payload.error,
        },
      }
    }
    case RECIPE_TO_SIMULATE_REQUEST: {
      return {
        ...state,
        recipeToSimulate: {
          data: {},
          loading: true,
          error: null,
        },
      }
    }
    case RECIPE_TO_SIMULATE_RESPONSE: {
      return {
        ...state,
        recipeToSimulate: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      }
    }
    case RECIPE_TO_SIMULATE_ERROR: {
      return {
        ...state,
        recipeToSimulate: {
          data: [],
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case RECIPE_TO_ADD_REQUEST: {
      return {
        ...state,
        recipeSearchResults: {
          ...state.recipeSearchResults,
          loading: true,
          error: null,
        },
        lastRecipeInfoAdded: {
          ...state.lastRecipeInfoAdded,
          loading: true,
          error: null,
        },
      }
    }
    case RECIPE_TO_ADD_RESPONSE: {
      return {
        ...state,
        recipeSearchResults: {
          data: action.payload.data.recipeSearchResults.data,
          loading: false,
          error: null,
        },
        lastRecipeInfoAdded: {
          ...action.payload.data.newRecipeAdded,
          loading: false,
          error: null,
        },
      }
    }
    case RECIPE_TO_ADD_ERROR: {
      return {
        ...state,
        recipeSearchResults: {
          ...state.recipeSearchResults,
          loading: false,
          error: action.payload.error,
        },
        loading: false,
      }
    }
    case ADD_INGREDIENT: {
      const {
        productionName: name,
        feedstock,
        ...rest
      } = state.ingredients.data.find(
        ingredient => action.payload.data.code === ingredient.code
      )
      const { ingredients: recipeIngredients } = state.recipe.data
      const index = recipeIngredients.findIndex(
        ingredient => action.payload.data.code === ingredient.code
      )
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            ingredients:
              index > -1
                ? dotProp.set(recipeIngredients, index, {
                    ...rest,
                    name,
                    price: feedstock.currentPrice,
                    quantity: action.payload.data.quantity,
                    showCompositionOnTag:
                      action.payload.data.showCompositionOnTag,
                  })
                : recipeIngredients.concat([
                    {
                      ...rest,
                      name,
                      price: feedstock.currentPrice,
                      quantity: action.payload.data.quantity,
                      showCompositionOnTag:
                        action.payload.data.showCompositionOnTag,
                    },
                  ]),
          },
          error: null,
          dirty: true,
          costDirty: true,
        },
      }
    }

    case ADD_CURRENT_RECIPE_INGREDIENT: {
      const {
        recipeName: name,
        feedstock,
        ...rest
      } = state.ingredients.data.find(
        ingredient => action.payload.data.code === ingredient.code
      )
      const { ingredients: recipeIngredients } = state.currentRecipe.data
      const index = recipeIngredients.findIndex(
        ingredient => action.payload.data.code === ingredient.code
      )
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            ingredients:
              index > -1
                ? dotProp.set(recipeIngredients, index, {
                    ...rest,
                    name,
                    price: feedstock.currentPrice,
                    quantity: action.payload.data.quantity,
                    showCompositionOnTag:
                      action.payload.data.showCompositionOnTag,
                  })
                : recipeIngredients.concat([
                    {
                      ...rest,
                      name,
                      price: feedstock.currentPrice,
                      quantity: action.payload.data.quantity,
                      showCompositionOnTag:
                        action.payload.data.showCompositionOnTag,
                    },
                  ]),
          },
          error: null,
          dirty: true,
          costDirty: true,
        },
      }
    }
    case REMOVE_INGREDIENT: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            ingredients: state.recipe.data.ingredients.filter(
              ingredient => action.payload.data.code !== ingredient.code
            ),
          },
          error: null,
          dirty: true,
          costDirty: true,
        },
      }
    }
    case REMOVE_CURRENT_RECIPE_INGREDIENT: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            ingredients: state.currentRecipe.data.ingredients.filter(
              ingredient => action.payload.data.code !== ingredient.code
            ),
          },
          dirty: true,
          costDirty: true,
        },
      }
    }
    case ADD_SUBRECIPE: {
      const newSubRecipe = state.recipeSearchResults.data.find(
        subRecipe => action.payload.data.code === subRecipe.code
      )
      const { subRecipes } = state.recipe.data
      const index = subRecipes.findIndex(
        subRecipe => action.payload.data.code === subRecipe.code
      )
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            subRecipes:
              index > -1
                ? dotProp.set(subRecipes, index, {
                    ...newSubRecipe,
                    quantity: action.payload.data.quantity,
                  })
                : subRecipes.concat([
                    {
                      ...newSubRecipe,
                      quantity: action.payload.data.quantity,
                    },
                  ]),
          },
          error: null,
          dirty: true,
          costDirty: true,
        },
      }
    }

    case ADD_CURRENT_RECIPE_SUBRECIPE: {
      const newSubRecipe = state.recipeSearchResults.data.find(
        subRecipe => action.payload.data.code === subRecipe.code
      )
      const { subRecipes } = state.currentRecipe.data
      const index = subRecipes.findIndex(
        subRecipe => action.payload.data.code === subRecipe.code
      )
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            subRecipes:
              index > -1
                ? dotProp.set(subRecipes, index, {
                    ...newSubRecipe,
                    quantity: action.payload.data.quantity,
                  })
                : subRecipes.concat([
                    {
                      ...newSubRecipe,
                      quantity: action.payload.data.quantity,
                    },
                  ]),
          },
          error: null,
          dirty: true,
          costDirty: true,
        },
      }
    }
    case REMOVE_SUBRECIPE: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          data: {
            ...state.recipe.data,
            subRecipes: state.recipe.data.subRecipes.filter(
              subRecipe => action.payload.data.code !== subRecipe.code
            ),
          },
          error: null,
          dirty: true,
          costDirty: true,
        },
      }
    }
    case CREATE_RECIPE_REQUEST: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          loading: true,
          error: null,
        },
      }
    }
    case CREATE_RECIPE_RESPONSE: {
      return {
        ...state,
        recipe: {
          ...initialState.recipe,
          success: true,
        },
      }
    }
    case CREATE_RECIPE_ERROR: {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          loading: false,
          error: action.payload.error,
        },
      }
    }
    case CREATE_RECIPE_RESET: {
      return {
        ...state,
        recipe: { ...state.recipe, error: null, success: false },
      }
    }
    case REMOVE_CURRENT_RECIPE_SUBRECIPE: {
      return {
        ...state,
        currentRecipe: {
          ...state.currentRecipe,
          data: {
            ...state.currentRecipe.data,
            subRecipes: state.currentRecipe.data.subRecipes.filter(
              subRecipe => action.payload.data.code !== subRecipe.code
            ),
          },
          dirty: true,
          costDirty: true,
        },
      }
    }
    default:
      return state
  }
}
