// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu'
export const TOGGLE_DISABLED_NAV = 'toggle_disabled_nav'
export const TOGGLE_FULL_SCREEN = 'toggle_full_screen'
export const TOGGLE_DRAWER_TYPE = 'toggle_drawer_type'
export const DRAWER_TYPE = 'drawer_type'
export const FIXED_DRAWER = 'fixed_drawer'
export const COLLAPSED_DRAWER = 'collapsible'
export const MINI_DRAWER = 'mini_drawer'
export const THEME_COLOR = 'theme_color'
export const DARK_THEME = 'dark_theme'
export const WINDOW_WIDTH = 'window-width'
export const SWITCH_LANGUAGE = 'switch-language'

// Auth const
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST'
export const SIGNIN_RESPONSE = 'SIGNIN_RESPONSE'
export const SIGNIN_ERROR = 'SIGNIN_ERROR'
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST'
export const SIGNOUT_RESPONSE = 'SIGNOUT_RESPONSE'
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR'
export const SET_USER = 'SET_USER'

// Notification constants
export const NOTIFICATION_SEND = 'NOTIFICATION_SEND'
export const NOTIFICATION_RESET = 'NOTIFICATION_RESET'

// Receptions - Search
export const SEARCH_RECEPTIONS_REQUEST = 'SEARCH_RECEPTIONS_REQUEST'
export const SEARCH_RECEPTIONS_RESPONSE = 'SEARCH_RECEPTIONS_RESPONSE'
export const SEARCH_RECEPTIONS_ERROR = 'SEARCH_RECEPTIONS_ERROR'

// Receptions - Edit
export const EDIT_RECEPTIONS_REQUEST = 'EDIT_RECEPTIONS_REQUEST'
export const EDIT_RECEPTIONS_RESPONSE = 'EDIT_RECEPTIONS_RESPONSE'
export const EDIT_RECEPTIONS_ERROR = 'EDIT_RECEPTIONS_ERROR'

// Receptions - Fetch all from last month
export const FETCH_ALL_RECEPTIONS_REQUEST = 'FETCH_ALL_RECEPTIONS_REQUEST'
export const FETCH_ALL_RECEPTIONS_RESPONSE = 'FETCH_ALL_RECEPTIONS_RESPONSE'
export const FETCH_ALL_RECEPTIONS_ERROR = 'FETCH_ALL_RECEPTIONS_ERROR'

// Receptions - Fetch Kitchens
export const FETCH_KITCHENS_REQUEST = 'FETCH_KITCHENS_REQUEST'
export const FETCH_KITCHENS_RESPONSE = 'FETCH_KITCHENS_RESPONSE'
export const FETCH_KITCHENS_ERROR = 'FETCH_KITCHENS_ERROR'

// Recipe recalc
export const RECALCULATE_RECIPES_REQUEST = 'RECALCULATE_RECIPES_REQUEST'
export const RECALCULATE_RECIPES_RESPONSE = 'RECALCULATE_RECIPES_RESPONSE'
export const RECALCULATE_RECIPES_ERROR = 'RECALCULATE_RECIPES_ERROR'

// Recipe Simulation
export const GET_INGREDIENTS_REQUEST = 'GET_INGREDIENTS_REQUEST'
export const GET_INGREDIENTS_RESPONSE = 'GET_INGREDIENTS_RESPONSE'
export const GET_INGREDIENTS_ERROR = 'GET_INGREDIENTS_ERROR'
export const GET_RECIPES_REQUEST = 'GET_RECIPES_REQUEST'
export const GET_RECIPES_RESPONSE = 'GET_RECIPES_RESPONSE'
export const GET_RECIPES_ERROR = 'GET_RECIPES_ERROR'

// Recipe Simulation - Selected Recipe
export const RECIPE_TO_SIMULATE_REQUEST = 'RECIPE_TO_SIMULATE_REQUEST'
export const RECIPE_TO_SIMULATE_RESPONSE = 'RECIPE_TO_SIMULATE_RESPONSE'
export const RECIPE_TO_SIMULATE_ERROR = 'RECIPE_TO_SIMULATE_ERROR'
export const RECIPE_TO_ADD_REQUEST = 'RECIPE_TO_ADD_REQUEST'
export const RECIPE_TO_ADD_RESPONSE = 'RECIPE_TO_ADD_RESPONSE'
export const RECIPE_TO_ADD_ERROR = 'RECIPE_TO_ADD_ERROR'

// Recipe Versioning Historic
export const FETCH_RECIPE_VERSIONS_REQUEST = 'FETCH_RECIPE_VERSIONS_REQUEST'
export const FETCH_RECIPE_VERSIONS_RESPONSE = 'FETCH_RECIPE_VERSIONS_RESPONSE'
export const FETCH_RECIPE_VERSIONS_ERROR = 'FETCH_RECIPE_VERSIONS_ERROR'

// Recipe - Search
export const SEARCH_RECIPES_REQUEST = 'SEARCH_RECIPES_REQUEST'
export const SEARCH_RECIPES_RESPONSE = 'SEARCH_RECIPES_RESPONSE'
export const SEARCH_RECIPES_ERROR = 'SEARCH_RECIPES_ERROR'

// Recipe - Fetch
export const FETCH_RECIPES_REQUEST = 'FETCH_RECIPES_REQUEST'
export const FETCH_RECIPES_RESPONSE = 'FETCH_RECIPES_RESPONSE'
export const FETCH_RECIPES_ERROR = 'FETCH_RECIPES_ERROR'
export const FETCH_RECIPE_REQUEST = 'FETCH_RECIPE_REQUEST'
export const FETCH_RECIPE_RESPONSE = 'FETCH_RECIPE_RESPONSE'
export const FETCH_RECIPE_ERROR = 'FETCH_RECIPE_ERROR'

// Recipe - handleSKU
export const CLEAR_GENERATED_RECIPE_SKU = 'CLEAR_GENERATED_RECIPE_SKU'
export const GENERATE_RECIPE_SKU_REQUEST = 'GENERATE_RECIPE_SKU_REQUEST'
export const GENERATE_RECIPE_SKU_RESPONSE = 'GENERATE_RECIPE_SKU_RESPONSE'
export const GENERATE_RECIPE_SKU_ERROR = 'GENERATE_RECIPE_SKU_ERROR'
export const DELETE_RECIPE_SKU = 'DELETE_RECIPE_SKU'
export const CHANGE_RECIPE_INFORMATION = 'CHANGE_RECIPE_INFORMATION'
export const CHANGE_CURRENT_RECIPE_INFORMATION =
  'CHANGE_CURRENT_RECIPE_INFORMATION'

// Recipe - Activation
export const FETCH_RECIPE_USAGE_REQUEST = 'FETCH_RECIPE_USAGE_REQUEST'
export const FETCH_RECIPE_USAGE_RESPONSE = 'FETCH_RECIPE_USAGE_RESPONSE'
export const FETCH_RECIPE_USAGE_ERROR = 'FETCH_RECIPE_USAGE_ERROR'
export const UPDATE_RECIPE_VERSION_REQUEST = 'UPDATE_RECIPE_VERSION_REQUEST'
export const UPDATE_RECIPE_VERSION_RESPONSE = 'UPDATE_RECIPE_VERSION_RESPONSE'
export const UPDATE_RECIPE_VERSION_ERROR = 'UPDATE_RECIPE_VERSION_ERROR'
export const FETCH_RECIPE_RELATED_PRODUCTS_REQUEST =
  'FETCH_RECIPE_RELATED_PRODUCTS_REQUEST'
export const FETCH_RECIPE_RELATED_PRODUCTS_RESPONSE =
  'FETCH_RECIPE_RELATED_PRODUCTS_RESPONSE'
export const FETCH_RECIPE_RELATED_PRODUCTS_ERROR =
  'FETCH_RECIPE_RELATED_PRODUCTS_ERROR'
export const DELETE_RECIPE_REQUEST = 'REMOVE_RECIPE_REQUEST'
export const DELETE_RECIPE_RESPONSE = 'REMOVE_RECIPE_RESPONSE'
export const DELETE_RECIPE_ERROR = 'REMOVE_RECIPE_ERROR'

// Recipe - Nutritional Info
export const UPDATE_CURRENT_RECIPE_NUTRITIONAL_INFO =
  'UPDATE_CURRENT_RECIPE_NUTRITIONAL_INFO'
export const UPDATE_RECIPE_NUTRITIONAL_INFO = 'UPDATE_RECIPE_NUTRITIONAL_INFO'
export const UPDATE_CURRENT_RECIPE_MANUALLY_CALCULATED =
  'UPDATE_CURRENT_RECIPE_MANUALLY_CALCULATED'
export const UPDATE_RECIPE_MANUALLY_CALCULATED =
  'UPDATE_RECIPE_MANUALLY_CALCULATED'

/**
 * Recipe Creation - Ingredients
 */
export const ADD_INGREDIENT = 'ADD_INGREDIENT'
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT'
export const ADD_CURRENT_RECIPE_INGREDIENT = 'ADD_CURRENT_RECIPE_INGREDIENT'
export const REMOVE_CURRENT_RECIPE_INGREDIENT =
  'REMOVE_CURRENT_RECIPE_INGREDIENT'
/**
 * Recipe Creation - Add SubRecipe
 */
export const ADD_SUBRECIPE = 'ADD_SUBRECIPE'
export const REMOVE_SUBRECIPE = 'REMOVE_SUBRECIPE'
export const ADD_CURRENT_RECIPE_SUBRECIPE = 'ADD_CURRENT_RECIPE_SUBRECIPE'
export const REMOVE_CURRENT_RECIPE_SUBRECIPE = 'REMOVE_CURRENT_RECIPE_SUBRECIPE'
/**
 * Recipe Creation - Processes
 */
export const ADD_RECIPE_PROCESS = 'ADD_RECIPE_PROCESS'
export const REPLACE_RECIPE_PROCESSES = 'REPLACE_RECIPE_PROCESSES'
export const ADD_CURRENT_RECIPE_PROCESS = 'ADD_CURRENT_RECIPE_PROCESS'
export const REPLACE_CURRENT_RECIPE_PROCESSES =
  'REPLACE_CURRENT_RECIPE_PROCESSES'
export const FETCH_PROCESSES_CONSTANTS_RESPONSE =
  'FETCH_PROCESSES_CONSTANTS_RESPONSE'
export const FETCH_PROCESSES_CONSTANTS_ERROR = 'FETCH_PROCESSES_CONSTANTS_ERROR'

/**
 * Recipe Creation - Fetch Available Types
 */
export const FETCH_AVAILABLE_RECIPE_TYPES_REQUEST =
  'FETCH_AVAILABLE_RECIPE_TYPES_REQUEST'
export const FETCH_AVAILABLE_RECIPE_TYPES_RESPONSE =
  'FETCH_AVAILABLE_RECIPE_TYPES_RESPONSE'
export const FETCH_AVAILABLE_RECIPE_TYPES_ERROR =
  'FETCH_AVAILABLE_RECIPE_TYPES_ERROR'

/**
 * Recipe Creation - Allergenics
 */
export const DISPLAY_ALLERGENICS_START = 'DISPLAY_ALLERGENICS_START'
export const REMOVE_ALLERGENIC = 'REMOVE_ALLERGENIC'
export const ADD_ALLERGENIC = 'ADD_ALLERGENIC'
export const REMOVE_ALL_ALLERGENICS = 'REMOVE_ALL_ALLERGENICS'

/**
 * Recipe - Production Locations
 */
export const PRODUCTION_LOCATIONS_REQUEST_START =
  'PRODUCTION_LOCATIONS_REQUEST_START'
export const PRODUCTION_LOCATIONS_REQUEST_SUCCESS =
  'PRODUCTION_LOCATIONS_REQUEST_SUCCESS'
export const PRODUCTION_LOCATIONS_REQUEST_END =
  'PRODUCTION_LOCATIONS_REQUEST_END'

/**
 * Recipe Creation - Calculate Nutritional Table And Cost
 */
export const CALCULATE_NUTRITIONAL_TABLE_REQUEST =
  'CALCULATE_NUTRITIONAL_TABLE_REQUEST'
export const CALCULATE_NUTRITIONAL_TABLE_RESPONSE =
  'CALCULATE_NUTRITIONAL_TABLE_RESPONSE'
export const CALCULATE_CURRENT_RECIPE_NUTRITIONAL_TABLE_RESPONSE =
  'CALCULATE_CURRENT_RECIPE_NUTRITIONAL_TABLE_RESPONSE'
export const CALCULATE_NUTRITIONAL_TABLE_ERROR =
  'CALCULATE_NUTRITIONAL_TABLE_ERROR'
export const CALCULATE_RECIPE_COST_REQUEST = 'CALCULATE_RECIPE_COST_REQUEST'
export const CALCULATE_RECIPE_COST_RESPONSE = 'CALCULATE_RECIPE_COST_RESPONSE'
export const CALCULATE_CURRENT_RECIPE_COST_RESPONSE =
  'CALCULATE_CURRENT_RECIPE_COST_RESPONSE'
export const CALCULATE_RECIPE_COST_ERROR = 'CALCULATE_RECIPE_COST_ERROR'

/**
 * Recipe Creation - Create
 */
export const CREATE_RECIPE_REQUEST = 'CREATE_RECIPE_REQUEST'
export const CREATE_RECIPE_RESPONSE = 'CREATE_RECIPE_RESPONSE'
export const CREATE_RECIPE_ERROR = 'CREATE_RECIPE_ERROR'
export const CREATE_RECIPE_RESET = 'CREATE_RECIPE_RESET'

/**
 * Recipe Editing
 */
export const EDIT_RECIPE_REQUEST = 'EDIT_RECIPE_REQUEST'
export const EDIT_RECIPE_RESPONSE = 'EDIT_RECIPE_RESPONSE'
export const EDIT_RECIPE_ERROR = 'EDIT_RECIPE_ERROR'

// Cooking Methods
export const FETCH_COOKING_METHODS_REQUEST = 'FETCH_COOKING_METHODS_REQUEST'
export const FETCH_COOKING_METHODS_RESPONSE = 'FETCH_COOKING_METHODS_RESPONSE'
export const FETCH_COOKING_METHODS_ERROR = 'FETCH_COOKING_METHODS_ERROR'

// Resources - Search
export const SEARCH_RESOURCES_REQUEST = 'SEARCH_RESOURCES_REQUEST'
export const SEARCH_RESOURCES_RESPONSE = 'SEARCH_RESOURCES_RESPONSE'
export const SEARCH_RESOURCES_ERROR = 'SEARCH_RESOURCES_ERROR'

// Resources - Fetch
export const FETCH_RESOURCES_REQUEST = 'FETCH_RESOURCES_REQUEST'
export const FETCH_RESOURCES_RESPONSE = 'FETCH_RESOURCES_RESPONSE'
export const FETCH_RESOURCES_ERROR = 'FETCH_RESOURCES_ERROR'

// Resources - FetchDropDown
export const FETCH_SELECT_RESOURCES_REQUEST = 'FETCH_SELECT_RESOURCES_REQUEST'
export const FETCH_SELECT_RESOURCES_RESPONSE = 'FETCH_SELECT_RESOURCES_RESPONSE'
export const FETCH_SELECT_RESOURCES_ERROR = 'FETCH_SELECT_RESOURCES_ERROR'

// Resources - Edit
export const EDIT_RESOURCES_REQUEST = 'EDIT_RESOURCES_REQUEST'
export const EDIT_RESOURCES_RESPONSE = 'EDIT_RESOURCES_RESPONSE'
export const EDIT_RESOURCES_ERROR = 'EDIT_RESOURCES_ERROR'

// Resources - Create
export const CREATE_RESOURCES_REQUEST = 'CREATE_RESOURCES_REQUEST'
export const CREATE_RESOURCES_RESPONSE = 'CREATE_RESOURCES_RESPONSE'
export const CREATE_RESOURCES_ERROR = 'CREATE_RESOURCES_ERROR'

// PPCP - Production Data
export const FETCH_PRODUCTION_DATA_REQUEST = 'FETCH_PRODUCTION_DATA_REQUEST'
export const FETCH_PRODUCTION_DATA_RESPONSE = 'FETCH_PRODUCTION_DATA_RESPONSE'
export const FETCH_PRODUCTION_DATA_ERROR = 'FETCH_PRODUCTION_DATA_ERROR'

// PPCP - Production Recipes
export const FETCH_PRODUCTION_RECIPES_REQUEST =
  'FETCH_PRODUCTION_RECIPES_REQUEST'
export const FETCH_PRODUCTION_RECIPES_RESPONSE =
  'FETCH_PRODUCTION_RECIPES_RESPONSE'
export const FETCH_PRODUCTION_RECIPES_ERROR = 'FETCH_PRODUCTION_RECIPES_ERROR'

// PPCP - Planning
export const FETCH_PRODUCTION_PLANNING_REQUEST =
  'FETCH_PRODUCTION_PLANNING_REQUEST'
export const FETCH_PRODUCTION_PLANNING_RESPONSE =
  'FETCH_PRODUCTION_PLANNING_RESPONSE'
export const FETCH_PRODUCTION_PLANNING_ERROR = 'FETCH_PRODUCTION_PLANNING_ERROR'

// PPCP - Historic
export const FETCH_PLANNING_HISTORIC_REQUEST = 'FETCH_PLANNING_HISTORIC_REQUEST'
export const FETCH_PLANNING_HISTORIC_RESPONSE =
  'FETCH_PLANNING_HISTORIC_RESPONSE'
export const FETCH_PLANNING_HISTORIC_ERROR = 'FETCH_PLANNING_HISTORIC_ERROR'

// PPCP - Run PPCP
export const RUN_PPCP_REQUEST = 'RUN_PPCP_REQUEST'
export const RUN_PPCP_RESPONSE = 'RUN_PPCP_RESPONSE'
export const RUN_PPCP_ERROR = 'RUN_PPCP_ERROR'

// PPCP - Task Status
export const FETCH_PPCP_TASK_STATUS_REQUEST = 'FETCH_PPCP_TASK_STATUS_REQUEST'
export const FETCH_PPCP_TASK_STATUS_RESPONSE = 'FETCH_PPCP_TASK_STATUS_RESPONSE'
export const FETCH_PPCP_TASK_STATUS_ERROR = 'FETCH_PPCP_TASK_STATUS_ERROR'

// PPCP - Production Recipe
export const FETCH_PRODUCTION_RECIPE_REQUEST = 'FETCH_PRODUCTION_RECIPE_REQUEST'
export const FETCH_PRODUCTION_RECIPE_RESPONSE =
  'FETCH_PRODUCTION_RECIPE_RESPONSE'
export const FETCH_PRODUCTION_RECIPE_ERROR = 'FETCH_PRODUCTION_RECIPE_ERROR'

// PPCP - Create Production Recipe
export const CREATE_PRODUCTION_RECIPE_REQUEST =
  'CREATE_PRODUCTION_RECIPE_REQUEST'
export const CREATE_PRODUCTION_RECIPE_RESPONSE =
  'CREATE_PRODUCTION_RECIPE_RESPONSE'
export const CREATE_PRODUCTION_RECIPE_ERROR = 'CREATE_PRODUCTION_RECIPE_ERROR'

// PPCP - Edit Production Recipe
export const EDIT_PRODUCTION_RECIPE_REQUEST = 'EDIT_PRODUCTION_RECIPE_REQUEST'
export const EDIT_PRODUCTION_RECIPE_RESPONSE = 'EDIT_PRODUCTION_RECIPE_RESPONSE'
export const EDIT_PRODUCTION_RECIPE_ERROR = 'EDIT_PRODUCTION_RECIPE_ERROR'

// PPCP - Production Recipe Step
export const ADD_RECIPE_STEP = 'ADD_RECIPE_STEP'
export const REMOVE_RECIPE_STEP = 'REMOVE_RECIPE_STEP'
export const CHANGE_RECIPE_DATA = 'CHANGE_RECIPE_DATA'
export const CHANGE_RECIPE_STEP = 'CHANGE_RECIPE_STEP'
export const SELECT_RECIPE_STEP = 'SELECT_RECIPE_STEP'
export const SET_EMPTY_RECIPE = 'SET_EMPTY_RECIPE'
