import { defineMessages } from 'react-intl'

export default defineMessages({
  configTag: {
    id: 'app.tags.config',
    defaultMessage: 'Configurar etiquetas',
  },
  printTag: {
    id: 'app.tags.print',
    defaultMessage: 'Imprimir etiquetas de produto - Cozinha',
  },
  productionDate: {
    id: 'app.tags.print.productionDate',
    defaultMessage: 'Data de Produção',
  },
  printAction: {
    id: 'app.tags.print.action',
    defaultMessage: 'Imprimir',
  },
  selectProduct: {
    id: 'app.tags.print.selectProduct',
    defineMessages: 'Selecionar Produto',
  },
})
