import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import { withConsumer } from 'helperFunctions/withConsumer'
import { FormContext } from 'components/Form'
import styles from './styles'
import { save, edit } from '../../../formattedMessages'

class ActionButtons extends Component {
  handleClick = validateAll => {
    const bool = validateAll()
    if (bool && this.props.isRegistering) {
      this.props.onSaveClick()
    }
    if (bool && !this.props.isRegistering) {
      this.props.onSaveEditionClick()
    }
  }

  render() {
    const { isRegistering, classes, onEditClick } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        spacing={8}
        className={classes.buttonsContainer}
      >
        <Grid item>
          <Button
            isDefault
            onClick={() => this.handleClick(this.props.validateAll)}
          >
            {save}
          </Button>
        </Grid>
        {isRegistering ? null : (
          <Grid item>
            <Button onClick={onEditClick}>{edit}</Button>
          </Grid>
        )}
      </Grid>
    )
  }
}

ActionButtons.propTypes = {
  isRegistering: PropTypes.bool,
  onEditClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  onSaveClick: PropTypes.func,
  onSaveEditionClick: PropTypes.func,
}

ActionButtons.defaultProps = {
  isRegistering: false,
  onEditClick: () => {},
  onSaveClick: () => {},
  onSaveEditionClick: () => {},
}

export default withConsumer(FormContext)(withStyles(styles)(ActionButtons))
