import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'material-ui-pickers'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './styles'

class DateSelect extends React.Component {
  handleChange = date => {
    this.props.onSelect(date ? date.format('YYYY-MM-DD') : null)
  }

  render() {
    const { selectedDate, maxDate, className, classes, emptyLabel, label } =
      this.props

    return (
      <form className={`row ${classes[className]}`} autoComplete="off">
        <div className="col-lg-12 col-sm-12 col-12">
          <InputLabel
            className={`w-100 mb-2 ${classes.form} ${classes[className]}`}
          >
            <DatePicker
              className={classes[className]}
              maxDate={maxDate}
              label={label}
              autoOk
              clearable
              clearLabel="Limpar"
              cancelLabel="Cancelar"
              emptyLabel={emptyLabel}
              okLabel="Buscar"
              format="DD/MM/YYYY"
              leftArrowIcon={<KeyboardArrowLeftIcon />}
              rightArrowIcon={<KeyboardArrowRightIcon />}
              showTodayButton
              onChange={this.handleChange}
              value={selectedDate}
            />
          </InputLabel>
        </div>
      </form>
    )
  }
}

DateSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  emptyLabel: PropTypes.string,
}

DateSelect.defaultProps = {
  selectedDate: null,
  label: 'Data',
  emptyLabel: 'Selecione uma data',
  className: '',
}
export default withStyles(styles)(DateSelect)
