import { nutritionFactsNames } from './recipeNutritionalValues'

export const formatIngredient = (ingredient, quantity) => {
  const {
    productionName: name,
    feedstock,
    processingYield,
    waste,
    code,
  } = ingredient
  if (!feedstock) return ingredient
  const { currentPrice, nutritionalInfo } = feedstock
  return {
    name,
    code,
    quantity,
    price: currentPrice,
    currentPrice,
    pricePerUnit: 0,
    percentPrice: 0,
    waste,
    processingYield,
    nutritionFacts: {
      kcal: nutritionalInfo?.kcal ?? 0,
      carbs: nutritionalInfo?.carbs ?? 0,
      proteins: nutritionalInfo?.proteins ?? 0,
      totalFats: nutritionalInfo?.totalFats ?? 0,
      satFats: nutritionalInfo?.satFats ?? 0,
      transFats: nutritionalInfo?.transFats ?? 0,
      fibers: nutritionalInfo?.fibers ?? 0,
      sodium: nutritionalInfo?.sodium ?? 0,
    },
  }
}

export const formatIngredients = ingredients =>
  ingredients.map(ingredientObject => ({
    ...formatIngredient(
      ingredientObject.ingredient
        ? ingredientObject.ingredient
        : ingredientObject
    ),
    quantity: ingredientObject.quantity,
  }))

export const formatNutritionFacts = nutritionFacts =>
  Object.keys(nutritionFactsNames).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...nutritionFacts[key],
        name: nutritionFactsNames[key],
      },
    }),
    {}
  )

export const formatSubRecipesForSimulate = recipes =>
  recipes.map(recipe => ({
    ...formatSubRecipe(recipe.recipe, recipe.quantity, { isSimulation: true }),
    subRecipeYield: 100,
  }))

export const formatSubRecipesForCreate = recipes =>
  recipes.map(recipe => ({
    ...formatSubRecipe(recipe, recipe.quantity, { isSimulation: false }),
    subRecipeYield: 100,
  }))

const getSubRecipes = recipe => {
  if (recipe.recipesInfo && recipe.recipesInfo.length > 0)
    return formatSubRecipesForSimulate(recipe.recipesInfo)
  if (recipe.subRecipes && recipe.subRecipes.length > 0)
    return formatSubRecipesForCreate(recipe.subRecipes)
  return []
}

export const formatRecipe = (recipe, isSimulation) => ({
  name: recipe.name || (recipe.recipeInfo && recipe.recipeInfo.name),
  recipeYield:
    (recipe.recipeYield && Number(recipe.recipeYield).toFixed(2)) ||
    (recipe.recipeInfo && Number(recipe.recipeInfo.recipeYield).toFixed(2)) ||
    0,
  recipeWaste:
    (recipe.recipeWaste && Number(recipe.recipeWaste).toFixed(2)) ||
    (recipe.recipeInfo && Number(recipe.recipeInfo.recipeWaste).toFixed(2)) ||
    0,
  portionSize:
    (recipe.packageWeight && Number(recipe.packageWeight).toFixed(2)) ||
    (recipe.recipeInfo && Number(recipe.recipeInfo.portionSize).toFixed(2)) ||
    0,
  referenceGrammage:
    (recipe.referenceGrammage && Number(recipe.referenceGrammage).toFixed(2)) ||
    (recipe.recipeInfo &&
      Number(recipe.recipeInfo.referenceGrammage).toFixed(2)) ||
    0,
  nutritionFacts: isSimulation
    ? formatNutritionFacts(recipe.nutritionalInfo)
    : recipe.nutritionalInfo,
  ingredients:
    recipe.ingredients && recipe.ingredients.length > 0
      ? formatIngredients(recipe.ingredients)
      : [],
  subRecipes: getSubRecipes(recipe),
})

export const formatSubRecipe = (recipe, quantity, { isSimulation }) => {
  const { ingredients, subRecipes, recipeYield, recipeWaste } = formatRecipe(
    recipe,
    isSimulation
  )
  return {
    name: recipe.name,
    ingredients,
    subRecipes,
    recipeYield,
    recipeWaste,
    quantity,
    costPerUnit: 0,
    percentPrice: 0,
  }
}

export const formatAddedRecipe = (recipe, quantity) => {
  const { ingredients, subRecipes, recipeYield, recipeWaste } =
    formatRecipe(recipe)
  return {
    name: recipe.name,
    subRecipeYield: 100,
    quantity,
    ingredients,
    subRecipes,
    recipeYield,
    recipeWaste,
    costPerUnit: 0,
    percentPrice: 0,
  }
}
