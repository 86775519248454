import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from 'components/TextField'
import DropDown from 'components/DropDown'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputValidator from 'components/Form/validators/InputValidator'
import { required, positiveNumber } from 'components/Form/validations'
import {
  recipeProductionResource,
  recipeSetupTime,
  recipeComplexity,
} from '../../../formattedMessages'
import defineMessages from '../../../Messages'
import styles from './styles'

class RecipeSteps extends Component {
  handleClick = () => {
    this.props.removeStep(this.props.index)
  }

  render() {
    const {
      index,
      productionResources,
      handleSelect,
      selectedItem,
      complexityRange,
      onChange,
      isVisualizing,
      classes,
    } = this.props
    const { errors } = defineMessages
    return (
      <Grid container direction="row" alignItems="flex-start" spacing={8}>
        <Grid item xs={3}>
          <DropDown
            index={index}
            name="name"
            items={productionResources}
            fullWidth
            label={recipeProductionResource}
            handleChange={name => value => handleSelect(index, name, value)}
            selectedItem={
              selectedItem[index] &&
              selectedItem[index].resource &&
              selectedItem[index].resource.name
            }
            validations={[required(errors.required)]}
            isVisualizing={isVisualizing}
          />
        </Grid>
        <Grid item>
          <InputValidator
            id="timeSetup"
            validations={[
              required(errors.required),
              positiveNumber(errors.positiveNumber),
            ]}
          >
            <TextField
              label={recipeSetupTime}
              margin="normal"
              id="timeSetup"
              isInputNumber
              value={selectedItem[index] && selectedItem[index].timeSetup}
              handleChange={e => onChange(e, index)}
              disabled={isVisualizing}
            />
          </InputValidator>
        </Grid>
        <Grid item>
          <DropDown
            name="complexity"
            label={recipeComplexity}
            items={complexityRange}
            handleChange={name => value => handleSelect(index, name, value)}
            validations={[
              required(errors.required),
              positiveNumber(errors.positiveNumber),
            ]}
            selectedItem={selectedItem[index] && selectedItem[index].complexity}
            isVisualizing={isVisualizing}
          />
        </Grid>
        <Grid item className={classes.delete}>
          <Fab
            disabled={isVisualizing}
            aria-label="Delete"
            onClick={this.handleClick}
            className={classes.fab}
          >
            <DeleteIcon className={classes.icon} />
          </Fab>
        </Grid>
      </Grid>
    )
  }
}

RecipeSteps.propTypes = {
  index: PropTypes.number.isRequired,
  removeStep: PropTypes.func.isRequired,
  productionResources: PropTypes.array,
  handleSelect: PropTypes.func.isRequired,
  selectedItem: PropTypes.array,
  complexityRange: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isVisualizing: PropTypes.bool,
}

RecipeSteps.defaultProps = {
  productionResources: [],
  selectedItem: [],
  complexityRange: [],
  isVisualizing: false,
}

export default withStyles(styles)(RecipeSteps)
