import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'
import dotProp from 'dot-prop-immutable'
import styles from './styles'

class ResultsTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 20,
  }

  onRowClick = resource => {
    const { goToEditPage } = this.props

    goToEditPage && goToEditPage(resource)
  }

  renderTableRowCells = result =>
    this.props.fieldsPath.map(fieldpath => (
      <TableCell
        key={fieldpath}
        className={this.props.textAlign}
        align="center"
      >
        {dotProp.get(result, fieldpath)}
      </TableCell>
    ))

  renderHeader() {
    const {
      isStickyColumn,
      isStickyHeader,
      tableHeader,
      classes,
      stickyColumnTitle,
    } = this.props
    if (isStickyColumn && isStickyHeader) {
      return (
        <TableHead>
          <TableRow>
            <TableCell
              key="0"
              className={classes.stikyHeadColumn}
              align="center"
            >
              {stickyColumnTitle}
            </TableCell>
            {tableHeader.map((headerItem, idx) => (
              <TableCell
                key={idx + 1}
                className={classes.columnHead}
                align="center"
              >
                {headerItem}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )
    }
    if (isStickyColumn) {
      return (
        <TableHead>
          <TableRow>
            <TableCell key="0" className={classes.column} align="center">
              {stickyColumnTitle}
            </TableCell>
            {tableHeader.map((headerItem, idx) => (
              <TableCell
                key={idx + 1}
                className={classes.reducedTableCell}
                align="center"
              >
                {headerItem}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )
    } else if (isStickyHeader) {
      return (
        <TableHead>
          <TableRow>
            {tableHeader.map((headerItem, idx) => (
              <TableCell key={idx} align="center" className={classes.head}>
                {headerItem}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )
    }
    return (
      <TableHead>
        <TableRow>
          {tableHeader.map((headerItem, idx) => (
            <TableCell key={idx} align="center">
              {headerItem}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  renderBody() {
    const { isStickyColumn, results, classes } = this.props
    const resultsPerPage = results.slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    )

    if (isStickyColumn) {
      const rows = Object.keys(results).map(sku => {
        const lots = results[sku]
        return (
          <TableRow hover key={sku}>
            <TableCell className={classes.column}>{sku}</TableCell>
            {lots.map(date => (
              <TableCell
                key={date.date}
                className={classes.reducedTableCell}
                align="center"
              >
                {date.lots}
              </TableCell>
            ))}
          </TableRow>
        )
      })
      return <TableBody>{rows}</TableBody>
    }

    return resultsPerPage.map(result => (
      <TableBody key={`table_body_${result.id}`}>
        <TableRow
          className={`${this.props.goToEditPage && classes.tableRow} ${
            this.props.shoudHighlightRow && this.props.shoudHighlightRow(result)
              ? classes.highlight
              : ''
          }`}
          hover
          key={`table_row_${result.id}`}
          onClick={() => this.onRowClick(result)}
        >
          {this.renderTableRowCells(result)}
        </TableRow>
      </TableBody>
    ))
  }

  handleChangePage = (_, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: Number(event.target.value) })
  }

  renderFooter() {
    const { results } = this.props
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50]}
            count={results.length}
            rowsPerPage={this.state.rowsPerPage}
            labelRowsPerPage={'Linhas por página'}
            labelDisplayedRows={({ from, to, count }) =>
              `Exibindo as páginas ${from}-${to} de um total de ${count} páginas`
            }
            page={this.state.page}
            SelectProps={{ native: true }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    )
  }

  renderTable() {
    const { isStickyColumn, isStickyHeader, classes } = this.props
    if (isStickyColumn && isStickyHeader) {
      return (
        <Grid container justify="center" className={classes.scrollGrid}>
          <Grid className={classes.columnHeaderRoot}>
            <Table>
              {this.renderHeader()}
              {this.renderBody()}
              {this.renderFooter()}
            </Table>
          </Grid>
        </Grid>
      )
    }
    if (isStickyColumn) {
      return (
        <Grid container justify="center" className={classes.scrollGrid}>
          <Grid className={classes.columnRoot}>
            <Table>
              {this.renderHeader()}
              {this.renderBody()}
              {this.renderFooter()}
            </Table>
          </Grid>
        </Grid>
      )
    } else if (isStickyHeader) {
      return (
        <Grid className={classes.root}>
          <Table>
            {this.renderHeader()}
            {this.renderBody()}
            {this.renderFooter()}
          </Table>
        </Grid>
      )
    }
    return (
      <Table>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </Table>
    )
  }

  render() {
    return <Fragment>{this.renderTable()}</Fragment>
  }
}

ResultsTable.propTypes = {
  isStickyHeader: PropTypes.bool,
  goToEditPage: PropTypes.func,
  results: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tableHeader: PropTypes.array.isRequired,
  fieldsPath: PropTypes.array,
  textAlign: PropTypes.string,
  shoudHighlightRow: PropTypes.func,
  isStickyColumn: PropTypes.bool,
  stickyColumnTitle: PropTypes.string,
}

ResultsTable.defaultProps = {
  results: PropTypes.oneOf([{}, []]),
  fieldsPath: [],
  textAlign: '',
  shoudHighlightRow: null,
  isStickyHeader: false,
  isStickyColumn: false,
  stickyColumnTitle: '',
  goToEditPage: null,
}

export default withStyles(styles)(ResultsTable)
