import React, { PureComponent } from 'react'

import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

const Wrapper = styled.div`
  margin-bottom: 8px
  padding: 4px;
  padding-left: 16px;
`

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SwitchText = styled.h2`
  margin-bottom: 0;
`

const SwitchHint = styled.p`
  font-size: 12px;
  color: gray;
`

const ProductsListButton = styled(Button)`
  margin-left: 30px;
`

class ActivationHeader extends PureComponent {
  render() {
    const {
      classes,
      active,
      disabled,
      tooltip,
      onProductsListClick,
      onToggleClick,
    } = this.props
    return (
      <Paper className={classes.wrapper} elevation={1}>
        <Wrapper>
          <SwitchWrapper>
            <SwitchText>
              {active
                ? 'Essa receita está ativa'
                : ' Essa receita não está ativa. Deseja ativá-la?'}
            </SwitchText>
            {tooltip ? (
              <Tooltip title={tooltip}>
                <span>
                  <Switch
                    checked={active}
                    onChange={onToggleClick}
                    value="active"
                    color="secondary"
                    disabled={disabled}
                  />
                </span>
              </Tooltip>
            ) : (
              <Switch
                checked={active}
                onChange={onToggleClick}
                value="active"
                color="secondary"
                disabled={disabled}
              />
            )}
            {active && (
              <ProductsListButton
                variant="contained"
                color="primary"
                onClick={onProductsListClick}
                className="jr-btn text-blue-gray"
              >
                Atualizar produtos relacionados à receita
              </ProductsListButton>
            )}
          </SwitchWrapper>
          {disabled && (
            <SwitchHint>
              {' '}
              Só estarei disponível se nenhum campo for alterado.{' '}
            </SwitchHint>
          )}
        </Wrapper>
      </Paper>
    )
  }
}

export default withStyles(styles)(ActivationHeader)
