import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from './Messages'

export const ppcpStart = <FormattedMessage {...defineMessages.ppcpStart} />

export const productionSubtitle = (
  <FormattedMessage {...defineMessages.production} />
)

export const productionTableHeader = [
  <FormattedMessage {...defineMessages.sku} />,
  <FormattedMessage {...defineMessages.name} />,
  <FormattedMessage {...defineMessages.inventory} />,
  <FormattedMessage {...defineMessages.demand} />,
]

export const productionSchedule = (
  <FormattedMessage {...defineMessages.productionSchedule} />
)

export const start = <FormattedMessage {...defineMessages.start} />

export const ppcpResults = <FormattedMessage {...defineMessages.ppcpResults} />

export const ppcpHistoric = (
  <FormattedMessage {...defineMessages.ppcpHistoric} />
)

export const searchMessage = (
  <FormattedMessage {...defineMessages.searchMessage} />
)

export const historicTableHeader = [
  <FormattedMessage {...defineMessages.date} />,
  <FormattedMessage {...defineMessages.planningBeginning} />,
  <FormattedMessage {...defineMessages.planningEnd} />,
  <FormattedMessage {...defineMessages.planningCode} />,
]
