import * as t from '../actionTypes'

const initialState = {
  batch: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case t.SEARCH_PRODUCT_BY_BATCH_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case t.SEARCH_PRODUCT_BY_BATCH_SUCCESS: {
      const { batch, name, sku } = payload
      return {
        ...state,
        loading: false,
        batch,
        name,
        sku,
      }
    }
    case t.SEARCH_PRODUCT_BY_BATCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      }
    }
    case t.REJECT_PRODUCT_BY_BATCH_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case t.REJECT_PRODUCT_BY_BATCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        successMessage: payload.message,
      }
    }
    case t.REJECT_PRODUCT_BY_BATCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      }
    }
    case t.RESET_REJECT_STORE: {
      return initialState
    }
    default:
      return state
  }
}
