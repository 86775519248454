export const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'hidden',
  },
  tableHeader: {
    textAlign: 'center',
    fontSize: '1.250em',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: '1.250em',
  },
  RedBorderBottom: {
    borderBottom: ' 1px solid red',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      'text-decoration': 'underline',
    },
  },
  buttonPadding: {
    margin: '5px',
  },
  formControl: {
    display: 'flex',
    flexGrow: 1,
  },
})
