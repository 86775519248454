import ls from 'local-storage'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Form from 'components/Form'
import DefaultSubmit from 'components/DefaultSubmit'
import { Loading } from 'components/Loading'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ChipList from 'components/ChipList'
import { Modal } from 'src/v2/components/molecules/modal'
import TableHeader from 'components/TableHeader'
import { Typography } from 'src/v2/components/atoms/typography'
import RecipeInfo from '../RecipeInfo'
import NutritionFacts from '../../../components/NutritionFacts'
import CostPerUnit from '../../../components/CostPerUnit'
import Ingredients from '../../containers/Ingredients'
import SubRecipes from '../../containers/SubRecipes'
import Processes from '../../containers/Processes'
import ObservationDialog from '../../../../../components/ObservationDialog'
import styles from './styles'
import {
  calculateBatchSize,
  calculateRecipeResultingWeight,
  calculateRecipeCost,
  formatRecipe,
} from '../../../helpers'
import { getJwtPayload } from '../../../../../utils/decodeToken'
import { shouldUpdateNutritionalInfo } from '../../../logic'

class CreateRecipeForm extends PureComponent {
  state = {
    openObservation: false,
  }

  onManuallyCalculatedSwitchChange = event => {
    const { onManuallyCalculatedValueChange, editing } = this.props
    onManuallyCalculatedValueChange(event.target.checked, editing)
  }

  recalculateSubRecipesCosts = () => {
    const { recipe } = this.props
    const formattedRecipe = formatRecipe(recipe)
    const resultingWeight = calculateRecipeResultingWeight(
      formattedRecipe.recipeYield || 0,
      formattedRecipe.recipeWaste || 0,
      formattedRecipe.ingredients || [],
      formattedRecipe.subRecipes || []
    )
    const { updatedState } = calculateRecipeCost(
      formattedRecipe,
      resultingWeight.value,
      this.state,
      '',
      calculateRecipeResultingWeight
    )
    const subRecipesCosts =
      updatedState.subRecipes &&
      Object.values(updatedState.subRecipes).reduce(
        (acc, subRecipe) =>
          subRecipe.recipeTotalCost ? [...acc, subRecipe.recipeTotalCost] : acc,
        []
      )
    return subRecipesCosts || []
  }

  disableCalculateButton = () => {
    const { dirty, costDirty, recipe } = this.props
    if (shouldUpdateNutritionalInfo(recipe))
      return (
        !dirty ||
        (recipe.ingredients.length === 0 && recipe.subRecipes.length === 0)
      )

    return (
      !costDirty ||
      (recipe.ingredients.length === 0 && recipe.subRecipes.length === 0)
    )
  }

  handleChange = id => value => {
    this.setState({ [id]: value })
  }

  handleGenerate = type => {
    this.props.generateSKU(type)
  }

  handleDelete = () => {
    this.props.deleteRecipeSku()
  }

  handleSubmit = commitMessage => {
    const { recipe, handleSubmit, history, editing } = this.props
    if (editing) {
      handleSubmit(
        {
          ...recipe,
          changingCommit: {
            message: commitMessage,
            responsible: getJwtPayload(ls.get('_jwt')).email,
          },
        },
        history
      )
    } else {
      handleSubmit(recipe, history)
    }
    this.closeObservationDialog()
    this.closeAllergenicsDialog()
  }

  handleCalculate = () => {
    const { recipe, calculateNutritionalTable, updateRecipeCost } = this.props

    if (shouldUpdateNutritionalInfo(recipe)) calculateNutritionalTable(recipe)
    updateRecipeCost(recipe)
  }

  openObservationDialog = () => {
    this.setState({ openObservation: true })
  }

  openAllergenicsDialog = () => {
    this.setState({ allergenicsDialog: true })
  }

  closeObservationDialog = () => {
    this.setState({ openObservation: false })
  }

  closeAllergenicsDialog = () => {
    this.setState({ allergenicsDialog: false })
  }

  formatSKU = sku => {
    if (!sku) return undefined
    const splittedSKU = sku.split('_')
    return splittedSKU.length > 0 ? splittedSKU[0] : sku
  }

  renderRecipeInfo() {
    const {
      availableRecipeTypes,
      cookingMethods,
      editing,
      handleChangeRecipeInfo,
      kitchens,
      loadingSku,
      productionLocations,
      productionLocationsLoading,
      recipe,
    } = this.props

    return (
      <RecipeInfo
        {...recipe.recipeInfo}
        sku={this.formatSKU(
          recipe.sku || (recipe.recipeInfo && recipe.recipeInfo.sku)
        )}
        loadingSku={loadingSku}
        onChange={handleChangeRecipeInfo}
        availableColors={[
          { id: 'blue', text: 'Azul' },
          { id: 'green', text: 'Verde' },
          { id: 'red', text: 'Vermelho' },
          { id: 'yellow', text: 'Amarelo' },
          { id: 'orange', text: 'Laranja' },
          { id: 'soup', text: 'Sopa' },
          { id: 'cup', text: 'Potinho' },
          { id: 'tray', text: 'Bandeja' },
          { id: 'blueCloud', text: 'Azul Nuvem' },
          { id: 'yellowSun', text: 'Amarelo Sol' },
          { id: 'redHearth', text: 'Vermelho Coração' },
          { id: 'greenLeaves', text: 'Verde Folha' },
          { id: 'mvp', text: 'MVP' },
          { id: 'familySizePouch', text: 'Pouch tamanho família' },
          { id: 'familySizeTray', text: 'Bandeja tamanho família' },
        ]}
        availableIngredientsTypes={[
          { id: 'convencional', text: 'convencional' },
          { id: 'ingredientes orgânicos', text: 'ingredientes orgânicos' },
          { id: 'orgânico', text: 'orgânico' },
        ]}
        cookingMethods={cookingMethods}
        productionLocations={productionLocations}
        productionLocationsLoading={productionLocationsLoading}
        availableTypes={availableRecipeTypes}
        onGenerateSKU={this.handleGenerate}
        batchSize={
          calculateBatchSize(recipe, calculateRecipeResultingWeight) || 0
        }
        onDeleteSKU={this.handleDelete}
        editing={editing}
        availableKitchens={kitchens}
      />
    )
  }

  render() {
    const {
      classes,
      recipe,
      loadingNutritionalTable,
      dirty,
      costDirty,
      editing,
      loading,
      allergenics,
      allergenicsLoading,
      onNutritionalFactsChange,
    } = this.props
    const { openObservation, allergenicsDialog } = this.state
    if (!recipe || !recipe.recipeInfo || loading) return <Loading size={50} />
    const subRecipesTotalCosts = this.recalculateSubRecipesCosts()
    let { subRecipes } = recipe
    if (subRecipesTotalCosts.length > 0) {
      subRecipes = subRecipes.map((subRecipe, index) => ({
        ...subRecipe,
        recipeTotalCost: subRecipesTotalCosts[index],
      }))
    }
    const batch =
      calculateBatchSize(recipe, calculateRecipeResultingWeight) || 0
    const resultingWeight = calculateRecipeResultingWeight(
      recipe.recipeInfo.recipeYield || 0,
      recipe.recipeInfo.recipeWaste || 0,
      recipe.ingredients || [],
      recipe.subRecipes || []
    )
    return (
      <Form key={recipe}>
        <ObservationDialog
          open={openObservation && editing}
          submitTitle="Confirmar"
          onClose={this.closeObservationDialog}
          handleSubmit={this.handleSubmit}
          title="Confirmar edição de receita"
          observationLabel="Descrição da edição*"
        />
        <Modal
          open={allergenicsDialog}
          actions={[
            {
              type: 'success',
              text: 'Confirmar',
              onClick: editing ? this.openObservationDialog : this.handleSubmit,
            },
            {
              type: 'neutral',
              text: 'Fechar',
              onClick: this.closeAllergenicsDialog,
            },
          ]}
          onClose={this.closeAllergenicsDialog}
        >
          <Typography fontSize="large" fontWeight="bold">
            Confirma que todos os alergênicos estão corretos?
          </Typography>
        </Modal>
        <Grid container spacing={8}>
          <Grid item xs={9}>
            <Grid item>{this.renderRecipeInfo()}</Grid>
            <Grid item className={classes.tablesContainer}>
              <Grid container direction="row" spacing={8}>
                <Grid container direction="column" spacing={8}>
                  <Grid item>
                    <Paper elevation={1}>
                      <TableHeader title="Alergênicos" />
                      <div
                        style={{
                          paddingLeft: '16px',
                          paddingRight: '16px',
                        }}
                      >
                        {allergenicsLoading ? (
                          <Loading />
                        ) : (
                          <ChipList
                            id="allergenics.contains"
                            label="Contém"
                            color="primary"
                            onChange={this.handleChange}
                            chips={allergenics}
                            isDisabled
                          />
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper elevation={1}>
                      <Ingredients
                        totalCost={recipe.recipeInfo.costPerUnit}
                        batchSize={batch}
                        ingredients={recipe.ingredients}
                        editing={editing}
                        recipeWeight={resultingWeight.value}
                        dirty={dirty}
                        fetchIngredientAllergenics={
                          this.props.fetchIngredientAllergenics
                        }
                        removeAllergenic={this.props.removeAllergenic}
                        recipeKitchens={recipe.recipeInfo.kitchens}
                      />
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper elevation={1}>
                      <SubRecipes
                        totalCost={recipe.recipeInfo.costPerUnit}
                        batchSize={batch}
                        subRecipes={subRecipes}
                        editing={editing}
                        recipeWeight={resultingWeight.value}
                        dirty={dirty}
                        fetchRecipeAllergenics={
                          this.props.fetchRecipeAllergenics
                        }
                        removeAllergenic={this.props.removeAllergenic}
                        recipeKitchens={recipe.recipeInfo.kitchens}
                      />
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper elevation={1}>
                      <Processes
                        processes={recipe.processes}
                        editing={editing}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Grid container direction="column" className={classes.stickyGrid}>
              <CostPerUnit
                value={recipe.recipeInfo.costPerUnit}
                loading={loadingNutritionalTable}
              />
              <NutritionFacts
                onManuallyCalculatedSwitchChange={
                  this.onManuallyCalculatedSwitchChange
                }
                recipe={recipe}
                loading={loadingNutritionalTable}
                nutritionFacts={recipe.nutritionalInfo}
                onNutritionalFactsChange={onNutritionalFactsChange}
              />
              <Grid item xs={12}>
                <Paper>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-around"
                    wrap="nowrap"
                  >
                    <Grid item className={classes.button}>
                      <Button
                        disabled={this.disableCalculateButton()}
                        onClick={this.handleCalculate}
                        variant="contained"
                        color="primary"
                      >
                        {recipe.manuallyCalculated ? 'Confirmar' : 'Calcular'}
                      </Button>
                    </Grid>
                    <Grid item className={classes.button}>
                      <DefaultSubmit
                        disabled={
                          shouldUpdateNutritionalInfo(recipe)
                            ? dirty
                            : costDirty
                        }
                        submitText={
                          editing ? 'Editar Receita' : 'Criar Receita'
                        }
                        onSubmit={this.openAllergenicsDialog}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    )
  }
}

CreateRecipeForm.propTypes = {
  recipe: PropTypes.shape({
    recipeInfo: PropTypes.shape({
      sku: PropTypes.string,
      costPerUnit: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      type: PropTypes.string,
      ingredientsType: PropTypes.string,
      cookingTimes: PropTypes.array,
      recipeYield: PropTypes.number,
      recipeWaste: PropTypes.number,
      portionSize: PropTypes.number,
      allergenics: PropTypes.shape({
        contains: PropTypes.array,
        mayContain: PropTypes.array,
      }),
    }),
    manuallyCalculated: PropTypes.bool,
    nutritionalInfo: PropTypes.shape({
      kcal: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      carbs: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      proteins: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      satFats: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      transFats: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      totalFats: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      fibers: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
      sodium: {
        name: PropTypes.string,
        portion: PropTypes.number,
        dailyValue: PropTypes.number,
      },
    }),
    ingredients: PropTypes.array,
    subRecipes: PropTypes.array,
    processes: PropTypes.array,
  }).isRequired,
  allergenics: PropTypes.array.isRequired,
  allergenicsLoading: PropTypes.bool,
  availableRecipeTypes: PropTypes.array.isRequired,
  calculateNutritionalTable: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  cookingMethods: PropTypes.array.isRequired,
  costDirty: PropTypes.bool.isRequired,
  deleteRecipeSku: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  generateSKU: PropTypes.func.isRequired,
  handleChangeRecipeInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  kitchens: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  loadingNutritionalTable: PropTypes.bool.isRequired,
  loadingSku: PropTypes.bool.isRequired,
  onManuallyCalculatedValueChange: PropTypes.func.isRequired,
  onNutritionalFactsChange: PropTypes.func.isRequired,
  productionLocations: PropTypes.array.isRequired,
  productionLocationsLoading: PropTypes.bool.isRequired,
  updateRecipeCost: PropTypes.func.isRequired,
  validateAll: PropTypes.func.isRequired,
}

CreateRecipeForm.defaultProps = {
  allergenicsLoading: false,
  kitchens: [],
}

export default withRouter(withStyles(styles)(CreateRecipeForm))
