import styled from 'styled-components'
import { StyledButton } from './styles'

export const OutlinedButton = styled(StyledButton).attrs({
  classes: { label: 'label' },
})`
  && {
    border-radius: 2px;
    border-width: 1.5px;
    text-transform: uppercase;
    border-color: ${props =>
      props.disabled
        ? props.theme.v2.palette.grayScale['40']
        : props.theme.v2.palette[props.color].regular};
    background-color: ${props =>
        props.disabled
          ? props.theme.v2.palette.grayScale['20']
          : props.theme.v2.palette.background}
      & .label {
      font-size: 14px;
      font-style: normal;
      line-height: 14px;
      letter-spacing: 0em;
      font-weight: 600;
      color: ${props =>
        props.disabled
          ? props.theme.v2.palette.grayScale['40']
          : props.theme.v2.palette[props.color].regular};
    }
    &:hover {
      background-color: ${props =>
        props.disabled
          ? props.theme.v2.palette.grayScale['20']
          : props.theme.v2.palette[props.color].regular};
      & .label {
        color: ${props =>
          props.disabled
            ? props.theme.v2.palette.grayScale['40']
            : props.theme.v2.palette.background};
      }
    }
  }
`
