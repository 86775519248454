import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import settings from './Settings'
import auth from './Auth'
import notificationCenter from './NotificationCenter'
import receptions from './Reception'
import feedstocks from '../v2/feedstock/reducers'
import recipes from './Recipe'
import resources from './Resources'
import production from './PPCP'
import productionRecipes from './ProductionRecipes'
import tags from '../v2/tags/reducers'
import rejectBatch from '../v2/quality/rejected/reducers'
import feedstockRegister from '../v2/feedstock/register/reducers/index'

const reducers = combineReducers({
  routing: routerReducer,
  settings,
  auth,
  notificationCenter,
  receptions,
  resources,
  feedstocks,
  recipes,
  production,
  productionRecipes,
  tags,
  rejectBatch,
  feedstockRegister,
})

export default reducers
