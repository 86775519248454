export const RECIPE_KINDS = [
  { value: 'CK', label: 'CK' },
  { value: 'Homemade', label: 'Caseiros' },
  { value: 'FoodService', label: 'FoodService' },
  { value: 'Kids', label: 'Kids' },
  { value: 'Regular', label: 'Regular' },
  { value: 'ToShare', label: 'Para Compartilhar' },
]

export const TAG_LAYOUTS = [
  { value: 'Regular', label: 'Regular' },
  { value: 'SIF', label: 'SIF/Anvisa' },
  { value: 'Legisl22', label: 'Legisl22' },
  { value: 'RegularQRCode', label: 'RegularQRCode' },
]

export const HOME_MEASUREMENT_UNITS = [
  { value: 'tablespoons', label: 'Colher(es) de sopa' },
  { value: 'teaspoons', label: 'Colher(es) de chá' },
  { value: 'units', label: 'Unidade(s)' },
  { value: 'teacups', label: 'Xícara(s)' },
  { value: 'cups', label: 'Copo(s)' },
  { value: 'slices', label: 'Fatia(s)' },
  { value: 'fraction', label: 'Fração(ões)' },
]
