import { Grid } from '@material-ui/core'
import React from 'react'

import { Ingredient } from '../../../api/dto/ingredients'
import IngredientCard from '../IngredientCard'
import {
  HeaderTitle,
  Header,
  StyledCircularProgress,
  NoResultsFound,
} from './styles'

interface IngredientsListProps {
  ingredients: Ingredient[]
  loading: boolean
  onEdit?: (_ingredient: Ingredient) => void
}

function IngredientsList(props: IngredientsListProps) {
  const { ingredients, loading, onEdit } = props

  const handleEdit = (ingredient: Ingredient) => {
    onEdit && onEdit(ingredient)
  }

  const buildIngredientList = () =>
    ingredients.length ? (
      <>
        <Header>
          <Grid container spacing={8}>
            <Grid item md={6} sm={4} xs={3}>
              <HeaderTitle>Ingrediente</HeaderTitle>
            </Grid>
            <Grid item md={4} sm={4} xs={3}>
              <HeaderTitle>Matéria Prima</HeaderTitle>
            </Grid>
            <Grid item md={1} sm={2} xs={3}>
              <HeaderTitle>Status</HeaderTitle>
            </Grid>
            <Grid item md={1} sm={2} xs={3} />
          </Grid>
        </Header>
        {ingredients.map(ingredient => (
          <IngredientCard
            key={`igd-card-${ingredient.code}`}
            onEdit={() => handleEdit(ingredient)}
            active={ingredient.active}
            feedstock={ingredient.feedstock?.feedstockName || ''}
            disableEdit={loading}
            title={`${ingredient.code} - ${ingredient.recipeName}`}
          />
        ))}
      </>
    ) : (
      <Grid container justify="center" spacing={24}>
        <Grid item>
          <NoResultsFound>Nenhum resultado encontrado.</NoResultsFound>
        </Grid>
      </Grid>
    )

  return loading ? (
    <Grid container justify="center" spacing={24}>
      <StyledCircularProgress size={35} />
    </Grid>
  ) : (
    buildIngredientList()
  )
}

export default IngredientsList
