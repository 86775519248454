import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

import styles from './styles.css'

const ErrorBox = ({ message, retry, params }) => (
  <div>
    <Card className={styles.errorBox}>
      <CardContent>Erro: {message}</CardContent>
      <CardActions>
        {retry ? (
          <Button
            size="small"
            variant="raised"
            onClick={() => retry(params)}
            color="primary"
          >
            Clique aqui para tentar novamente
          </Button>
        ) : null}
      </CardActions>
    </Card>
  </div>
)

export default ErrorBox

ErrorBox.propTypes = {
  message: PropTypes.string,
  retry: PropTypes.func,
  params: PropTypes.object,
}

ErrorBox.defaultProps = {
  message: '',
  retry: null,
  params: null,
}
