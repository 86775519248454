import styled from 'styled-components'

import HeaderfulContainer from '../../../components/molecules/headerfulContainer'

export const Container = styled(HeaderfulContainer)`
  height: 100%;
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PrintPreview = styled.div`
  && {
    display: flex;
    justify-content: center;
  }
  img {
    max-height: 530px;
    max-width: 980px;
    object-fit: contain;
    overflow: auto;
    height: auto;
    width: auto;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
`
