import styled from 'styled-components'
import { ComponentType } from 'react'
import MuiButton, { ButtonProps } from '@material-ui/core/Button'

export const fontSize = {
  small: '12px',
  medium: '12px',
  large: '14px',
}

export const height = {
  small: '24px',
  medium: '30px',
  large: '36px',
}

export const padding = {
  small: '4px 8px',
  medium: '6px 16px',
  large: '8px 24px',
}

export const StyledButton: ComponentType<ButtonProps> = styled(MuiButton).attrs(
  {
    classes: { label: 'label' },
  }
)`
  && {
    border-radius: 2px;
    text-transform: inherit;
    width: max-content;
    height: ${props => height[props.size]};
    padding: ${props => padding[props.size]};
  }

  & .label {
    font-family: Lato;
    line-height: normal;
    font-size: ${props => fontSize[props.size]};
  }
`
