import React, { FC, useState } from 'react'

import { ExportXLSXContainer } from '../components/ExportXlsxContainer'
import IngredientsShopList from '../components/IngredientsShopList'

const IngredientsShopListPage: FC = () => {
  const [ingredientsShopList, setIngredientsShopList] = useState<
    Array<Record<string, string>>
  >([])
  return (
    <>
      <ExportXLSXContainer onUpload={setIngredientsShopList} />
      <IngredientsShopList ingredients={ingredientsShopList} />
    </>
  )
}
export default IngredientsShopListPage
