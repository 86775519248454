import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CardBox from 'components/CardBox'
import PropTypes from 'prop-types'
import styles from './styles'

class SearchBar extends Component {
  handleChange = event => {
    const { onChange } = this.props
    const { value } = event.target
    onChange(value)
  }

  render() {
    const { classes, searchPlaceholder } = this.props
    return (
      <Grid container direction="row" justify="center" alignContent="center">
        <CardBox styleName="col-sm-10">
          <TextField
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            className={classes.searchBar}
            label={searchPlaceholder}
            margin="none"
            onChange={this.handleChange}
            variant="outlined"
          />
        </CardBox>
      </Grid>
    )
  }
}

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  searchPlaceholder: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchBar)
