import React, { PureComponent, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchRecipeVersions, deleteRecipe } from 'actions/Recipes'
import CardBox from 'components/CardBox'
import ErrorBox from 'components/ErrorBox'
import ResultsTable from 'components/ResultsTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Stars } from '@material-ui/icons'
import dotProp from 'dot-prop-immutable'
import defaultTheme from '../../../../containers/themes/defaultTheme'

import RecipeVersioningHeader from '../components/RecipeVersioningHeader'
import { tableHeader } from '../formattedMessages'

class VersioningHistoricContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedVersion: null,
    }
  }

  componentDidMount = () => {
    const recipeCode = this.props.match.params.code
    this.props.fetchRecipeVersions(recipeCode)
  }

  componentDidUpdate = () => {
    if (this.props.allRecipeVersions.deleted) {
      this.props.history.push({
        pathname: '/app/recipes/search',
      })
    }
  }

  getActiveRecipe = () =>
    this.props.allRecipeVersions.data &&
    this.props.allRecipeVersions.data.length > 0 &&
    this.props.allRecipeVersions.data.find(recipe =>
      this.isInProduction(recipe)
    )

  getRecipesFieldsPath = () => [
    'versionNumber',
    'inProduction',
    'changingCommit.message',
    'changingCommit.responsible',
    'previousVersion.versionNumber',
  ]
  formatInProductionField = allRecipesData =>
    allRecipesData.map(recipe =>
      recipe.inProduction
        ? dotProp.set(
            recipe,
            'inProduction',
            <Stars
              style={{ color: defaultTheme.palette.active, fontSize: '20px' }}
            />
          )
        : recipe
    )

  isInProduction = recipe => recipe.inProduction

  validateRecipeForDeletion = () => {
    const activeRecipe = this.getActiveRecipe()
    const [firstSkuLetter] = activeRecipe.code
    if (firstSkuLetter === 'I') {
      return false
    }
    if (activeRecipe.parents && activeRecipe.parents.length) {
      return false
    }
    return true
  }

  goToEditPage = selectedVersion => {
    this.setState(prevState => ({
      ...prevState,
      selectedVersion,
    }))
    this.props.history.push({
      pathname: `/app/recipes/edit/${selectedVersion.code}`,
      selectedVersion,
    })
  }

  handleDeleteRecipe = () => {
    const { code } = this.getActiveRecipe()
    this.props.deleteRecipe(code)
  }

  renderLoading = () => <CircularProgress size={50} />

  renderError = () => <ErrorBox message={this.props.allRecipeVersions.error} />

  renderRecipeVersioningHeader = () => {
    const { allRecipeVersions } = this.props
    if (allRecipeVersions.loading) return this.renderLoading()
    if (allRecipeVersions.error) return this.renderError()
    const { name, code, versionNumber, changingCommit } = this.getActiveRecipe()
    return (
      <RecipeVersioningHeader
        name={name}
        sku={code}
        versionNumber={versionNumber}
        changingCommit={changingCommit}
        validateRecipeForDeletion={this.validateRecipeForDeletion}
        onDeleteRecipe={this.handleDeleteRecipe}
      />
    )
  }

  renderRecipeVersioningTable = () => {
    const { allRecipeVersions } = this.props
    if (allRecipeVersions.loading) return this.renderLoading()
    if (allRecipeVersions.error) return this.renderError()
    const formatedRecipes = this.formatInProductionField(allRecipeVersions.data)
    return (
      <CardBox styleName="col-sm-12">
        <ResultsTable
          goToEditPage={this.goToEditPage}
          tableHeader={tableHeader}
          results={formatedRecipes}
          fieldsPath={this.getRecipesFieldsPath()}
          textAlign="center"
          shoudHighlightRow={this.isInProduction}
        />
      </CardBox>
    )
  }

  render = () => (
    <Fragment>
      <CardBox styleName="col-sm-12">
        {this.renderRecipeVersioningHeader()}
      </CardBox>
      {this.renderRecipeVersioningTable()}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  allRecipeVersions: state.recipes.allRecipeVersions,
})

VersioningHistoricContainer.propTypes = {
  fetchRecipeVersions: PropTypes.func.isRequired,
  allRecipeVersions: PropTypes.object,
}

VersioningHistoricContainer.defaultProps = {
  allRecipeVersions: {},
}

export default withRouter(
  connect(mapStateToProps, {
    fetchRecipeVersions,
    deleteRecipe,
  })(VersioningHistoricContainer)
)
