import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import TableHeader from 'components/TableHeader'
import { nutritionFactsMessage, dailyValueMessage } from '../formattedMessages'
import FixedPrecisionInput from '../../../components/FixedPrecisionInput/index'
import { FACTS_ALIASES } from 'constants/recipes'

const styles = theme => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  rowBackgroundColor: {
    backgroundColor: theme.palette.primary.lightest,
  },
  table: {
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  row: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    height: 4 * theme.spacing.unit,
    fontSize: 14,
  },
  nutritionFactsHeaders: {
    fontWeight: 'boldest',
  },
  dailyValueCell: {
    textAlign: 'right',
  },
  activityIndicator: {
    marginTop: '8px',
  },
  input: {
    padding: '0px 0px 0px',
  },
})

class NutritionFacts extends PureComponent {
  onNutritionalValueChange = key => value => {
    const nutriFacts = Object.assign(this.props.recipe.nutritionalInfo)
    nutriFacts[key].portion = Number(value)
    this.props.onNutritionalFactsChange(nutriFacts)
  }

  onDailyValueChange = key => value => {
    const nutriFacts = Object.assign(this.props.recipe.nutritionalInfo)
    nutriFacts[key].dailyValue = Number(value)
    this.props.onNutritionalFactsChange(nutriFacts)
  }

  render() {
    const { classes, recipe, loading, onManuallyCalculatedSwitchChange } =
      this.props

    const sugarFacts = Object.keys(recipe.nutritionalInfo).filter(name =>
      name.endsWith('Sugars')
    )

    const otherFacts = Object.keys(recipe.nutritionalInfo).filter(
      name => !name.endsWith('Sugars')
    )

    return (
      <Paper className={classes.root} elevation={1} key={recipe}>
        <TableHeader title={nutritionFactsMessage} />
        <FormControlLabel
          className={classes.table}
          control={
            <Switch
              checked={recipe.manuallyCalculated}
              onChange={onManuallyCalculatedSwitchChange}
              value="manuallyCalculated"
            />
          }
          label="Preenchimento manual"
        />
        <Grid container direction="column" className={classes.table}>
          <Grid item className={classes.row}>
            <Grid
              container
              direction="row"
              className={classes.nutritionFactsHeaders}
              spacing={8}
            >
              <Grid item xs={7} />
              <Grid item xs>
                <b>{recipe.recipeInfo.referenceGrammage}g</b>
              </Grid>
              <Grid item xs className={classes.dailyValueCell}>
                <b>{dailyValueMessage}</b>
              </Grid>
            </Grid>
          </Grid>
          {loading ? (
            <CircularProgress className={classes.activityIndicator} />
          ) : (
            otherFacts.length > 0 &&
            otherFacts.map((factName, rowIndex) => (
              <Grid
                item
                key={factName}
                className={classNames(
                  classes.row,
                  rowIndex % 2 && classes.rowBackgroundColor
                )}
              >
                <Grid container direction="row" spacing={8}>
                  <Grid item xs={7}>
                    {recipe.nutritionalInfo[factName].name}
                  </Grid>
                  <Grid item xs>
                    <FixedPrecisionInput
                      id={`${factName}-value`}
                      type="number"
                      precision={1}
                      value={Number(recipe.nutritionalInfo[factName].portion)}
                      onChange={this.onNutritionalValueChange(factName)}
                      disabled={!recipe.manuallyCalculated}
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  </Grid>
                  <Grid item xs className={classes.dailyValueCell}>
                    <FixedPrecisionInput
                      id={`${factName}-daily`}
                      type="number"
                      precision={1}
                      value={recipe.nutritionalInfo[factName].dailyValue}
                      onChange={this.onDailyValueChange(factName)}
                      disabled={!recipe.manuallyCalculated}
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
          <hr></hr>
          {sugarFacts.length > 0 &&
            sugarFacts.map((factName, rowIndex) => (
              <Grid
                item
                key={factName}
                className={classNames(
                  classes.row,
                  rowIndex % 2 && classes.rowBackgroundColor
                )}
              >
                <Grid container direction="row" spacing={8}>
                  <Grid item xs={7}>
                    {FACTS_ALIASES[factName] ||
                      recipe.nutritionalInfo[factName].name}
                    *¹
                  </Grid>
                  <Grid item xs>
                    <FixedPrecisionInput
                      id={`${factName}-value`}
                      type="number"
                      precision={1}
                      value={Number(recipe.nutritionalInfo[factName].portion)}
                      onChange={this.onNutritionalValueChange(factName)}
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  </Grid>
                  <Grid item xs className={classes.dailyValueCell}>
                    {factName !== 'totalSugars' && (
                      <FixedPrecisionInput
                        id={`${factName}-daily`}
                        type="number"
                        precision={1}
                        value={recipe.nutritionalInfo[factName].dailyValue}
                        onChange={this.onDailyValueChange(factName)}
                        InputProps={{ classes: { input: classes.input } }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <p>
            <small>
              <i>*¹ Valores preenchidos manualmente</i>
            </small>
          </p>
        </Grid>
      </Paper>
    )
  }
}

NutritionFacts.propTypes = {
  loading: PropTypes.bool.isRequired,
  nutritionFacts: PropTypes.shape({
    kcal: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    carbs: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    proteins: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    totalFats: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    satFats: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    transFats: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    fibers: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    sodium: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
    totalSugars: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
    }),
    addedSugars: PropTypes.shape({
      name: PropTypes.string,
      portion: PropTypes.number,
      dailyValue: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.object.isRequired,
  onManuallyCalculatedSwitchChange: PropTypes.func,
}

export default withStyles(styles)(NutritionFacts)
