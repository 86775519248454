/* eslint-disable max-len */
import dotProp from 'dot-prop-immutable'
import jquery from 'jquery'

import {
  FETCH_RECIPE_VERSIONS_REQUEST,
  FETCH_RECIPE_VERSIONS_RESPONSE,
  FETCH_RECIPE_VERSIONS_ERROR,
  FETCH_RECIPES_REQUEST,
  FETCH_RECIPES_RESPONSE,
  FETCH_RECIPES_ERROR,
  FETCH_PROCESSES_CONSTANTS_RESPONSE,
  FETCH_PROCESSES_CONSTANTS_ERROR,
  SEARCH_RECIPES_REQUEST,
  SEARCH_RECIPES_RESPONSE,
  SEARCH_RECIPES_ERROR,
  GET_INGREDIENTS_REQUEST,
  GET_INGREDIENTS_RESPONSE,
  GET_INGREDIENTS_ERROR,
  CLEAR_GENERATED_RECIPE_SKU,
  GENERATE_RECIPE_SKU_REQUEST,
  GENERATE_RECIPE_SKU_RESPONSE,
  GENERATE_RECIPE_SKU_ERROR,
  DELETE_RECIPE_SKU,
  ADD_RECIPE_PROCESS,
  REPLACE_RECIPE_PROCESSES,
  ADD_CURRENT_RECIPE_PROCESS,
  REPLACE_CURRENT_RECIPE_PROCESSES,
  CHANGE_RECIPE_INFORMATION,
  CHANGE_CURRENT_RECIPE_INFORMATION,
  RECALCULATE_RECIPES_REQUEST,
  RECALCULATE_RECIPES_RESPONSE,
  RECALCULATE_RECIPES_ERROR,
  RECIPE_TO_SIMULATE_REQUEST,
  RECIPE_TO_SIMULATE_RESPONSE,
  RECIPE_TO_SIMULATE_ERROR,
  RECIPE_TO_ADD_REQUEST,
  RECIPE_TO_ADD_RESPONSE,
  RECIPE_TO_ADD_ERROR,
  ADD_INGREDIENT,
  REMOVE_INGREDIENT,
  ADD_SUBRECIPE,
  REMOVE_SUBRECIPE,
  ADD_CURRENT_RECIPE_INGREDIENT,
  REMOVE_CURRENT_RECIPE_INGREDIENT,
  ADD_CURRENT_RECIPE_SUBRECIPE,
  REMOVE_CURRENT_RECIPE_SUBRECIPE,
  CALCULATE_NUTRITIONAL_TABLE_REQUEST,
  CALCULATE_NUTRITIONAL_TABLE_RESPONSE,
  CALCULATE_CURRENT_RECIPE_NUTRITIONAL_TABLE_RESPONSE,
  CALCULATE_NUTRITIONAL_TABLE_ERROR,
  CALCULATE_RECIPE_COST_REQUEST,
  CALCULATE_RECIPE_COST_RESPONSE,
  CALCULATE_CURRENT_RECIPE_COST_RESPONSE,
  CALCULATE_RECIPE_COST_ERROR,
  CREATE_RECIPE_REQUEST,
  CREATE_RECIPE_RESPONSE,
  CREATE_RECIPE_RESET,
  CREATE_RECIPE_ERROR,
  FETCH_COOKING_METHODS_REQUEST,
  FETCH_COOKING_METHODS_RESPONSE,
  FETCH_COOKING_METHODS_ERROR,
  FETCH_RECIPE_REQUEST,
  FETCH_RECIPE_RESPONSE,
  FETCH_RECIPE_ERROR,
  FETCH_RECIPE_USAGE_REQUEST,
  FETCH_RECIPE_USAGE_RESPONSE,
  FETCH_RECIPE_USAGE_ERROR,
  FETCH_RECIPE_RELATED_PRODUCTS_REQUEST,
  FETCH_RECIPE_RELATED_PRODUCTS_RESPONSE,
  FETCH_RECIPE_RELATED_PRODUCTS_ERROR,
  DELETE_RECIPE_REQUEST,
  DELETE_RECIPE_RESPONSE,
  DELETE_RECIPE_ERROR,
  UPDATE_RECIPE_VERSION_REQUEST,
  UPDATE_RECIPE_VERSION_RESPONSE,
  UPDATE_RECIPE_VERSION_ERROR,
  EDIT_RECIPE_REQUEST,
  EDIT_RECIPE_RESPONSE,
  EDIT_RECIPE_ERROR,
  ADD_ALLERGENIC,
  REMOVE_ALLERGENIC,
  REMOVE_ALL_ALLERGENICS,
  DISPLAY_ALLERGENICS_START,
  FETCH_AVAILABLE_RECIPE_TYPES_RESPONSE,
  FETCH_AVAILABLE_RECIPE_TYPES_REQUEST,
  FETCH_AVAILABLE_RECIPE_TYPES_ERROR,
  UPDATE_RECIPE_NUTRITIONAL_INFO,
  UPDATE_CURRENT_RECIPE_NUTRITIONAL_INFO,
  UPDATE_CURRENT_RECIPE_MANUALLY_CALCULATED,
  UPDATE_RECIPE_MANUALLY_CALCULATED,
  PRODUCTION_LOCATIONS_REQUEST_START,
  PRODUCTION_LOCATIONS_REQUEST_SUCCESS,
  PRODUCTION_LOCATIONS_REQUEST_END,
} from '../constants/ActionTypes'
import { handleRequestError } from './ErrorHandling'
import { errorNotification, sendNotification } from './Notifications'
import {
  RECIPE_PROCESS_RELATIONS,
  RECIPE_PROCESS_TYPES,
} from '../constants/RecipeProcessTypes'
import { getRecipeColor } from '../v2/recipe/adapters/getRecipeColor'

import {
  validateRecipeCookingTimes,
  validateRecipeRequiredFields,
  validateNutricionalTable,
  validateIngredientsAndSubrecipesKitchen,
} from './logic'

export const fetchIngredientAllergenics = id => async (dispatch, _, api) => {
  try {
    dispatch({
      type: DISPLAY_ALLERGENICS_START,
    })
    const response = await api.get(`/api/ingredients/allergenics/${id}`)
    const { allergenics } = response.data
    const allergenicsNames = allergenics.map(al => al.name)
    dispatch({
      type: ADD_ALLERGENIC,
      payload: {
        allergenics: {
          id,
          allergenics: allergenicsNames,
        },
      },
    })
  } catch (err) {
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchRecipeAllergenics = code => async (dispatch, _, api) => {
  try {
    dispatch({
      type: DISPLAY_ALLERGENICS_START,
    })
    const response = await api.get(`/api/recipes/allergenics/${code}`)
    const allergenics = response.data
    const allergenicsNames = allergenics.map(al => al.name)
    dispatch({
      type: ADD_ALLERGENIC,
      payload: {
        allergenics: {
          id: code,
          allergenics: allergenicsNames,
        },
      },
    })
  } catch (err) {
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchProductionLocations = () => async (dispatch, _, api) => {
  try {
    dispatch({ type: PRODUCTION_LOCATIONS_REQUEST_START })
    const response = await api.get('/api/recipes/production-location')
    const locations = response.data
    dispatch({
      type: PRODUCTION_LOCATIONS_REQUEST_SUCCESS,
      payload: locations,
    })
  } catch (err) {
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  } finally {
    dispatch({ type: PRODUCTION_LOCATIONS_REQUEST_END })
  }
}

export const removeAllergenic = id => ({
  type: REMOVE_ALLERGENIC,
  payload: {
    id,
  },
})

export const fetchAvailableRecipeTypes = () => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_AVAILABLE_RECIPE_TYPES_REQUEST,
  })
  try {
    const response = await api.get(`/api/recipes/recipeTypes`)
    dispatch({
      type: FETCH_AVAILABLE_RECIPE_TYPES_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: FETCH_AVAILABLE_RECIPE_TYPES_ERROR,
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchRecipeVersions = code => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_RECIPE_VERSIONS_REQUEST,
  })
  try {
    const response = await api.get(`/api/recipes/versions`, {
      params: {
        code,
      },
    })
    dispatch({
      type: FETCH_RECIPE_VERSIONS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    console.log(err)
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_RECIPE_VERSIONS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchRecipes =
  (skuPrefixBlacklist, productInfo) => async (dispatch, _, api) => {
    dispatch({
      type: FETCH_RECIPES_REQUEST,
    })
    try {
      const queryInfo = {
        skuPrefixBlacklist,
        productInfo,
      }
      const queryValues = Object.entries(queryInfo)
        .filter(([, v]) => Boolean(v))
        .map(([k, v]) => `${k}=${v}`)
        .join('&')
      const query = queryValues ? `?${queryValues}` : ''

      const response = await api.get(`/api/recipes/fetch${query}`)
      dispatch({
        type: FETCH_RECIPES_RESPONSE,
        payload: {
          data: response.data,
        },
      })
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : err.message
      dispatch({
        type: FETCH_RECIPES_ERROR,
        payload: {
          error: errorMessage,
        },
      })
      dispatch(handleRequestError(err))
      dispatch(errorNotification(err))
    }
  }

export const fetchIngredients = () => async (dispatch, _, api) => {
  dispatch({
    type: GET_INGREDIENTS_REQUEST,
  })
  try {
    const response = await api.get('/api/ingredients')
    dispatch({
      type: GET_INGREDIENTS_RESPONSE,
      payload: {
        data: response.data.ingredients,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: GET_INGREDIENTS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const recalculateRecipes = () => async (dispatch, _, api) => {
  dispatch({
    type: RECALCULATE_RECIPES_REQUEST,
  })

  try {
    const response = await api.post('/api/recipes/recalculateAllRecipes')

    dispatch({
      type: RECALCULATE_RECIPES_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: RECALCULATE_RECIPES_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const searchRecipes = params => async (dispatch, _, api) => {
  dispatch({
    type: SEARCH_RECIPES_REQUEST,
  })
  try {
    const response = await api.get('/api/recipes/search', {
      params,
    })

    dispatch({
      type: SEARCH_RECIPES_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: SEARCH_RECIPES_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchRecipeUsage = code => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_RECIPE_USAGE_REQUEST,
  })

  try {
    const { data: recipes } = await api.get(`api/recipes/${code}/usage`)
    dispatch({
      type: FETCH_RECIPE_USAGE_RESPONSE,
      payload: {
        data: recipes,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_RECIPE_USAGE_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchCookingMethods = () => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_COOKING_METHODS_REQUEST,
  })
  try {
    const response = await api.get(`api/cooking-methods/search`)
    dispatch({
      type: FETCH_COOKING_METHODS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: FETCH_COOKING_METHODS_ERROR,
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchRecipeRelatedProducts = code => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_RECIPE_RELATED_PRODUCTS_REQUEST,
  })
  try {
    const { data } = await api.get(`api/recipes/${code}/products`)
    dispatch({
      type: FETCH_RECIPE_RELATED_PRODUCTS_RESPONSE,
      payload: {
        data: data.products,
      },
    })
  } catch (err) {
    dispatch({
      type: FETCH_RECIPE_RELATED_PRODUCTS_ERROR,
    })
    dispatch(handleRequestError(err))
  }
}

export const deleteRecipe = code => async (dispatch, _, api) => {
  dispatch({
    type: DELETE_RECIPE_REQUEST,
  })
  try {
    await api.delete(`api/recipes/${code}`)
    dispatch({
      type: DELETE_RECIPE_RESPONSE,
    })
  } catch (err) {
    dispatch({
      type: DELETE_RECIPE_ERROR,
      payload: {
        errorObject: err,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const updateRecipeRelatedProducts =
  products => async (dispatch, _, api) => {
    try {
      const codes = products.map(product => product.sku)
      await api.put(`api/recipes/products?${jquery.param({ codes })}`)
      dispatch(
        sendNotification({
          type: 'success',
          message: 'Produtos atualizados com sucesso',
          autoDismiss: true,
          soundOff: false,
        })
      )
    } catch (err) {
      dispatch(handleRequestError(err))
    }
  }

const fromRecipeToDto = recipe => ({
  ...recipe,
  parents: [],
  recipeInfo: {
    ...recipe.recipeInfo,
    referenceGrammage: Number(recipe.recipeInfo.referenceGrammage),
    color: getRecipeColor(recipe.recipeInfo.color),
    sealing: {
      machineType: recipe.recipeInfo.sealingMachine,
      machineConfig: recipe.recipeInfo.sealingType,
      temperature: recipe.recipeInfo.sealingTemperature || null,
    },
    freezing: {
      skusPerContainer: recipe.recipeInfo.skusPerFreezingContainer,
      position: recipe.recipeInfo.freezingPosition,
    },
    productionLocation: { id: recipe.recipeInfo.productionLocation },
  },
})

export const createRecipe = (recipe, router) => async (dispatch, _, api) => {
  if (!validateRecipeRequiredFields(fromRecipeToDto(recipe))) {
    dispatch(
      sendNotification({
        type: 'error',
        message:
          'Verifique se todos os campos obrigatórios (*) foram preenchidos corretamente.',
        autoDismiss: true,
        soundOff: false,
      })
    )
    return
  }

  try {
    validateRecipeCookingTimes(recipe?.recipeInfo?.cookingTimes)
  } catch (e) {
    if (e.message) {
      dispatch(
        sendNotification({
          type: 'error',
          message: e.message,
          autoDismiss: true,
          soundOff: false,
        })
      )
      return
    }
  }

  if (!validateIngredientsAndSubrecipesKitchen(recipe)) {
    dispatch(
      sendNotification({
        type: 'error',
        message: `Verifique se todos os ingredientes e sub receitas pertencem a mesma cozinha da receita`,
        autoDismiss: true,
        soundOff: false,
      })
    )
    return
  }

  try {
    validateNutricionalTable(recipe)
  } catch (e) {
    if (e.message) {
      dispatch(
        sendNotification({
          type: 'error',
          message: e.message,
          autoDismiss: true,
          soundOff: false,
        })
      )
      return
    }
  }

  dispatch({
    type: CREATE_RECIPE_REQUEST,
  })
  try {
    await api.put(`api/recipes/cost/${recipe.recipeInfo.sku}`, {
      cost: recipe.recipeInfo.costPerUnit,
    })

    const response = await api.post('api/recipes/', fromRecipeToDto(recipe))
    dispatch({
      type: CREATE_RECIPE_RESPONSE,
      payload: {
        data: response.data,
      },
    })

    router.push(`/app/recipes/edit/${response.data.code}`)
  } catch (err) {
    const error =
      err.response &&
      err.response.data &&
      err.response.data.output &&
      err.response.data.output.payload &&
      err.response.data.output.payload.message
        ? err.response.data.output.payload.message
        : 'Verifique se todos os campos obrigatórios (*) foram preenchidos corretamente.'
    dispatch({
      type: CREATE_RECIPE_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateRecipe = (recipe, router) => async (dispatch, _, api) => {
  if (!validateRecipeRequiredFields(fromRecipeToDto(recipe))) {
    dispatch(
      sendNotification({
        type: 'error',
        message:
          'Verifique se todos os campos obrigatórios (*) foram preenchidos corretamente.',
        autoDismiss: true,
        soundOff: false,
      })
    )
    return
  }

  try {
    validateRecipeCookingTimes(recipe?.recipeInfo?.cookingTimes)
  } catch (e) {
    if (e.message) {
      dispatch(
        sendNotification({
          type: 'error',
          message: e.message,
          autoDismiss: true,
          soundOff: false,
        })
      )
      return
    }
  }

  if (!validateIngredientsAndSubrecipesKitchen(recipe)) {
    dispatch(
      sendNotification({
        type: 'error',
        message: `Verifique se todos os ingredientes e sub receitas pertencem a mesma cozinha da receita - ${recipe.recipeInfo.kitchens[0]}`,
        autoDismiss: true,
        soundOff: false,
      })
    )
    return
  }

  try {
    validateNutricionalTable(recipe)
  } catch (e) {
    if (e.message) {
      dispatch(
        sendNotification({
          type: 'error',
          message: e.message,
          autoDismiss: true,
          soundOff: false,
        })
      )
      return
    }
  }

  dispatch({
    type: EDIT_RECIPE_REQUEST,
  })
  try {
    const response = await api.put(
      `/api/recipes/${recipe.recipeInfo.sku}`,
      fromRecipeToDto(recipe)
    )

    await api.put(`api/recipes/cost/${recipe.recipeInfo.sku}`, {
      cost: recipe.recipeInfo.costPerUnit,
    })

    dispatch({
      type: EDIT_RECIPE_RESPONSE,
      payload: {
        data: response.data,
      },
    })
    router.push(`/app/recipes/edit/${response.data.code}`)
    router.go() // reload
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Receita atualizada com sucesso!',
        autoDismiss: true,
        soundOff: false,
      })
    )
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: EDIT_RECIPE_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const generateRecipeSKU = recipeType => async (dispatch, _, api) => {
  dispatch({
    type: GENERATE_RECIPE_SKU_REQUEST,
  })
  try {
    const response = await api.get('/api/recipes/nextAvailableCode', {
      params: {
        recipeType,
      },
    })

    dispatch({
      type: GENERATE_RECIPE_SKU_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    dispatch({
      type: GENERATE_RECIPE_SKU_ERROR,
      payload: {
        error: 'Verifique se o Tipo de Saquinho foi selecionado',
      },
    })
  }
}

export const clearGeneratedRecipeSKU = () => ({
  type: CLEAR_GENERATED_RECIPE_SKU,
})

export const removeAllAllergenics = () => ({
  type: REMOVE_ALL_ALLERGENICS,
})

export const deleteRecipeSKU = () => async dispatch => {
  dispatch({
    type: DELETE_RECIPE_SKU,
  })
}

export const handleChangeRecipeInfo = editing => info => async dispatch => {
  dispatch({
    type: editing
      ? CHANGE_CURRENT_RECIPE_INFORMATION
      : CHANGE_RECIPE_INFORMATION,
    payload: {
      data: info,
    },
  })
}

export const fetchRecipeProcesses = () => async (dispatch, _, _api) => {
  /**
   * TO-DO:
   * Pegar do back os tipos de processos e suas relações.
   */
  try {
    // const response = await api.get(`/api/recipes/processes`);
    const response = {
      data: {
        relations: RECIPE_PROCESS_RELATIONS,
        types: RECIPE_PROCESS_TYPES,
      },
    }
    dispatch({
      type: FETCH_PROCESSES_CONSTANTS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_PROCESSES_CONSTANTS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const addRecipeProcess = (process, editing) => async dispatch =>
  dispatch({
    type: editing ? ADD_CURRENT_RECIPE_PROCESS : ADD_RECIPE_PROCESS,
    payload: {
      data: process,
    },
  })

export const replaceRecipeProcesses = (processes, editing) => async dispatch =>
  dispatch({
    type: editing ? REPLACE_CURRENT_RECIPE_PROCESSES : REPLACE_RECIPE_PROCESSES,
    payload: {
      data: processes,
    },
  })

export const calculateNutritionalTable =
  editing => recipe => async (dispatch, _, api) => {
    dispatch({
      type: CALCULATE_NUTRITIONAL_TABLE_REQUEST,
    })
    try {
      const response = await api.post(
        '/api/recipes/calculateNutritionalTable',
        recipe
      )
      dispatch({
        type: editing
          ? CALCULATE_CURRENT_RECIPE_NUTRITIONAL_TABLE_RESPONSE
          : CALCULATE_NUTRITIONAL_TABLE_RESPONSE,
        payload: {
          data: response.data && response.data.nutritionalTable,
        },
      })
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : err.message
      dispatch({
        type: CALCULATE_NUTRITIONAL_TABLE_ERROR,
        payload: {
          error: errorMessage,
        },
      })
      dispatch(handleRequestError(err))
    }
  }

export const calculateRecipeCost =
  editing => recipe => async (dispatch, _, api) => {
    dispatch({
      type: CALCULATE_RECIPE_COST_REQUEST,
    })
    try {
      const response = await api.post(
        '/api/recipes/calculateRecipeCost',
        recipe
      )
      dispatch({
        type: editing
          ? CALCULATE_CURRENT_RECIPE_COST_RESPONSE
          : CALCULATE_RECIPE_COST_RESPONSE,
        payload: {
          data: response.data,
        },
      })
    } catch (err) {
      dispatch({
        type: CALCULATE_RECIPE_COST_ERROR,
        payload: {
          error:
            'Verifique se todos os campos obrigatórios (*) foram preenchidos corretamente.',
        },
      })
    }
  }

const ingredientsAllergenicsRequest = async (ingredient, api) => ({
  id: ingredient.id,
  response: await api.get(`/api/ingredients/allergenics/${ingredient.id}`),
})

const subRecipesAllergenicsRequest = async (sub, api) => ({
  id: sub.code,
  response: await api.get(`/api/recipes/allergenics/${sub.code}`),
})

export const updateRecipeManuallyCalculatedValue =
  (value, editing) => async dispatch => {
    dispatch({
      type: editing
        ? UPDATE_CURRENT_RECIPE_MANUALLY_CALCULATED
        : UPDATE_RECIPE_MANUALLY_CALCULATED,
      payload: {
        manuallyCalculated: value,
      },
    })
  }

export const updateRecipeNutritionalInfo =
  nutritionalInfo => async dispatch => {
    dispatch({
      type: UPDATE_RECIPE_NUTRITIONAL_INFO,
      payload: {
        nutritionalInfo,
      },
    })
  }

export const editCurrentRecipeNutritionalInfo =
  nutritionalInfo => async dispatch => {
    dispatch({
      type: UPDATE_CURRENT_RECIPE_NUTRITIONAL_INFO,
      payload: {
        nutritionalInfo,
      },
    })
  }

export const fetchRecipe = code => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_RECIPE_REQUEST,
  })
  dispatch(removeAllAllergenics())
  try {
    const response = await api.get(`/api/recipes/${code}`, {
      params: { code },
    })
    const { ingredients } = response.data
    let { subRecipes } = response.data
    if (subRecipes.length > 0) {
      const subRecipesPromises = subRecipes.map(subRecipe =>
        api.get(`/api/recipes/${subRecipe.code}/complete`, {
          params: { code: subRecipe.code },
        })
      )
      const subRecipesResponse = await Promise.all(subRecipesPromises)
      subRecipes = subRecipesResponse.map((subRecipeResponse, index) => ({
        ...subRecipeResponse.data,
        quantity: subRecipes[index].quantity,
      }))
    }

    if (ingredients.length > 0) {
      const ingredientsAllergenics = await Promise.all(
        ingredients.map(ing => ingredientsAllergenicsRequest(ing, api))
      )
      ingredientsAllergenics.forEach(ingredientAllergenic => {
        dispatch({
          type: ADD_ALLERGENIC,
          payload: {
            allergenics: {
              id: ingredientAllergenic.id,
              allergenics: ingredientAllergenic.response.data.allergenics.map(
                al => al.name
              ),
            },
          },
        })
      })
    }

    if (subRecipes.length > 0) {
      const subRecipesAllergenics = await Promise.all(
        subRecipes.map(sub => subRecipesAllergenicsRequest(sub, api))
      )
      subRecipesAllergenics.forEach(subRecipeAllergenic => {
        dispatch({
          type: ADD_ALLERGENIC,
          payload: {
            allergenics: {
              id: subRecipeAllergenic.id,
              allergenics: subRecipeAllergenic.response.data.map(al => al.name),
            },
          },
        })
      })
    }

    const {
      freezing = {},
      sealing = {},
      ...recipeInfo
    } = response.data.recipeInfo

    const recipe = {
      ...response.data,
      recipeInfo: {
        ...recipeInfo,
        freezingPosition: freezing.position,
        sealingMachine: sealing.machineType,
        sealingTemperature: sealing.temperature,
        sealingType: sealing.machineConfig,
        skusPerFreezingContainer: freezing.skusPerContainer,
      },
    }
    dispatch({
      type: FETCH_RECIPE_RESPONSE,
      payload: {
        data: { ...recipe, subRecipes },
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_RECIPE_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const updateRecipeVersion =
  (code, responsible) => async (dispatch, _, api) => {
    dispatch({
      type: UPDATE_RECIPE_VERSION_REQUEST,
    })
    try {
      const response = await api.patch(`/api/recipes/${code}`, { responsible })
      dispatch({
        type: UPDATE_RECIPE_VERSION_RESPONSE,
        payload: {
          data: response.data,
        },
      })
    } catch (err) {
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message
      dispatch({
        type: UPDATE_RECIPE_VERSION_ERROR,
        payload: {
          error: errorMessage,
        },
      })
      dispatch(handleRequestError(err))
    }
  }

export const fetchRecipeToSimulate = code => async (dispatch, _, api) => {
  dispatch({
    type: RECIPE_TO_SIMULATE_REQUEST,
  })
  try {
    const response = await api.get(`/api/recipes/${code}/complete`, {
      params: { code },
    })
    dispatch({
      type: RECIPE_TO_SIMULATE_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: RECIPE_TO_SIMULATE_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
    dispatch(errorNotification(err))
  }
}

export const fetchRecipeToAdd =
  (code, quantity) => async (dispatch, getState, api) => {
    dispatch({
      type: RECIPE_TO_ADD_REQUEST,
    })
    try {
      const response = await api.get(`/api/recipes/${code}/complete`, {
        params: { code },
      })
      const { recipeSearchResults } = getState().recipes
      const recipeIndex = recipeSearchResults.data.findIndex(
        recipe => recipe.code === response.data.code
      )
      const updatedSearchResults = dotProp.set(
        recipeSearchResults,
        `data.${recipeIndex}`,
        response.data
      )
      dispatch({
        type: RECIPE_TO_ADD_RESPONSE,
        payload: {
          data: {
            recipeSearchResults: updatedSearchResults,
            newRecipeAdded: {
              quantity,
              recipe: response.data,
            },
          },
        },
      })
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : err.message
      dispatch({
        type: RECIPE_TO_ADD_ERROR,
        payload: {
          error: errorMessage,
        },
      })
      dispatch(handleRequestError(err))
      dispatch(errorNotification(err))
    }
  }

export const addIngredient =
  (code, quantity, showCompositionOnTag, editing) =>
  async (dispatch, _getState, _api) =>
    dispatch({
      type: editing ? ADD_CURRENT_RECIPE_INGREDIENT : ADD_INGREDIENT,
      payload: {
        data: {
          quantity,
          showCompositionOnTag,
          code,
        },
      },
    })

export const removeIngredient =
  (code, editing) => async (dispatch, _getState, _api) =>
    dispatch({
      type: editing ? REMOVE_CURRENT_RECIPE_INGREDIENT : REMOVE_INGREDIENT,
      payload: {
        data: {
          code,
        },
      },
    })

export const addSubRecipe =
  (code, quantity, editing) => async (dispatch, _getState, _api) =>
    dispatch({
      type: editing ? ADD_CURRENT_RECIPE_SUBRECIPE : ADD_SUBRECIPE,
      payload: {
        data: {
          quantity,
          code,
        },
      },
    })

export const removeSubRecipe =
  (code, editing) => async (dispatch, _getState, _api) =>
    dispatch({
      type: editing ? REMOVE_CURRENT_RECIPE_SUBRECIPE : REMOVE_SUBRECIPE,
      payload: {
        data: {
          code,
        },
      },
    })

export const resetMessagesState = () => (dispatch, _getState, _api) =>
  dispatch({
    type: CREATE_RECIPE_RESET,
  })
