import React from 'react'
import { FormattedMessage } from 'react-intl'
import defineMessages from './Messages'

export const recipeSteps = <FormattedMessage {...defineMessages.recipeSteps} />
export const addStep = <FormattedMessage {...defineMessages.addStep} />
export const remove = <FormattedMessage {...defineMessages.removeStep} />
export const sku = <FormattedMessage {...defineMessages.sku} />
export const minLots = <FormattedMessage {...defineMessages.minLots} />
export const maxLots = <FormattedMessage {...defineMessages.maxLots} />
export const lotSize = <FormattedMessage {...defineMessages.lotSize} />
export const minInventory = (
  <FormattedMessage {...defineMessages.minInventory} />
)
export const maxInventory = (
  <FormattedMessage {...defineMessages.maxInventory} />
)
export const recipeProductionResource = (
  <FormattedMessage {...defineMessages.productionResource} />
)
export const recipeSetupTime = (
  <FormattedMessage {...defineMessages.setupTime} />
)
export const recipeComplexity = (
  <FormattedMessage {...defineMessages.complexity} />
)
export const save = <FormattedMessage {...defineMessages.save} />
export const edit = <FormattedMessage {...defineMessages.edit} />
export const searchPlaceholder = (
  <FormattedMessage {...defineMessages.searchPlaceholder} />
)
export const tableHeader = [
  <FormattedMessage {...defineMessages.sku} />,
  <FormattedMessage {...defineMessages.name} />,
]
