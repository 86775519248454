import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import { withStyles } from '@material-ui/core/styles'
import MaterialSelect from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import styles from './styles'

class Select extends Component {
  constructor(props) {
    super(props)
    this.input = <OutlinedInput name={this.props.id} labelWidth={0} />
  }

  handleChange = event => {
    const { onChange } = this.props
    if (!onChange) {
      return
    }
    onChange(dotProp.set(event, 'target.id', event.target.name))
  }

  handleBlur = event => {
    const { onBlur } = this.props
    if (!onBlur) {
      return
    }
    onBlur(dotProp.set(event, 'target.id', event.target.name))
  }

  render() {
    const { label, value, error, children, classes, ...rest } = this.props
    return (
      <Grid
        container
        justify="flex-end"
        direction="column"
        className={classes.helperTextAndSelectContainer}
      >
        <FormHelperText error={error}>{label}</FormHelperText>
        <MaterialSelect
          {...rest}
          input={this.input}
          value={value}
          error={error}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          className={classes.select}
          inputProps={{
            root: classes.root,
          }}
        >
          {children}
        </MaterialSelect>
      </Grid>
    )
  }
}

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.any,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
}

Select.defaultProps = {
  id: '',
  label: '',
  value: null,
  error: false,
  onChange: () => {},
  onBlur: () => {},
  classes: {},
}

export default withStyles(styles)(Select)
