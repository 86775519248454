import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'

import { FilterContainer } from '../../../components/molecules/filterContainer'
import Checkbox from '../../../components/atoms/checkbox'
import Input from '../../../components/atoms/input'
import { SearchIngredientsRequest } from '../../../api/dto/ingredients'
import { LookupItem } from '../../../../v2/contracts/lookupItem'
import SelectKitchens from '../../../components/molecules/selectKitchens'

interface IngredientSearchProps {
  data: SearchIngredientsRequest
  onChange: (_searchParams: SearchIngredientsRequest) => void
}

function IngredientSearch(props: IngredientSearchProps) {
  const { data, onChange } = props

  const handleShowInactivesToggle = useCallback(() => {
    if (onChange) {
      onChange({
        ...data,
        showInactives: !data.showInactives,
      })
    }
  }, [data, onChange])

  const handleSearchParamChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange({
          ...data,
          searchParam: e?.target?.value,
        })
      }
    },
    [data, onChange]
  )

  const handleSelectedKitchensChange = useCallback(
    (_items: LookupItem | LookupItem[], keys: string[]) => {
      if (onChange) {
        onChange({
          ...data,
          kitchens: keys,
        })
      }
    },
    [data, onChange]
  )

  return (
    <FilterContainer>
      <Grid container direction="row" spacing={24} justify="center">
        <Grid item xs={4}>
          <Input
            label={'Nome ou SKU do ingrediente'}
            required={false}
            disabled={false}
            type="text"
            value={data.searchParam || ''}
            fullWidth={true}
            onChange={handleSearchParamChange}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectKitchens
            selected={data.kitchens ?? []}
            onSelect={handleSelectedKitchensChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Checkbox
            selected={data.showInactives || false}
            onSelect={handleShowInactivesToggle}
            text={'Exibir desativados'}
            index={0}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  )
}

export default IngredientSearch
