export default theme => ({
  title: {
    marginTop: theme.spacing.unit * 2,
  },
  addStep: {
    marginRight: theme.spacing.unit * 4,
  },
  actions: {
    marginTop: theme.spacing.unit * 8,
  },
})
