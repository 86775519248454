export default _theme => ({
  helperTextAndSelectContainer: {
    height: '100%',
  },
  select: {
    marginTop: '5px',
    marginBottom: '8px',
  },
  root: {
    borderRadius: '4px',
  },
  input: {
    padding: '20px 12px 17px',
  },
})
