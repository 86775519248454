import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import RecipeSimulationContainer from './containers/RecipeSimulationContainer'
import { recipeSimulationMessage } from './formattedMessages/'

class RecipeSimulationPage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={recipeSimulationMessage}
        />
        <RecipeSimulationContainer />
      </div>
    )
  }
}

export default RecipeSimulationPage
