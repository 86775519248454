export const calculateBatchSize = (recipe, calculateRecipeResultingWeight) => {
  if (!recipe || !recipe.recipeInfo) return 0
  return Math.floor(
    calculateRecipeResultingWeight(
      recipe.recipeInfo.recipeYield || 0,
      recipe.recipeInfo.recipeWaste || 0,
      recipe.ingredients || [],
      recipe.subRecipes || []
    ).value / (recipe.recipeInfo.portionSize || 0)
  )
}
