import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from 'components/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import {
  ingredientCompositionMessage,
  ingredientNameMessage,
  ingredientQuantityMessage,
  ingredientPriceMessageSimulation,
  ingredientPriceMessage,
  ingredientCurrentPriceMessage,
  ingredientPricePerUnitMessage,
  ingredientPercentPriceMessage,
} from '../formattedMessages'
import {
  priceAdornment,
  quantityGramsAdornment,
} from '../../../components/InputAdornments'
import { validatePositiveNumber } from '../../../helperFunctions/validateInput'
import { calculateIngredientTotalCost } from '../../../utils/ingredientTotalCost'

const styles = theme => ({
  ingredientContainer: {
    minHeight: '120px',
  },
  ingredientSubContainer: {
    borderRadius: `${0.5 * theme.spacing.unit}px`,
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    marginTop: '4px',
  },
  ingredientOptions: {
    marginTop: '1.875rem',
    flex: 'none',
  },
  composition: {
    marginBottom: '15px',
    padding: '12px',
  },
  hiddenComposition: {
    height: '0px',
    backgroundColor: 'yellow',
  },
  helperTextAndTextFieldContainer: {
    height: '100%',
  },
  priceInput: {
    padding: '20px 12px 17px 0',
  },
  priceInputAdornment: {
    marginBottom: '14px',
  },
  priceInputAdornmentFontSize: {
    fontSize: 16,
  },
  percentPriceInputAdornment: {
    marginTop: '4px',
  },
  percentPriceInputAdornmentFontSize: {
    fontSize: 18,
  },
  boxOpened: {
    display: 'flex',
    width: '100%',
    maxHeight: '113px',
    transition: 'all 0.5s 0s ease',
  },
  boxClosed: {
    width: '100%',
    maxHeight: '0px',
    transition: 'all 0.5s 0s ease',
    opacity: '0.2',
    overflow: 'hidden',
  },
})

const StyledTextField = withStyles({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
})(TextField)

class Ingredient extends PureComponent {
  constructor(props) {
    super(props)
    this.priceInputProps = {
      startAdornment: (
        <InputAdornment
          position="start"
          className={props.classes.priceInputAdornment}
        >
          <div className={props.classes.priceInputAdornmentFontSize}>R$</div>
        </InputAdornment>
      ),
      classes: {
        input: props.classes.priceInput,
      },
    }
    this.percentPriceInputProps = {
      endAdornment: (
        <InputAdornment
          position="end"
          className={props.classes.percentPriceInputAdornment}
        >
          <div className={props.classes.percentPriceInputAdornmentFontSize}>
            %
          </div>
        </InputAdornment>
      ),
    }
  }

  handleChange = event => {
    const { handleChange } = this.props
    const { id, value } = event.target
    let validatedInput
    if (id.endsWith('name')) {
      validatedInput = value
    } else {
      validatedInput = validatePositiveNumber(value)
    }
    if (validatedInput !== null) {
      handleChange(id)(validatedInput)
    }
  }

  handleRemove = () => {
    const { path, handleRemove, ingredientIndex } = this.props
    handleRemove(`${path}.${ingredientIndex}`)
  }

  handleIngredientOpen = () => {
    window.open(`/#/app/ingredients/edit/${this.props.ingredient.code}`)
  }

  render() {
    const {
      ingredient,
      ingredientIndex,
      classes,
      path,
      totalCost,
      batchSize,
      simulation,
      dirty,
    } = this.props
    const ingredientTotalCost =
      ingredient.ingredientTotalCost && !isNaN(ingredient.ingredientTotalCost)
        ? ingredient.ingredientTotalCost
        : calculateIngredientTotalCost(ingredient)

    const costPerUnit =
      !dirty && ingredientTotalCost && batchSize > 0
        ? ingredientTotalCost / batchSize
        : '-'

    const percentCost =
      !dirty && costPerUnit > 0 && totalCost > 0
        ? (100 * (costPerUnit / totalCost)).toFixed(2)
        : '-'

    return (
      <Grid container direction="row">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          className={classes.ingredientContainer}
        >
          <Grid
            container
            direction="row"
            spacing={8}
            className={classes.ingredientSubContainer}
          >
            <Grid item xs={4}>
              <TextField
                label={ingredientNameMessage}
                id={`${path}.${ingredientIndex}.name`}
                fullWidth
                value={ingredient.name}
                handleChange={this.handleChange}
                readOnly
              />
            </Grid>
            <Grid item xs>
              <TextField
                label={ingredientQuantityMessage}
                id={`${path}.${ingredientIndex}.quantity`}
                value={ingredient.quantity}
                type="number"
                handleChange={this.handleChange}
                inputProps={quantityGramsAdornment}
              />
            </Grid>
            {simulation ? (
              <Grid item xs>
                <TextField
                  id={`${path}.${ingredientIndex}.currentPrice`}
                  label={ingredientCurrentPriceMessage}
                  value={ingredient.currentPrice}
                  handleChange={this.handleChange}
                  inputProps={priceAdornment}
                />
              </Grid>
            ) : null}
            <Grid item xs>
              <TextField
                label={
                  simulation
                    ? ingredientPriceMessageSimulation
                    : ingredientPriceMessage
                }
                id={`${path}.${ingredientIndex}.price`}
                value={ingredient.price}
                readOnly
                inputProps={this.priceInputProps}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label={ingredientPricePerUnitMessage}
                id={`${path}.${ingredientIndex}.pricePerUnit`}
                value={
                  typeof costPerUnit === 'number'
                    ? costPerUnit.toFixed(2)
                    : costPerUnit
                }
                readOnly
                inputProps={this.priceInputProps}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label={ingredientPercentPriceMessage}
                id={`${path}.${ingredientIndex}.percentPrice`}
                fullWidth
                value={percentCost}
                readOnly
                inputProps={this.percentPriceInputProps}
              />
            </Grid>
            <Grid item xs className={classes.ingredientOptions}>
              <IconButton onClick={this.handleIngredientOpen}>
                <OpenInNewOutlined />
              </IconButton>
              <IconButton onClick={this.handleRemove}>
                <DeleteOutlined />
              </IconButton>
            </Grid>
          </Grid>
          {ingredient.composition && (
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.ingredientSubContainer}
            >
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Switch
                      id={`${path}.${ingredientIndex}.showCompositionOnTag`}
                      checked={ingredient.showCompositionOnTag || false}
                      disabled={!ingredient.composition}
                      onChange={(e, isChecked) => {
                        const { id } = e.target
                        this.props.handleChange(id)(isChecked)
                      }}
                      value="showCompositonOnTag"
                    />
                  }
                  label="Exibir composição na etiqueta?"
                />

                <div
                  className={
                    ingredient.showCompositionOnTag
                      ? classes.boxOpened
                      : classes.boxClosed
                  }
                >
                  <StyledTextField
                    id={`${path}.${ingredientIndex}.composition`}
                    multiline
                    rowsMax={3}
                    label={ingredientCompositionMessage}
                    value={ingredient.composition}
                    handleChange={this.handleChange}
                    readOnly
                    classes={classes.hiddenComposition}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
}

Ingredient.propTypes = {
  ingredient: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    currentPrice: PropTypes.number,
    pricePerUnit: PropTypes.number,
    percentPrice: PropTypes.number,
    composition: PropTypes.string,
    showCompositionOnTag: PropTypes.bool,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  ingredientIndex: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  totalCost: PropTypes.number,
  batchSize: PropTypes.number,
  simulation: PropTypes.bool,
  dirty: PropTypes.bool,
}

Ingredient.defaultProps = {
  simulation: false,
  totalCost: 0,
  batchSize: 0,
  dirty: false,
}

export default withStyles(styles)(Ingredient)
