import React, { useEffect, Dispatch, SetStateAction } from 'react'
import { LookupItem } from 'src/v2/contracts/lookupItem'
import {
  KitchensSelect,
  Container,
  ContentContainer,
  NameFilter,
} from './styles'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

export interface FilterContainerProps {
  onChange: (value: string) => void
  kitchens: LookupItem[]
  kitchensOptions: LookupItem[]
  onSelectKitchen: Dispatch<SetStateAction<LookupItem[]>>
}
const subject = new Subject()

export const FilterContainer: React.FC<FilterContainerProps> = props => {
  const handleChange = (event: any) => {
    subject.next(event.target.value)
  }
  useEffect(() => {
    const subs = subject.pipe(debounceTime(150)).subscribe(props.onChange)

    return () => {
      subs.unsubscribe()
    }
  })

  return (
    <Container title={'Filtros'}>
      <ContentContainer>
        <NameFilter
          onChange={handleChange}
          label={'Nome ou sku'}
          required={false}
          disabled={false}
          type=""
        />
        <KitchensSelect
          selected={props.kitchens}
          options={props.kitchensOptions}
          onSelect={props.onSelectKitchen}
          placeholder="Filtre por cozinhas"
          isMulti
          isClearable
        />
      </ContentContainer>
    </Container>
  )
}
