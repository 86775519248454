import ConfigContainer from './containers/configContainer'
import { PrintPage } from './pages/print-page'

export const tagsRoutes = {
  path: 'tags',
  restricted: false,
  children: [
    {
      path: 'config',
      component: ConfigContainer,
    },
    {
      path: 'print',
      component: PrintPage,
    },
  ],
}
