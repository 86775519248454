import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import MuiDivider from '@material-ui/core/Divider'
import styled from 'styled-components'

import { Lookup } from 'src/v2/components/molecules/lookup'
import { Typography } from 'src/v2/components/atoms'

export const IngredientTitle = styled(Typography)`
  && {
    flex: 1;
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    font-weight: bold;
  }
`

export const ProcessTitle = styled(Typography)`
  && {
    flex: 1;
    font-size: ${props => props.theme.v2.typography.fontSize.small};
    color: #737373;
  }
`

export const FullWidthLookup = styled(Lookup)`
  width: 100%;
`

export const Divider = styled(MuiDivider)`
  && {
    margin: 16px 0;
    background-color: #d5d5d5;
  }
`

export const DeleteIcon = styled(DeleteOutlineIcon)`
  && {
    color: #4d4d4d;
  }
`

export const ActionsContainer = styled.div`
  && {
    display: flex;
    :hover {
      cursor: pointer;
    }
  }
`

export const NewProcessLabel = styled(Typography)`
  && {
    font-size: ${props => props.theme.v2.typography.fontSize.medium};
    color: #ff8833;
  }
`

export const NewProcessIcon = styled(AddCircleOutlineIcon)`
  && {
    color: #ff8833;
    margin-left: 4px;
  }
`

export const CancelButton = styled(Button)`
  font-family: ${props => props.theme.v2.typography.fontFamily};
  font-size: ${props => props.theme.v2.typography.fontSize.small};
  background-color: #ffffff;
  color: #0d8059;
  font-weight: bold;
  border: 1px solid #0d8059;
  border-radius: 2px;

  :hover {
    background-color: #cef2e6;
  }
`

export const SaveButton = styled(Button)`
  font-family: ${props => props.theme.v2.typography.fontFamily};
  font-size: ${props => props.theme.v2.typography.fontSize.small};
  background-color: #0d8059;
  color: #ffffff;
  font-weight: bold;
  border-radius: 2px;

  :hover {
    background-color: #084d35;
  }
`
