import React from 'react'
import moment from 'moment'
import { ConnectedRouter } from 'react-router-redux'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import configureStore, { history } from './store'
import App from './containers/App'

moment.locale('pt-BR')

export const store = configureStore()

const MainApp = () => (
  <Provider store={store}>
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={5000}
    >
      <ConnectedRouter history={history}>
        <Route path="/" component={App} />
      </ConnectedRouter>
    </SnackbarProvider>
  </Provider>
)

export default MainApp
