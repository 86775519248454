import React, { Component, Fragment } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PeriodSelector from 'components/PeriodSelector'
import { Typography } from '@material-ui/core'
import { reactDates } from 'utils/customPropTypes'
import Button from 'components/Button'
import styles from './styles'

class DateSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { onDatesChange, startDate, endDate, classes, title } = this.props
    return (
      <Fragment>
        <Grid
          container
          direction="row"
          spacing={16}
          justify="flex-start"
          alignItems="flex-end"
          className={classes.searchBar}
        >
          <Grid item>
            <Grid className={classes.title}>
              <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <PeriodSelector
              onDatesChange={onDatesChange}
              startDate={startDate}
              endDate={endDate}
              isOutsideRange
            />
          </Grid>
          <Grid item className={classes.title}>
            <Button onClick={this.props.onSearchClick}>Buscar</Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

DateSelector.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  startDate: reactDates,
  endDate: reactDates,
  title: PropTypes.object.isRequired,
  onSearchClick: PropTypes.func.isRequired,
}

DateSelector.defaultProps = {
  startDate: null,
  endDate: null,
}

export default withStyles(styles)(DateSelector)
