import React, { Component } from 'react'
import { parseWeekday } from 'utils/parseWeekday'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import CardBox from 'components/CardBox'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ResultsTable from 'components/ResultsTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorBox from 'components/ErrorBox'
import { fetchPPCPResults } from 'actions/PPCP'
import { productionSubtitle } from '../../formattedMessages'

const styles = () => ({
  scrollGrid: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
})

class PppcpResultsContainer extends Component {
  componentDidMount() {
    this.props.fetchPPCPResults()
  }

  convertDate = dates =>
    dates.map(date => {
      const splittedDate = date.split('/')
      const transformedDay = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`
      const weekday = parseWeekday(new Date(transformedDay).getDay())
      return `${date} - ${weekday}`
    })

  renderProductionPlanning() {
    const { productionPlanning, planningDates, productionLotsBySKU } =
      this.props
    if (productionPlanning.isLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (productionPlanning.error) {
      return <ErrorBox message={productionPlanning.error} />
    }
    if (productionPlanning.data) {
      return (
        <Grid container>
          <Grid>
            <Typography variant="subtitle1">{productionSubtitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ResultsTable
              tableHeader={this.convertDate(planningDates)}
              stickyColumnTitle="SKU"
              results={productionLotsBySKU}
              isStickyColumn
              isStickyHeader
            />
          </Grid>
        </Grid>
      )
    }
  }

  render() {
    return (
      <CardBox styleName="col-sm-12">{this.renderProductionPlanning()}</CardBox>
    )
  }
}

const mapActionsToProps = {
  fetchPPCPResults,
}

const mapStateToProps = state => ({
  productionPlanning: state.production.productionPlanning,
  productionLotsBySKU: state.production.productionPlanning.data,
  planningDates: state.production.productionPlanning.productionDates,
  isLoading: state.production.productionPlanning.loading,
})

PppcpResultsContainer.propTypes = {
  productionLotsBySKU: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  productionPlanning: PropTypes.object,
  planningDates: PropTypes.arrayOf(PropTypes.string.isRequired),
}

PppcpResultsContainer.defaultProps = {
  productionPlanning: {},
  planningDates: [],
  productionLotsBySKU: [],
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(PppcpResultsContainer))
)
