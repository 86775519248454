export default theme => ({
  costPerUnitContainer: {
    borderTop: `6px solid ${theme.palette.primary.main}`,
    padding: 2 * theme.spacing.unit,
    borderRadius: 0,
  },
  priceInput: {
    padding: '20px 12px 17px 0',
  },
  priceInputAdornment: {
    marginBottom: '14px',
  },
  priceInputAdornmentFontSize: {
    fontSize: 16,
  },
  activityIndicator: {
    marginTop: '8px',
    alignSelf: 'center',
  },
})
