import React, { Component } from 'react'
import dotProp from 'dot-prop-immutable'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ReceptionInfoRow from './ReceptionInfoRow'
import { styles } from '../styles'

class ReceptionsInfoTable extends Component {
  handleReceptionInfoUpdate = receptionInfoToUpdate => {
    const index = this.props.receptionsInfo.findIndex(
      receptionInfo =>
        receptionInfo.feedstock.feedstockName ===
        receptionInfoToUpdate.feedstock.feedstockName
    )
    const { receptionsInfo } =
      index > -1
        ? dotProp.set(
            this.props,
            `receptionsInfo.${index}`,
            receptionInfoToUpdate
          )
        : {
            ...this.props,
            receptionsInfo: this.props.receptionsInfo.concat([
              receptionInfoToUpdate,
            ]),
          }
    this.props.onReceptionsInfoUpdate('receptionsInfo', receptionsInfo)
  }

  renderTableHead = () => {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell padding="none">Nome</TableCell>
        <TableCell padding="none">Qtd. Esperada</TableCell>
        <TableCell padding="none">Qtd. Nota Fiscal</TableCell>
        <TableCell padding="none" className={classes.RedBorderBottom}>
          Qtd. Recebida (*)
        </TableCell>
        <TableCell padding="none" className={classes.RedBorderBottom}>
          Total Pago (*)
        </TableCell>
        <TableCell padding="none" className={classes.RedBorderBottom}>
          Preço/Kg
        </TableCell>
        <TableCell padding="none">Média atual</TableCell>
        <TableCell padding="none">Recebido em (*)</TableCell>
        <TableCell padding="default">Válido até</TableCell>
      </TableRow>
    )
  }

  renderReceptionsInfoList = () => {
    const { receptionsInfo } = this.props
    return (
      receptionsInfo &&
      receptionsInfo.map(receptionInfo => (
        <ReceptionInfoRow
          receptionInfo={receptionInfo}
          onReceptionInfoUpdate={this.handleReceptionInfoUpdate}
          classes={this.props.classes}
          feedstockPricesArray={this.props.feedstockPricesArray}
        />
      ))
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.tableWrapper}>
        <h3>Matérias-Primas:</h3>
        <Table className={classes.table}>
          <TableHead>{this.renderTableHead()}</TableHead>
          <TableBody>{this.renderReceptionsInfoList()}</TableBody>
        </Table>
      </div>
    )
  }
}

ReceptionsInfoTable.propTypes = {
  receptionsInfo: PropTypes.array,
  feedstockPricesArray: PropTypes.array,
  onReceptionsInfoUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

ReceptionsInfoTable.defaultProps = {
  receptionsInfo: [],
  feedstockPricesArray: [],
  classes: {},
}

export default withStyles(styles)(ReceptionsInfoTable)
