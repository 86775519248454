import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { tableHeader } from '../../../messages/formattedMessages'
import { StyledPaper } from './styles'

export interface FeedstockData {
  code: string
  name: string
  stockType: string
  price: number
  type: string
}

export interface FeedstockSearchResults {
  loading: boolean
  error: string
  data: FeedstockData[]
}

export interface FeedstockTableProps {
  feedstockSearchResults: FeedstockSearchResults
}

export const FeedstockTable = ({
  feedstockSearchResults,
}: FeedstockTableProps) => (
  <StyledPaper>
    <Table>
      <TableHead>
        <TableRow>
          {tableHeader.map(formattedMessage => (
            <TableCell>{formattedMessage}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {feedstockSearchResults.data.map(feedstock => (
          <TableRow hover>
            <TableCell>{feedstock.code}</TableCell>
            <TableCell>
              <NavLink to={`/app/feedstock/regular/${feedstock.code}`}>
                {feedstock.name}
              </NavLink>
            </TableCell>
            <TableCell>{feedstock.stockType}</TableCell>
            <TableCell>{feedstock.price}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </StyledPaper>
)
