import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { productionProduct } from 'utils/customPropTypes'
import ResultsTable from 'components/ResultsTable'

const styles = () => ({
  sectionTitle: {
    marginBottom: '2rem',
    marginTop: '2rem',
  },
})

class ProductionTable extends Component {
  getProductionFieldsPath = () => ['sku', 'name', 'inventory', 'demand']

  render() {
    const { production, classes, header, sectionTitle } = this.props
    return (
      <Fragment>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          {sectionTitle}
        </Typography>
        <ResultsTable
          tableHeader={header}
          results={production}
          textAlign="center"
          fieldsPath={this.getProductionFieldsPath()}
          isStickyHeader
        />
      </Fragment>
    )
  }
}

ProductionTable.propTypes = {
  classes: PropTypes.object.isRequired,
  production: productionProduct,
  header: PropTypes.array,
  sectionTitle: PropTypes.object.isRequired,
}

ProductionTable.defaultProps = {
  production: [],
  header: [],
}

export default withStyles(styles)(ProductionTable)
