import styled from 'styled-components'

export const RecipesContainer = styled.div`
  && {
  }
`

export const LoadingContainer = styled.div`
  && {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Divider = styled.div`
  && {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #d5d5d5;
  }
`
