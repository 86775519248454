import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { FormattedMessage } from 'react-intl'
import RegisterResourceContainer from './containers/RegisterResourceContainer'
import defineMessages from '../Register/containers/Messages'

class RegisterResourcePage extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={<FormattedMessage {...defineMessages.registerResource} />}
        />
        <RegisterResourceContainer />
      </div>
    )
  }
}

export default RegisterResourcePage
