import {
  SEARCH_RECEPTIONS_REQUEST,
  SEARCH_RECEPTIONS_RESPONSE,
  SEARCH_RECEPTIONS_ERROR,
  EDIT_RECEPTIONS_REQUEST,
  EDIT_RECEPTIONS_RESPONSE,
  EDIT_RECEPTIONS_ERROR,
  FETCH_KITCHENS_REQUEST,
  FETCH_KITCHENS_RESPONSE,
  FETCH_KITCHENS_ERROR,
  FETCH_ALL_RECEPTIONS_REQUEST,
  FETCH_ALL_RECEPTIONS_RESPONSE,
  FETCH_ALL_RECEPTIONS_ERROR,
} from '../constants/ActionTypes'
import { handleRequestError } from './ErrorHandling'
import { sendNotification } from './Notifications'

export const getSearchResults = params => async (dispatch, _, api) => {
  try {
    dispatch({
      type: SEARCH_RECEPTIONS_REQUEST,
    })
    const response = await api.get('api/receptions/search', {
      params,
    })
    dispatch({
      type: SEARCH_RECEPTIONS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: SEARCH_RECEPTIONS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const fetchReceptions = () => async (dispatch, _, api) => {
  try {
    dispatch({
      type: FETCH_ALL_RECEPTIONS_REQUEST,
    })
    const response = await api.get('api/receptions/fetch')
    dispatch({
      type: FETCH_ALL_RECEPTIONS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_ALL_RECEPTIONS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const fetchKitchens = () => async (dispatch, _, api) => {
  dispatch({
    type: FETCH_KITCHENS_REQUEST,
  })
  try {
    const response = await api.get('api/receptions/fetchKitchens')
    dispatch({
      type: FETCH_KITCHENS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: FETCH_KITCHENS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const fetchSingleReception = code => async (dispatch, _, api) => {
  try {
    dispatch({
      type: EDIT_RECEPTIONS_REQUEST,
    })
    const response = await api.get('api/receptions/fetchSingle', {
      params: { code },
    })
    dispatch({
      type: EDIT_RECEPTIONS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: EDIT_RECEPTIONS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const updateReception = reception => async (dispatch, _, api) => {
  try {
    dispatch({
      type: EDIT_RECEPTIONS_REQUEST,
    })
    const response = await api.post('api/receptions/updateOne', {
      reception,
    })
    dispatch({
      type: EDIT_RECEPTIONS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
    dispatch(
      sendNotification({
        type: 'success',
        message: 'Recebimento atualizado com sucesso!',
        autoDismiss: true,
      })
    )
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message : err.message
    dispatch({
      type: EDIT_RECEPTIONS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}
