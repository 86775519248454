import React, { useMemo, useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import { Ingredient, PreparationProcess } from '../../../api/dto/ingredients'
import { FactoryProcesses } from '../../../api/dto/FactoryProcesses'
import Input from '../../../components/atoms/input'
import { LookupItem } from '../../../contracts/lookupItem'
import {
  Divider,
  DeleteIcon,
  ActionsContainer,
  NewProcessLabel,
  NewProcessIcon,
  FullWidthLookup,
  CancelButton,
  SaveButton,
  IngredientTitle,
  ProcessTitle,
} from './styles'

interface Props {
  ingredient: Ingredient
  processes: FactoryProcesses
  loading: boolean
  onCancel(): void
  onSave(processes: PreparationProcess[]): void
}

function IngredientProcessForm(props: Props) {
  const activities = useMemo(
    () =>
      props.processes.activities.map(activity => ({
        label: activity,
        value: activity,
      })),
    [props.processes.activities]
  )

  const areas = useMemo(
    () =>
      props.processes.areas.map(area => ({
        label: area,
        value: area,
      })),
    [props.processes.areas]
  )

  const processes = useMemo(
    () =>
      props.processes.processes.map(process => ({
        label: process,
        value: process,
      })),
    [props.processes.processes]
  )

  const resources = useMemo(
    () =>
      props.processes.resources.map(resource => ({
        label: resource,
        value: resource,
      })),
    [props.processes.resources]
  )

  function generateUniqueID() {
    return new Date().getTime().toString()
  }

  const [selectedProcesses, setSelectedProcesses] = useState(
    props.ingredient.preparationProcesses &&
      props.ingredient.preparationProcesses.length
      ? props.ingredient.preparationProcesses
      : [
          {
            id: generateUniqueID(),
            activity: '',
            area: '',
            process: '',
            resource: '',
            hint: '',
            waste: undefined,
          },
        ]
  )

  function handleLookupChange(name: string, index: number) {
    return (item: LookupItem) => {
      const updatedItem = { ...selectedProcesses[index], [name]: item.value }
      setSelectedProcesses(currentSelectedProcesses => {
        const updatedSelectedProcesses = [...currentSelectedProcesses]
        updatedSelectedProcesses[index] = updatedItem
        return updatedSelectedProcesses
      })
    }
  }

  function handleWasteChange(index: number) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedItem = {
        ...selectedProcesses[index],
        waste: Number(e.target.value?.replace(',', '')),
      }
      setSelectedProcesses(currentSelectedProcesses => {
        const updatedSelectedProcesses = [...currentSelectedProcesses]
        updatedSelectedProcesses[index] = updatedItem
        return updatedSelectedProcesses
      })
    }
  }

  function handleHintChange(index: number) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedItem = {
        ...selectedProcesses[index],
        hint: e.target.value,
      }
      setSelectedProcesses(currentSelectedProcesses => {
        const updatedSelectedProcesses = [...currentSelectedProcesses]
        updatedSelectedProcesses[index] = updatedItem
        return updatedSelectedProcesses
      })
    }
  }

  function handleNewProcess() {
    setSelectedProcesses(oldProcesses => [
      ...oldProcesses,
      {
        id: generateUniqueID(),
        activity: '',
        area: '',
        process: '',
        resource: '',
        hint: '',
        waste: undefined,
      },
    ])
  }

  function handlePreparationProcessRemove(
    processToBeRemoved: PreparationProcess
  ) {
    return () => {
      setSelectedProcesses(oldProcesses =>
        oldProcesses.filter(process => process !== processToBeRemoved)
      )
    }
  }

  function handleCancel() {
    props.onCancel()
  }

  function handleSave() {
    props.onSave(selectedProcesses)
  }

  return (
    <Grid container direction="row" spacing={16}>
      <Grid container item xs={12}>
        <IngredientTitle>{props.ingredient.recipeName}</IngredientTitle>
      </Grid>
      {selectedProcesses.map((process, i) => (
        <React.Fragment key={process.id}>
          <Grid container item md={1} sm={12}>
            <ProcessTitle>Processo {i + 1}</ProcessTitle>
          </Grid>

          <Grid container item md={10} sm={12} direction="row" spacing={16}>
            <Grid container item md={6} sm={12}>
              <FullWidthLookup
                selected={
                  process.area
                    ? { label: process.area, value: process.area }
                    : undefined
                }
                placeholder="Selecione a área"
                options={areas}
                onSelect={handleLookupChange('area', i)}
                disabled={props.loading}
              />
            </Grid>
            <Grid container item md={6} sm={12}>
              <FullWidthLookup
                selected={
                  process.process
                    ? { label: process.process, value: process.process }
                    : undefined
                }
                placeholder="Selecione o processo"
                options={processes}
                onSelect={handleLookupChange('process', i)}
                disabled={props.loading}
              />
            </Grid>
            <Grid container item md={4} sm={12}>
              <FullWidthLookup
                selected={
                  process.activity
                    ? { label: process.activity, value: process.activity }
                    : undefined
                }
                placeholder="Selecione uma atividade"
                options={activities}
                onSelect={handleLookupChange('activity', i)}
                disabled={props.loading}
              />
            </Grid>
            <Grid container item md={4} sm={12}>
              <FullWidthLookup
                selected={
                  process.resource
                    ? { label: process.resource, value: process.resource }
                    : undefined
                }
                placeholder="Selecione um recurso"
                options={resources}
                onSelect={handleLookupChange('resource', i)}
                disabled={props.loading}
              />
            </Grid>
            <Grid container item md={4} sm={12}>
              <Input
                fullWidth
                label="Desperdício"
                value={String(process.waste ?? 0)}
                type="percentage"
                onChange={handleWasteChange(i)}
                disabled={props.loading}
              />
            </Grid>
            <Grid container item xs={12}>
              <Input
                fullWidth
                label="Observação"
                placeholder="Ex.: Tirar folhas amarelas da salsinha"
                type="text"
                value={process.hint || ''}
                onChange={handleHintChange(i)}
                disabled={props.loading}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={1}
            sm={12}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <IconButton onClick={handlePreparationProcessRemove(process)}>
              <DeleteIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={12} direction="column">
            <Divider />
          </Grid>
        </React.Fragment>
      ))}
      <Grid container item xs={12} justify="flex-end">
        <ActionsContainer onClick={handleNewProcess}>
          <NewProcessLabel>ADICIONAR PROCESSO</NewProcessLabel>
          <NewProcessIcon />
        </ActionsContainer>
      </Grid>
      <Grid container item xs={12} spacing={16}>
        {props.loading ? (
          <CircularProgress size={30} />
        ) : (
          <React.Fragment>
            <Grid item>
              <CancelButton variant="outlined" onClick={handleCancel}>
                CANCELAR
              </CancelButton>
            </Grid>
            <Grid item>
              <SaveButton variant="contained" onClick={handleSave}>
                SALVAR
              </SaveButton>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  )
}

export default IngredientProcessForm
