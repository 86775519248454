import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import CardBox from 'components/CardBox'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchResources } from 'actions/Resources'
import ErrorBox from 'components/ErrorBox'
import ResultsTable from 'components/ResultsTable'
import SearchBar from 'components/SearchBar'
import { tableHeader, searchPlaceholder } from './formattedMessages'

class SearchResourcesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedResource: '',
      searchToken: '',
    }
  }

  componentDidMount() {
    this.props.fetchResources()
  }
  getResourceFieldsPath = () => [
    'name',
    'resourceLimit',
    'type.label',
    'status.label',
  ]

  handleSearch = filter => this.setState({ searchToken: filter })

  filterResources() {
    const { resources } = this.props
    const { searchToken } = this.state
    if (!this.state.searchToken) {
      return resources
    }
    const filterRegex = new RegExp(searchToken, 'i')
    return resources.filter(el => el.name.match(filterRegex))
  }

  goToEditPage = selectedResource => {
    this.setState({
      selectedResource,
    })
    this.props.history.push({
      pathname: '/app/resources/register',
      selectedResource,
    })
  }

  renderResources() {
    const { resources } = this.props
    if (resources.loading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (resources.error) {
      return <ErrorBox message={resources.error} />
    }
    if (resources) {
      return (
        <CardBox styleName="col-sm-12">
          <ResultsTable
            goToEditPage={this.goToEditPage}
            tableHeader={tableHeader}
            results={this.filterResources()}
            fieldsPath={this.getResourceFieldsPath()}
          />
        </CardBox>
      )
    }
  }

  render() {
    return (
      <Fragment>
        <SearchBar
          onChange={this.handleSearch}
          searchPlaceholder={searchPlaceholder}
        />
        <Grid container justify="center">
          {this.renderResources()}
        </Grid>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  resources: state.resources.fetchResources.data,
})

SearchResourcesContainer.propTypes = {
  fetchResources: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  resources: PropTypes.array,
}

SearchResourcesContainer.defaultProps = {
  resources: [],
}

export default withRouter(
  connect(mapStateToProps, {
    fetchResources,
  })(SearchResourcesContainer)
)
