import { FormControl } from '@material-ui/core'
import styled from 'styled-components'

export const StyledFormControl = styled(FormControl)`
  && {
    min-width: 120px;
    margin: 11px 11px 0 0;

    div[class*='MuiInputBase-root'] {
      height: 49px;
    }

    label[class*='MuiFormLabel-root'] {
      background: white;
      padding: 0 5px 0 5px;
      left: -5px;
    }
  }
`
