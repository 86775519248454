import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import dotProp from 'dot-prop-immutable'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorBox from 'components/ErrorBox'
import {
  fetchRecipes,
  fetchIngredients,
  fetchRecipeToSimulate,
  fetchRecipeToAdd,
} from 'actions/Recipes'
import { stick } from '../../../../components/Stickier'
import RecipeInfo from '../components/RecipeInfo'
import NutritionFacts from '../../components/NutritionFacts'
import Breadcrumbs from '../components/Breadcrumbs'
import SubRecipesSideNav from '../components/SubRecipesSideNav'
import SelectModal from '../components/SelectModal'
import Options from '../components/Options'
import { toggleDrawerType } from '../../../../actions/Setting'
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
} from '../../../../constants/ActionTypes'
import {
  calculateRecipeResultingWeight,
  calculateRecipeCost,
  calculateRecipeNutritionFacts,
  formatAddedRecipe,
  formatIngredient,
  formatRecipe,
  nutritionFactsNames,
} from '../../helpers'

import {
  selectModalIngredientsTitleMessage,
  selectModalIngredientsDropDownMessage,
  selectModalRecipesTitleMessage,
  selectModalRecipesDropDownMessage,
  selectModalConfirmButtonMessage,
  selectModalAddButtonMessage,
} from '../formattedMessages/'
import Recipe from '../components/Recipe'
import CostPerUnit from '../../components/CostPerUnit'

const StickyGrid = stick(Grid)
const styles = _theme => ({
  priceInput: {
    padding: '20px 12px 17px 0',
  },
  priceInputAdornment: {
    marginBottom: '14px',
  },
  priceInputAdornmentFontSize: {
    fontSize: 16,
  },
})

class RecipeSimulationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      newRecipe: false,
      ingredientsModalOpen: false,
      recipesModalOpen: false,
      name: '',
      recipeYield: '',
      recipeWaste: '',
      portionSize: '',
      referenceGrammage: '',
      costPerUnit: '',
      batchSize: '',
      nutritionFacts: '',
      ingredients: '',
      subRecipes: '',
      totalCost: 0,
      selectedRecipe: {},
      path: '',
      modalPath: '',
      breadcrumbs: [],
    }

    this.NUTRITIONAL_REFERENCE = {
      kcal: 2000,
      fibers: 25,
      sodium: 2000,
      transFats: 2,
      satFats: 20,
      totalFats: 65,
      proteins: 50,
      carbs: 300,
    }
  }
  componentDidMount() {
    this.props.fetchRecipes()
    this.props.fetchIngredients()
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.recipeToSimulate.data !== prevProps.recipeToSimulate.data) {
      this.prepareRecipeToSimulate()
    }

    if (
      this.props.lastRecipeInfoAdded.recipe !==
      prevProps.lastRecipeInfoAdded.recipe
    ) {
      this.setState(prevState =>
        this.updateRecipeWithNewInfo(
          prevState,
          'recipes',
          formatAddedRecipe(
            this.props.lastRecipeInfoAdded.recipe,
            this.props.lastRecipeInfoAdded.quantity
          )
        )
      )
    }
  }

  componentWillUnmount() {
    this.props.toggleDrawerType(FIXED_DRAWER)
  }

  handleAddModalOpen = path => type => {
    this.setState(prevState => ({
      ...prevState,
      [type]: true,
      modalPath: path,
    }))
  }

  handleConfirmRecipe = name => {
    this.props.toggleDrawerType(COLLAPSED_DRAWER)
    this.setState(prevState => ({
      ...prevState,
      newRecipe: false,
      step: 1,
      name,
      recipeYield: 0,
      recipeWaste: 0,
      portionSize: 0,
      referenceGrammage: 0,
      costPerUnit: 0,
      batchSize: 0,
      nutritionFacts: {},
      ingredients: [],
      subRecipes: [],
      breadcrumbs: [{ name, path: '' }],
      recipesModalOpen: false,
    }))
  }

  handleClickNewRecipe = () => {
    this.setState(prevState => ({
      ...prevState,
      newRecipe: true,
      recipesModalOpen: true,
    }))
  }

  handleClickChooseRecipe = () => {
    this.setState(prevState => ({
      ...prevState,
      newRecipe: false,
      recipesModalOpen: true,
    }))
  }

  handleSelectRecipe = value => () => {
    const { allRecipes } = this.props
    const chosenRecipe =
      allRecipes && allRecipes.data.find(recipe => recipe.code === value)
    this.props.fetchRecipeToSimulate(chosenRecipe.code)
  }

  updateRecipe = state => {
    const {
      recipeYield,
      recipeWaste,
      ingredients,
      subRecipes,
      portionSize,
      referenceGrammage,
    } = state
    const resultingWeight = calculateRecipeResultingWeight(
      recipeYield,
      recipeWaste,
      ingredients,
      subRecipes
    )
    const { updatedState, recipeTotalCost } = calculateRecipeCost(
      { recipeYield, recipeWaste, ingredients, subRecipes },
      resultingWeight.value,
      state,
      '',
      calculateRecipeResultingWeight
    )
    const batchSize = resultingWeight.value / portionSize
    const costPerUnit = recipeTotalCost / batchSize
    const nutritionFacts = calculateRecipeNutritionFacts(
      ingredients,
      subRecipes,
      resultingWeight.valueWithoutWaste,
      referenceGrammage,
      {},
      calculateRecipeResultingWeight
    )
    return {
      ...updatedState,
      totalCost: recipeTotalCost,
      costPerUnit: costPerUnit.toFixed(2),
      batchSize: Math.floor(batchSize || 0),
      nutritionFacts: Object.keys(nutritionFacts).reduce(
        (acc, field) => ({
          ...acc,
          [field]: {
            name: nutritionFactsNames[field],
            portion: nutritionFacts[field].portion.toFixed(2),
            dailyValue: Math.ceil(
              (nutritionFacts[field].portion /
                this.NUTRITIONAL_REFERENCE[field]) *
                100
            ),
          },
        }),
        {}
      ),
    }
  }

  getRecipeToBeRendered = path => {
    let selectedRecipe = dotProp.get(this.state, path)
    if (!selectedRecipe) {
      const {
        recipeYield,
        recipeWaste,
        ingredients,
        subRecipes,
        totalCost: recipeTotalCost,
      } = this.state
      selectedRecipe = {
        ingredients,
        subRecipes,
        recipeYield,
        recipeWaste,
        recipeTotalCost,
      }
    }
    return selectedRecipe
  }

  updateRecipeNameInBreadcrumbs = ([_recipe, ...rest], value) => [
    { name: value, path: '' },
    ...rest,
  ]

  handleChange = path => value => {
    this.setState(prevState => {
      const updatedState = dotProp.set(prevState, path, value)
      return this.updateRecipe(
        path === 'name'
          ? dotProp.set(
              updatedState,
              'breadcrumbs',
              this.updateRecipeNameInBreadcrumbs(
                updatedState.breadcrumbs,
                value
              )
            )
          : updatedState
      )
    })
  }

  updateRecipeWithNewInfo = (prevState, type, formattedInfo) => {
    const updatedState = dotProp.set(
      dotProp.set(prevState, `${type}ModalOpen`, false),
      this.state.modalPath,
      [...(dotProp.get(prevState, prevState.modalPath) || []), formattedInfo]
    )
    return this.updateRecipe(updatedState)
  }

  isFoundRecipePopulated = foundRecipe =>
    (foundRecipe.ingredients &&
      foundRecipe.ingredients.length > 0 &&
      foundRecipe.ingredients[0].ingredient.code) ||
    (foundRecipe.recipesInfo &&
      foundRecipe.recipesInfo.length > 0 &&
      foundRecipe.recipesInfo[0].recipe.code)

  handleAdd = value => type => quantity => {
    const { ingredients, allRecipes } = this.props
    if (!value)
      this.setState(prevState => ({
        ...prevState,
        ingredientsModalOpen: false,
        recipesModalOpen: false,
      }))
    else if (type === 'ingredients') {
      this.setState(prevState =>
        this.updateRecipeWithNewInfo(
          prevState,
          type,
          formatIngredient(
            ingredients.data.find(ingredient => ingredient.code === value),
            quantity || 0
          )
        )
      )
    } else {
      const foundRecipe =
        allRecipes.data && allRecipes.data.find(recipe => recipe.code === value)
      if (this.isFoundRecipePopulated(foundRecipe)) {
        this.setState(prevState =>
          this.updateRecipeWithNewInfo(
            prevState,
            type,
            formatAddedRecipe(
              allRecipes &&
                allRecipes.data &&
                allRecipes.data.find(recipe => recipe.code === value),
              quantity || 0
            )
          )
        )
      } else {
        this.props.fetchRecipeToAdd(foundRecipe.code, quantity)
      }
    }
  }

  handleClose = () => {
    this.setState(prevState => ({
      ...prevState,
      ingredientsModalOpen: false,
      recipesModalOpen: false,
    }))
  }

  handleRemove = path => {
    this.setState(prevState => {
      const updatedState = dotProp.delete(prevState, path)
      return this.updateRecipe(updatedState)
    })
  }

  getBreadcrumbs = (path, state) => {
    const breadcrumbs = state.breadcrumbs.slice(0, 1)
    const pathArray = path.split('.')
    for (let i = 2; i <= pathArray.length; i += 2) {
      const currentPath = pathArray.slice(0, i).join('.')
      const currentRecipe = dotProp.get(state, currentPath)
      breadcrumbs.push({ name: currentRecipe.name, path: currentPath })
    }
    return breadcrumbs
  }

  handleRecipeClick = path => {
    this.setState(prevState => {
      const breadcrumbs = this.getBreadcrumbs(path, prevState)
      return {
        ...prevState,
        breadcrumbs,
        path,
      }
    })
  }

  renderInitialPage() {
    return (
      <Options
        handleClickNewRecipe={this.handleClickNewRecipe}
        handleClickChooseRecipe={this.handleClickChooseRecipe}
      />
    )
  }

  renderRecipeInfo() {
    const {
      name,
      recipeYield,
      recipeWaste,
      referenceGrammage,
      portionSize,
      costPerUnit,
      batchSize,
    } = this.state
    return (
      <RecipeInfo
        handleChange={this.handleChange}
        name={name}
        recipeYield={recipeYield}
        recipeWaste={recipeWaste}
        portionSize={portionSize}
        referenceGrammage={referenceGrammage}
        costPerUnit={costPerUnit}
        batchSize={batchSize}
      />
    )
  }

  renderNutritionFactsTable() {
    const { nutritionFacts, referenceGrammage } = this.state
    return (
      <NutritionFacts
        recipe={{
          manuallyCalculated: false,
          nutritionalInfo: nutritionFacts,
          recipeInfo: { referenceGrammage },
        }}
      />
    )
  }

  renderSubRecipesSidenav() {
    const { path } = this.state
    const selectedRecipe = this.getRecipeToBeRendered(path)
    return (
      selectedRecipe.subRecipes &&
      selectedRecipe.subRecipes.length > 0 && (
        <SubRecipesSideNav
          path={path}
          subRecipes={selectedRecipe.subRecipes.map(
            subRecipe => subRecipe.name
          )}
          handleRecipeClick={this.handleRecipeClick}
        />
      )
    )
  }

  renderCosPerUnit() {
    const { costPerUnit } = this.state
    return <CostPerUnit value={costPerUnit} />
  }

  renderCurrentRecipeContainer() {
    const { batchSize, totalCost, path } = this.state
    const selectedRecipe = this.getRecipeToBeRendered(path)
    return (
      <Recipe
        batchSize={batchSize}
        totalCost={totalCost}
        selectedRecipe={selectedRecipe}
        handleChange={this.handleChange}
        handleRemove={this.handleRemove}
        path={this.state.path}
        handleAddModalOpen={this.handleAddModalOpen}
      />
    )
  }
  renderBreadcrumbs() {
    const { breadcrumbs } = this.state
    return (
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        handleBreadcrumbClick={this.handleRecipeClick}
      />
    )
  }

  renderSelectIngredientsModal() {
    const { step } = this.state
    const { ingredients } = this.props
    return (
      <Modal open={this.state.ingredientsModalOpen} onClose={this.handleClose}>
        <SelectModal
          items={
            ingredients &&
            ingredients.data &&
            ingredients.data.map(ingredient => ({
              label: ingredient.recipeName,
              value: ingredient.code,
            }))
          }
          step={step}
          label={selectModalIngredientsDropDownMessage}
          title={selectModalIngredientsTitleMessage}
          handleConfirm={this.handleAdd}
          type="ingredients"
          buttonMessage={selectModalAddButtonMessage}
        />
      </Modal>
    )
  }

  prepareRecipeToSimulate() {
    const { recipeToSimulate } = this.props
    if (Object.keys(recipeToSimulate.data).length > 0) {
      const chosenRecipe = recipeToSimulate.data
      this.setState(() => {
        this.props.toggleDrawerType(COLLAPSED_DRAWER)
        const formattedRecipe = formatRecipe(chosenRecipe, true)
        const {
          name,
          recipeYield,
          recipeWaste,
          portionSize,
          referenceGrammage,
          nutritionFacts,
          ingredients,
          subRecipes,
        } = formattedRecipe
        const resultingWeight = calculateRecipeResultingWeight(
          recipeYield,
          recipeWaste,
          ingredients,
          subRecipes
        )
        const { updatedState, recipeTotalCost } = calculateRecipeCost(
          formattedRecipe,
          resultingWeight.value,
          formattedRecipe,
          '',
          calculateRecipeResultingWeight
        )
        const batchSize = resultingWeight.value / portionSize
        const costPerUnit = recipeTotalCost / batchSize
        return {
          ...updatedState,
          recipesModalOpen: false,
          step: 1,
          name,
          recipeYield,
          recipeWaste,
          portionSize,
          referenceGrammage,
          nutritionFacts,
          totalCost: recipeTotalCost,
          costPerUnit: costPerUnit.toFixed(2),
          batchSize: Math.ceil(batchSize),
          breadcrumbs: [{ name, path: '' }],
        }
      })
    }
  }

  renderSimulationInfo() {
    return (
      <Grid container justify="flex-start" direction="column">
        {this.renderRecipeInfo()}
        <Grid container direction="row" spacing={8}>
          <Grid item xs={3}>
            <StickyGrid
              id="nutrition-facts-and-cost-per-unit-container"
              verticalOffset="90"
              scrollTarget="app-main-content-wrapper"
              container
              direction="column"
            >
              {this.renderCosPerUnit()}
              {this.renderNutritionFactsTable()}
            </StickyGrid>
          </Grid>
          <Grid item xs>
            {this.renderBreadcrumbs()}
            {this.renderCurrentRecipeContainer()}
          </Grid>
          {this.renderSubRecipesSidenav()}
        </Grid>
      </Grid>
    )
  }

  renderSelectRecipesModal() {
    const { step, newRecipe } = this.state
    const { allRecipes, recipeToSimulate } = this.props
    return (
      <Modal open={this.state.recipesModalOpen} onClose={this.handleClose}>
        <SelectModal
          newRecipe={newRecipe}
          step={step}
          items={allRecipes.data.map(recipe => ({
            label: `${recipe.code.split('_')[0]} - ${recipe.name}`,
            value: recipe.code,
          }))}
          label={selectModalRecipesDropDownMessage}
          title={selectModalRecipesTitleMessage}
          handleConfirm={
            step === 0
              ? newRecipe
                ? this.handleConfirmRecipe
                : this.handleSelectRecipe
              : this.handleAdd
          }
          type="recipes"
          isLoading={allRecipes.loading || recipeToSimulate.loading}
          buttonMessage={
            step === 0
              ? selectModalConfirmButtonMessage
              : selectModalAddButtonMessage
          }
        />
      </Modal>
    )
  }

  render() {
    const { allRecipes, ingredients } = this.props
    const { step } = this.state
    if (ingredients.loading) {
      return (
        <Grid container justify="center">
          <CircularProgress size={50} />
        </Grid>
      )
    }
    if (allRecipes.error || ingredients.error) {
      return <ErrorBox />
    }
    if (allRecipes.data && ingredients.data) {
      return (
        <Grid container>
          {step === 0 && this.renderInitialPage()}
          {step === 1 && this.renderSimulationInfo()}
          {this.renderSelectIngredientsModal()}
          {this.renderSelectRecipesModal()}
        </Grid>
      )
    }
  }
}
const mapActionsToProps = {
  fetchRecipes,
  toggleDrawerType,
  fetchIngredients,
  fetchRecipeToSimulate,
  fetchRecipeToAdd,
}

const mapStateToProps = state => ({
  allRecipes: state.recipes.recipeSearchResults,
  ingredients: state.recipes.ingredients,
  recipeToSimulate: state.recipes.recipeToSimulate,
  lastRecipeInfoAdded: state.recipes.lastRecipeInfoAdded,
})

RecipeSimulationContainer.propTypes = {
  allRecipes: PropTypes.object,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  recipeYield: PropTypes.string,
  recipeWaste: PropTypes.string,
  portionSize: PropTypes.string,
  referenceGrammage: PropTypes.string,
  costPerUnit: PropTypes.string,
  batchSize: PropTypes.string,
  nutritionFacts: PropTypes.shape({
    carbs: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    proteins: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    totalFats: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    satFats: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    transFats: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    sodium: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    fibers: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
    kcal: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.string,
      dailyValue: PropTypes.string,
    }),
  }),
  ingredients: PropTypes.arrayOf(PropTypes.object),
  subRecipes: PropTypes.arrayOf(PropTypes.object),
  recipeToSimulate: PropTypes.object.isRequired,
  lastRecipeInfoAdded: PropTypes.object.isRequired,
  toggleDrawerType: PropTypes.func.isRequired,
  fetchRecipes: PropTypes.func.isRequired,
  fetchIngredients: PropTypes.func.isRequired,
  fetchRecipeToSimulate: PropTypes.func.isRequired,
  fetchRecipeToAdd: PropTypes.func.isRequired,
}

RecipeSimulationContainer.defaultProps = {
  allRecipes: [],
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapActionsToProps)
)(RecipeSimulationContainer)
