import { ComponentType } from 'react'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'

interface StyledFormControlLabelProps {
  fontSize?: string
  color?: string
}

export const StyledFormControlLabel: ComponentType<
  FormControlLabelProps & StyledFormControlLabelProps
> = styled(FormControlLabel)<StyledFormControlLabelProps>`
  && {
    span[class*='MuiFormControlLabel-label'] {
      color: ${props =>
        !props.disabled && props.theme.v2.palette.grayScale[80]};
      font-family: ${props => props.theme.v2.typography.fontFamily};
      font-size: ${props => props.fontSize || '14'}px;
    }
    span[class*='MuiCheckbox-checked'] {
      color: ${props => props.color || props.theme.v2.palette.primary.light};
    }
  }
`

export const StyledCheckbox: ComponentType<CheckboxProps> = styled(Checkbox)`
  & {
    color: ${props => props.theme.v2.palette.primary.darker};
  }
`
