import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import Ingredient from './Ingredient'
import TableHeader from '../../../components/TableHeader'
import { ingredientsMessage, emptyIngredients } from '../formattedMessages'

const styles = theme => ({
  root: {
    width: '100%',
  },
  expanded: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
  },
  headerContainer: {
    borderTop: 0,
    backgroundColor: theme.palette.secondary.light,
  },
  headerTitle: {
    color: theme.palette.common.white,
    fontSize: 16,
    opacity: 0.9,
  },
  ingredientsContainer: {
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  emptyIngredients: {
    marginTop: 2 * theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    opacity: 0.5,
    cursor: 'pointer',
    textAlign: 'center',
  },
})

class IngredientsTable extends PureComponent {
  constructor(props) {
    super(props)
  }

  handleAddModalOpen = () => {
    const { path, handleAddModalOpen } = this.props
    handleAddModalOpen(path)('ingredientsModalOpen')
  }

  render() {
    const {
      classes,
      ingredients,
      handleChange,
      path,
      batchSize,
      totalCost,
      handleRemove,
      simulation,
      recipeWeight,
      dirty,
    } = this.props
    return (
      <Grid className={classes.root} elevation={1}>
        <TableHeader
          title={ingredientsMessage}
          addIcon
          handleAddClick={this.handleAddModalOpen}
          classes={{
            headerContainer: classes.headerContainer,
            actionButton: classes.actionButton,
            headerTitle: classes.headerTitle,
          }}
        />
        <Grid className={classes.ingredientsContainer}>
          {ingredients &&
            ingredients.length > 0 &&
            ingredients.map((ingredient, ingredientIndex) => (
              <React.Fragment>
                <Ingredient
                  handleRemove={handleRemove}
                  recipeWeight={recipeWeight}
                  batchSize={batchSize}
                  totalCost={totalCost}
                  key={ingredientIndex}
                  last={ingredientIndex === ingredients.length - 1}
                  ingredient={ingredient}
                  ingredientIndex={ingredientIndex}
                  handleChange={handleChange}
                  path={path}
                  simulation={simulation}
                  dirty={dirty}
                />
                {ingredientIndex !== ingredients.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          {ingredients && ingredients.length === 0 && (
            <Typography
              variant="h6"
              className={classes.emptyIngredients}
              onClick={this.handleAddModalOpen}
            >
              {emptyIngredients}
            </Typography>
          )}
        </Grid>
      </Grid>
    )
  }
}
IngredientsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  handleAddModalOpen: PropTypes.func.isRequired,
  batchSize: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  simulation: PropTypes.bool,
  recipeWeight: PropTypes.number,
  dirty: PropTypes.bool,
}

IngredientsTable.defaultProps = {
  simulation: false,
  recipeWeight: 0,
}

IngredientsTable.defaultProps = {
  simulation: false,
  recipeWeight: 0,
  dirty: false,
}

export default withStyles(styles)(IngredientsTable)
