import * as t from '../actionTypes/index'

const initialState = {
  nutritionalInfos: null,
  suppliers: null,
  allergenics: null,
  nutritionalInfosLoading: false,
  suppliersLoading: false,
  allergenicsLoading: false,
  nextAvailableCodeLoading: false,
  nextAvailableCode: null,
  saving: false,
  saved: false,
  saveError: '',
  feedstock: null,
  feedstockLoading: false,
  feedstockError: null,
  feedstockProduct: {
    nutritionalFacts: {
      referenceGrammage: null,
    },
  },
  feedstockProductLoading: false,
  feedstockProductError: null,
  products: null,
  productsLoading: false,
  productsError: null,
}

export default (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case t.GET_NUTRITIONAL_INFO_REQUEST: {
      return {
        ...state,
        nutritionalInfosLoading: true,
      }
    }
    case t.GET_NUTRITIONAL_INFO_RESPONSE: {
      return {
        ...state,
        nutritionalInfosLoading: false,
        nutritionalInfos: payload.data,
      }
    }
    case t.GET_NUTRITIONAL_INFO_ERROR: {
      return {
        ...state,
        nutritionalInfos: null,
        nutritionalInfosLoading: false,
        error: payload.error,
      }
    }
    case t.GET_SUPPLIERS_REQUEST: {
      return {
        ...state,
        suppliersLoading: true,
      }
    }
    case t.GET_SUPPLIERS_RESPONSE: {
      return {
        ...state,
        suppliersLoading: false,
        suppliers: payload.data,
      }
    }
    case t.GET_SUPPLIERS_ERROR: {
      return {
        ...state,
        suppliersLoading: false,
        error: payload.error,
      }
    }
    case t.GET_ALLERGENICS_REQUEST: {
      return {
        ...state,
        allergenicsLoading: true,
      }
    }
    case t.GET_ALLERGENICS_RESPONSE: {
      return {
        ...state,
        allergenicsLoading: false,
        allergenics: payload.data,
      }
    }
    case t.GET_ALLERGENICS_ERROR: {
      return {
        ...state,
        allergenicsLoading: false,
        error: payload.error,
      }
    }
    case t.SAVE_FEEDSTOCK_REQUEST: {
      return {
        ...state,
        saving: true,
      }
    }
    case t.SAVE_FEEDSTOCK_RESPONSE: {
      return {
        ...state,
        saving: false,
        saved: true,
      }
    }
    case t.SAVE_FEEDSTOCK_ERROR: {
      return {
        ...state,
        saving: false,
        saveError: payload.error,
      }
    }
    case t.GET_NEXT_AVAILABLE_CODE_REQUEST: {
      return {
        ...state,
        nextAvailableCodeLoading: true,
      }
    }
    case t.GET_NEXT_AVAILABLE_CODE_RESPONSE: {
      return {
        ...state,
        nextAvailableCodeLoading: false,
        nextAvailableCode: payload.data,
      }
    }
    case t.GET_NEXT_AVAILABLE_CODE_ERROR: {
      return {
        ...state,
        nextAvailableCodeLoading: false,
        error: payload.error,
      }
    }
    case t.GET_FEEDSTOCK_REQUEST: {
      return {
        ...state,
        feedstockLoading: true,
      }
    }
    case t.GET_FEEDSTOCK_RESPONSE: {
      return {
        ...state,
        feedstockLoading: false,
        feedstock: payload.data,
      }
    }
    case t.GET_FEEDSTOCK_ERROR: {
      return {
        ...state,
        feedstockLoading: false,
        feedstockError: payload.error,
      }
    }
    case t.GET_FEEDSTOCK_PRODUCT_REQUEST: {
      return {
        ...state,
        feedstockProductLoading: true,
      }
    }
    case t.GET_FEEDSTOCK_PRODUCT_RESPONSE: {
      return {
        ...state,
        feedstockProductLoading: false,
        feedstockProduct: payload.data,
      }
    }
    case t.GET_FEEDSTOCK_PRODUCT_ERROR: {
      return {
        ...state,
        feedstockProductLoading: false,
        feedstockProductError: payload.error,
      }
    }
    case t.GET_PRODUCTS_REQUEST: {
      return {
        ...state,
        productsLoading: true,
      }
    }
    case t.GET_PRODUCTS_RESPONSE: {
      return {
        ...state,
        productsLoading: false,
        products: payload.data,
      }
    }
    case t.GET_PRODUCTS_ERROR: {
      return {
        ...state,
        productsLoading: false,
        products: payload.error,
      }
    }
    case t.DELETE_FEEDSTOCK_REQUEST: {
      return {
        ...state,
        deleting: true,
      }
    }
    case t.DELETE_FEEDSTOCK_RESPONSE: {
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    }
    case t.DELETE_FEEDSTOCK_ERROR: {
      return {
        ...state,
        deleting: false,
        deleteError: payload.error,
      }
    }
    case t.CLEAR_FEEDSTOCK_STORE: {
      return initialState
    }
    default:
      return state
  }
}
