import React, { useState } from 'react'

import moment from 'moment'
import { Buffer } from 'buffer'
import { Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import ContainerHeader from '../../../../components/ContainerHeader'
import { useAPI } from '../../../api'
import useDebounce from '../../../hooks/useDebounce'
import { printTags } from '../../messages/formattedMessages'
import {
  PrintSettings,
  TagPreview,
} from '../../components/print-settings/interfaces'
import TagViewer from '../../components/tag-viewer'
import {
  PRODUCT_BATCH_MUTATION,
  PRODUCT_TAG_PREVIEW_MUTATION,
} from './constants'
import { PrintSettingsForm } from '../../components/print-settings'

export function PrintPage() {
  const [fetch] = useAPI()
  const { enqueueSnackbar } = useSnackbar()
  const [previewLoading, setPreviewLoading] = useState<boolean>(false)
  const [tagPreview, setTagPreview] = useState<TagPreview>({
    zpl: null,
    preview: null,
  })

  async function createProductBatch(settings: PrintSettings) {
    return fetch({
      baseUrl: process.env.KITCHEN_SERVICE_URL,
      uri: '/graphql',
      method: 'POST',
      body: {
        query: PRODUCT_BATCH_MUTATION,
        operationName: 'ProductBatch',
        variables: {
          input: {
            quantity: Number(settings.quantity),
            manufacturing: settings.manufacturing.toISOString(),
            recipe: settings.sku,
            expiration: settings.expiration,
          },
        },
      },
    }).catch(err => {
      enqueueSnackbar('Não foi possível gravar o lote do produto.', {
        variant: 'error',
      })
      throw err
    })
  }

  async function sendZplToPrinter() {
    const zplLatin1 = Buffer.from(tagPreview.zpl, 'utf8').toString('latin1')

    return fetch({
      baseUrl: 'http://localhost:8080',
      uri: '/printer/print',
      method: 'POST',
      body: {
        contents: window.btoa(zplLatin1),
        type: 'PRINTER_TAG',
      },
    }).catch(err => {
      enqueueSnackbar('Não foi possível enviar a etiqueta para a impressora.', {
        variant: 'error',
      })
      throw err
    })
  }

  function handlePrintTag(settings: PrintSettings) {
    createProductBatch(settings)
      .then(sendZplToPrinter)
      .catch(() => null)
  }

  function clearTagPreview() {
    setTagPreview({
      zpl: null,
      preview: null,
    })
  }

  const handleChangePrintSettings = useDebounce(
    ([settings]: [settings: PrintSettings]) => {
      clearTagPreview()

      if (!settings.ready) {
        return
      }

      setPreviewLoading(true)

      fetch({
        baseUrl: process.env.KITCHEN_SERVICE_URL,
        uri: '/graphql',
        method: 'POST',
        body: {
          query: PRODUCT_TAG_PREVIEW_MUTATION,
          operationName: 'ProductTagPreview',
          variables: {
            input: {
              allergenics: settings.allergenics,
              copies: settings.quantity,
              ingredients: settings.ingredients,
              manufacturing: settings.manufacturing.toISOString(),
              mayContain: settings.mayContain,
              sku: settings.sku,
              type: settings.storageType,
            },
          },
        },
      })
        .then(response => {
          const preview: TagPreview = response?.data?.productTagPreview
          if (!preview) {
            clearTagPreview()
            return
          }
          setTagPreview(preview)
        })
        .catch(err => {
          enqueueSnackbar(err.message, {
            variant: 'error',
          })
        })
        .finally(() => {
          setPreviewLoading(false)
        })
    },
    500
  )

  return (
    <div className="app-wrapper">
      <ContainerHeader location={location} title={printTags} />
      <Grid container direction="row" spacing={16}>
        <Grid item xs={5}>
          <PrintSettingsForm
            initialValue={{
              allergenics: '',
              expiration: 0,
              ingredients: '',
              manufacturing: moment(new Date()).startOf('day').toDate(),
              mayContain: '',
              name: '',
              quantity: 1,
              ready: false,
              sku: null,
              storageType: null,
            }}
            onChange={handleChangePrintSettings}
            onPrintButtonClick={handlePrintTag}
            printButtonEnabled={!!tagPreview.zpl}
          />
        </Grid>
        <Grid item xs={7}>
          <TagViewer layout={tagPreview.preview} loading={previewLoading} />
        </Grid>
      </Grid>
    </div>
  )
}
