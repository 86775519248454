import React, { FC, useState } from 'react'
import { ImportPlanningsContainer } from '../components/import-plannings-container'
import { PrintPlanningsContainer } from '../components/print-plannings-container'

export interface ProductionPlanningIds {
  firstPlanningId: string
  secondPlanningId: string
}
const SaveProductionPlanningPage: FC = () => {
  const [isSavedProductionPlanning, setSavedProductionPlanning] =
    useState(false)
  const [createdProductionPlanningIds, setCreatedProductionPlanningIds] =
    useState<ProductionPlanningIds>({
      firstPlanningId: '',
      secondPlanningId: '',
    })
  return (
    <div>
      <ImportPlanningsContainer
        onSave={() => setSavedProductionPlanning(true)}
        onCreatePlanning={savedPlanningIds =>
          setCreatedProductionPlanningIds(savedPlanningIds)
        }
      />
      <PrintPlanningsContainer
        disabled={!isSavedProductionPlanning}
        productionPlanning={createdProductionPlanningIds}
      />
    </div>
  )
}
export default SaveProductionPlanningPage
