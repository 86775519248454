import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSnackbar } from 'notistack'
import { useAPI } from '../../../api'
import useDebounce from '../../../hooks/useDebounce'
import { Endpoints } from '../../../api/endpoints'
import { createSnackbarOptions } from '../../../logic/notification'
import {
  Ingredient,
  SearchIngredientsRequest,
  SearchIngredientsResponse,
} from '../../../api/dto/ingredients'
import IngredientsList from '../../components/IngredientsList'
import ContainerHeader from '../../../../../src/components/ContainerHeader'
import IngredientSearch from '../../components/IngredientSearch'

const searchIngredientsDelay = 600

function SearchIngredientsPage(props: RouteComponentProps) {
  const [fetchData] = useAPI()
  const { enqueueSnackbar } = useSnackbar()
  const [ingredients, setIngredients] = useState<Ingredient[]>([])
  const [loading, setLoading] = useState(false)
  const [currentDelay, setCurrentDelay] = useState(0)
  const [searchParams, setSearchParams] = useState<SearchIngredientsRequest>({
    searchParam: '',
    kitchens: [],
    showInactives: false,
  })

  const handleRequestError = useCallback(
    (error: Error) =>
      enqueueSnackbar(error.message, createSnackbarOptions('error')),
    [enqueueSnackbar]
  )

  const handleSearchParamsChange = useCallback(
    (newSearchParams: SearchIngredientsRequest) => {
      setSearchParams(newSearchParams)
      setCurrentDelay(searchIngredientsDelay)
    },
    []
  )

  const listIngredients = useCallback(
    useDebounce(() => {
      setLoading(true)
      const query = new URLSearchParams(searchParams as {})
      fetchData({
        uri: `${Endpoints.SEARCH_INGREDIENTS}?${query.toString()}`,
        method: 'GET',
      })
        .then((data: SearchIngredientsResponse) =>
          setIngredients(data.ingredients)
        )
        .catch(handleRequestError)
        .finally(() => setLoading(false))
    }, currentDelay),
    [fetchData, searchParams, currentDelay]
  )

  useEffect(() => {
    listIngredients()
  }, [listIngredients, searchParams])

  const handleEdit = ({ code }: Ingredient) => {
    props.history.push({ pathname: `/app/ingredients/edit/${code}` })
  }

  return (
    <div className="app-wrapper">
      <ContainerHeader
        location={props.location}
        title={'Buscar ingredientes'}
      />
      <IngredientSearch
        data={searchParams}
        onChange={handleSearchParamsChange}
      />
      <IngredientsList
        ingredients={ingredients}
        loading={loading}
        onEdit={handleEdit}
      />
    </div>
  )
}

export default SearchIngredientsPage
