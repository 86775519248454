import { defineMessages } from 'react-intl'

export default defineMessages({
  recipeName: {
    id: 'app.recipeSearch.recipes.name',
    defaultMessage: 'Nome',
  },
  recipeCode: {
    id: 'app.recipeSearch.recipes.code',
    defaultMessage: 'Código',
  },
  recipeLastUpdate: {
    id: 'app.recipeSearch.recipes.lastUpdate',
    defaultMessage: 'Última Atualização',
  },
  recipeEdit: {
    id: 'app.recipeSearch.recipes.edit',
    defaultMessage: 'Editar',
  },
  recipeHistory: {
    id: 'app.recipeSearch.recipes.history',
    defaultMessage: 'Histórico',
  },
  recipeFieldLabel: {
    id: 'app.recipeSearch.label.recipe',
    defaultMessage: 'Código ou Nome da Receita',
  },
  ingredientFieldLabel: {
    id: 'app.recipeSearch.label.ingredient',
    defaultMessage: 'Código ou Nome do Ingrediente',
  },
  includeInactivesFieldLabel: {
    id: 'app.recipeSearch.label.includeInactives',
    defaultMessage: 'Incluir Receitas Inativas',
  },
  recipeSearchTitle: {
    id: 'app.recipeSearch.title',
    defaultMessage: 'Busca de Receitas',
  },
  versionInfo: {
    id: 'app.recipeSearch.versionInfo',
    defaultMessage: 'Informações da Versão',
  },
})
