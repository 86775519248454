import styled from 'styled-components'
import Input from '../../atoms/input'
import { Lookup } from '../lookup'

export const IconButtonContainer = styled.div`
  && {
    position: absolute;
    right: 0;
    top: 5px;
  }
`

export const IconButtonBorder = styled.div`
  && {
    position: absolute;
    right: 44px;
    top: 6px;
    height: 75%;
    border-left: 1px solid #ccc;
  }
`
export const ActionInputContainer = styled.div`
  && {
    position: relative;
  }
`

export const StyledInput = styled(Input)`
  && {
    width: 100%;
  }
`

export const ActionInputLookup = styled(Lookup)``
