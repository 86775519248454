import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import { ppcpStart } from '../formattedMessages'
import PpcpInputContainer from './containers/PpcpInputContainer'

class PpcpInput extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader location={this.props.location} title={ppcpStart} />
        <PpcpInputContainer />
      </div>
    )
  }
}

export default PpcpInput
