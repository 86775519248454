export const PRODUCT_TAG_QUERY = `
  query ProductTag($sku: String!) {
    productTag(sku: $sku) {
      title
      sku
      expiration
      weight
      storage {
        type
      }
      nutritionalTable {
        allergenics
        ingredients
        mayContain
        referenceGrammage
      }
    }
  }
`
