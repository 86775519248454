import React from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import {
  DeleteOutlined as DeleteIcon,
  Add as AddIcon,
} from '@material-ui/icons'

export const StyledDeleteButton = styled(props => (
  <IconButton {...props}>
    <DeleteIcon />
  </IconButton>
))``

export const StyledAddButton = styled(props => (
  <IconButton {...props}>
    <AddIcon />
  </IconButton>
))``

export const WrapIcons = styled(props => (
  <Grid style={{ paddingTop: 12 }} {...props} />
))``

export const StyledGrid = styled(props => (
  <Grid
    classes={{
      item: 'item',
      'spacing-xs-16': 'spacing16',
      'spacing-xs-24': 'spacing24',
    }}
    {...props}
  />
))`
  & {
    padding-left: ${props => `${props.theme.spacing.unit * 2}px`};
    padding-right: ${props => `${props.theme.spacing.unit * 2}px`};
  }
`
