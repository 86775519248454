import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from 'components/TextField'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputValidator from 'components/Form/validators/InputValidator'
import { required, positiveNumber } from 'components/Form/validations'
import defineMessages from '../../../Messages'

const styles = () => ({
  textField: {
    height: 11,
    fontSize: '1rem',
  },
  fieldLabel: {
    fontSize: '0.25rem',
  },
})

class ProductionInfoForms extends Component {
  render() {
    const { onChange, selectedItem, isVisualizing, isRegistering } = this.props
    const { errors } = defineMessages
    return (
      <Grid container direction="column">
        <Grid container direction="row" spacing={16} alignItems="flex-start">
          <Grid item xs={3}>
            <InputValidator id="sku" validations={[required(errors.required)]}>
              <TextField
                fullWidth
                label={defineMessages.sku.defaultMessage}
                margin="normal"
                id="sku"
                handleChange={onChange}
                value={selectedItem && selectedItem.sku}
                disabled={!isRegistering}
              />
            </InputValidator>
          </Grid>
          <Grid item xs={3}>
            <InputValidator
              id="minProductionLots"
              validations={[
                required(errors.required),
                positiveNumber(errors.positiveNumber),
              ]}
            >
              <TextField
                isInputNumber
                fullWidth
                label={defineMessages.minLots.defaultMessage}
                margin="normal"
                id="minLot"
                handleChange={onChange}
                value={selectedItem && selectedItem.minLot}
                disabled={isVisualizing}
              />
            </InputValidator>
          </Grid>
          <Grid item xs={3}>
            <InputValidator
              id="maxProductionLots"
              validations={[
                required(errors.required),
                positiveNumber(errors.positiveNumber),
              ]}
            >
              <TextField
                isInputNumber
                fullWidth
                label={defineMessages.maxLots.defaultMessage}
                margin="normal"
                id="maxLot"
                handleChange={onChange}
                value={selectedItem && selectedItem.maxLot}
                disabled={isVisualizing}
              />
            </InputValidator>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={16} alignItems="flex-start">
          <Grid item xs={3}>
            <InputValidator
              id="productionLotSize"
              validations={[
                required(errors.required),
                positiveNumber(errors.positiveNumber),
              ]}
            >
              <TextField
                isInputNumber
                fullWidth
                label={defineMessages.lotSize.defaultMessage}
                margin="normal"
                id="lotSize"
                handleChange={onChange}
                value={selectedItem && selectedItem.lotSize}
                disabled={isVisualizing}
              />
            </InputValidator>
          </Grid>
          <Grid item xs={3}>
            <InputValidator
              id="minInventory"
              validations={[
                required(errors.required),
                positiveNumber(errors.positiveNumber),
              ]}
            >
              <TextField
                isInputNumber
                fullWidth
                label={defineMessages.minInventory.defaultMessage}
                margin="normal"
                id="minInventory"
                handleChange={onChange}
                value={selectedItem && selectedItem.minInventory}
                disabled={isVisualizing}
              />
            </InputValidator>
          </Grid>
          <Grid item xs={3}>
            <InputValidator
              id="maxInventory"
              validations={[
                required(errors.required),
                positiveNumber(errors.positiveNumber),
              ]}
            >
              <TextField
                isInputNumber
                fullWidth
                label={defineMessages.maxInventory.defaultMessage}
                margin="normal"
                id="maxInventory"
                handleChange={onChange}
                value={selectedItem && selectedItem.maxInventory}
                disabled={isVisualizing}
              />
            </InputValidator>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ProductionInfoForms.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(ProductionInfoForms)
