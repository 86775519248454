import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import ChipInput from 'material-ui-chip-input'
import styles from './styles'

class ChipList extends Component {
  handleAdd = value => {
    const { id, chips, onChange } = this.props
    const currentChips = new Set(chips)
    const newChips = Array.from(currentChips.add(value))
    if (onChange) {
      onChange({ target: { id, value: newChips } })
    }
    return { chips: newChips }
  }

  handleDelete = value => {
    const { id, chips, onChange } = this.props
    const currentChips = new Set(chips)
    currentChips.delete(value)
    const newChips = Array.from(currentChips)
    if (onChange) {
      onChange({ target: { id, value: newChips } })
    }
    return { chips: newChips }
  }

  renderChip = (
    {
      value,
      _text,
      _chip,
      _isFocused,
      isDisabled,
      handleClick,
      handleDelete,
      className,
    },
    key
  ) => {
    const { color } = this.props

    return (
      <Chip
        key={key}
        label={value}
        color={color}
        onClick={handleClick}
        disabled={isDisabled}
        className={className}
      />
    )
  }

  render() {
    const { chips, label, classes, isDisabled } = this.props
    return (
      <Grid
        container
        justify="flex-end"
        direction="column"
        className={classes.helperTextAndChipsListContainer}
      >
        <Grid item>
          <FormHelperText>{label}</FormHelperText>
        </Grid>
        <Grid item>
          <ChipInput
            variant="outlined"
            fullWidth
            fullWidthInput
            chipRenderer={this.renderChip}
            value={chips}
            onAdd={this.handleAdd}
            onDelete={this.handleDelete}
            disabled={isDisabled}
            classes={{
              chipContainer: classes.chipsContainer,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

ChipList.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  chips: PropTypes.array.isRequired,
  color: PropTypes.string,
  classes: PropTypes.object,
  onChange: PropTypes.func,
}

ChipList.defaultProps = {
  label: '',
  color: 'default',
  classes: {},
  onChange: null,
}

export default withStyles(styles)(ChipList)
