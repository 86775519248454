import React, { Component } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import RegisterProductionGroupContainer from './containers/RegisterProductionGroup'
import defineMessages from '../Messages'

class RegisterProductionGroup extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ContainerHeader
          location={this.props.location}
          title={defineMessages.title.defaultMessage}
        />
        <RegisterProductionGroupContainer />
      </div>
    )
  }
}

export default RegisterProductionGroup
