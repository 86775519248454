import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined'

import { validatePositiveNumber } from '../../../helperFunctions/validateInput'
import TextField from '../../../components/TextField'
import {
  subRecipeNameMessage,
  subRecipeQuantityMessage,
  subRecipeCostPerUnitMessage,
  subRecipePercentPriceMessage,
} from '../formattedMessages'

import { quantityGramsAdornment } from '../../../components/InputAdornments'

const styles = theme => ({
  subRecipeContainer: {
    borderRadius: `${0.5 * theme.spacing.unit}px`,
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    marginTop: '4px',
  },
  helperTextAndTextFieldContainer: {
    marginTop: '0.375rem',
    height: '100%',
  },
  priceInput: {
    padding: '20px 12px 17px 0',
  },
  inputAdornment: {
    marginBottom: '14px',
  },
  inputAdornmentFontSize: {
    fontSize: 16,
  },
  percentPriceInputAdornment: {
    marginTop: '4px',
  },
  percentPriceInputAdornmentFontSize: {
    fontSize: 18,
  },
})

class SubRecipe extends PureComponent {
  constructor(props) {
    super(props)
    this.priceInputProps = {
      startAdornment: (
        <InputAdornment
          position="start"
          className={props.classes.inputAdornment}
        >
          <div className={props.classes.inputAdornmentFontSize}>R$</div>
        </InputAdornment>
      ),
      classes: {
        input: props.classes.priceInput,
      },
    }
    this.percentPriceInputProps = {
      endAdornment: (
        <InputAdornment
          position="end"
          className={props.classes.percentPriceInputAdornment}
        >
          <div className={props.classes.percentPriceInputAdornmentFontSize}>
            %
          </div>
        </InputAdornment>
      ),
    }
  }

  handleChange = event => {
    const { handleChange } = this.props
    const { id, value } = event.target
    let validatedInput
    if (id.endsWith('name')) {
      validatedInput = value
    } else {
      validatedInput = validatePositiveNumber(value)
    }
    if (validatedInput !== null) {
      handleChange(id)(validatedInput)
    }
  }

  handleRemove = () => {
    const { path, handleRemove, subRecipeIndex } = this.props
    handleRemove(`${path}.${subRecipeIndex}`)
  }

  handleOpenSubRecipe = () => {
    window.open(`/#/app/recipes/edit/${this.props.subRecipe.code}`)
  }

  render() {
    const {
      classes,
      subRecipe,
      subRecipeIndex,
      path,
      batchSize,
      totalCost,
      dirty,
    } = this.props

    const costPerUnit =
      subRecipe.recipeTotalCost && !dirty
        ? subRecipe.recipeTotalCost / batchSize
        : '-'
    const percentCost =
      subRecipe.recipeTotalCost && !dirty
        ? ((costPerUnit * 100) / totalCost).toFixed(2)
        : '-'
    return (
      <Grid
        container
        direction="row"
        spacing={8}
        className={classes.subRecipeContainer}
      >
        <Grid item xs={4}>
          <TextField
            label={subRecipeNameMessage}
            fullWidth
            id={`${path}.${subRecipeIndex}.name`}
            handleChange={this.handleChange}
            value={subRecipe.name}
            readOnly
          />
        </Grid>
        <Grid item xs>
          <TextField
            label={subRecipeQuantityMessage}
            fullWidth
            id={`${path}.${subRecipeIndex}.quantity`}
            value={subRecipe.quantity}
            handleChange={this.handleChange}
            inputProps={quantityGramsAdornment}
          />
        </Grid>
        <Grid item xs>
          <TextField
            label={subRecipeCostPerUnitMessage}
            fullWidth
            id={`${path}.${subRecipeIndex}.costPerUnit`}
            value={
              typeof costPerUnit === 'number'
                ? costPerUnit.toFixed(2)
                : costPerUnit
            }
            readOnly
            inputProps={this.priceInputProps}
          />
        </Grid>
        <Grid item xs>
          <TextField
            label={subRecipePercentPriceMessage}
            fullWidth
            id={`${path}.${subRecipeIndex}.percentPrice`}
            value={percentCost}
            readOnly
            inputProps={this.percentPriceInputProps}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.helperTextAndTextFieldContainer}
            justify="center"
            alignItems="center"
            direction="row"
          >
            <IconButton onClick={this.handleOpenSubRecipe}>
              <OpenInNewOutlined />
            </IconButton>
            <IconButton onClick={this.handleRemove}>
              <DeleteOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
SubRecipe.propTypes = {
  subRecipe: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  subRecipeIndex: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  batchSize: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SubRecipe)
