export const STYLE_DESKTOP = {
  Containers: {
    DefaultStyle: {
      margin: '0% 0% -5% 26%',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      marginTop: '0',
      borderTop: '0',
      color: '#ffffff',
      fontSize: '1em',
      width: '720px',
      minHeight: '86px',
      fontWeight: 'normal',
      transform: 'translateX(-30%)',
      paddingLeft: '10%',
    },
    success: {
      backgroundColor: '#00c3bf',
    },

    error: {
      backgroundColor: '#fa023c',
    },

    warning: {
      backgroundColor: '#ffab00',
    },

    info: {
      backgroundColor: '#7d4294',
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      transform: 'translateY(70%)',
      marginLeft: '15%',
      fontFamily: 'Lato',
    },
  },
  Dismiss: {
    DefaultStyle: {
      marginTop: '4%',
      marginRight: '4%',
      backgroundColor: '#00c3bf',
      fontSize: '30px',
      color: '#ffffff',
      fontWeight: '100',
      fontFamily: 'Open Sans',
    },
    success: {
      backgroundColor: '#00c3bf',
    },

    error: {
      backgroundColor: '#fa023c',
    },

    warning: {
      backgroundColor: '#ffab00',
    },

    info: {
      backgroundColor: '#7d4294',
    },
  },
}

export const STYLE_MOBILE = {
  Containers: {
    DefaultStyle: {
      marginBottom: '10%',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      marginTop: '0',
      borderTop: '0',
      color: '#ffffff',
      fontSize: '1em',
      width: '100%',
      minHeight: '75px',
      fontWeight: 'normal',
      paddingLeft: '10%',
    },
    success: {
      backgroundColor: '#00c3bf',
    },

    error: {
      backgroundColor: '#fa023c',
    },

    warning: {
      backgroundColor: '#ffab00',
    },

    info: {
      backgroundColor: '#7d4294',
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      marginTop: '10%',
      marginLeft: '25%',
      marginRight: '10%',
      fontFamily: 'Lato',
    },
  },
  Dismiss: {
    DefaultStyle: {
      marginTop: '9%',
      marginRight: '3%',
      backgroundColor: '#00c3bf',
      fontSize: '30px',
      color: '#ffffff',
      fontWeight: '100',
      fontFamily: 'Open Sans',
    },
    success: {
      backgroundColor: '#00c3bf',
    },

    error: {
      backgroundColor: '#fa023c',
    },

    warning: {
      backgroundColor: '#ffab00',
    },

    info: {
      backgroundColor: '#7d4294',
    },
  },
}
