import { ComponentType } from 'react'
import styled from 'styled-components'
import Input from '../../atoms/input'
import HeaderfulContainer from '../../molecules/headerfulContainer'
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'

export const TableRow = styled.div`
  display: flex;
`

export const NutrientNamesCellContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  max-width: 50%;
  p {
    color: ${props => props.theme.v2.palette.grayScale[80]};
  }
`

export const NutritionalInfoTableHeader = styled.div`
  flex-basis: 51%;
  max-width: 51%;
  padding-bottom: 6px;
  padding-right: 6px;
  display: flex;
  justify-content: space-between;
`
export const CellContainer = styled.div`
  flex-basis: 25%;
  max-width: 25%;
  padding: 6px;
`

export const StyledInput = styled(Input)`
  && {
    div[class*='InputBase-root-'] {
      height: 40px;
      padding: 0;
    }
    fieldset {
      border-radius: 2px;
    }
    input {
      font-size: 14px;
      padding: 0;
      text-align: center;
    }
    width: 100%;
  }
`
export const NutritionalInfoContainer = styled(HeaderfulContainer)`
  && {
  }
`

export const CircularProgressContainer = styled.div`
  && {
    position: relative;
  }
`

export const StyledCircularProgress: ComponentType<CircularProgressProps> = styled(
  CircularProgress
)`
  && {
    position: absolute;
    top: 170px;
    left: 43%;
  }
`
