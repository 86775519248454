export const NUTRITIONAL_INFO_NAMES = {
  kcal: 'Calorias (kcal)',
  carbs: 'Carboidratos (g)',
  proteins: 'Proteínas (g)',
  totalFats: 'Gorduras totais (g)',
  satFats: 'Gorduras saturadas (g)',
  transFats: 'Gorduras trans (g)',
  fibers: 'Fibras (g)',
  sodium: 'Sódio (mg)',
  totalSugars: 'Açúcares totais',
  addedSugars: 'Açú adicionados',
}
export const REUSED_WASTE_INGREDIENTS = ['IR089']
export const NUTRITIONAL_REFERENCE = {
  kcal: 2000,
  fibers: 25,
  sodium: 2400,
  transFats: 2000,
  satFats: 22,
  totalFats: 55,
  proteins: 75,
  carbs: 300,
}
