import React, { useMemo } from 'react'

import parse from 'html-react-parser'
import { CircularProgress } from '@material-ui/core'

import { Container, LoadingContainer, PrintPreview } from './styles'

export interface TagViewerProps {
  layout: string
  loading?: boolean
}

const TagViewer: React.FC<TagViewerProps> = ({ ...props }) => {
  const imgTag = useMemo(
    () => (props.layout ? parse(props.layout) : null),
    [props.layout]
  )

  function handleClick(e: any) {
    const w = window.open('')
    w.document.write(e.target.outerHTML)
  }

  return (
    <Container title="Visualização da etiquetas">
      {props.loading ? (
        <LoadingContainer>
          <CircularProgress size={30} />
        </LoadingContainer>
      ) : (
        <PrintPreview
          id="tag-preview"
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >
          {imgTag}
        </PrintPreview>
      )}
    </Container>
  )
}

export default TagViewer
